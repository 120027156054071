{
  "$id": "1",
  "Result": [
    {
      "$id": "2",
      "MaskCardNumber": "436915******7128",
      "BankShortName": "Банк Авангард",
      "CardColor": "1E8942",
      "ColorScheme": "ffffff",
      "BankLogo": "https://myc-test.homecredit.ru/rp/api/Images/Resources/Image/banklogo_Avangard.svg"
    },
    {
      "$id": "3",
      "MaskCardNumber": "446915******7028",
      "BankShortName": "Хоум Банк",
      "CardColor": "f53d5b",
      "ColorScheme": "ffffff",
      "BankLogo": "https://myc-test.homecredit.ru/rp/api/Images/Resources/Image/banklogo_HCFB.svg"
    },
    {
      "$id": "4",
      "MaskCardNumber": "************1234"
    }
  ],
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
