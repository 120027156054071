{
  "$id": "1",
  "result": {
    "productFrom": [
      {
        "maskCardNumber": "4469 15** **** 9498",
        "accountNumber": "40817810790012545944",
        "type": 1,
        "availableBalance": 1913.36,
        "currency": "RUR"
      },
      {
        "maskCardNumber": "2200 50** **** 8244",
        "accountNumber": "40817810690012553780",
        "type": 1,
        "availableBalance": 672.0,
        "currency": "RUR"
      },
      {
        "maskCardNumber": null,
        "accountNumber": "40817810790012553774",
        "type": 3,
        "availableBalance": 0.0,
        "currency": "RUR"
      }
    ],
    "productTo": [
      {
        "maskCardNumber": "4469 15** **** 9498",
        "accountNumber": "40817810790012545944",
        "type": 1,
        "availableBalance": 1913.36,
        "currency": "RUR"
      },
      {
        "maskCardNumber": "2200 50** **** 8244",
        "accountNumber": "40817810690012553780",
        "type": 1,
        "availableBalance": 672.0,
        "currency": "RUR"
      },
      {
        "maskCardNumber": null,
        "accountNumber": "40817810790012553774",
        "type": 3,
        "availableBalance": 0.0,
        "currency": "RUR"
      },
      {
        "maskCardNumber": "450726******4933",
        "accountNumber": "40817810993640037870",
        "type": 2,
        "availableBalance": 50000.0,
        "currency": "RUR"
      },
      {
        "maskCardNumber": "406296******5152",
        "accountNumber": "40817810393640037632",
        "type": 2,
        "availableBalance": 11602.17,
        "currency": "RUR"
      },
      {
        "maskCardNumber": "406296******2519",
        "accountNumber": "40817810293640037910",
        "type": 2,
        "availableBalance": 39989.67,
        "currency": "RUR"
      }
    ],
    "links": [
      {
        "rel": "commission",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/7ec961bf-353f-43a0-a28a-6ff04c466d1b/commission"
      },
      {
        "rel": "type",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/7ec961bf-353f-43a0-a28a-6ff04c466d1b/type"
      },
      {
        "rel": "confirm",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/7ec961bf-353f-43a0-a28a-6ff04c466d1b/confirm"
      },
      {
        "rel": "product-list",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/7ec961bf-353f-43a0-a28a-6ff04c466d1b/product-list"
      },
      {
        "rel": "product-list-v2",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v2/self/transfer/7ec961bf-353f-43a0-a28a-6ff04c466d1b/product-list"
      },
      {
        "rel": "exchange-rates",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/7ec961bf-353f-43a0-a28a-6ff04c466d1b/exchange-rates"
      }
    ],
    "resultCode": 0,
    "resultText": null
  },
  "statusCode": 200,
  "errors": [],
  "errorDetails": [],
  "containsAnyError": false,
  "containsAnyWarning": false,
  "containsAnyInfo": false
}
