{
  "Result": {
    "IsFinalStep": false,
    "PaymentHubSession": "eb365e7df1c341dbafdd19b9fbaf743d78163",
    "ProcessPaymentType": 1,
    "Name": "УК Ромашка_А3",
    "Icon": "a3_5090",
    "FeeInfo": "Комиссия составит 1 % от суммы платежа",
    "Fields": [
      {
        "Name": "Лицевой счет:",
        "FieldKey": "pay_a3_PERSONAL_ACCOUNT_1_1",
        "Type": "String",
        "InputRules": {
          "$type": "StringPaymentParameterType",
          "Mask": "",
          "Regexp": ""
        },
        "InputMethods": "Keyboard",
        "IsRequired": true,
        "IsHidden": false,
        "IsReadOnly": false,
        "DefaultValue": "111111",
        "Hint": "Пример: 1234567890",
        "IsRefreshRequired": false,
        "ErrorText": "Вы ввели неправильный номер."
      }
    ]
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
