{
  "provider": [
    {
      "providerId": "sberbank",
      "alias": "70005310024",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "446915******2009",
        "accountNumber": "40817810690012594451"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 0.0,
      "sum": 0.0,
      "fullSum": 0.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    }
  ],
  "links": [
    {
      "rel": "commission",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/visa-phone-transfer/v1/479d062e-fd67-485a-8a26-6d59fcba1226/commission"
    },
    {
      "rel": "transfer",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/visa-phone-transfer/v1/479d062e-fd67-485a-8a26-6d59fcba1226/transfer"
    }
  ]
}
