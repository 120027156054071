import { createMocks } from '@/features/developer/utils/createMocks'
import success from './success.json'
import error from './error.json'

export const mockItems = createMocks([
  {
    title: 'Error',
    status: 200,
    data: error,
    description: '',
  },
  {
    title: 'Success',
    status: 200,
    data: success,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
