import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Button } from '@platform-ui/components/Button'
import React from 'react'
import { ButtonList, Title, TitleContainer } from './styled'

export const Authorization = (): JSX.Element => {
  const context = useAppContext<AppContext>()

  return (
    <>
      <TitleContainer>
        <Title>Экран: Authorization</Title>
      </TitleContainer>
      <ButtonList>
        <Button size="m" onClick={() => context.auth.setCurrentScreen(context.auth.screens.SignIn)}>
          SignIn
        </Button>
        <Button
          size="m"
          onClick={() => context.auth.setCurrentScreen(context.auth.screens.Password)}
        >
          Password
        </Button>
        <Button
          size="m"
          onClick={() => context.auth.setCurrentScreen(context.auth.screens.Passport)}
        >
          Passport
        </Button>
        <Button
          size="m"
          onClick={() => context.auth.setCurrentScreen(context.auth.screens.SMSCode)}
        >
          SMSCode
        </Button>
        <Button
          size="m"
          onClick={() => context.auth.setCurrentScreen(context.auth.screens.PasswordCreate)}
        >
          PasswordCreate
        </Button>
      </ButtonList>
    </>
  )
}
