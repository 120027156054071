{
  "debitCards": [
    {
      "maskCardNumber": "446915XXXXXX6491",
      "mbr": 0,
      "accountNumber": "40817810890011944828",
      "availableBalance": 938.16,
      "cardStatusDisplayed": "Active",
      "currency": "RUR",
      "cardRole": "PRIMARY",
      "expiration": "2025-09-30T00:00:00",
      "isSalaryCard": false,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": true,
      "isDigital": false,
      "isAvailableCardInfo": true,
      "isFamilyCard": false,
      "contractNumber": "7313021215",
      "contractStatus": "Active",
      "guiContractStatus": "NotSet",
      "cardType": "TW",
      "cardSubType": "NotSet",
      "isPPKRequire": false,
      "isPolza": true,
      "isSalaryCardApplicationAvailable": true,
      "hasDebt": false,
      "displayOrder": 1,
      "productName": "Польза",
      "productType": "DebitCard",
      "paymentSystem": "VISA",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "productIdent": "KARTAPOLZA_RUR",
      "detailIsFilled": true,
      "isMfoContractNumber": false,
      "isHomerMigratedCard": false,
      "contractBillingDay": "2020-09-01T00:00:00",
      "isActivationAvailable": false
    }
  ],
  "creditCards": [],
  "accounts": [],
  "deposits": [],
  "mkks": [],
  "serverDate": "2024-05-29T18:43:00.8271752+03:00"
}
