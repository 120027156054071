import { createMocks } from '@/features/developer/utils/createMocks'
import successFile from './success.json'
import successAcsFormFile from './success_acsForm.json'

export const mockItems = createMocks([
  {
    title: 'Успешно',
    status: 200,
    data: successFile,
    description: '',
  },
  {
    title: 'Успешно, откроется форма 3ds-check',
    status: 200,
    data: successAcsFormFile,
    description: '',
  },
  {
    title: 'Код 401',
    status: 401,
    data: null,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
