{
  "orderId": "204006522",
  "maskCardTo": "445433******0644",
  "maskCardFrom": "553691******5369",
  "expMonth": "12",
  "expYear": "29",
  "totalPaymentSum": 10,
  "paymentSum": 10,
  "commissionSum": 0,
  "paymentSystem": "MC",
  "tranDateTime": "2023-05-11T12:27:22",
  "approvalCode": "030026",
  "merchantTranID": "MTY4Mzc5NzIyNjI4NzAwMDAwMDA=",
  "status": "APPROVED",
  "contractNumber": "7027415164",
  "changeType": false
}
