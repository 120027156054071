{
  "provider": [
    {
      "providerId": "hcfb",
      "alias": "79630265501",
      "recipientName": "ЩФЧВЦ Т.",
      "bankName": "Хоум Банк",
      "cardType": "VISA",
      "recipientAddress": "Ижевск",
      "transactionProductFrom": {
        "maskCardNumber": "4454 33** **** 0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "446915******7028",
        "accountNumber": "40817810690011312294"
      },
      "fee": 0.0,
      "sum": 10.0,
      "fullSum": 10.0,
      "currency": "RUR",
      "transferType": 2,
      "limit": {
        "minTransferLimit": 0.01,
        "maxTransferLimit": 600000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sbp",
      "alias": "79630265501",
      "recipientName": null,
      "bankName": null,
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": null,
      "fee": 0.0,
      "sum": 10.0,
      "fullSum": 10.0,
      "currency": "RUR",
      "transferType": 5,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 1000000.0
      },
      "defaultBankId": null,
      "banks": [
        {
          "bankId": "100000000111",
          "bankName": "Сбербанк",
          "sbpAccountExist": "true",
          "duplicate": "true"
        },
        {
          "bankId": "100000000004",
          "bankName": "Тинькофф Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000008",
          "bankName": "Альфа-Банк",
          "sbpAccountExist": "true",
          "duplicate": "false"
        },
        {
          "bankId": "100000000005",
          "bankName": "ВТБ Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000007",
          "bankName": "Райффайзенбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000015",
          "bankName": "ФК Открытие",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000001",
          "bankName": "Газпромбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000012",
          "bankName": "Росбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000010",
          "bankName": "Промсвязьбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "188888888882",
          "bankName": "MKБ Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000118",
          "bankName": "АГРОПРОМКРЕДИТ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000006",
          "bankName": "АК БАРС БАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000200",
          "bankName": "АКБ СЛАВИЯ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000217",
          "bankName": "АКБ ФОРА-БАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000107",
          "bankName": "АКИБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000150",
          "bankName": "АКРОПОЛЬ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000211",
          "bankName": "АЛЕКСАНДРОВСКИЙ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000041",
          "bankName": "АО БКС Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000001",
          "bankName": "АО Бифит",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000125",
          "bankName": "АО Горбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000129",
          "bankName": "АРЕСБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000047",
          "bankName": "Абсолют Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000028",
          "bankName": "Авангард",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000154",
          "bankName": "Аверс",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000130",
          "bankName": "Автоградбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000181",
          "bankName": "Автоторгбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000108",
          "bankName": "Азиатско-Тихоокеанский Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000017",
          "bankName": "АйДи финансовые технологии",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000011",
          "bankName": "АйДиСистемс",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000113",
          "bankName": "Алеф-Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000204",
          "bankName": "Америкэн Экспресс Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000049",
          "bankName": "БАНК ВБРР",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000055",
          "bankName": "БАНК ЙОШКАР-ОЛА",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000124",
          "bankName": "БАНК ОРЕНБУРГ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000029",
          "bankName": "БАНК САНКТ-ПЕТЕРБУРГ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000219",
          "bankName": "БАНК СГБ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000026",
          "bankName": "БАНК Уралсиб",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000040",
          "bankName": "БАНК ФИНАМ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000133",
          "bankName": "ББР Банк (АО)",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000179",
          "bankName": "БМ-БАНK",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "500000000004",
          "bankName": "БПЦ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000232",
          "bankName": "БайкалИнвестБанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000143",
          "bankName": "Банк 131",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000102",
          "bankName": "Банк «Агророс",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000090",
          "bankName": "Банк «Екатеринбург»",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000135",
          "bankName": "Банк Акцепт",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000260",
          "bankName": "Банк БЖФ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000227",
          "bankName": "Банк БКФ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000053",
          "bankName": "Банк Веста",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000257",
          "bankName": "Банк Вологжанин",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000082",
          "bankName": "Банк ДОМ.РФ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000045",
          "bankName": "Банк Зенит",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000236",
          "bankName": "Банк ИПБ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000158",
          "bankName": "Банк ИТУРУП",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000191",
          "bankName": "Банк Казани",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000201",
          "bankName": "Банк Кремлевский",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000052",
          "bankName": "Банк Левобережный",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000192",
          "bankName": "Банк МБА-МОСКВА",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000255",
          "bankName": "Банк ПТБ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000095",
          "bankName": "Банк Россия",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000014",
          "bankName": "Банк Русский Стандарт",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100100000014",
          "bankName": "Банк Русский Стандарт Стриж",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000078",
          "bankName": "Банк СОЮЗ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000126",
          "bankName": "Банк Саратов",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000259",
          "bankName": "Банк Стандарт-Кредит",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000216",
          "bankName": "Банк Финсервис",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000056",
          "bankName": "Банк Хлынов",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000231",
          "bankName": "Банк ЦентроКредит",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000266",
          "bankName": "Банк Элита",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000029",
          "bankName": "Банковские информационные системы",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000225",
          "bankName": "Белгородсоцбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "500000000003",
          "bankName": "Бест2пей",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000092",
          "bankName": "БыстроБанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000215",
          "bankName": "ВУЗ- банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000153",
          "bankName": "Венец",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000112",
          "bankName": "ГАРАНТ-ИНВЕСТ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000149",
          "bankName": "ГУТА-БАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000183",
          "bankName": "Газтрансбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000043",
          "bankName": "Газэнергобанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000037",
          "bankName": "Генбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000083",
          "bankName": "Дальневосточный банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000070",
          "bankName": "Датабанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000057",
          "bankName": "Деньги.Мэйл.Ру",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000235",
          "bankName": "Держава",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000213",
          "bankName": "Джей энд Ти Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000012",
          "bankName": "Диасофт",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000207",
          "bankName": "Дойче банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000167",
          "bankName": "ЕВРОФИНАНС МОСНАРБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "540000000001",
          "bankName": "ЕКассир 4",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "500000000001",
          "bankName": "Екассир",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000258",
          "bankName": "Енисейский объединенный банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000066",
          "bankName": "Земский Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "500000000005",
          "bankName": "Золотая корона",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "188888888885",
          "bankName": "Золотой Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000122",
          "bankName": "ИК Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000002",
          "bankName": "ИНВЕРСИЯ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000199",
          "bankName": "ИШБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000196",
          "bankName": "Инбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000239",
          "bankName": "Индустриальный Сберегательный Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000170",
          "bankName": "Интеза",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000103",
          "bankName": "КБ Пойдём!",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000096",
          "bankName": "КБ Уралфинанс",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000089",
          "bankName": "КОЛЬЦО УРАЛА",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000005",
          "bankName": "КОМПАС ПЛЮС",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000146",
          "bankName": "КОШЕЛЕВ-БАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000064",
          "bankName": "КУБ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000164",
          "bankName": "КЭБ ЭйчЭнБи Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000009",
          "bankName": "Киви Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000027",
          "bankName": "Кредит Европа Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000212",
          "bankName": "Крокус-Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000180",
          "bankName": "Кубаньторгбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000195",
          "bankName": "Кузнецкбизнесбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000161",
          "bankName": "ЛОКО-Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000016",
          "bankName": "Ланит Технологии",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000245",
          "bankName": "Ланта-Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000203",
          "bankName": "МЕЖДУНАРОДНЫЙ ФИНАНСОВЫЙ КЛУБ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000171",
          "bankName": "МОРСКОЙ БАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000234",
          "bankName": "МОСКВА-СИТИ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000025",
          "bankName": "МОСКОВСКИЙ КРЕДИТНЫЙ БАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000176",
          "bankName": "МОСКОМБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000221",
          "bankName": "МОСОБЛБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000229",
          "bankName": "МС Банк Рус",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000246",
          "bankName": "МСП Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000027",
          "bankName": "МСТ Компани",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000017",
          "bankName": "МТС Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000046",
          "bankName": "Металлинвест",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000136",
          "bankName": "Меткомбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000123",
          "bankName": "Моби.Деньги",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000271",
          "bankName": "Мобильная карта",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000110",
          "bankName": "Москоммерцбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000134",
          "bankName": "НБД-Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000115",
          "bankName": "НИКО-БАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000233",
          "bankName": "НК Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000061",
          "bankName": "НКО Монета",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000184",
          "bankName": "НРБанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000071",
          "bankName": "НС Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000074",
          "bankName": "Народный Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000185",
          "bankName": "Нацинвестпромбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000241",
          "bankName": "Национальный расчетный депозитарий",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000243",
          "bankName": "Национальный стандарт",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000177",
          "bankName": "Новикомбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000222",
          "bankName": "Новобанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000067",
          "bankName": "Новый век",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000202",
          "bankName": "Норвик Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000003",
          "bankName": "ОВЛ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000264",
          "bankName": "ОЗОН Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000007",
          "bankName": "ООО ИНФОРМАЦИОННЫЕ СИСТЕМЫ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000022",
          "bankName": "ООО Инвойс",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000018",
          "bankName": "ОТП Банк»",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000182",
          "bankName": "Объединенный капитал",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000033",
          "bankName": "ПАО МИнБ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "110000000111",
          "bankName": "ПАО Сбербанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "1crt88888881",
          "bankName": "ПИР Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000068",
          "bankName": "ПЛАТЕЖИ И РАСЧЕТЫ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000086",
          "bankName": "ПНКО ЭЛПЛАТ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000137",
          "bankName": "Первый Дортрансбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000174",
          "bankName": "Первый Инвестиционный Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000087",
          "bankName": "Петербургский социальный коммерческий банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000016",
          "bankName": "Почта Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000226",
          "bankName": "Приморье",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000228",
          "bankName": "Прио-Внешторгбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000117",
          "bankName": "ПроБанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000187",
          "bankName": "РЕСО Кредит",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000253",
          "bankName": "РН Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000011",
          "bankName": "РНКБ Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000002",
          "bankName": "РНКО Платежный Центр",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000020",
          "bankName": "РОССЕЛЬХОЗБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000194",
          "bankName": "РУСНАРБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000172",
          "bankName": "Развитие-Столица",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000006",
          "bankName": "Райт Лайн",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000032",
          "bankName": "Ренессанс Кредит",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000084",
          "bankName": "РосДорБанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "110000000084",
          "bankName": "РосДорБанк 1",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000098",
          "bankName": "РостФинанс",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000263",
          "bankName": "Роял Кредит Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000165",
          "bankName": "Русьуниверсалбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000147",
          "bankName": "САРОВБИЗНЕСБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000069",
          "bankName": "СДМ-БАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000166",
          "bankName": "СИБСОЦБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000148",
          "bankName": "СИНКО-БАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000003",
          "bankName": "СКБ-банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "130000000003",
          "bankName": "СКБ-банк 3",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000169",
          "bankName": "СКС",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000036",
          "bankName": "СМП Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000223",
          "bankName": "СОЦИУМ-БАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000193",
          "bankName": "СТРОЙЛЕСБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000208",
          "bankName": "Северный Народный Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000250",
          "bankName": "Сетелем Банк ООО",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000128",
          "bankName": "Ситибанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000163",
          "bankName": "Снежинский",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000013",
          "bankName": "Совкомбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000230",
          "bankName": "Солид Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000121",
          "bankName": "Солидарность",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000091",
          "bankName": "Сургутнефтегазбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000189",
          "bankName": "ТАТСОЦБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000031",
          "bankName": "ТЕКО",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000034",
          "bankName": "ТКБ БАНК ПАО",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000065",
          "bankName": "ТОЧКА (филиал ФК ОТКРЫТИЕ)",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000173",
          "bankName": "Таврический",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000175",
          "bankName": "Тендер-Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000144",
          "bankName": "Тимер Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000138",
          "bankName": "Тойота Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000152",
          "bankName": "Тольяттихимбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000197",
          "bankName": "Трансстройбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000031",
          "bankName": "УБРиР",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "1crt88888886",
          "bankName": "УМ Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000142",
          "bankName": "УРАЛПРОМБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000093",
          "bankName": "Углеметбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000151",
          "bankName": "Урал ФДД",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000240",
          "bankName": "ФК Открытие филиал №3 (экс-РГСБ)",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000265",
          "bankName": "ФФИН Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000015",
          "bankName": "ФинСтрим",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000020",
          "bankName": "Финист-софт",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000081",
          "bankName": "Форштадт",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000272",
          "bankName": "Хайс",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000127",
          "bankName": "Хакасский муниципальный банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000059",
          "bankName": "ЦЕНТР-ИНВЕСТ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "110000000059",
          "bankName": "Центр-инвест 1",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "500000000006",
          "bankName": "Цифровой платёж",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000106",
          "bankName": "ЧЕЛИНДБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000094",
          "bankName": "ЧЕЛЯБИНВЕСТБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000139",
          "bankName": "ЭНЕРГОТРАНСБАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000025",
          "bankName": "ЭПАМ Систэмз",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000198",
          "bankName": "Экономбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000044",
          "bankName": "Экспобанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000159",
          "bankName": "Энергобанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000019",
          "bankName": "Эр-Стайл",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000105",
          "bankName": "Эс-Би-Ай Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000160",
          "bankName": "ЮГ-Инвестбанк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000042",
          "bankName": "ЮНИСТРИМ БАНК",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000030",
          "bankName": "Юникредит Банк",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "600000000034",
          "bankName": "Юнион Бизнес Софт",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000022",
          "bankName": "ЯНДЕКС.ДЕНЬГИ",
          "sbpAccountExist": "false",
          "duplicate": "false"
        },
        {
          "bankId": "100000000247",
          "bankName": "банк Раунд",
          "sbpAccountExist": "false",
          "duplicate": "false"
        }
      ]
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    },
    {
      "providerId": "sberbank",
      "alias": "79630265501",
      "recipientName": "Иван И.",
      "bankName": "Сбербанк",
      "cardType": null,
      "recipientAddress": null,
      "transactionProductFrom": {
        "maskCardNumber": "445433******0016",
        "accountNumber": "40817810290011090806"
      },
      "transactionProductTo": {
        "maskCardNumber": "************1234",
        "accountNumber": null
      },
      "fee": 30.0,
      "sum": 10.0,
      "fullSum": 40.0,
      "currency": "RUR",
      "transferType": 6,
      "limit": {
        "minTransferLimit": 10.0,
        "maxTransferLimit": 15000.0
      },
      "defaultBankId": null,
      "banks": null
    }
  ],
  "links": [
    {
      "rel": "commission",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/visa-phone-transfer/v1/70663273-8796-43a5-967f-7908e54b549e/commission"
    },
    {
      "rel": "transfer",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/visa-phone-transfer/v1/70663273-8796-43a5-967f-7908e54b549e/transfer"
    },
    {
      "rel": "prepare",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/visa-phone-transfer/v1/70663273-8796-43a5-967f-7908e54b549e/prepare"
    }
  ]
}
