import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { isBrowser } from '@platform-ui/utils'
import React, { createContext, useEffect, useMemo } from 'react'
import { DeveloperStore } from './DeveloperStore'

export const DeveloperStoreContext = createContext<DeveloperStore>(null)

export const DeveloperStoreProvider: React.FC = ({ children }) => {
  const context = useAppContext<AppContext>()

  const store = useInitializeStore(context)

  useEffect(() => {
    store.init()
  }, [store])

  return <DeveloperStoreContext.Provider value={store}>{children}</DeveloperStoreContext.Provider>
}

let store: DeveloperStore
function useInitializeStore(context: AppContext) {
  const _store =
    store ??
    new DeveloperStore(
      context.apiManager,
      context.eventBus,
      context.httpClient,
      context.toastify,
      context.config,
      context.auth,
      context.cookie
    )

  // For server side rendering always create a new store
  if (!isBrowser) {
    return _store
  }

  // Create the store once in the client
  if (!store) {
    store = _store
  }

  return _store
}
