{
  "resultCode": "Mismatch",
  "resultText": "СМС-код введен неверно. Попробуйте еще раз",
  "resendNum": 3,
  "verifyNum": 1,
  "links": [
    {
      "rel": "resend",
      "href": "{apiUrl}/visa-phone-transfer/v1/{uid}/resend"
    },
    {
      "rel": "check",
      "href": "{apiUrl}/visa-phone-transfer/v1/{uid}/check"
    }
  ]
}
