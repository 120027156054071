{
  "fee": 0,
  "sum": 12,
  "fullSum": 12,
  "currency": "RUR",
  "links": [
    {
      "rel": "transfer",
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/visa-phone-transfer/v1/80ab2cde-c055-45a7-b102-661761a3afee/transfer"
    },
    {
      "rel": "commission",
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/visa-phone-transfer/v1/80ab2cde-c055-45a7-b102-661761a3afee/commission"
    }
  ]
}
