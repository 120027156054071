{
  "StatusCode": 404,
  "Errors": ["Отсутствует запись о проверке данных транзакции"],
  "ErrorDetails": [
    {
      "Title": "Невозможно подтвердить платёж",
      "Error": "Отсутствует запись о проверке данных транзакции",
      "ErrorType": 1
    }
  ]
}
