import styled from '@emotion/styled'
export const Panel = styled.div(({ theme }) => ({
  position: 'absolute',
  width: '36px',
  backgroundColor: '#fff',
  boxShadow: '0 10px 10px #00000012',
  left: 0,
  minHeight: '60px',
  borderRadius: '0 16px 16px 0',
  display: 'flex',
  flexDirection: 'column',

  ['&>*']: {
    marginTop: '8px',

    ['&:first-of-type']: {
      marginTop: 0,
    },
  },

  [`${theme.media.md}`]: {
    left: '4px',
    width: '60px',
    borderRadius: '16px',

    ['&>*']: {
      marginTop: 0,
    },
  },
}))

export const Root = styled.div(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  width: 0,
  display: 'flex',
  alignItems: 'center',
  zIndex: 1111111111,
}))

export const Badge = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px',
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '18px',
}))
