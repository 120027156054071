{
  "$id": "1",
  "StatusCode": 401,
  "Errors": [],
  "ErrorDetails": [
    {
      "Error": "Не удалось подключить категории повышенного кэшбэка",
      "Title": "Ошибка 401 при подключении категорий"
    }
  ],
  "ContainsAnyError": false,
  "ContainsAnyWarning": false,
  "ContainsAnyInfo": false
}
