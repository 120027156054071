{
  "phone": "+7 (***) ***-65-33",
  "smsCheck": false,
  "links": [
    {
      "rel": "check",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/7bfaee8b-ec0f-4d9f-ba7c-58f730c9f23b/check"
    },
    {
      "rel": "resend",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/7bfaee8b-ec0f-4d9f-ba7c-58f730c9f23b/resend"
    },
    {
      "rel": "transfer",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer"
    }
  ]
}
