{
  "resultCode": "REGENERATE",
  "sessionUID": "740e144e-ac68-49dc-80f2-7b89ad9413b3",
  "mobilePhone": "+7 (XXX) XXX-23-16",
  "links": [
    {
      "href": "https://balancer-gateway-ft.omni.homecredit.ru:8112/pin/v3/mcpingenerations/740e144e-ac68-49dc-80f2-7b89ad9413b3/sendsms",
      "rel": "sendsms"
    },
    {
      "href": "https://balancer-gateway-ft.omni.homecredit.ru:8112/pin/v3/mcpingenerations/740e144e-ac68-49dc-80f2-7b89ad9413b3/codeword",
      "rel": "codeword"
    }
  ]
}
