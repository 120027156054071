{
  "acsForm": {
    "formUrl": "https://3ds-ds1.mirconnect.ru:443/sc1/pareq",
    "merchantData": "MjA2MTgxNDM5LS0tOEFDOTg1RTg2MjBCNzI0MDBGNzA2QTUwNzQ0RUNERDE=",
    "pareqMessage": "eJxVUstuwyAQPOcvot5jMNhOHBGktFHVHBxFrS89Urxp3NbYBbuvry9L4io9IO3OwMDMIsqjBdg8gB4sSFGAc+oZpnW1ukpzmqlFWs1ymqazJOGH2UIxNYufWMLjA0/mkFzJidiv7+FdTibiA6yrWyPjiEZMkLH1TAFWH5XpfT0RSr9fb3cy5TTPuCDnFpkG7HYj725ur6elVcYdwApyApE2qoHAChJKxHQ7mN5+yyzxUmODxGDf5LHvO7ckpFPfDZg+OrYNaAtV3Ud2IMV+S+7Bda1xsLet9tZr8xy9uE4QPO1FyMXDxX7A2p3u/aorWZSPye5H811Z8N3Pa1yU689iE9ZKENyBOyvVg2SUcZqxbBrTJcuXfC5IwEMeDT5bxiyi1Mdx6pDo8ML1yCJ5iQT3g7Vg9Gh/7JCCL2/Mm5Z+En81WrqwIW7uzhPRvc+YxQvK6DxjeYxjCdhZqvapevikVYeIBcHTXi+MH6MKX8dX/77UL0HZt5M="
  },
  "_links": {
    "3ds-check": {
      "href": "https://pay-mycredit.homecredit.ru/cascader/v1/payments/31eafa98-6d05-48a3-840f-5d04aade5068/3ds-check"
    },
    "open-check-3ds": {
      "href": "https://pay-mycredit.homecredit.ru/cascader/v1/payments/31eafa98-6d05-48a3-840f-5d04aade5068/open-3ds-check"
    },
    "self": {
      "href": "https://pay-mycredit.homecredit.ru/cascader/v1/payments/31eafa98-6d05-48a3-840f-5d04aade5068/execution"
    }
  }
}
