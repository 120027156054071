{
  "Result": {
    "ExpiresIn": 3599,
    "ClientType": "Base",
    "CurrentLevel": 4,
    "SessionId": "002619be-6600-4530-b7e3-7755eeab839f"
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
