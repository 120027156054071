import { createMocks } from '@/features/developer/utils/createMocks'
import successFile from './success.json'
import successOneProduct from './success-one-product.json'
import resolveError from './resolve-error.json'
import rejected401 from './rejected-401.json'

export const mockItems = createMocks([
  {
    title: 'Успешный ответ',
    status: 200,
    data: successFile,
    description: '',
  },
  {
    title: 'Успешный ответ, доступен один продукт списания',
    status: 200,
    data: successOneProduct,
    description: '',
  },
  {
    title: 'Не нашли доступных продуктов',
    status: 200,
    data: resolveError,
    description: '',
  },
  {
    title: 'Код 401',
    status: 401,
    data: rejected401,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
