{
  "scheduleList": [
    {
      "paymentNum": "1",
      "dueDate": "2023-10-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Paid"
    },
    {
      "paymentNum": "2",
      "dueDate": "2023-11-20T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Paid"
    },
    {
      "paymentNum": "3",
      "dueDate": "2023-12-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Paid"
    },
    {
      "paymentNum": "4",
      "dueDate": "2024-01-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "5",
      "dueDate": "2024-02-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "6",
      "dueDate": "2024-03-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "7",
      "dueDate": "2024-04-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "8",
      "dueDate": "2024-05-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "9",
      "dueDate": "2024-06-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "10",
      "dueDate": "2024-07-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "11",
      "dueDate": "2024-08-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "12",
      "dueDate": "2024-09-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "13",
      "dueDate": "2024-10-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "14",
      "dueDate": "2024-11-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "15",
      "dueDate": "2024-12-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "16",
      "dueDate": "2025-01-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "17",
      "dueDate": "2025-02-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "18",
      "dueDate": "2025-03-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "19",
      "dueDate": "2025-04-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "20",
      "dueDate": "2025-05-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "21",
      "dueDate": "2025-06-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "22",
      "dueDate": "2025-07-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "23",
      "dueDate": "2025-08-19T00:00:00",
      "paymentAmount": 4028.81,
      "paymentStatus": "Future"
    },
    {
      "paymentNum": "24",
      "dueDate": "2025-09-19T00:00:00",
      "paymentAmount": 4028.12,
      "paymentStatus": "Future"
    }
  ],
  "isExpiredData": false
}
