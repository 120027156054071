import { createMocks } from '@/features/developer/utils/createMocks'
import success from './success.json'
import error from './error.json'
import reject441 from './reject-441.json'

export const mockItems = createMocks([
  {
    title: 'Успешно',
    status: 200,
    data: success,
    description: '',
  },
  {
    title: 'Ошибка. Невалидный рефреш токен',
    status: 401,
    data: error,
    description: '',
  },
  {
    title: 'Error (441)',
    status: 441,
    data: reject441,
    description: '',
  },
])
