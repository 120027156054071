{
  "$id": "1",
  "Result": [
    {
      "$id": "2",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Tinkoff.svg",
      "sbpBankId": "100000000004",
      "bankName": "АО \"ТИНЬКОФФ БАНК\""
    },
    {
      "$id": "3",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Sberbank.svg",
      "sbpBankId": "100000000111",
      "bankName": "ПАО СБЕРБАНК"
    },
    {
      "$id": "4",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Alfa.svg",
      "sbpBankId": "100000000008",
      "bankName": "АО \"АЛЬФА-БАНК\""
    },
    {
      "$id": "5",
      "sbpBankLogo": "Images/Resources/Image/banklogo_VTB.svg",
      "sbpBankId": "100000000005",
      "bankName": "ФИЛИАЛ № 7701 БАНКА ВТБ (ПАО)"
    },
    {
      "$id": "6",
      "sbpBankLogo": "Images/Resources/Image/banklogo_RaiffeisenBank_v2.svg",
      "sbpBankId": "100000000007",
      "bankName": "АО \"РАЙФФАЙЗЕНБАНК\""
    },
    {
      "$id": "7",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Otkritie.svg",
      "sbpBankId": "100000000015",
      "bankName": "ПАО БАНК \"ФК ОТКРЫТИЕ\""
    },
    {
      "$id": "8",
      "sbpBankLogo": "Images/Resources/Image/banklogo_GazpromBank.svg",
      "sbpBankId": "100000000001",
      "bankName": "БАНК ГПБ (АО)"
    },
    {
      "$id": "9",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Rosbank_v2.svg",
      "sbpBankId": "100000000012",
      "bankName": "ПАО РОСБАНК"
    },
    {
      "$id": "10",
      "sbpBankLogo": "Images/Resources/Image/banklogo_PSB.svg",
      "sbpBankId": "100000000010",
      "bankName": "ПАО \"ПРОМСВЯЗЬБАНК\""
    },
    {
      "$id": "11",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Sovest.svg",
      "sbpBankId": "100000000009",
      "bankName": "КИВИ Банк (карта Совесть)"
    },
    {
      "$id": "12",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000057",
      "bankName": "VK Pay - РНКО Деньги.Мэйл.Ру"
    },
    {
      "$id": "13",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000259",
      "bankName": "Wildberries (Вайлдберриз Банк)"
    },
    {
      "$id": "14",
      "sbpBankLogo": "Images/Resources/Image/banklogo_BankRossiya_v2.svg",
      "sbpBankId": "100000000095",
      "bankName": "АО \"АБ \"РОССИЯ\""
    },
    {
      "$id": "15",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000258",
      "bankName": "АИКБ Енисейский объединенный банк"
    },
    {
      "$id": "16",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000235",
      "bankName": "АКБ Держава"
    },
    {
      "$id": "17",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000167",
      "bankName": "АКБ ЕВРОФИНАНС МОСНАРБАНК"
    },
    {
      "$id": "18",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000245",
      "bankName": "АКБ Ланта Банк"
    },
    {
      "$id": "19",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000184",
      "bankName": "АКБ НРБанк"
    },
    {
      "$id": "20",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000200",
      "bankName": "АКБ СЛАВИЯ"
    },
    {
      "$id": "21",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000175",
      "bankName": "АКБ Тендер Банк"
    },
    {
      "$id": "22",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Akibank.svg",
      "sbpBankId": "100000000107",
      "bankName": "ПАО \"АКИБАНК\""
    },
    {
      "$id": "23",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Absolut.svg",
      "sbpBankId": "100000000047",
      "bankName": "АКБ \"АБСОЛЮТ БАНК\" (ПАО)"
    },
    {
      "$id": "24",
      "sbpBankLogo": "Images/Resources/Image/banklogo_AutogradBank.svg",
      "sbpBankId": "100000000130",
      "bankName": "Акционерное общество «Автоградбанк»"
    },
    {
      "$id": "25",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000181",
      "bankName": "Автоторгбанк"
    },
    {
      "$id": "26",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000108",
      "bankName": "Азиатско Тихоокеанский Банк"
    },
    {
      "$id": "27",
      "sbpBankLogo": "Images/Resources/Image/banklogo_AkBars.svg",
      "sbpBankId": "100000000006",
      "bankName": "ПАО \"АК БАРС\" БАНК"
    },
    {
      "$id": "28",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Alefbank.svg",
      "sbpBankId": "100000000113",
      "bankName": "АО АКБ \"АЛЕФ-БАНК\""
    },
    {
      "$id": "29",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Almazergien.svg",
      "sbpBankId": "100000000080",
      "bankName": "АКБ \"АЛМАЗЭРГИЭНБАНК\" АО"
    },
    {
      "$id": "30",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000276",
      "bankName": "Алтайкапиталбанк"
    },
    {
      "$id": "31",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000204",
      "bankName": "Америкэн Экспресс Банк"
    },
    {
      "$id": "32",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000234",
      "bankName": "БАНК МОСКВА СИТИ"
    },
    {
      "$id": "33",
      "sbpBankLogo": "Images/Resources/Image/banklogo_BankOrenburg.svg",
      "sbpBankId": "100000000124",
      "bankName": "АО \"БАНК ОРЕНБУРГ\""
    },
    {
      "$id": "34",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000219",
      "bankName": "БАНК СГБ"
    },
    {
      "$id": "35",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000091",
      "bankName": "БАНК СНГБ"
    },
    {
      "$id": "36",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Uralsib.svg",
      "sbpBankId": "100000000026",
      "bankName": "ПАО \"БАНК УРАЛСИБ\""
    },
    {
      "$id": "37",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000133",
      "bankName": "ББР Банк"
    },
    {
      "$id": "38",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000041",
      "bankName": "БКС Банк"
    },
    {
      "$id": "39",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000256",
      "bankName": "Байкалкредобанк"
    },
    {
      "$id": "40",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000143",
      "bankName": "Банк 131"
    },
    {
      "$id": "41",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Avangard.svg",
      "sbpBankId": "100000000028",
      "bankName": "ПАО АКБ \"АВАНГАРД\""
    },
    {
      "$id": "42",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000211",
      "bankName": "Банк АЛЕКСАНДРОВСКИЙ"
    },
    {
      "$id": "43",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000154",
      "bankName": "Банк Аверс"
    },
    {
      "$id": "44",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000102",
      "bankName": "Банк Агророс"
    },
    {
      "$id": "45",
      "sbpBankLogo": "Images/Resources/Image/banklogo_BankAksept.svg",
      "sbpBankId": "100000000135",
      "bankName": "МОСКОВСКИЙ ФИЛИАЛ АО \"БАНК АКЦЕПТ\""
    },
    {
      "$id": "46",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000260",
      "bankName": "Банк БЖФ"
    },
    {
      "$id": "47",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000227",
      "bankName": "Банк БКФ"
    },
    {
      "$id": "48",
      "sbpBankLogo": "Images/Resources/Image/banklogo_VBRR.svg",
      "sbpBankId": "100000000049",
      "bankName": "БАНК \"ВБРР\" (АО)"
    },
    {
      "$id": "49",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Venets.svg",
      "sbpBankId": "100000000153",
      "bankName": "АО БАНК \"ВЕНЕЦ\""
    },
    {
      "$id": "50",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000257",
      "bankName": "Банк Вологжанин"
    },
    {
      "$id": "51",
      "sbpBankLogo": "Images/Resources/Image/banklogo_DomRfBank.svg",
      "sbpBankId": "100000000082",
      "bankName": "АО \"Банк ДОМ.РФ\""
    },
    {
      "$id": "52",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000090",
      "bankName": "Банк Екатеринбург"
    },
    {
      "$id": "53",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Zenit.svg",
      "sbpBankId": "100000000045",
      "bankName": "ПАО БАНК ЗЕНИТ"
    },
    {
      "$id": "54",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000205",
      "bankName": "Банк Заречье"
    },
    {
      "$id": "55",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000236",
      "bankName": "Банк ИПБ"
    },
    {
      "$id": "56",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000158",
      "bankName": "Банк ИТУРУП"
    },
    {
      "$id": "57",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000170",
      "bankName": "Банк Интеза"
    },
    {
      "$id": "58",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000055",
      "bankName": "Банк Йошкар-Ола"
    },
    {
      "$id": "59",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000201",
      "bankName": "Банк Кремлевский"
    },
    {
      "$id": "60",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Levoberezhniy.svg",
      "sbpBankId": "100000000052",
      "bankName": "БАНК \"ЛЕВОБЕРЕЖНЫЙ\" (ПАО)"
    },
    {
      "$id": "61",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000192",
      "bankName": "Банк МБА МОСКВА"
    },
    {
      "$id": "62",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000243",
      "bankName": "Банк Национальный стандарт"
    },
    {
      "$id": "63",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000182",
      "bankName": "Банк Объединенный капитал"
    },
    {
      "$id": "64",
      "sbpBankLogo": "Images/Resources/Image/banklogo_PskbBank.svg",
      "sbpBankId": "100000000087",
      "bankName": "АО БАНК \"ПСКБ\""
    },
    {
      "$id": "65",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000255",
      "bankName": "Банк ПТБ"
    },
    {
      "$id": "66",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000226",
      "bankName": "Банк Приморье"
    },
    {
      "$id": "67",
      "sbpBankLogo": "Images/Resources/Image/banklogo_ResoCredit.svg",
      "sbpBankId": "100000000187",
      "bankName": "БАНК \"РЕСО КРЕДИТ\" (АО)"
    },
    {
      "$id": "68",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Razvitie-Stolica.svg",
      "sbpBankId": "100000000172",
      "bankName": "АО БАНК \"РАЗВИТИЕ-СТОЛИЦА\""
    },
    {
      "$id": "69",
      "sbpBankLogo": "Images/Resources/Image/banklogo_RusStandart.svg",
      "sbpBankId": "100000000014",
      "bankName": "АО \"БАНК РУССКИЙ СТАНДАРТ\""
    },
    {
      "$id": "70",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000278",
      "bankName": "Банк СИАБ"
    },
    {
      "$id": "71",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000078",
      "bankName": "Банк СОЮЗ"
    },
    {
      "$id": "72",
      "sbpBankLogo": "Images/Resources/Image/banklogo_BankSPB_v2.svg",
      "sbpBankId": "100000000029",
      "bankName": "ПАО \"БАНК \"САНКТ-ПЕТЕРБУРГ\""
    },
    {
      "$id": "73",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000126",
      "bankName": "Банк Саратов"
    },
    {
      "$id": "74",
      "sbpBankLogo": "Images/Resources/Image/banklogo_SKB.svg",
      "sbpBankId": "100000000003",
      "bankName": "ПАО \"СКБ-БАНК\""
    },
    {
      "$id": "75",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Snezhinski.svg",
      "sbpBankId": "100000000163",
      "bankName": "БАНК \"СНЕЖИНСКИЙ\" АО"
    },
    {
      "$id": "76",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000096",
      "bankName": "Банк Уралфинанс"
    },
    {
      "$id": "77",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Finambank.svg",
      "sbpBankId": "100000000040",
      "bankName": "АО \"БАНК ФИНАМ\""
    },
    {
      "$id": "78",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000216",
      "bankName": "Банк Финсервис"
    },
    {
      "$id": "79",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000231",
      "bankName": "Банк ЦентроКредит"
    },
    {
      "$id": "80",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000269",
      "bankName": "Банк ЧБРР"
    },
    {
      "$id": "81",
      "sbpBankLogo": "Images/Resources/Image/banklogo_VestaBank.svg",
      "sbpBankId": "100000000053",
      "bankName": "ИНВЕСТИЦИОННЫЙ БАНК \"ВЕСТА\" (ООО)"
    },
    {
      "$id": "82",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000072",
      "bankName": "Братский АНКБ"
    },
    {
      "$id": "83",
      "sbpBankLogo": "Images/Resources/Image/banklogo_BistroBank.svg",
      "sbpBankId": "100000000092",
      "bankName": "МОСКОВСКИЙ ФИЛИАЛ ПАО \"БЫСТРОБАНК\""
    },
    {
      "$id": "84",
      "sbpBankLogo": "Images/Resources/Image/banklogo_VladBiznessBank.svg",
      "sbpBankId": "100000000058",
      "bankName": "АО \"ВЛАДБИЗНЕСБАНК\""
    },
    {
      "$id": "85",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000248",
      "bankName": "ВНЕШФИНБАНК"
    },
    {
      "$id": "86",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000215",
      "bankName": "ВУЗ-банк"
    },
    {
      "$id": "87",
      "sbpBankLogo": "Images/Resources/Image/banklogo_GarantInvest_v2.svg",
      "sbpBankId": "100000000112",
      "bankName": "КБ \"ГАРАНТ-ИНВЕСТ\" (АО)"
    },
    {
      "$id": "88",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000037",
      "bankName": "ГЕНБАНК"
    },
    {
      "$id": "89",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000125",
      "bankName": "ГОРБАНК"
    },
    {
      "$id": "90",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000149",
      "bankName": "ГУТА-БАНК"
    },
    {
      "$id": "91",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000183",
      "bankName": "Газтрансбанк"
    },
    {
      "$id": "92",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Gazenergobank.svg",
      "sbpBankId": "100000000043",
      "bankName": "АО \"ГАЗЭНЕРГОБАНК\""
    },
    {
      "$id": "93",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000083",
      "bankName": "Дальневосточный банк"
    },
    {
      "$id": "94",
      "sbpBankLogo": "Images/Resources/Image/banklogo_DataBank.svg",
      "sbpBankId": "100000000070",
      "bankName": "АО \"Датабанк\""
    },
    {
      "$id": "95",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000213",
      "bankName": "Джей энд Ти Банк (АО)"
    },
    {
      "$id": "96",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000207",
      "bankName": "Дойче банк"
    },
    {
      "$id": "97",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000250",
      "bankName": "Драйв Клик Банк"
    },
    {
      "$id": "98",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000066",
      "bankName": "Земский банк"
    },
    {
      "$id": "99",
      "sbpBankLogo": "Images/Resources/Image/banklogo_RNKOPC.svg",
      "sbpBankId": "100000000002",
      "bankName": "РНКО \"ПЛАТЕЖНЫЙ ЦЕНТР\" (ООО)"
    },
    {
      "$id": "100",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000122",
      "bankName": "ИК Банк"
    },
    {
      "$id": "101",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000239",
      "bankName": "ИС Банк"
    },
    {
      "$id": "102",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000199",
      "bankName": "ИШБАНК"
    },
    {
      "$id": "103",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000196",
      "bankName": "Инбанк"
    },
    {
      "$id": "104",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000098",
      "bankName": "КБ  РостФинанс"
    },
    {
      "$id": "105",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000118",
      "bankName": "КБ АГРОПРОМКРЕДИТ"
    },
    {
      "$id": "106",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Aresbank.svg",
      "sbpBankId": "100000000129",
      "bankName": "ООО КБ \"АРЕСБАНК\""
    },
    {
      "$id": "107",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000270",
      "bankName": "КБ Долинск"
    },
    {
      "$id": "108",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000212",
      "bankName": "КБ Крокус Банк"
    },
    {
      "$id": "109",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000161",
      "bankName": "КБ ЛОКО-Банк"
    },
    {
      "$id": "110",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000099",
      "bankName": "КБ Модульбанк"
    },
    {
      "$id": "111",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000110",
      "bankName": "КБ Москоммерцбанк"
    },
    {
      "$id": "112",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000103",
      "bankName": "КБ Пойдём"
    },
    {
      "$id": "113",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Solidarnost.svg",
      "sbpBankId": "100000000121",
      "bankName": "АО КБ \"СОЛИДАРНОСТЬ\""
    },
    {
      "$id": "114",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000193",
      "bankName": "КБ Стройлесбанк"
    },
    {
      "$id": "115",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Hlynov.svg",
      "sbpBankId": "100000000056",
      "bankName": "АО КБ \"ХЛЫНОВ\""
    },
    {
      "$id": "116",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Energotransbank.svg",
      "sbpBankId": "100000000139",
      "bankName": "КБ \"ЭНЕРГОТРАНСБАНК\" (АО)"
    },
    {
      "$id": "117",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000191",
      "bankName": "КБЭР Банк Казани"
    },
    {
      "$id": "118",
      "sbpBankLogo": "Images/Resources/Image/banklogo_KoshelevBank.svg",
      "sbpBankId": "100000000146",
      "bankName": "АО \"КОШЕЛЕВ-БАНК\""
    },
    {
      "$id": "119",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000164",
      "bankName": "КЭБ БАНК РУС"
    },
    {
      "$id": "120",
      "sbpBankLogo": "Images/Resources/Image/banklogo_CreditEurope.svg",
      "sbpBankId": "100000000027",
      "bankName": "АО \"Кредит Европа Банк (Россия)\""
    },
    {
      "$id": "121",
      "sbpBankLogo": "Images/Resources/Image/banklogo_GazpromBank.svg",
      "sbpBankId": "100000000064",
      "bankName": "БАНК \"КУБ\" (АО)"
    },
    {
      "$id": "122",
      "sbpBankLogo": "Images/Resources/Image/banklogo_KubanCredit.svg",
      "sbpBankId": "100000000050",
      "bankName": "КБ \"КУБАНЬ КРЕДИТ\" ООО"
    },
    {
      "$id": "123",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000180",
      "bankName": "Кубаньторгбанк"
    },
    {
      "$id": "124",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000195",
      "bankName": "Кузнецкбизнесбанк"
    },
    {
      "$id": "125",
      "sbpBankLogo": "Images/Resources/Image/banklogo_MBB.svg",
      "sbpBankId": "100000000140",
      "bankName": "АО \"МБ БАНК\""
    },
    {
      "$id": "126",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000203",
      "bankName": "МЕЖДУНАРОДНЫЙ ФИНАНСОВЫЙ КЛУБ"
    },
    {
      "$id": "127",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000136",
      "bankName": "МЕТКОМБАНК"
    },
    {
      "$id": "128",
      "sbpBankLogo": "Images/Resources/Image/banklogo_MIB.svg",
      "sbpBankId": "100000000033",
      "bankName": "ПАО \"МИНБАНК\""
    },
    {
      "$id": "129",
      "sbpBankLogo": "Images/Resources/Image/banklogo_MKB.svg",
      "sbpBankId": "100000000025",
      "bankName": "ПАО \"МОСКОВСКИЙ КРЕДИТНЫЙ БАНК\""
    },
    {
      "$id": "130",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Moneta.svg",
      "sbpBankId": "100000000061",
      "bankName": "НКО \"МОНЕТА\" (ООО)"
    },
    {
      "$id": "131",
      "sbpBankLogo": "Images/Resources/Image/banklogo_MorskoyBank.svg",
      "sbpBankId": "100000000171",
      "bankName": "МОРСКОЙ БАНК (АО)"
    },
    {
      "$id": "132",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000176",
      "bankName": "МОСКОМБАНК"
    },
    {
      "$id": "133",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000221",
      "bankName": "МОСОБЛБАНК"
    },
    {
      "$id": "134",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000229",
      "bankName": "МС Банк Рус"
    },
    {
      "$id": "135",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000246",
      "bankName": "МСП Банк"
    },
    {
      "$id": "136",
      "sbpBankLogo": "Images/Resources/Image/banklogo_MTS.svg",
      "sbpBankId": "100000000017",
      "bankName": "ПАО \"МТС-БАНК\""
    },
    {
      "$id": "137",
      "sbpBankLogo": "Images/Resources/Image/banklogo_MetallInvest.svg",
      "sbpBankId": "100000000046",
      "bankName": "ГУ БАНКА РОССИИ ПО ЦФО"
    },
    {
      "$id": "138",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000169",
      "bankName": "Мир Привилегий (МП Банк)"
    },
    {
      "$id": "139",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000271",
      "bankName": "Мобильная карта"
    },
    {
      "$id": "140",
      "sbpBankLogo": "Images/Resources/Image/banklogo_NbdBank.svg",
      "sbpBankId": "100000000134",
      "bankName": "ПАО \"НБД-БАНК\""
    },
    {
      "$id": "141",
      "sbpBankLogo": "Images/Resources/Image/banklogo_NikoBank.svg",
      "sbpBankId": "100000000115",
      "bankName": "ПАО \"НИКО-БАНК\""
    },
    {
      "$id": "142",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000233",
      "bankName": "НК Банк"
    },
    {
      "$id": "143",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000123",
      "bankName": "НКО МОБИ.Деньги"
    },
    {
      "$id": "144",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000261",
      "bankName": "НКО Перспектива"
    },
    {
      "$id": "145",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000275",
      "bankName": "НКО ЭЛЕКСИР"
    },
    {
      "$id": "146",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000177",
      "bankName": "НОВИКОМБАНК"
    },
    {
      "$id": "147",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Nokssbank.svg",
      "sbpBankId": "100000000062",
      "bankName": "АО НОКССБАНК"
    },
    {
      "$id": "148",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000071",
      "bankName": "НС Банк"
    },
    {
      "$id": "149",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000185",
      "bankName": "Нацинвестпромбанк"
    },
    {
      "$id": "150",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000241",
      "bankName": "Национальный расчетный депозитарий"
    },
    {
      "$id": "151",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000222",
      "bankName": "Новобанк"
    },
    {
      "$id": "152",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000067",
      "bankName": "Новый век"
    },
    {
      "$id": "153",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000202",
      "bankName": "Норвик Банк"
    },
    {
      "$id": "154",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000018",
      "bankName": "ОТП Банк"
    },
    {
      "$id": "155",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000273",
      "bankName": "Озон (Еком Банк)"
    },
    {
      "$id": "156",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000137",
      "bankName": "Первый Дортрансбанк"
    },
    {
      "$id": "157",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000174",
      "bankName": "Первый Инвестиционный Банк"
    },
    {
      "$id": "158",
      "sbpBankLogo": "Images/Resources/Image/banklogo_PochtaBank_v2.svg",
      "sbpBankId": "100000000016",
      "bankName": "АО \"Почта Банк\""
    },
    {
      "$id": "159",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000228",
      "bankName": "Прио-Внешторгбанк"
    },
    {
      "$id": "160",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000117",
      "bankName": "ПроБанк"
    },
    {
      "$id": "161",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000253",
      "bankName": "РН БАНК"
    },
    {
      "$id": "162",
      "sbpBankLogo": "Images/Resources/Image/banklogo_RNKB.svg",
      "sbpBankId": "100000000011",
      "bankName": "РНКБ БАНК (ПАО)"
    },
    {
      "$id": "163",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000194",
      "bankName": "РУСНАРБАНК"
    },
    {
      "$id": "164",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Baikal.svg",
      "sbpBankId": "100000000232",
      "bankName": "АО \"РЕАЛИСТ БАНК\""
    },
    {
      "$id": "165",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Renesans.svg",
      "sbpBankId": "100000000032",
      "bankName": "КБ \"РЕНЕССАНС КРЕДИТ\" (ООО)"
    },
    {
      "$id": "166",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000084",
      "bankName": "РосДорБанк"
    },
    {
      "$id": "167",
      "sbpBankLogo": "Images/Resources/Image/banklogo_RSKB.svg",
      "sbpBankId": "100000000020",
      "bankName": "АО \"РОССЕЛЬХОЗБАНК\""
    },
    {
      "$id": "168",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000263",
      "bankName": "Роял Кредит Банк"
    },
    {
      "$id": "169",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000165",
      "bankName": "Русьуниверсалбанк"
    },
    {
      "$id": "170",
      "sbpBankLogo": "Images/Resources/Image/banklogo_SDM.svg",
      "sbpBankId": "100000000069",
      "bankName": "СДМ-БАНК\" (ПАО)"
    },
    {
      "$id": "171",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000166",
      "bankName": "СИБСОЦБАНК"
    },
    {
      "$id": "172",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000148",
      "bankName": "СИНКО-БАНК"
    },
    {
      "$id": "173",
      "sbpBankLogo": "Images/Resources/Image/banklogo_PrimSocBank.svg",
      "sbpBankId": "100000000088",
      "bankName": "ПАО СКБ ПРИМОРЬЯ \"ПРИМСОЦБАНК\""
    },
    {
      "$id": "174",
      "sbpBankLogo": "Images/Resources/Image/banklogo_SMP.svg",
      "sbpBankId": "100000000036",
      "bankName": "АО \"СМП БАНК\""
    },
    {
      "$id": "175",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000223",
      "bankName": "СОЦИУМ БАНК"
    },
    {
      "$id": "176",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000208",
      "bankName": "Северный Народный Банк"
    },
    {
      "$id": "177",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000128",
      "bankName": "Ситибанк"
    },
    {
      "$id": "178",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Sovkom.svg",
      "sbpBankId": "100000000013",
      "bankName": "ПАО \"СОВКОМБАНК\""
    },
    {
      "$id": "179",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000230",
      "bankName": "Солид Банк"
    },
    {
      "$id": "180",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000189",
      "bankName": "ТАТСОЦБАНК"
    },
    {
      "$id": "181",
      "sbpBankLogo": "Images/Resources/Image/banklogo_TochkaBank.svg",
      "sbpBankId": "100000000065",
      "bankName": "ТОЧКА ПАО БАНКА \"ФК ОТКРЫТИЕ\""
    },
    {
      "$id": "182",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000034",
      "bankName": "ТРАНСКАПИТАЛБАНК"
    },
    {
      "$id": "183",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000173",
      "bankName": "Таврический Банк"
    },
    {
      "$id": "184",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000144",
      "bankName": "Тимер Банк"
    },
    {
      "$id": "185",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000138",
      "bankName": "Тойота Банк"
    },
    {
      "$id": "186",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000152",
      "bankName": "Тольяттихимбанк"
    },
    {
      "$id": "187",
      "sbpBankLogo": "Images/Resources/Image/banklogo_TPSB.svg",
      "sbpBankId": "100000000206",
      "bankName": "ПАО \"ТОМСКПРОМСТРОЙБАНК\""
    },
    {
      "$id": "188",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000197",
      "bankName": "Трансстройбанк"
    },
    {
      "$id": "189",
      "sbpBankLogo": "Images/Resources/Image/banklogo_UB.svg",
      "sbpBankId": "100000000031",
      "bankName": "ПАО КБ \"УБРИР\""
    },
    {
      "$id": "190",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000225",
      "bankName": "УКБ Белгородсоцбанк"
    },
    {
      "$id": "191",
      "sbpBankLogo": "Images/Resources/Image/banklogo_UralProm.svg",
      "sbpBankId": "100000000142",
      "bankName": "АО \"УРАЛПРОМБАНК\""
    },
    {
      "$id": "192",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Uglemet.svg",
      "sbpBankId": "100000000093",
      "bankName": "АО \"УГЛЕМЕТБАНК\""
    },
    {
      "$id": "193",
      "sbpBankLogo": "Images/Resources/Image/banklogo_UralFD.svg",
      "sbpBankId": "100000000151",
      "bankName": "АО КБ \"Урал ФД\""
    },
    {
      "$id": "194",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000217",
      "bankName": "ФОРА-БАНК"
    },
    {
      "$id": "195",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000265",
      "bankName": "ФФИН Банк"
    },
    {
      "$id": "196",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Forshtadt.svg",
      "sbpBankId": "100000000081",
      "bankName": "АКБ \"ФОРШТАДТ\" (АО)"
    },
    {
      "$id": "197",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000272",
      "bankName": "Хайс"
    },
    {
      "$id": "198",
      "sbpBankLogo": "Images/Resources/Image/banklogo_HMB.svg",
      "sbpBankId": "100000000127",
      "bankName": "ООО \"ХАКАССКИЙ МУНИЦИПАЛЬНЫЙ БАНК\""
    },
    {
      "$id": "199",
      "sbpBankLogo": "Images/Resources/Image/banklogo_CentrInvest.svg",
      "sbpBankId": "100000000059",
      "bankName": "ФИЛИАЛ №10 ПАО КБ \"ЦЕНТР-ИНВЕСТ\""
    },
    {
      "$id": "200",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000106",
      "bankName": "ЧЕЛИНДБАНК"
    },
    {
      "$id": "201",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000094",
      "bankName": "ЧЕЛЯБИНВЕСТБАНК"
    },
    {
      "$id": "202",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Elplat.svg",
      "sbpBankId": "100000000086",
      "bankName": "ООО ПНКО \"ЭЛПЛАТ\""
    },
    {
      "$id": "203",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000198",
      "bankName": "Экономбанк"
    },
    {
      "$id": "204",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Expobank.svg",
      "sbpBankId": "100000000044",
      "bankName": "ООО \"ЭКСПОБАНК\""
    },
    {
      "$id": "205",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000159",
      "bankName": "Энергобанк"
    },
    {
      "$id": "206",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000105",
      "bankName": "Эс-Би-Ай Банк"
    },
    {
      "$id": "207",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000160",
      "bankName": "ЮГ-Инвестбанк"
    },
    {
      "$id": "208",
      "sbpBankLogo": "Images/Resources/Image/banklogo_Yandex_v2.svg",
      "sbpBankId": "100000000022",
      "bankName": "ООО НКО \"ЮМани\""
    },
    {
      "$id": "209",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000042",
      "bankName": "ЮНИСТРИМ БАНК"
    },
    {
      "$id": "210",
      "sbpBankLogo": "Images/Resources/Image/banklogo_UniCredit.svg",
      "sbpBankId": "100000000030",
      "bankName": "АО ЮНИКРЕДИТ БАНК"
    },
    {
      "$id": "211",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000150",
      "bankName": "Яндекс Банк"
    },
    {
      "$id": "212",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000247",
      "bankName": "банк Раунд"
    },
    {
      "$id": "213",
      "sbpBankLogo": "Images/Resources/Image/BanksSbp.png",
      "sbpBankId": "100000000266",
      "bankName": "банк Элита"
    }
  ],
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
