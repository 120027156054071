import success from './success.json'
import successEmpty from './success-empty.json'
import reject403 from './reject-403.json'
import reject401 from './reject-401.json'
import { createMocks } from '@/features/developer/utils/createMocks'

export const mockItems = createMocks([
  {
    title: 'Альфа, Открытие, Дальневосточный',
    status: 200,
    data: success,
    description: '',
  },
  {
    title: 'Нет карт',
    status: 200,
    data: successEmpty,
    description: '',
  },
  {
    title: 'Необходимо заново пройти авторизации',
    status: 401,
    data: reject401,
    description: '',
  },
  {
    title: 'Код 403 - Токен истек',
    status: 403,
    data: reject403,
    description: 'Токен истек',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
