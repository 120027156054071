import { useContext } from 'react'
import { DeveloperStoreContext } from './DeveloperStoreProvider'

export function useDeveloperStore() {
  const context = useContext(DeveloperStoreContext)
  if (context === undefined) {
    throw new Error('useDeveloperStore must be used within DeveloperStoreProvider')
  }

  return context
}
