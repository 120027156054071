{
  "$id": "1",
  "Result": {
    "ContractsInfo": [
      {
        "$id": "3",
        "Amount": 10000,
        "Balance": 11153.6,
        "ContractId": "77715054959",
        "DebtDays": 0,
        "Fee": 0,
        "GuiStatus": 1,
        "AutoCins": false,
        "Interest": 886.38,
        "InterestRate": 19.41,
        "NearestDate": "2024-02-17T00:00:00",
        "NearestPayment": 5718.37,
        "Penalty": 267.22,
        "Period": 10,
        "Principal": 10000,
        "ProductType": 15,
        "StartDate": "2024-01-17T00:00:00",
        "Status": 1,
        "Type": "Заём",
        "Wallet": 10000,
        "RemainingDebt": 30000,
        "EarlyPaymentType": 1,
        "NearestPaymentDetails": {
          "$id": "5",
          "EarlyPayment": 0,
          "MonthlyPayment": 1090.23,
          "OverduePayment": 4523.41,
          "ServicePayment": 0
        },
        "PartnerInfo": {
          "$id": "4",
          "LogoUrl": "https://223104.selcdn.ru/mk_prod/partners/wildberries.png",
          "PartnerName": "Wildberries"
        }
      }
    ],
    "ServerDate": "2021-03-15T00:00:00+03:00"
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
