{
  "$id": "1",
  "Result": {
    "$id": "2",
    "ServerDate": "2021-04-13T00:00:00+03:00",
    "ProductType": "SC",
    "CreditLoan": {
      "TransactionAmount": 123,
      "PostingDateTicks": "12-22-44",
      "Insurance": true,
      "InsuranceType": [
        {
          "InsuranceName": "InsuranceName",
          "InsuranceCode": "123"
        }
      ],
      "FinProtection": true,
      "FinProtectionType": "FZINS",
      "CreditTerm": 12,
      "PaidPaymentNum": 24,
      "LeftPaymentNum": 2,
      "IsOnlineSign": true
    }
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
