{
  "StatusCode": 400,
  "Errors": ["Отсутствует обязательный параметр"],
  "ErrorDetails": [
    {
      "Title": "Не удалось рассчитать комиссию",
      "Error": "Отсутствует обязательный параметр",
      "ErrorType": 1
    }
  ]
}
