import { createMocks } from '@/features/developer/utils/createMocks'
import successFile from './success.json'
import successFee from './success-with-fee.json'

export const mockItems = createMocks([
  {
    title: 'Успешный ответ, без комиссии',
    status: 200,
    data: successFile,
    description: '',
  },
  {
    title: 'Успешный ответ, с комиссией',
    status: 200,
    data: successFee,
    description: '',
  },
  {
    title: 'Код 401',
    status: 401,
    data: null,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
