[
  {
    "isExpiredData": "False",
    "maskCardNumber": "446915XXXXXX6491",
    "availableBalance": 960.16,
    "contractNumber": "7313021215",
    "productName": "Польза",
    "contractStatus": "Active",
    "guiContractStatus": "NotSet",
    "cardStatus": "Active",
    "cardRole": "PRIMARY",
    "isDigital": false,
    "currency": "RUR",
    "accountNumber": "40817810890011944828",
    "cardMbr": 0,
    "paymentSystem": "VISA",
    "expiration": "2025-09-30T00:00:00",
    "isSalaryCard": false,
    "isChildCard": false,
    "isArrestedAccount": false,
    "isRestrictedBalance": false,
    "isResident": true,
    "isAvailableCardInfo": true,
    "isFamilyCard": false,
    "productIdent": "KARTAPOLZA_RUR",
    "productType": "DebitCard",
    "currencyCode": 643,
    "cardType": "TW",
    "cardSubType": "NotSet",
    "isPlasticActivationAvailable": false,
    "isPlasticInDelivery": false,
    "isPPKRequire": false,
    "isPolza": true,
    "isSalaryCardApplicationAvailable": true,
    "hasDebt": false,
    "displayOrder": 1,
    "detailIsFilled": true,
    "isMfoContractNumber": false,
    "isHomerMigratedCard": false,
    "isActivationAvailable": false
  },
  {
    "isExpiredData": "False",
    "maskCardNumber": "446915XXXXXX0321",
    "availableBalance": 4462.15,
    "contractNumber": "7209022855",
    "productName": "Польза",
    "contractStatus": "Active",
    "guiContractStatus": "NotSet",
    "cardStatus": "Active",
    "cardRole": "PRIMARY",
    "isDigital": false,
    "currency": "RUR",
    "accountNumber": "40817810390100081752",
    "cardMbr": 1,
    "paymentSystem": "VISA",
    "expiration": "2026-12-31T00:00:00",
    "isSalaryCard": false,
    "isChildCard": false,
    "isArrestedAccount": false,
    "isRestrictedBalance": false,
    "isResident": true,
    "isAvailableCardInfo": true,
    "isFamilyCard": false,
    "productIdent": "SPACE_RUR",
    "productType": "DebitCard",
    "currencyCode": 643,
    "cardType": "TW",
    "cardSubType": "NotSet",
    "isPlasticActivationAvailable": false,
    "isPlasticInDelivery": false,
    "isPPKRequire": false,
    "isPolza": true,
    "isSalaryCardApplicationAvailable": true,
    "hasDebt": false,
    "displayOrder": 2,
    "detailIsFilled": true,
    "isMfoContractNumber": false,
    "isHomerMigratedCard": false,
    "isActivationAvailable": false
  },
  {
    "isExpiredData": "False",
    "maskCardNumber": "522199XXXXXX3835",
    "availableBalance": 679.6,
    "contractNumber": "7021017738",
    "productName": "Польза",
    "contractStatus": "Active",
    "guiContractStatus": "NotSet",
    "cardStatus": "Active",
    "cardRole": "PRIMARY",
    "isDigital": false,
    "currency": "EUR",
    "accountNumber": "40817978090100000797",
    "cardMbr": 0,
    "paymentSystem": "MASTERCARD",
    "expiration": "2024-09-30T00:00:00",
    "isSalaryCard": false,
    "isChildCard": false,
    "isArrestedAccount": false,
    "isRestrictedBalance": false,
    "isResident": true,
    "isAvailableCardInfo": true,
    "isFamilyCard": false,
    "productIdent": "KEY_EUR",
    "productType": "DebitCard",
    "currencyCode": 978,
    "cardType": "TW",
    "cardSubType": "NotSet",
    "isPlasticActivationAvailable": false,
    "isPlasticInDelivery": false,
    "isPPKRequire": false,
    "isPolza": false,
    "isSalaryCardApplicationAvailable": false,
    "hasDebt": false,
    "displayOrder": 3,
    "isMfoContractNumber": false,
    "isHomerMigratedCard": false,
    "isActivationAvailable": false
  },
  {
    "isExpiredData": "False",
    "maskCardNumber": "445433XXXXXX5550",
    "availableBalance": 381.19,
    "contractNumber": "7011027343",
    "productName": "Польза",
    "contractStatus": "Active",
    "guiContractStatus": "NotSet",
    "cardStatus": "Active",
    "cardRole": "PRIMARY",
    "isDigital": false,
    "currency": "USD",
    "accountNumber": "40817840890100000986",
    "cardMbr": 0,
    "paymentSystem": "VISA",
    "expiration": "2024-09-30T00:00:00",
    "isSalaryCard": false,
    "isChildCard": false,
    "isArrestedAccount": false,
    "isRestrictedBalance": false,
    "isResident": true,
    "isAvailableCardInfo": true,
    "isFamilyCard": false,
    "productIdent": "KEY_USD",
    "productType": "DebitCard",
    "currencyCode": 840,
    "cardType": "TW",
    "cardSubType": "NotSet",
    "isPlasticActivationAvailable": false,
    "isPlasticInDelivery": false,
    "isPPKRequire": false,
    "isPolza": false,
    "isSalaryCardApplicationAvailable": false,
    "hasDebt": false,
    "displayOrder": 4,
    "isMfoContractNumber": false,
    "isHomerMigratedCard": false,
    "isActivationAvailable": false
  },
  {
    "isExpiredData": "False",
    "maskCardNumber": "522199XXXXXX7656",
    "availableBalance": 290.42,
    "contractNumber": "7005827784",
    "productName": "Польза ЗП",
    "contractStatus": "Active",
    "guiContractStatus": "NotSet",
    "cardStatus": "Active",
    "cardRole": "PRIMARY",
    "isDigital": false,
    "currency": "RUR",
    "accountNumber": "40817810190010108048",
    "cardMbr": 0,
    "paymentSystem": "MASTERCARD",
    "expiration": "2022-07-31T00:00:00",
    "isSalaryCard": true,
    "isChildCard": false,
    "isArrestedAccount": false,
    "isRestrictedBalance": false,
    "isResident": true,
    "isAvailableCardInfo": true,
    "isFamilyCard": false,
    "productIdent": "KARTAMIRZARPLATNYY_01_RUR",
    "productType": "DebitCard",
    "currencyCode": 643,
    "cardType": "TW",
    "cardSubType": "NotSet",
    "isPlasticActivationAvailable": false,
    "isPlasticInDelivery": false,
    "isPPKRequire": false,
    "isPolza": true,
    "isSalaryCardApplicationAvailable": true,
    "hasDebt": false,
    "displayOrder": 5,
    "detailIsFilled": true,
    "isMfoContractNumber": false,
    "isHomerMigratedCard": false,
    "isActivationAvailable": false
  },
  {
    "isExpiredData": "False",
    "maskCardNumber": "545762XXXXXX5456",
    "availableBalance": 194104.54,
    "contractNumber": "2206972258",
    "productName": "Польза CB Gold",
    "contractStatus": "Действующий",
    "guiContractStatus": "NotSet",
    "cardStatus": "NotActive",
    "cardRole": "PRIMARY",
    "isDigital": false,
    "currency": "RUR",
    "accountNumber": "40817810650100873710",
    "cardMbr": 0,
    "isSalaryCard": false,
    "isChildCard": false,
    "isArrestedAccount": false,
    "isRestrictedBalance": false,
    "isResident": false,
    "isAvailableCardInfo": false,
    "isFamilyCard": false,
    "productType": "CreditCard",
    "currencyCode": 643,
    "cardType": "Homer",
    "cardSubType": "NotSet",
    "isPlasticActivationAvailable": false,
    "isPlasticInDelivery": false,
    "isPolza": false,
    "isSalaryCardApplicationAvailable": false,
    "displayOrder": 1,
    "isMfoContractNumber": false,
    "isHomerMigratedCard": false,
    "isActivationAvailable": true
  },
  {
    "isExpiredData": "False",
    "maskCardNumber": "446098XXXXXX1177",
    "availableBalance": 194104.54,
    "contractNumber": "2206972258",
    "productName": "Польза CB Gold",
    "contractStatus": "Действующий",
    "guiContractStatus": "NotSet",
    "cardStatus": "Active",
    "cardRole": "PRIMARY",
    "isDigital": false,
    "currency": "RUR",
    "accountNumber": "40817810650100873710",
    "cardMbr": 0,
    "isSalaryCard": false,
    "isChildCard": false,
    "isArrestedAccount": false,
    "isRestrictedBalance": false,
    "isResident": false,
    "isAvailableCardInfo": false,
    "isFamilyCard": false,
    "productType": "CreditCard",
    "currencyCode": 643,
    "cardType": "Homer",
    "cardSubType": "NotSet",
    "isPlasticActivationAvailable": false,
    "isPlasticInDelivery": false,
    "isPolza": false,
    "isSalaryCardApplicationAvailable": false,
    "displayOrder": 2,
    "isMfoContractNumber": false,
    "isHomerMigratedCard": false,
    "isActivationAvailable": false
  },
  {
    "isExpiredData": "False",
    "maskCardNumber": "525933XXXXXX4335",
    "availableBalance": 194104.54,
    "contractNumber": "2206972258",
    "productName": "Польза CB Gold",
    "contractStatus": "Действующий",
    "guiContractStatus": "NotSet",
    "cardStatus": "NotActive",
    "cardRole": "PRIMARY",
    "isDigital": false,
    "currency": "RUR",
    "accountNumber": "40817810650100873710",
    "cardMbr": 0,
    "isSalaryCard": false,
    "isChildCard": false,
    "isArrestedAccount": false,
    "isRestrictedBalance": false,
    "isResident": false,
    "isAvailableCardInfo": false,
    "isFamilyCard": false,
    "productType": "CreditCard",
    "currencyCode": 643,
    "cardType": "Homer",
    "cardSubType": "NotSet",
    "isPlasticActivationAvailable": false,
    "isPlasticInDelivery": false,
    "isPolza": false,
    "isSalaryCardApplicationAvailable": false,
    "displayOrder": 3,
    "isMfoContractNumber": false,
    "isHomerMigratedCard": false,
    "isActivationAvailable": true
  }
]
