{
  "$id": "1",
  "Result": {
    "CreditLinesInfo": [
      {
        "$id": "3",
        "ContractId": "77715054950",
        "Status": 1,
        "PartnerInfo": {
          "$id": "4",
          "PartnerName": "Wildberries",
          "LogoUrl": "https://223104.selcdn.ru/mk_prod/partners/wildberries.png"
        },
        "Type": "Кредитная линия",
        "AvailabilityDate": 8000.0,
        "Limit": "Весенний_WB",
        "Balance": "brainySoftContracts",
        "ClosedDate": 15,
        "NearestPayment": 2500,
        "StartDate": "05-01-2024",
        "NearestDate": "05-02-2024",
        "Wallet": 12,
        "DebtDays": 12,
        "GuiStatus": 1,
        "ProductType": 1
      }
    ],
    "ServerDate": "2024-03-11T00:00:00+03:00"
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
