{
  "offers": [
    {
      "details": {
        "templateId": 1008,
        "channel": "Q5F",
        "channelNumber": 4915,
        "productType": "SC",
        "productSubtype": null,
        "priority": 3,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "3 001231230 000",
          "term": "84",
          "rate": "17.9",
          "monthPayment": "62 970",
          "paymentNum": "84",
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 3,
          "templateId": 1008,
          "title": "Кредит наличными",
          "subtitle": "До 3\u00A0000\u00A0000 ₽",
          "description": "Ставка 17,9%",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/cash04.09.23.jpg",
          "imageUrlWeb": "https://sales-father-images-nginx.homebank.ru/images/webcl1.jpg",
          "backgroundColor": "#FFCF0929",
          "secondBackgroundColor": "#FFFC3B5D",
          "textColor": "#FFFFFFFF",
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_cash_loan_icon.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 11
            },
            {
              "id": 21
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W482330_S01_C0058272753_6792219",
        "clickId": "1641c5b5-49d7-469c-8944-72e030488fc3",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1052,
        "channel": "Q5F",
        "channelNumber": 12132,
        "productType": "GR",
        "productSubtype": null,
        "priority": 8,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "670 000",
          "term": null,
          "rate": "10.9",
          "monthPayment": null,
          "paymentNum": null,
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 203,
          "templateId": 1052,
          "title": "120 дней без % — кредитная карта",
          "subtitle": "до 670\u00A0000 ₽",
          "description": "Бесплатное обслуживание навсегда",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/ccbacks3.png",
          "imageUrlWeb": "",
          "backgroundColor": "#FFFFFFFF",
          "secondBackgroundColor": null,
          "textColor": "#FF2D2D2D",
          "expiredDate": null,
          "buttonText": "Оформить",
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/ccbacks4.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "https://sales-father-images-nginx.homebank.ru/images/ccbacks4.png",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 13
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W484241_S01_C0058272753_6815385",
        "clickId": "3ff435b9-9edf-4656-aeb9-f8a506c77432",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1062,
        "channel": "Q5F",
        "channelNumber": 4915,
        "productType": "SC",
        "productSubtype": null,
        "priority": 1,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "3 000 000",
          "term": "84",
          "rate": "17.9",
          "monthPayment": "62 970",
          "paymentNum": "84",
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 181,
          "templateId": 1062,
          "title": "Кредит наличными",
          "subtitle": "До 3\u00A0000\u00A0000 ₽ - cтавка 17,9%",
          "description": null,
          "imageUrl": "",
          "imageUrlWeb": "",
          "backgroundColor": null,
          "secondBackgroundColor": null,
          "textColor": null,
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_cash_loan_icon.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 13
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W482330_S01_C0058272753_6792002",
        "clickId": "8010f365-7b3a-4232-ac37-04a934251447",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1110,
        "channel": "DCR",
        "channelNumber": 3911,
        "productType": "DC",
        "productSubtype": null,
        "priority": 17,
        "debitCardCode": 1124,
        "productIdentTW": "KARTAPOLZA_RUR",
        "productIdent": "KARTAPOLZA_RUR",
        "productNameTW": null,
        "currency": "RUR",
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": null,
          "term": null,
          "rate": null,
          "monthPayment": null,
          "paymentNum": null,
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 104,
          "templateId": 1110,
          "title": "Дебетовая Польза",
          "subtitle": "Кэшбэк до 15%",
          "description": "В 4 категориях на выбор",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf1.jpg",
          "imageUrlWeb": "https://sales-father-images-nginx.homebank.ru/images/webdc1.jpg",
          "backgroundColor": "#FF024360",
          "secondBackgroundColor": "#FF71D4FF",
          "textColor": "#FFFFFFFF",
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 21
            },
            {
              "id": 11
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W324062_S01_C0058272753_5307159",
        "clickId": "7905414c-543d-4638-a195-a20cc51b9fac",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1112,
        "channel": "DCR",
        "channelNumber": 3911,
        "productType": "DC",
        "productSubtype": null,
        "priority": 19,
        "debitCardCode": 1124,
        "productIdentTW": "KARTAPOLZA_RUR",
        "productIdent": "KARTAPOLZA_RUR",
        "productNameTW": null,
        "currency": "RUR",
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": null,
          "term": null,
          "rate": null,
          "monthPayment": null,
          "paymentNum": null,
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 207,
          "templateId": 1112,
          "title": "Дебетовая карта Польза",
          "subtitle": "Кэшбэк до 30%",
          "description": "В 4 категориях на выбор ",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf3.png",
          "imageUrlWeb": "",
          "backgroundColor": "#FFFFFFFF",
          "secondBackgroundColor": null,
          "textColor": "#FF383B4F",
          "expiredDate": null,
          "buttonText": "Оформить",
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/Polza_icon.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf4.png",
          "viewAllBackgroundColor": "#FFFFFFFF",
          "changeOrderBackgroundColor": "#FFFFFFFF",
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 13
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W324062_S01_C0058272753_4988376",
        "clickId": "a3a9b945-c94f-41b4-9825-6d2f7d05d6a2",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1113,
        "channel": "DCR",
        "channelNumber": 3911,
        "productType": "DC",
        "productSubtype": null,
        "priority": 16,
        "debitCardCode": 1673,
        "productIdentTW": "POLZAZARPLATNIY_RUR",
        "productIdent": "POLZAZARPLATNIY_RUR",
        "productNameTW": null,
        "currency": "RUR",
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": null,
          "term": null,
          "rate": null,
          "monthPayment": null,
          "paymentNum": null,
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 208,
          "templateId": 1113,
          "title": "Зарплатная карта ",
          "subtitle": "Кэшбэк до 30%",
          "description": "Дебетовая карта с кэшбэком и бесплатным обслуживанием",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf3.png",
          "imageUrlWeb": "",
          "backgroundColor": "#FFFFFFFF",
          "secondBackgroundColor": null,
          "textColor": "#FF383B4F",
          "expiredDate": null,
          "buttonText": "Оформить",
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/Polza_icon.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf4.png",
          "viewAllBackgroundColor": "#FFFFFFFF",
          "changeOrderBackgroundColor": "#FFFFFFFF",
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 13
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W286205_S01_C0058272753_4988380",
        "clickId": "8cf20c30-8e22-4809-a8c4-93fe41140790",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1153,
        "channel": "Q5F",
        "channelNumber": 4915,
        "productType": "SC",
        "productSubtype": null,
        "priority": 13,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "3 000 000",
          "term": "84",
          "rate": "17.9",
          "monthPayment": "62 970",
          "paymentNum": "84",
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 221,
          "templateId": 1153,
          "title": "Кредит под 17,9%",
          "subtitle": "До 3\u00A0000\u00A0000 ₽",
          "description": null,
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel2.jpg",
          "imageUrlWeb": "",
          "backgroundColor": null,
          "secondBackgroundColor": null,
          "textColor": "#FFFFFFFF",
          "expiredDate": null,
          "buttonText": "Оформить",
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/icon_kn.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 27
            },
            {
              "id": 26
            },
            {
              "id": 24
            },
            {
              "id": 23
            },
            {
              "id": 10
            },
            {
              "id": 9
            },
            {
              "id": 8
            },
            {
              "id": 7
            },
            {
              "id": 6
            },
            {
              "id": 5
            },
            {
              "id": 4
            },
            {
              "id": 3
            },
            {
              "id": 2
            },
            {
              "id": 25
            },
            {
              "id": 1
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W482330_S01_C0058272753_6792183",
        "clickId": "11bfcc67-0d4f-4ca3-b105-3c9a6b95a271",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1176,
        "channel": "Q5F",
        "channelNumber": 12129,
        "productType": "GR",
        "productSubtype": null,
        "priority": 12,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "670 000",
          "term": null,
          "rate": "10.9",
          "monthPayment": null,
          "paymentNum": null,
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 369,
          "templateId": 1176,
          "title": "120 дней без % — кредитная карта",
          "subtitle": "до 670\u00A0000 ₽",
          "description": "Бесплатное обслуживание навсегда",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/ccbacks3.png",
          "imageUrlWeb": "",
          "backgroundColor": "#FF2D2D2D",
          "secondBackgroundColor": null,
          "textColor": "#FFFFFFFF",
          "expiredDate": null,
          "buttonText": "Оформить",
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/ccbacks4.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "https://sales-father-images-nginx.homebank.ru/images/ccbacks4.png",
          "viewAllBackgroundColor": "#FF2D2D2D",
          "changeOrderBackgroundColor": "#FF2D2D2D",
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 12
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W484241_S01_C0058272753_6815650",
        "clickId": "79ba12c2-7322-4672-b711-7e3faeed2214",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1190,
        "channel": "Q5F",
        "channelNumber": 4915,
        "productType": "SC",
        "productSubtype": null,
        "priority": 4,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "2 980 000",
          "term": "84",
          "rate": "17.9",
          "monthPayment": "62 970",
          "paymentNum": "84",
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 213,
          "templateId": 1190,
          "title": "Кредит наличными",
          "subtitle": "До 2\u00A0980\u00A0000 ₽ ",
          "description": "Низкая ставка",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel2.jpg",
          "imageUrlWeb": "",
          "backgroundColor": null,
          "secondBackgroundColor": null,
          "textColor": "#FFFFFF",
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 19
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W482330_S01_C0058272753_6792025",
        "clickId": "f8b60025-2aae-4149-b64f-17bc822de6bb",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1198,
        "channel": "Q5F",
        "channelNumber": 12132,
        "productType": "SC",
        "productSubtype": null,
        "priority": 9,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "3 000 000",
          "term": "84",
          "rate": "17.9",
          "monthPayment": "62 970",
          "paymentNum": "84",
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 161,
          "templateId": 1198,
          "title": "Кредит под залог авто",
          "subtitle": "Увеличенная сумма до 5 000 000 ₽",
          "description": null,
          "imageUrl": "",
          "imageUrlWeb": "",
          "backgroundColor": null,
          "secondBackgroundColor": null,
          "textColor": null,
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/topcar5.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 13
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W482330_S01_C0058272753_6791999",
        "clickId": "412bd5f7-b08b-4f84-a01b-c930b84147f2",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1222,
        "channel": "Q5F",
        "channelNumber": 12131,
        "productType": "GR",
        "productSubtype": null,
        "priority": 11,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "670 000",
          "term": null,
          "rate": "10.9",
          "monthPayment": null,
          "paymentNum": null,
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 388,
          "templateId": 1222,
          "title": "120 дней без %",
          "subtitle": "До 670\u00A0000 ₽",
          "description": "Бонус 1000 ₽ и бесплатное обслуживание",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/creditcrebbonusftop1.jpg",
          "imageUrlWeb": "",
          "backgroundColor": "#FF8A1196",
          "secondBackgroundColor": null,
          "textColor": "#FFFFFFFF",
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 18
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W484241_S01_C0058272753_6810056",
        "clickId": "92e1d8a8-ccfd-4d40-9fd6-de7acd9559cd",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1227,
        "channel": "Q5F",
        "channelNumber": 4915,
        "productType": "SC",
        "productSubtype": null,
        "priority": 2,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "2 980 000",
          "term": "84",
          "rate": "17.9",
          "monthPayment": "48 180",
          "paymentNum": "84",
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 461,
          "templateId": 1227,
          "title": "Кредит наличными",
          "subtitle": "До 2\u00A0980\u00A0000 ₽ ",
          "description": "Низкая ставка",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel2.jpg",
          "imageUrlWeb": "",
          "backgroundColor": null,
          "secondBackgroundColor": null,
          "textColor": null,
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 29
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W482330_S01_C0058272753_6792061",
        "clickId": "63c95d53-b21e-4f90-9644-6924bd7fc0fd",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1243,
        "channel": "Q5F",
        "channelNumber": 13062,
        "productType": "SC",
        "productSubtype": null,
        "priority": 26,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "3 000 000",
          "term": "84",
          "rate": "17.9",
          "monthPayment": "62 970",
          "paymentNum": "84",
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 542,
          "templateId": 1243,
          "title": "Быстро, бесплатно, онлайн",
          "subtitle": "Узнайте свой лимит по кредиту",
          "description": null,
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/call04.09.23.2.jpg",
          "imageUrlWeb": "https://sales-father-images-nginx.homebank.ru/images/weblimit1.jpg",
          "backgroundColor": "#FF399D49",
          "secondBackgroundColor": "#FF8AF881",
          "textColor": "#FFFFFFFF",
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/call04.09.23.5.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 21
            },
            {
              "id": 11
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W482330_S01_C0058272753_6792077",
        "clickId": "f46890f5-7180-4cd5-9ec5-cdbeb223ff2b",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1244,
        "channel": "Q5F",
        "channelNumber": 13063,
        "productType": "SC",
        "productSubtype": null,
        "priority": 27,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "3 000 000",
          "term": "84",
          "rate": "17.9",
          "monthPayment": "62 970",
          "paymentNum": "84",
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 543,
          "templateId": 1244,
          "title": "Узнайте свой лимит по кредиту",
          "subtitle": "Быстро, бесплатно, онлайн",
          "description": null,
          "imageUrl": "",
          "imageUrlWeb": "",
          "backgroundColor": null,
          "secondBackgroundColor": null,
          "textColor": null,
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/call04.09.23.5.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 13
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W482330_S01_C0058272753_6792113",
        "clickId": "241a1452-9e74-4b22-824b-aecd88252d56",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 1941,
        "channel": "Q5F",
        "channelNumber": 12131,
        "productType": "GR",
        "productSubtype": null,
        "priority": 10,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "670 000",
          "term": null,
          "rate": "10.9",
          "monthPayment": null,
          "paymentNum": null,
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 803,
          "templateId": 1941,
          "title": "Что и требовалось заказать",
          "subtitle": "120 дней без %",
          "description": "До 670\u00A0000 ₽",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/ccbacks1.jpg",
          "imageUrlWeb": "https://sales-father-images-nginx.homebank.ru/images/webcc1.jpg",
          "backgroundColor": "#FF9F43FB",
          "secondBackgroundColor": "#FF4CBCD9",
          "textColor": "#FFFFFFFF",
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 11
            },
            {
              "id": 21
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W484241_S01_C0058272753_6815381",
        "clickId": "fff65526-238d-4f34-954a-11e3732dd511",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 4025,
        "channel": "Q5F",
        "channelNumber": 11515,
        "productType": "RF",
        "productSubtype": null,
        "priority": 6,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "3 000 000",
          "term": "84",
          "rate": "17.9",
          "monthPayment": "62 970",
          "paymentNum": "84",
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 2,
          "templateId": 4025,
          "title": "Рефинансирование кредитов других банков",
          "subtitle": "До 3\u00A0000\u00A0000 ₽",
          "description": "Ставка 17,9%",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/topup04.09.23.jpg",
          "imageUrlWeb": "https://sales-father-images-nginx.homebank.ru/images/webtp1.jpg",
          "backgroundColor": "#FF9E36C2",
          "secondBackgroundColor": "#FFEC80FE",
          "textColor": "#FFFFFFFF",
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/Refinance 2.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 21
            },
            {
              "id": 11
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W482330_S01_C0058272753_6791959",
        "clickId": "ab8d3af9-d8c0-4c09-84ec-c975720faef5",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 4030,
        "channel": "Q5F",
        "channelNumber": 11515,
        "productType": "RF",
        "productSubtype": null,
        "priority": 7,
        "debitCardCode": null,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": "3 000 000",
          "term": "84",
          "rate": "17.9",
          "monthPayment": "62 970",
          "paymentNum": "84",
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 303,
          "templateId": 4030,
          "title": "Рефинансирование кредитов",
          "subtitle": "Других банков: до 3\u00A0000\u00A0000 ₽, 17,9%",
          "description": "Cрок до 84 месяцев",
          "imageUrl": "",
          "imageUrlWeb": "",
          "backgroundColor": null,
          "secondBackgroundColor": null,
          "textColor": null,
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/iconrefin.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 13
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W482330_S01_C0058272753_6792037",
        "clickId": "644df4b3-5d31-456c-8896-44218f1aad5b",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    },
    {
      "details": {
        "templateId": 9010,
        "channel": "DCR",
        "channelNumber": 3911,
        "productType": "DC",
        "productSubtype": "SA",
        "priority": 1,
        "debitCardCode": 1933,
        "productIdentTW": null,
        "productIdent": null,
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": null,
          "term": null,
          "rate": null,
          "monthPayment": null,
          "paymentNum": null,
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": [],
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 1,
          "templateId": 9010,
          "title": "Накопительный счёт",
          "subtitle": "До 15% годовых на остаток",
          "description": null,
          "imageUrl": "",
          "imageUrlWeb": "",
          "backgroundColor": "#FF676767",
          "secondBackgroundColor": "#FFA0A0A0",
          "textColor": "#FFFFFFFF",
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "https://sales-father-images-nginx.homebank.ru/images/acc_icon.png",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 13
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": null
    },
    {
      "details": {
        "templateId": 9020,
        "channel": "DCR",
        "channelNumber": 3911,
        "productType": "DC",
        "productSubtype": "SA",
        "priority": 23,
        "debitCardCode": 1933,
        "productIdentTW": "NAKOPILKA_RUR",
        "productIdent": "NAKOPILKA_RUR",
        "productNameTW": null,
        "currency": null,
        "isContentEnabled": null,
        "paymentSystem": null,
        "additionalParams": null,
        "individualParams": {
          "limit": null,
          "term": null,
          "rate": null,
          "monthPayment": null,
          "paymentNum": null,
          "penaltyRate": null,
          "netAmount": null
        },
        "services": {
          "gift": null,
          "insurance": null,
          "box": null,
          "sms": null,
          "fz_fee": null
        },
        "tlmInfo": {
          "confirmation": "Заявка будет отправлена в банк",
          "buttonText": "Заказать звонок"
        }
      },
      "widgets": [
        {
          "id": 102,
          "templateId": 9020,
          "title": "Накопительный счёт",
          "subtitle": "До 15% годовых",
          "description": "Пополнение и снятие без ограничений",
          "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/nakop04.09.23.3.jpg",
          "imageUrlWeb": "https://sales-father-images-nginx.homebank.ru/images/webnak1.jpg",
          "backgroundColor": "#FF6945FA",
          "secondBackgroundColor": "#FFA06CF3",
          "textColor": "#FFFFFFFF",
          "expiredDate": null,
          "buttonText": null,
          "amount": null,
          "icon": "",
          "buttonColor": null,
          "buttonTextColor": null,
          "descriptionHtml": null,
          "viewAllImageUrl": "",
          "viewAllBackgroundColor": null,
          "changeOrderBackgroundColor": null,
          "toggleSwitches": [],
          "displayScreens": [
            {
              "id": 11
            },
            {
              "id": 21
            }
          ],
          "adaptiveImageUrl": "",
          "widthInPercent": null
        }
      ],
      "offerManagerInfo": {
        "offerId": "W372840_S01_C0058272753_5107442",
        "clickId": "a7ab6336-5dfb-4b29-859a-38689041eb86",
        "sessionId": "47825691-7a36-41c2-a796-07b1d0530625"
      }
    }
  ],
  "deposits": [
    {
      "templateId": 8000,
      "depositWidget": {
        "templateId": "8000",
        "title": "Вклады",
        "subtitle": "До 18,25% годовых",
        "description": null,
        "backgroundColor": "#FFC8C7CC",
        "textColor": "#FFFFFFFF",
        "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/dep04.09.23.jpg",
        "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_deposit_icon.png"
      },
      "displayScreens": [
        {
          "id": 13
        },
        {
          "id": 13
        }
      ],
      "offerManagerInfo": null
    }
  ],
  "marketplaceOffers": [
    {
      "templateId": 2005,
      "priority": 24,
      "widget": {
        "id": 161,
        "title": "Рекомендуйте нас",
        "subtitle": "До 1500 баллов за каждого друга",
        "description": null,
        "url": "https://hcdl.ru/mg",
        "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/mgm1.jpg",
        "androidVersions": null,
        "iosVersions": null,
        "icon": "",
        "backgroundColor": null,
        "textColor": null,
        "linkType": "deeplink",
        "imageWeb": "",
        "secondBackgroundColor": null,
        "adaptiveImageUrl": "",
        "widthInPercent": null
      },
      "displayScreens": [
        {
          "id": 14
        }
      ],
      "offerManagerInfo": {
        "offerId": "W317818_S01_C0058272753_5566858",
        "clickId": "bcf4bec8-b385-443a-b595-0601f36d8052",
        "sessionId": "e14f67c4-5a4a-47eb-ae90-cd37388836c2"
      }
    },
    {
      "templateId": 2002,
      "priority": 18,
      "widget": {
        "id": 121,
        "title": "Одна услуга",
        "subtitle": "Хоум Плюс",
        "description": "Много преимуществ",
        "url": "https://hcdl.ru/hp",
        "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/homeplus02.10.23.1.jpg",
        "androidVersions": null,
        "iosVersions": null,
        "icon": "",
        "backgroundColor": "#FF070E27",
        "textColor": "#FFFFFFFF",
        "linkType": "deeplink",
        "imageWeb": "",
        "secondBackgroundColor": null,
        "adaptiveImageUrl": "",
        "widthInPercent": null
      },
      "displayScreens": [
        {
          "id": 11
        }
      ],
      "offerManagerInfo": {
        "offerId": "W372840_S01_C0058272753_5576939",
        "clickId": "651967cf-cf56-4223-8db9-bda5240e0360",
        "sessionId": "e14f67c4-5a4a-47eb-ae90-cd37388836c2"
      }
    },
    {
      "templateId": 2006,
      "priority": 14,
      "widget": {
        "id": 162,
        "title": "Рекомендуйте нас",
        "subtitle": "До 1500 баллов",
        "description": "За каждого друга",
        "url": "https://hcdl.ru/mg",
        "imageUrl": "https://sales-father-images-nginx.homebank.ru/images/mgm02.10.2023.1.jpg",
        "androidVersions": null,
        "iosVersions": null,
        "icon": "",
        "backgroundColor": null,
        "textColor": null,
        "linkType": "deeplink",
        "imageWeb": "",
        "secondBackgroundColor": null,
        "adaptiveImageUrl": "",
        "widthInPercent": null
      },
      "displayScreens": [
        {
          "id": 11
        }
      ],
      "offerManagerInfo": {
        "offerId": "W317818_S01_C0058272753_6752150",
        "clickId": "0c516dce-d86a-4d34-99e2-593fe567c5d2",
        "sessionId": "e14f67c4-5a4a-47eb-ae90-cd37388836c2"
      }
    }
  ],
  "investments": null,
  "serviceInfo": [
    {
      "name": "investment",
      "title": "Инвестиции",
      "subtitle": "Простые решения инвестиционных задач",
      "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_investment_icon.png"
    },
    {
      "name": "insurance",
      "title": "Страхование",
      "subtitle": "На все случаи жизни",
      "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_insurance_icon.png"
    },
    {
      "name": "credit_bureau",
      "title": "Кредитная история",
      "subtitle": "Проверьте кредитную историю онлайн",
      "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_credit_bureau.png"
    }
  ],
  "mgmOffers": null,
  "rubbles": null
}
