{
  "transferType": "internalSelf",
  "recipientName": "Демид С.",
  "enrolment": "Деньги придут сразу",
  "limit": {
    "minTransferLimit": 0.01,
    "maxTransferLimit": 999999999.99
  },
  "links": [
    {
      "rel": "type",
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/transfer/v1/self/transfer/8697ec73-365b-400f-9e06-053b5aefdbc7/type"
    },
    {
      "rel": "commission",
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/transfer/v1/self/transfer/8697ec73-365b-400f-9e06-053b5aefdbc7/commission"
    },
    {
      "rel": "confirm",
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/transfer/v1/self/transfer/8697ec73-365b-400f-9e06-053b5aefdbc7/confirm"
    }
  ]
}
