{
  "debitCards": [
    {
      "maskCardNumber": "446915XXXXXX6491",
      "mbr": 0,
      "accountNumber": "40817810890011944828",
      "availableBalance": 938.16,
      "cardStatusDisplayed": "Active",
      "currency": "RUR",
      "cardRole": "PRIMARY",
      "expiration": "2025-09-30T00:00:00",
      "isSalaryCard": false,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": true,
      "isDigital": false,
      "isAvailableCardInfo": true,
      "isFamilyCard": false,
      "contractNumber": "7313021215",
      "contractStatus": "Active",
      "guiContractStatus": "NotSet",
      "cardType": "TW",
      "cardSubType": "NotSet",
      "isPPKRequire": false,
      "isPolza": true,
      "isSalaryCardApplicationAvailable": true,
      "hasDebt": false,
      "displayOrder": 1,
      "productName": "Польза",
      "productType": "DebitCard",
      "paymentSystem": "VISA",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "productIdent": "KARTAPOLZA_RUR",
      "detailIsFilled": true,
      "isMfoContractNumber": false,
      "isHomerMigratedCard": false,
      "contractBillingDay": "2020-09-01T00:00:00",
      "isActivationAvailable": false
    },
    {
      "maskCardNumber": "446915XXXXXX0321",
      "mbr": 1,
      "accountNumber": "40817810390100081752",
      "availableBalance": 4464.15,
      "cardStatusDisplayed": "Active",
      "currency": "RUR",
      "cardRole": "PRIMARY",
      "expiration": "2026-12-31T00:00:00",
      "isSalaryCard": false,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": true,
      "isDigital": false,
      "isAvailableCardInfo": true,
      "isFamilyCard": false,
      "contractNumber": "7209022855",
      "contractStatus": "Active",
      "guiContractStatus": "NotSet",
      "cardType": "TW",
      "cardSubType": "NotSet",
      "isPPKRequire": false,
      "isPolza": true,
      "isSalaryCardApplicationAvailable": true,
      "hasDebt": false,
      "displayOrder": 2,
      "productName": "Польза",
      "productType": "DebitCard",
      "paymentSystem": "VISA",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "productIdent": "SPACE_RUR",
      "detailIsFilled": true,
      "isMfoContractNumber": false,
      "isHomerMigratedCard": false,
      "contractBillingDay": "2017-01-05T00:00:00",
      "isActivationAvailable": false
    },
    {
      "maskCardNumber": "522199XXXXXX7656",
      "mbr": 0,
      "accountNumber": "40817810190010108048",
      "availableBalance": 290.42,
      "cardStatusDisplayed": "Active",
      "currency": "RUR",
      "cardRole": "PRIMARY",
      "expiration": "2022-07-31T00:00:00",
      "isSalaryCard": true,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": true,
      "isDigital": false,
      "isAvailableCardInfo": true,
      "isFamilyCard": false,
      "contractNumber": "7005827784",
      "contractStatus": "Active",
      "guiContractStatus": "NotSet",
      "cardType": "TW",
      "cardSubType": "NotSet",
      "isPPKRequire": false,
      "isPolza": true,
      "isSalaryCardApplicationAvailable": true,
      "hasDebt": false,
      "displayOrder": 5,
      "productName": "Польза ЗП",
      "productType": "DebitCard",
      "paymentSystem": "MASTERCARD",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "productIdent": "KARTAMIRZARPLATNYY_01_RUR",
      "detailIsFilled": true,
      "isMfoContractNumber": false,
      "isHomerMigratedCard": false,
      "contractBillingDay": "2011-08-05T00:00:00",
      "isActivationAvailable": false
    },
    {
      "maskCardNumber": "445433XXXXXX5550",
      "mbr": 0,
      "accountNumber": "40817840890100000986",
      "availableBalance": 381.19,
      "cardStatusDisplayed": "Active",
      "currency": "USD",
      "cardRole": "PRIMARY",
      "expiration": "2024-09-30T00:00:00",
      "isSalaryCard": false,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": true,
      "isDigital": false,
      "isAvailableCardInfo": true,
      "isFamilyCard": false,
      "contractNumber": "7011027343",
      "contractStatus": "Active",
      "guiContractStatus": "NotSet",
      "cardType": "TW",
      "cardSubType": "NotSet",
      "isPPKRequire": false,
      "isPolza": false,
      "isSalaryCardApplicationAvailable": false,
      "hasDebt": false,
      "displayOrder": 4,
      "productName": "Польза",
      "productType": "DebitCard",
      "paymentSystem": "VISA",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "productIdent": "KEY_USD",
      "isMfoContractNumber": false,
      "isHomerMigratedCard": false,
      "contractBillingDay": "2019-09-05T00:00:00",
      "isActivationAvailable": false
    },
    {
      "maskCardNumber": "522199XXXXXX3835",
      "mbr": 0,
      "accountNumber": "40817978090100000797",
      "availableBalance": 679.6,
      "cardStatusDisplayed": "Active",
      "currency": "EUR",
      "cardRole": "PRIMARY",
      "expiration": "2024-09-30T00:00:00",
      "isSalaryCard": false,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": true,
      "isDigital": false,
      "isAvailableCardInfo": true,
      "isFamilyCard": false,
      "contractNumber": "7021017738",
      "contractStatus": "Active",
      "guiContractStatus": "NotSet",
      "cardType": "TW",
      "cardSubType": "NotSet",
      "isPPKRequire": false,
      "isPolza": false,
      "isSalaryCardApplicationAvailable": false,
      "hasDebt": false,
      "displayOrder": 3,
      "productName": "Польза",
      "productType": "DebitCard",
      "paymentSystem": "MASTERCARD",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "productIdent": "KEY_EUR",
      "isMfoContractNumber": false,
      "isHomerMigratedCard": false,
      "contractBillingDay": "2019-09-05T00:00:00",
      "isActivationAvailable": false
    }
  ],
  "creditCards": [
    {
      "maskCardNumber": "545762XXXXXX5456",
      "mbr": 0,
      "accountNumber": "40817810650100873710",
      "availableBalance": 194104.54,
      "cardStatusDisplayed": "NotActive",
      "currency": "RUR",
      "cardRole": "PRIMARY",
      "isSalaryCard": false,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": false,
      "isDigital": false,
      "isAvailableCardInfo": false,
      "isFamilyCard": false,
      "contractNumber": "2206972258",
      "contractStatus": "Действующий",
      "guiContractStatus": "NotSet",
      "cardType": "Homer",
      "cardSubType": "NotSet",
      "isPolza": false,
      "isSalaryCardApplicationAvailable": false,
      "displayOrder": 1,
      "productName": "Польза CB Gold",
      "productType": "CreditCard",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "isMfoContractNumber": false,
      "isHomerMigratedCard": false,
      "creditCardTWGuiData": {
        "creditCardTWGuiStatus": "NotSet",
        "creditCardTWPolzaGuiStatus": "NotSet",
        "displayedPayments": "None",
        "showGauge": false,
        "showTwoBalances": false,
        "colour": "NotSet",
        "displayOrder": 0
      },
      "isActivationAvailable": true
    },
    {
      "maskCardNumber": "446098XXXXXX1177",
      "mbr": 0,
      "accountNumber": "40817810650100873710",
      "availableBalance": 194104.54,
      "cardStatusDisplayed": "Active",
      "currency": "RUR",
      "cardRole": "PRIMARY",
      "isSalaryCard": false,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": false,
      "isDigital": false,
      "isAvailableCardInfo": false,
      "isFamilyCard": false,
      "contractNumber": "2206972258",
      "contractStatus": "Действующий",
      "guiContractStatus": "NotSet",
      "cardType": "Homer",
      "cardSubType": "NotSet",
      "isPolza": false,
      "isSalaryCardApplicationAvailable": false,
      "displayOrder": 2,
      "productName": "Польза CB Gold",
      "productType": "CreditCard",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "isMfoContractNumber": false,
      "isHomerMigratedCard": false,
      "creditCardTWGuiData": {
        "creditCardTWGuiStatus": "NotSet",
        "creditCardTWPolzaGuiStatus": "NotSet",
        "displayedPayments": "None",
        "showGauge": false,
        "showTwoBalances": false,
        "colour": "NotSet",
        "displayOrder": 0
      },
      "isActivationAvailable": false
    },
    {
      "maskCardNumber": "525933XXXXXX4335",
      "mbr": 0,
      "accountNumber": "40817810650100873710",
      "availableBalance": 194104.54,
      "cardStatusDisplayed": "NotActive",
      "currency": "RUR",
      "cardRole": "PRIMARY",
      "isSalaryCard": false,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": false,
      "isDigital": false,
      "isAvailableCardInfo": false,
      "isFamilyCard": false,
      "contractNumber": "2206972258",
      "contractStatus": "Действующий",
      "guiContractStatus": "NotSet",
      "cardType": "Homer",
      "cardSubType": "NotSet",
      "isPolza": false,
      "isSalaryCardApplicationAvailable": false,
      "displayOrder": 3,
      "productName": "Польза CB Gold",
      "productType": "CreditCard",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "isMfoContractNumber": false,
      "isHomerMigratedCard": false,
      "creditCardTWGuiData": {
        "creditCardTWGuiStatus": "NotSet",
        "creditCardTWPolzaGuiStatus": "NotSet",
        "displayedPayments": "None",
        "showGauge": false,
        "showTwoBalances": false,
        "colour": "NotSet",
        "displayOrder": 0
      },
      "isActivationAvailable": true
    }
  ],
  "accounts": [
    {
      "accountNumber": "40817810190010108048",
      "contractNumber": "7005827784",
      "accountName": "Текущий счёт",
      "runningBalance": 290.42,
      "currency": "RUR",
      "status": "Open",
      "accountType": "DebitCard",
      "currencyCode": 643,
      "isExpiredData": false,
      "displayOrder": 0
    },
    {
      "accountNumber": "40817810390100081752",
      "contractNumber": "7209022855",
      "accountName": "Текущий счёт",
      "runningBalance": 4464.15,
      "currency": "RUR",
      "status": "Open",
      "accountType": "DebitCard",
      "currencyCode": 643,
      "isExpiredData": false,
      "displayOrder": 1
    },
    {
      "accountNumber": "40817810690100011914",
      "contractNumber": "7501023177",
      "accountName": "Текущий счёт",
      "runningBalance": 20,
      "currency": "RUR",
      "status": "Open",
      "accountType": "DebitCard",
      "currencyCode": 643,
      "isExpiredData": false,
      "displayOrder": 2
    },
    {
      "accountNumber": "40817810890011944828",
      "contractNumber": "7313021215",
      "accountName": "Текущий счёт",
      "runningBalance": 938.16,
      "currency": "RUR",
      "status": "Open",
      "accountType": "DebitCard",
      "currencyCode": 643,
      "isExpiredData": false,
      "displayOrder": 3
    },
    {
      "accountNumber": "40817810890012685704",
      "contractNumber": "7319134809",
      "accountName": "Накопительный счёт",
      "runningBalance": 113701.84,
      "currency": "RUR",
      "status": "Open",
      "accountType": "SavingAccount",
      "currencyCode": 643,
      "isExpiredData": false,
      "displayOrder": 4
    },
    {
      "accountNumber": "40817840890100000986",
      "contractNumber": "7011027343",
      "accountName": "Текущий счёт",
      "runningBalance": 381.19,
      "currency": "USD",
      "status": "Open",
      "accountType": "DebitCard",
      "currencyCode": 840,
      "isExpiredData": false,
      "displayOrder": 5
    },
    {
      "accountNumber": "40817978090100000797",
      "contractNumber": "7021017738",
      "accountName": "Текущий счёт",
      "runningBalance": 679.6,
      "currency": "EUR",
      "status": "Open",
      "accountType": "DebitCard",
      "currencyCode": 978,
      "isExpiredData": false,
      "displayOrder": 6
    }
  ],
  "deposits": [
    {
      "contractUniqueId": "MzE4LzAxMzk4MDtUVzsyMTsyNjUyOTkzMg==",
      "contractNumber": "318/013980",
      "accountNumber": "42305810450010181969",
      "depositName": "6 месяцев New",
      "contractStatus": "Active",
      "depositType": "Срочный",
      "systemCode": "Tw",
      "startDate": "2022-03-22T00:00:00+03:00",
      "maturityDate": "2023-03-22T00:00:00+03:00",
      "runningBalance": 50620,
      "currency": "RUR",
      "currencyCode": 643,
      "isPossibilityOfPartialWithdrawal": false,
      "isPossibilityOfReplenishment": false,
      "productVersion": "",
      "displayOrder": 0,
      "isExpiredData": false
    },
    {
      "contractUniqueId": "MzIxLzAwMTQ2MDtUVzsyMTsyNjUyOTkzMg==",
      "contractNumber": "321/001460",
      "accountNumber": "42306810050010346381",
      "depositName": "12 месяцев S онлайн",
      "contractStatus": "Active",
      "depositType": "Срочный",
      "systemCode": "Tw",
      "startDate": "2022-04-01T00:00:00+03:00",
      "maturityDate": "2023-04-01T00:00:00+03:00",
      "runningBalance": 50116,
      "currency": "RUR",
      "currencyCode": 643,
      "isPossibilityOfPartialWithdrawal": false,
      "isPossibilityOfReplenishment": false,
      "productVersion": "",
      "displayOrder": 1,
      "isExpiredData": false
    },
    {
      "contractUniqueId": "MzIxLzAwMjE0OTtUVzsyMTsyNjUyOTkzMg==",
      "contractNumber": "321/002149",
      "accountNumber": "42306810450010347498",
      "depositName": "12 месяцев S онлайн",
      "contractStatus": "Active",
      "depositType": "Срочный",
      "systemCode": "Tw",
      "startDate": "2022-04-08T00:00:00+03:00",
      "maturityDate": "2023-04-08T00:00:00+03:00",
      "runningBalance": 170116,
      "currency": "RUR",
      "currencyCode": 643,
      "isPossibilityOfPartialWithdrawal": false,
      "isPossibilityOfReplenishment": false,
      "productVersion": "",
      "displayOrder": 2,
      "isExpiredData": false
    },
    {
      "contractUniqueId": "Mjc2LzAwMzYxMjtUVzsyMTsyNjUyOTkzMg==",
      "contractNumber": "276/003612",
      "accountNumber": "42306810050010354043",
      "depositName": "18 месяцев S",
      "contractStatus": "Active",
      "depositType": "Срочный",
      "systemCode": "Tw",
      "startDate": "2022-05-29T00:00:00+03:00",
      "maturityDate": "2023-11-29T00:00:00+03:00",
      "runningBalance": 218125,
      "currency": "RUR",
      "currencyCode": 643,
      "isPossibilityOfPartialWithdrawal": false,
      "isPossibilityOfReplenishment": false,
      "productVersion": "",
      "displayOrder": 3,
      "isExpiredData": false
    },
    {
      "contractUniqueId": "MzIxLzAwNTE3NztUVzsyMTsyNjUyOTkzMg==",
      "contractNumber": "321/005177",
      "accountNumber": "42306810650010355950",
      "depositName": "12 месяцев S онлайн",
      "contractStatus": "Active",
      "depositType": "Срочный",
      "systemCode": "Tw",
      "startDate": "2022-06-01T00:00:00+03:00",
      "maturityDate": "2023-06-01T00:00:00+03:00",
      "runningBalance": 150112,
      "currency": "RUR",
      "currencyCode": 643,
      "isPossibilityOfPartialWithdrawal": false,
      "isPossibilityOfReplenishment": false,
      "productVersion": "",
      "displayOrder": 4,
      "isExpiredData": false
    },
    {
      "contractUniqueId": "MzM2LzAwMDU5OTtUVzsyMTsyNjUyOTkzMg==",
      "contractNumber": "336/000599",
      "accountNumber": "42305810750010217876",
      "depositName": "Максимум онлайн",
      "contractStatus": "Active",
      "depositType": "Срочный",
      "systemCode": "Tw",
      "startDate": "2022-08-31T00:00:00+03:00",
      "maturityDate": "2023-07-31T00:00:00+03:00",
      "runningBalance": 101108,
      "currency": "RUR",
      "currencyCode": 643,
      "isPossibilityOfPartialWithdrawal": false,
      "isPossibilityOfReplenishment": false,
      "productVersion": "",
      "displayOrder": 5,
      "isExpiredData": false
    },
    {
      "contractUniqueId": "MzM2LzAwMTM3MztUVzsyMTsyNjUyOTkzMg==",
      "contractNumber": "336/001373",
      "accountNumber": "42305810750010218749",
      "depositName": "Максимум онлайн",
      "contractStatus": "Active",
      "depositType": "Срочный",
      "systemCode": "Tw",
      "startDate": "2022-09-02T00:00:00+03:00",
      "maturityDate": "2023-08-02T00:00:00+03:00",
      "runningBalance": 101183,
      "currency": "RUR",
      "currencyCode": 643,
      "isPossibilityOfPartialWithdrawal": false,
      "isPossibilityOfReplenishment": false,
      "productVersion": "",
      "displayOrder": 6,
      "isExpiredData": false
    },
    {
      "contractUniqueId": "MzM2LzAwNzIzODtUVzsyMTsyNjUyOTkzMg==",
      "contractNumber": "336/007238",
      "accountNumber": "42305810150010225141",
      "depositName": "Максимум онлайн",
      "contractStatus": "Active",
      "depositType": "Срочный",
      "systemCode": "Tw",
      "startDate": "2022-09-13T00:00:00+03:00",
      "maturityDate": "2023-08-13T00:00:00+03:00",
      "runningBalance": 111875,
      "currency": "RUR",
      "currencyCode": 643,
      "isPossibilityOfPartialWithdrawal": false,
      "isPossibilityOfReplenishment": false,
      "productVersion": "",
      "displayOrder": 7,
      "isExpiredData": false
    },
    {
      "contractUniqueId": "Mjc5LzAwMjM4ODtUVzsyMTsyNjUyOTkzMg==",
      "contractNumber": "279/002388",
      "accountNumber": "42306810450010381331",
      "depositName": "24 месяца S",
      "contractStatus": "Active",
      "depositType": "Срочный",
      "systemCode": "Tw",
      "startDate": "2022-10-23T00:00:00+03:00",
      "maturityDate": "2024-10-23T00:00:00+03:00",
      "runningBalance": 50029,
      "currency": "RUR",
      "currencyCode": 643,
      "isPossibilityOfPartialWithdrawal": false,
      "isPossibilityOfReplenishment": false,
      "productVersion": "",
      "displayOrder": 8,
      "isExpiredData": false
    }
  ],
  "mkks": [],
  "serverDate": "2024-05-29T18:43:00.8271752+03:00"
}
