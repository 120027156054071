{
  "Result": {
    "IsValid": true,
    "ExpiresIn": 3599,
    "FirstName": "Евгений",
    "ClientType": "Base",
    "CurrentLevel": 4,
    "SessionId": "034c35c1-d478-4242-8715-39f646166778"
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
