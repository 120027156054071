{
  "$id": "1",
  "result": {
    "phone": null,
    "resultCode": "BLOCKED",
    "resultText": "В целях вашей безопасности просмотр данных карты ограничен на 59 минут",
    "_links": null
  },
  "statusCode": 403,
  "errors": ["The remote server returned an error: (403) Forbidden."],
  "errorDetails": [
    {
      "title": "",
      "error": "The remote server returned an error: (403) Forbidden.",
      "errorType": 0
    }
  ],
  "containsAnyError": true,
  "containsAnyWarning": false,
  "containsAnyInfo": false
}
