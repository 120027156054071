{
  "$id": "1",
  "Result": {
    "$id": "2",
    "ServerDate": "2023-12-07T00:00:00+03:00",
    "CreditLoan": [
      {
        "$id": "3",
        "CreditLoanGuiData": {
          "$id": "4",
          "CreditLoanGuiStatus": 10,
          "Colour": 3,
          "DisplayOrder": 1,
          "ShowDetails": false,
          "ScheduleAvailable": false,
          "ShowGauge": false,
          "DebtDaysWordAgreement": 3,
          "PercentPaid": 100,
          "RemainingDebt": 0.0,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "InterestRate": 21.56,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2609131904",
        "ProductName": "Кредит на товар",
        "ProductType": 1,
        "Contract": {
          "$id": "5",
          "Properties": {
            "$id": "6",
            "NextPaymentDate": "2023-12-07T00:00:00+03:00",
            "NextPaymentDateDisplayed": "2023-12-07T00:00:00+03:00",
            "PaymentNum": 6,
            "LastPaymentNum": 6,
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "EarlyPaymentCalculationDate": "2023-12-07T00:00:00+03:00"
          },
          "LastPayDate": "2006-08-11T00:00:00"
        },
        "ContractStatus": 1,
        "CreditAmount": 5816.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810040010042726",
        "DateSign": "2006-02-12T00:00:00",
        "DateClosure": "2006-09-02T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 0.0,
        "EarlyPaymentSum": 0.0,
        "ProductSet": {
          "$id": "7",
          "Code": "Без переплаты",
          "Name": "Сада 0-0-6"
        },
        "IsCovenant": false,
        "AccountArrest": {
          "$id": "8",
          "ActualBalance": 0.0,
          "IsArrest": false,
          "ArrestSum": 0.0,
          "ArrestLeft": 0.0,
          "PercentPaidArrest": 0
        },
        "RassrochkaPlus": false,
        "AutoCins": false,
        "SoldOutDate": "2024-07-01T00:00:00",
        "SoldOutStatus": 0
      },
      {
        "$id": "9",
        "CreditLoanGuiData": {
          "$id": "10",
          "CreditLoanGuiStatus": 6,
          "Colour": 3,
          "DisplayOrder": 4,
          "ShowDetails": true,
          "ScheduleAvailable": true,
          "ShowGauge": false,
          "DaysLeft": 27,
          "DaysLeftWordAgreement": 3,
          "DebtDaysWordAgreement": 2,
          "PercentPaid": 0,
          "FollowingDate": "2024-02-03T00:00:00",
          "FollowingSum": 5377.79,
          "RemainingDebt": 0.0,
          "DebtDays": 34,
          "DebtAmount": 10755.58,
          "InterestRate": 14.9,
          "LoanBalance": 73167.0,
          "PercentPaidBodyCredit": 100,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2419169462",
        "ProductName": "Кредит наличными",
        "ProductType": 2,
        "Contract": {
          "$id": "11",
          "Properties": {
            "$id": "12",
            "NextPaymentDate": "2024-01-03T00:00:00",
            "NextPaymentDateDisplayed": "2024-01-03T00:00:00",
            "PaymentNum": 15,
            "LastPaymentNum": 2,
            "SumToPay": 16133.37,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "EarlyPaymentCalculationDate": "2024-01-03T00:00:00"
          },
          "LastPayDate": "2025-01-03T00:00:00"
        },
        "ContractStatus": 1,
        "CreditAmount": 73167.0,
        "Payment": 5377.79,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810840640320458",
        "DateSign": "2023-10-03T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 60000.0,
        "EarlyPaymentSum": 0.0,
        "ProductSet": {
          "$id": "13",
          "Code": "База",
          "Name": "Оптимальная ставка 14,9%"
        },
        "IsCovenant": true,
        "CovenantInfo": {
          "$id": "14",
          "IsInterestRateChanged": false,
          "ConditionRateChangeDate": "23.10.2023",
          "ConditionSum": 48000.0,
          "ContractTermsUrl": "https://www.homecredit.ru/loans/cash/covenanta"
        },
        "AccountArrest": {
          "$id": "15",
          "ActualBalance": 60000.0,
          "IsArrest": false,
          "ArrestSum": 0.0,
          "ArrestLeft": 0.0,
          "PercentPaidArrest": 0
        },
        "RassrochkaPlus": false,
        "AutoCins": false,
        "SoldOutDate": "2024-07-01T00:00:00",
        "SoldOutStatus": 0
      },
      {
        "$id": "16",
        "CreditLoanGuiData": {
          "$id": "17",
          "CreditLoanGuiStatus": 23,
          "Colour": 3,
          "DisplayOrder": 2,
          "ShowDetails": true,
          "ScheduleAvailable": true,
          "ShowGauge": false,
          "DaysLeft": 1,
          "DaysLeftWordAgreement": 1,
          "DebtDaysWordAgreement": 3,
          "PercentPaid": 0,
          "FollowingDate": "2024-01-08T00:00:00",
          "FollowingSum": 5376.33,
          "RemainingDebt": 0.0,
          "DebtDays": 60,
          "DebtAmount": 10752.66,
          "InterestRate": 14.9,
          "LoanBalance": 73167.0,
          "PercentPaidBodyCredit": 100,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2419147026",
        "ProductName": "Кредит наличными",
        "ProductType": 2,
        "Contract": {
          "$id": "18",
          "Properties": {
            "$id": "19",
            "NextPaymentDate": "2023-12-08T00:00:00",
            "NextPaymentDateDisplayed": "2023-12-08T00:00:00",
            "PaymentNum": 15,
            "LastPaymentNum": 2,
            "SumToPay": 16128.99,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "EarlyPaymentCalculationDate": "2023-12-08T00:00:00"
          },
          "LastPayDate": "2024-12-08T00:00:00"
        },
        "ContractStatus": 6,
        "CreditAmount": 73167.0,
        "Owner": {
          "$id": "1",
          "Id": 966,
          "Name": "ПАО «Совкомбанк»",
          "Code": "000"
        },
        "Payment": 5376.33,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810540640299656",
        "DateSign": "2023-09-08T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 60033.0,
        "EarlyPaymentSum": 0.0,
        "ProductSet": {
          "$id": "20",
          "Code": "База",
          "Name": "Оптимальная ставка 14,9%"
        },
        "IsCovenant": true,
        "CovenantInfo": {
          "$id": "21",
          "IsInterestRateChanged": false,
          "ConditionRateChangeDate": "28.09.2023",
          "ConditionSum": 48000.0,
          "ContractTermsUrl": "https://www.homecredit.ru/loans/cash/covenanta"
        },
        "AccountArrest": {
          "$id": "22",
          "ActualBalance": 60033.0,
          "IsArrest": false,
          "ArrestSum": 0.0,
          "ArrestLeft": 0.0,
          "PercentPaidArrest": 0
        },
        "RassrochkaPlus": false,
        "AutoCins": false,
        "SoldOutDate": "2024-07-01T00:00:00",
        "SoldOutStatus": 0
      },
      {
        "$id": "23",
        "CreditLoanGuiData": {
          "$id": "24",
          "CreditLoanGuiStatus": 6,
          "Colour": 3,
          "DisplayOrder": 5,
          "ShowDetails": true,
          "ScheduleAvailable": true,
          "ShowGauge": false,
          "DaysLeft": 27,
          "DaysLeftWordAgreement": 3,
          "DebtDaysWordAgreement": 3,
          "PercentPaid": 0,
          "FollowingDate": "2024-02-03T00:00:00",
          "FollowingSum": 5376.33,
          "RemainingDebt": 0.0,
          "DebtDays": 65,
          "DebtAmount": 16128.99,
          "InterestRate": 14.9,
          "LoanBalance": 73167.0,
          "PercentPaidBodyCredit": 100,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2419139365",
        "ProductName": "Кредит наличными",
        "ProductType": 2,
        "Contract": {
          "$id": "25",
          "Properties": {
            "$id": "26",
            "NextPaymentDate": "2024-01-03T00:00:00",
            "NextPaymentDateDisplayed": "2024-01-03T00:00:00",
            "PaymentNum": 15,
            "LastPaymentNum": 3,
            "SumToPay": 21505.32,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "EarlyPaymentCalculationDate": "2024-01-03T00:00:00"
          },
          "LastPayDate": "2024-12-03T00:00:00"
        },
        "ContractStatus": 1,
        "CreditAmount": 73167.0,
        "Payment": 5376.33,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810940640290857",
        "DateSign": "2023-09-03T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 60000.0,
        "EarlyPaymentSum": 0.0,
        "ProductSet": {
          "$id": "27",
          "Code": "База",
          "Name": "Оптимальная ставка 14,9%"
        },
        "IsCovenant": true,
        "CovenantInfo": {
          "$id": "28",
          "IsInterestRateChanged": false,
          "ConditionRateChangeDate": "23.09.2023",
          "ConditionSum": 48000.0,
          "ContractTermsUrl": "https://www.homecredit.ru/loans/cash/covenanta"
        },
        "AccountArrest": {
          "$id": "29",
          "ActualBalance": 60000.0,
          "IsArrest": false,
          "ArrestSum": 0.0,
          "ArrestLeft": 0.0,
          "PercentPaidArrest": 0
        },
        "RassrochkaPlus": false,
        "AutoCins": false,
        "SoldOutDate": "2024-07-01T00:00:00",
        "SoldOutStatus": 0
      },
      {
        "$id": "30",
        "CreditLoanGuiData": {
          "$id": "31",
          "CreditLoanGuiStatus": 6,
          "Colour": 3,
          "DisplayOrder": 3,
          "ShowDetails": true,
          "ScheduleAvailable": true,
          "ShowGauge": false,
          "DaysLeft": 9,
          "DaysLeftWordAgreement": 3,
          "DebtDaysWordAgreement": 2,
          "PercentPaid": 0,
          "FollowingDate": "2024-01-16T00:00:00",
          "FollowingSum": 5377.79,
          "RemainingDebt": 0.0,
          "DebtDays": 82,
          "DebtAmount": 16133.37,
          "InterestRate": 14.9,
          "LoanBalance": 73167.0,
          "PercentPaidBodyCredit": 100,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2419118399",
        "ProductName": "Кредит наличными",
        "ProductType": 2,
        "Contract": {
          "$id": "32",
          "Properties": {
            "$id": "33",
            "NextPaymentDate": "2023-12-16T00:00:00",
            "NextPaymentDateDisplayed": "2023-12-16T00:00:00",
            "PaymentNum": 15,
            "LastPaymentNum": 3,
            "SumToPay": 21511.16,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "EarlyPaymentCalculationDate": "2023-12-16T00:00:00"
          },
          "LastPayDate": "2024-11-16T00:00:00"
        },
        "ContractStatus": 1,
        "CreditAmount": 73167.0,
        "Payment": 5377.79,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810340640272857",
        "DateSign": "2023-08-16T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 60000.0,
        "EarlyPaymentSum": 0.0,
        "ProductSet": {
          "$id": "34",
          "Code": "База",
          "Name": "Оптимальная ставка 14,9%"
        },
        "IsCovenant": true,
        "CovenantInfo": {
          "$id": "35",
          "IsInterestRateChanged": false,
          "ConditionRateChangeDate": "05.09.2023",
          "ConditionSum": 48000.0,
          "ContractTermsUrl": "https://www.homecredit.ru/loans/cash/covenanta"
        },
        "AccountArrest": {
          "$id": "36",
          "ActualBalance": 60000.0,
          "IsArrest": false,
          "ArrestSum": 0.0,
          "ArrestLeft": 0.0,
          "PercentPaidArrest": 0
        },
        "RassrochkaPlus": false,
        "AutoCins": false,
        "SoldOutDate": "2024-07-01T00:00:00",
        "SoldOutStatus": 0
      },
      {
        "$id": "37",
        "CreditLoanGuiData": {
          "$id": "38",
          "CreditLoanGuiStatus": 8,
          "Colour": 3,
          "DisplayOrder": 6,
          "ShowDetails": false,
          "ScheduleAvailable": false,
          "ShowGauge": false,
          "DebtDaysWordAgreement": 3,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "InterestRate": 16.9,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2298595951",
        "ProductName": "Кредит наличными",
        "ProductType": 2,
        "Contract": {
          "$id": "39",
          "Properties": {
            "$id": "40",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 15,
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          },
          "LastPayDate": "2020-05-27T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 100000.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810040010042726",
        "DateSign": "2019-05-27T00:00:00",
        "DateClosure": "2020-05-28T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 0.0,
        "EarlyPaymentSum": 0.0,
        "ProductSet": {
          "$id": "41",
          "Code": "Наличные в кредит для сотрудников",
          "Name": "Наличные сотрудникам_16,9%"
        },
        "IsCovenant": false,
        "RassrochkaPlus": false,
        "AutoCins": false,
        "SoldOutDate": "2024-07-01T00:00:00",
        "SoldOutStatus": 0
      },
      {
        "$id": "42",
        "CreditLoanGuiData": {
          "$id": "43",
          "CreditLoanGuiStatus": 8,
          "Colour": 3,
          "DisplayOrder": 7,
          "ShowDetails": false,
          "ScheduleAvailable": false,
          "ShowGauge": false,
          "DebtDaysWordAgreement": 3,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "InterestRate": 28.5,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2690108954",
        "ProductName": "Кредит на товар",
        "ProductType": 1,
        "Contract": {
          "$id": "44",
          "Properties": {
            "$id": "45",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 6,
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          },
          "LastPayDate": "2007-07-07T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 23488.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810040010042726",
        "DateSign": "2006-12-01T00:00:00",
        "DateClosure": "2007-08-05T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 0.0,
        "EarlyPaymentSum": 0.0,
        "ProductSet": {
          "$id": "46",
          "Code": "Стандартные",
          "Name": "Сада Стандартный+"
        },
        "IsCovenant": false,
        "RassrochkaPlus": false,
        "AutoCins": false,
        "SoldOutDate": "2024-07-01T00:00:00",
        "SoldOutStatus": 0
      }
    ],
    "CreditCard": [
      {
        "$id": "47",
        "CreditCardGuiData": {
          "$id": "48",
          "CreditCardGuiStatus": 1,
          "DisplayOrder": 0,
          "Colour": 3,
          "ShowGauge": false,
          "DaysLeft": 0,
          "DaysLeftWordAgreement": 3,
          "DebtDaysWordAgreement": 3,
          "PaymentSystem": 1,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "ShowTopUpWarning": true
        },
        "ContractNumber": "2207213218",
        "ProductName": "Польза Gold",
        "ProductType": 3,
        "Contract": {
          "$id": "49",
          "Properties": {
            "$id": "50",
            "NextPaymentDate": "2023-12-15T00:00:00",
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          },
          "LastPayDate": "0001-01-01T00:00:00"
        },
        "ContractStatus": 1,
        "AccountNumber": "40817810150010109207",
        "CreditLimit": 0.0,
        "AvailableBalance": 106.0,
        "TotalIndebtedness": 0.0,
        "PrincipalDebtSum": 0.0,
        "PenaltySum": 0.0,
        "InstalmentDebtSum": 0.0,
        "MinMonthDebtAmount": 0.0,
        "OverdueDaysNum": 0,
        "MainCardStatus": 1,
        "DateSign": "2014-09-24T00:00:00",
        "ProductSet": {
          "$id": "51",
          "Code": "CASHBACK для сотрудников",
          "Name": "НАША Карта CASHBACK Gold (без ТРИО)"
        },
        "MainCardNumber": "446098XXXXXX9262",
        "IsPolza": true,
        "CardType": 1,
        "IsPolzaLkAvalible": true,
        "IsActivationAvailable": false,
        "AccountArrest": {
          "$id": "52",
          "ActualBalance": 106.0,
          "IsArrest": false,
          "ArrestSum": 0.0,
          "ArrestLeft": 0.0,
          "PercentPaidArrest": 0
        },
        "GracePaymentInfo": "Эту сумму нужно внести, чтобы не платить проценты за уже совершённые операции.",
        "HasDebt": false
      }
    ],
    "CreditCardTW": [],
    "IsCreditLoanAndCardError": false,
    "IsCreditCardTWError": false,
    "IsAvailableEarlyRepayment": true
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
