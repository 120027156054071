[
  {
    "maskCard": "553691******5369",
    "expMonth": "12",
    "expYear": "29",
    "cardholderName": null,
    "paymentSystem": "MC",
    "templateId": "5C5CD8C3DBB9488F9595D27A19B98B92"
  },
  {
    "maskCard": "427616******8468",
    "expMonth": "12",
    "expYear": "26",
    "cardholderName": null,
    "paymentSystem": "VISA",
    "templateId": "A5248D6742FE4313B5C1E71B6A4639DA"
  },
  {
    "maskCard": "510621******7752",
    "expMonth": "04",
    "expYear": "44",
    "cardholderName": null,
    "paymentSystem": "MC",
    "templateId": "3095227A949C420689BA945FD0D05B4E"
  },
  {
    "maskCard": "427668******0909",
    "expMonth": "07",
    "expYear": "24",
    "cardholderName": null,
    "paymentSystem": "VISA",
    "templateId": "5AB0E320E46D4D3BAB3E910112322ED2"
  }
]
