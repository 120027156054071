import React, { forwardRef } from 'react'
import { observer } from 'mobx-react-lite'
import { AppContext } from '@/models/AppContext'
import styled from '@emotion/styled'
import { Button } from '@platform-ui/components/Button'
import { useAppContext } from '@/core'
import { useClientInfoQuery } from '@/api/client/clientInfo'
import { ResponseType } from '@/models/ResponseType'
import { Rules } from '@/models/Rules'
import { Checkbox } from '@platform-ui/components/Checkbox'
import { useDeveloperStore } from '../../developerStore'
import { useEndSessionQuery } from '@/api/auth/endSession'

const Root = styled.div(() => {
  return {
    maxHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  }
})

const ButtonList = styled.div(({ theme }) => {
  return {
    margin: '-4px',
    '& > button, & > a': {
      margin: '4px',
    },
  }
})

const Title = styled.h4(({ theme }) => {
  return {
    flexGrow: 1,
    fontFamily: theme.fontFamily.base,
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    marginTop: 0,
  }
})

const TitleText = styled.span(({ theme }) => {
  return {
    marginLeft: '8px',
  }
})

const ButtonCustom = styled(Button)(() => ({
  marginBottom: '12px',
}))

const Widget = styled.div(() => ({
  marginTop: '24px',
  display: 'flex',
  flexDirection: 'column',
}))

const ButtonBlockStyled = styled.div(() => ({
  marginTop: '24px',
  display: 'flex',
  flexDirection: 'column',

  [`>${ButtonList}`]: {
    marginBottom: '8px',
  },
}))

export type TAuthProps = {
  className?: string
  children?: React.ReactNode
}

const ERROR_TOAST_TITLE = 'Ошибка авторизации'

export const Auth = observer(
  forwardRef<HTMLDivElement, TAuthProps>(({ children, ...otherProps }, ref) => {
    const context = useAppContext<AppContext>()
    const developerStore = useDeveloperStore()

    const resetExpireAt = () => context.auth.resetExpireAt()

    const getErrorToast = () =>
      context.toastify.error({
        title: ERROR_TOAST_TITLE,
        description: '',
      })

    const clientInfoQuery = useClientInfoQuery(
      {
        apiVersion: context.config.apiVersion,
      },
      {
        enabled: context.auth.rules.has(Rules.Access),
        onError: () => {
          getErrorToast()
        },
      }
    )

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        developerStore.addAccessRule(event.currentTarget.value as Rules)
        return
      }
      developerStore.deleteAccessRule(event.currentTarget.value as Rules)
    }

    const endSessionQuery = useEndSessionQuery({
      apiVersion: context.config.apiVersion,
    })

    return (
      <Root>
        <Title>
          {context.auth.rules.has(Rules.Access)
            ? clientInfoQuery.data && clientInfoQuery.data.type === ResponseType.ResolveWithData
              ? `Привет, ${clientInfoQuery.data.lastName} ${clientInfoQuery.data.firstName}`
              : 'Загрузка'
            : ' Вы не авторизованы'}
        </Title>

        <ButtonList>
          <ButtonCustom
            type="button"
            view="primary"
            size="m"
            onClick={() => {
              if (!context.auth.rules.has(Rules.Access)) {
                context.auth.addRules(Rules.Access, Rules.AuthPasswordAccess)
              } else {
                context.auth.deleteRules(Rules.Access, Rules.AuthPasswordAccess)
                context.auth.setCurrentScreen(context.auth.screens.SignIn)
              }
            }}
          >
            {!context.auth.rules.has(Rules.Access)
              ? 'Стать авторизованным'
              : 'Стать не авторизованным'}
          </ButtonCustom>
        </ButtonList>
        <Widget>
          <Title>Права доступа</Title>
          {Object.keys(Rules).map((item: Rules) => {
            return (
              <Title key={item}>
                <Checkbox
                  isChecked={context.auth.rules.has(item)}
                  value={item}
                  onChange={handleCheckboxChange}
                />
                <TitleText>{item}</TitleText>
              </Title>
            )
          })}
        </Widget>
        {context.auth.rules.has(Rules.Access) && (
          <ButtonBlockStyled>
            <ButtonList>
              <ButtonCustom
                type="button"
                view="primary"
                size="m"
                onClick={() => {
                  context.auth.signOut()
                }}
                isLoading={endSessionQuery.isLoading}
              >
                Выйти
              </ButtonCustom>
            </ButtonList>
            <ButtonList>
              <ButtonCustom
                type="button"
                view="primary"
                size="m"
                isLoading={clientInfoQuery.isLoading}
                onClick={() => {
                  clientInfoQuery.refetch()
                }}
              >
                Запросить данные пользователя
              </ButtonCustom>
            </ButtonList>
            <ButtonList>
              <ButtonCustom type="button" view="primary" size="m" onClick={resetExpireAt}>
                Обнулить expireAt
              </ButtonCustom>
            </ButtonList>
          </ButtonBlockStyled>
        )}
      </Root>
    )
  })
)
