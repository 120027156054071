import { createMocks } from '@/features/developer/utils/createMocks'
import successFile from './success.json'
import successBlockedFile from './successBlocked.json'
import successBlocked2File from './successBlocked2.json'
import successBlocked3File from './successBlocked3.json'

export const mockItems = createMocks([
  {
    title: 'Успешно',
    status: 200,
    data: successFile,
    description: '',
  },
  {
    title: 'Успешно, но клиент заблокирован (mobilePhoneChanged)',
    status: 200,
    data: successBlockedFile,
    description: '',
  },
  {
    title: 'Успешно, но клиент заблокирован (transfersSms)',
    status: 200,
    data: successBlocked2File,
    description: '',
  },
  {
    title: 'Успешно, но клиент заблокирован (mobilePhoneError)',
    status: 200,
    data: successBlocked3File,
    description: '',
  },
  {
    title: 'Код 401',
    status: 401,
    data: null,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
