{
  "popups": [
    {
      "id": 41,
      "templateId": 5020,
      "url": "https://sales-father-images-nginx-rt.omni.homecredit.ru/images/popup_5020_2fb1cf35-833d-40bc-87e1-4d158d4d1363.html",
      "channelNumber": 9427,
      "priority": null,
      "isCloseButtonEnabled": true,
      "actions": [
        {
          "targetId": null,
          "target": "deeplink",
          "location": "",
          "link": "https://4191472.redirect.appmetrica.yandex.com/?ClientAction=ReviewOfInformation&docType=PDNExceeding&contractNumber=7321111111&applicationNumber=3333-3333333&pdn=55,07&appmetrica_tracking_id=749091024537652113",
          "nameInFile": "popupAction-0",
          "productIdent": null
        }
      ],
      "displayScreens": [
        {
          "id": 11
        }
      ],
      "offerManagerInfo": null
    },
    {
      "templateId": 6500,
      "id": 123,
      "url": "https://sales-father-images-nginx.homecredit.ru/images/popup_5188_desktop_303ac875-588c-4b2a-9e1b-wfwe.html#",
      "channelNumber": 9427,
      "priority": 1,
      "isCloseButtonEnabled": true,
      "actions": [
        {
          "target": "deeplink",
          "targetId": null,
          "location": null,
          "productIdent": null,
          "nameInFile": "popupAction-0",
          "link": "https://hcdl.ru/k"
        },
        {
          "target": "webview",
          "targetId": "SC",
          "location": "Q5F",
          "nameInFile": "popupAction-1",
          "productIdent": "CREDIT",
          "link": null
        }
      ],
      "displayScreens": [
        {
          "id": 11
        }
      ],
      "offerManagerInfo": {
        "OfferId": "W372840_S01_C0044449855_5531225",
        "ClickId": "851fe5ea-804a-4b61-9cf3-52841a29aa48",
        "SessionId": "67eee627-d3ba-4b8b-b85c-1ce979ac495d"
      }
    }
  ]
}
