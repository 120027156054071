{
  "provider": {
    "providerId": "sbp",
    "alias": "70005310024",
    "recipientName": "Петр Петрович П",
    "bankName": "ПИР Банк",
    "bankBic": "044525491",
    "transactionProductFrom": {
      "maskCardNumber": "220050******1559",
      "accountNumber": "40817810290013007651"
    },
    "transactionProductTo": {
      "maskCardNumber": null,
      "accountNumber": "4081****************2089"
    },
    "fee": 0,
    "sum": 12,
    "fullSum": 12,
    "currency": "RUR",
    "transferType": "sbp",
    "limit": {
      "minTransferLimit": 10.0,
      "maxTransferLimit": 1000000.0
    },
    "selfTransfer": false
  },
  "links": [
    {
      "rel": "transfer",
      "href": "https://ocelot-api-gateway-ft.omni.homecredit.ru/visa-phone-transfer/v1/69363c7b-2e42-4b4d-ac3e-6d9658000e09/transfer"
    },
    {
      "rel": "commission",
      "href": "https://ocelot-api-gateway-ft.omni.homecredit.ru/visa-phone-transfer/v1/69363c7b-2e42-4b4d-ac3e-6d9658000e09/commission"
    },
    {
      "rel": "prepare",
      "href": "https://ocelot-api-gateway-ft.omni.homecredit.ru/visa-phone-transfer/v1/69363c7b-2e42-4b4d-ac3e-6d9658000e09/prepare"
    }
  ]
}
