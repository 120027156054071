import { createMocks } from '@/features/developer/utils/createMocks'
import success from './success.json'
import reject400 from './reject-400.json'
import reject403 from './reject-403.json'
import reject441 from './reject-441.json'
import reject500 from './reject-500.json'

export const mockItems = createMocks([
  {
    title: 'Успешная регистрация',
    status: 200,
    data: success,
    description: '',
  },
  {
    title: 'Bad Request (400)',
    status: 400,
    data: reject400,
    description: '',
  },
  {
    title: 'Forbidden (403)',
    status: 403,
    data: reject403,
    description: '',
  },
  {
    title: 'Error (441)',
    status: 441,
    data: reject441,
    description: '',
  },
  {
    title: 'Server Error (500)',
    status: 500,
    data: reject500,
    description: '',
  },
])
