{
  "$id": "1",
  "Result": {
    "$id": "2",
    "ClientFIO": "Филатов Даниил Одобревич",
    "BankName": "Хоум Кредит энд Финанс Банк",
    "CorrAcc": "30101810845250000245",
    "BankNum": "044525245",
    "BankINN": "7735057951",
    "BankKPP": "771401001",
    "BankNameEng": "HOME CREDIT AND FINANCE BANK, Moscow, Russia",
    "BankSwift": "HCFBRUMM",
    "CorrAccUsd": "890-0575-204",
    "CorrBankNameUsd": "THE BANK OF NEW YORK MELLON, NEW YORK, NY USA",
    "CorrBankSwiftUsd": "IRVTUS3N",
    "CorrAccEur": "2005840200/6000",
    "CorrBankNameEur": "PPF BANKA A.S., Prague, Czech Republic",
    "CorrBankSwiftEur": "PMBPCZPP",
    "CorrAccCny": "30109156900000000802",
    "CorrBankNameCny": "NATIONAL SETTLEMENT DEPOSITORY MOSCOW RU",
    "CorrBankSwiftCny": "MICURUMM"
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
