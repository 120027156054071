{
  "productFrom": [
    {
      "maskCardNumber": "4469 15** **** 6491",
      "accountNumber": "40817810890011944828",
      "type": "debit",
      "availableBalance": 938.16,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "4469 15** **** 0321",
      "accountNumber": "40817810390100081752",
      "type": "debit",
      "availableBalance": 4464.15,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "5221 99** **** 7656",
      "accountNumber": "40817810190010108048",
      "type": "debit",
      "availableBalance": 290.42,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "4454 33** **** 5550",
      "accountNumber": "40817840890100000986",
      "type": "debit",
      "availableBalance": 381.19,
      "currency": "USD"
    },
    {
      "maskCardNumber": "5221 99** **** 3835",
      "accountNumber": "40817978090100000797",
      "type": "debit",
      "availableBalance": 679.6,
      "currency": "EUR"
    },
    {
      "accountNumber": "40817810690100011914",
      "type": "account",
      "availableBalance": 20,
      "currency": "RUR"
    },
    {
      "accountNumber": "40817810890012685704",
      "type": "account",
      "availableBalance": 113701.84,
      "currency": "RUR"
    }
  ],
  "productTo": [
    {
      "maskCardNumber": "4469 15** **** 6491",
      "accountNumber": "40817810890011944828",
      "type": "debit",
      "availableBalance": 938.16,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "4469 15** **** 0321",
      "accountNumber": "40817810390100081752",
      "type": "debit",
      "availableBalance": 4464.15,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "5221 99** **** 7656",
      "accountNumber": "40817810190010108048",
      "type": "debit",
      "availableBalance": 290.42,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "4454 33** **** 5550",
      "accountNumber": "40817840890100000986",
      "type": "debit",
      "availableBalance": 381.19,
      "currency": "USD"
    },
    {
      "maskCardNumber": "5221 99** **** 3835",
      "accountNumber": "40817978090100000797",
      "type": "debit",
      "availableBalance": 679.6,
      "currency": "EUR"
    },
    {
      "accountNumber": "40817810690100011914",
      "type": "account",
      "availableBalance": 20,
      "currency": "RUR"
    },
    {
      "accountNumber": "40817810890012685704",
      "type": "account",
      "availableBalance": 113701.84,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "4460 98** **** 1177",
      "accountNumber": "40817810650100873710",
      "type": "credit",
      "availableBalance": 194104.54,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "5457 62** **** 5456",
      "accountNumber": "40817810650100873710",
      "type": "credit",
      "availableBalance": 194104.54,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "5259 33** **** 4335",
      "accountNumber": "40817810650100873710",
      "type": "credit",
      "availableBalance": 194104.54,
      "currency": "RUR"
    }
  ],
  "links": [
    {
      "rel": "commission",
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/transfer/v1/self/transfer/189d783a-8842-4435-bdb7-c8dd1bdd0275/commission"
    },
    {
      "rel": "type",
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/transfer/v1/self/transfer/189d783a-8842-4435-bdb7-c8dd1bdd0275/type"
    },
    {
      "rel": "confirm",
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/transfer/v1/self/transfer/189d783a-8842-4435-bdb7-c8dd1bdd0275/confirm"
    },
    {
      "rel": "product-list",
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/transfer/v1/self/transfer/189d783a-8842-4435-bdb7-c8dd1bdd0275/product-list"
    },
    {
      "rel": "product-list-v2",
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/transfer/v2/self/transfer/189d783a-8842-4435-bdb7-c8dd1bdd0275/product-list"
    },
    {
      "rel": "exchange-rates",
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/transfer/v1/self/transfer/189d783a-8842-4435-bdb7-c8dd1bdd0275/exchange-rates"
    }
  ]
}
