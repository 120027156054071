{
  "verifyNum": 3,
  "resendNum": 1,
  "links": [
    {
      "rel": "resend",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/visa-phone-transfer/v1/9f9682b5-5040-4425-bac2-d74d7b66006e/resend"
    },
    {
      "rel": "check",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/visa-phone-transfer/v1/9f9682b5-5040-4425-bac2-d74d7b66006e/check"
    }
  ]
}
