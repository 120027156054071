import { createMocks } from '@/features/developer/utils/createMocks'
import success from './success.json'
import sbpAvailable from './sbpAvailable.json'
import sberbankAvailable from './sberbankAvailable.json'

export const mockItems = createMocks([
  {
    title: 'Выбор из двух провайдеров',
    status: 200,
    data: success,
    description: '',
  },
  {
    title: 'Перевод по СБП',
    status: 200,
    data: sbpAvailable,
    description: '',
  },
  {
    title: 'Перевод только по номеру',
    status: 200,
    data: sberbankAvailable,
    description: '',
  },
  {
    title: 'Код 401',
    status: 401,
    data: null,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
