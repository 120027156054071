import React, { forwardRef, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import { Fade } from '@platform-ui/components/effects/Fade'
import { ClearIcon } from '@platform-ui/icons/ClearIcon'
import { Tooltip } from '@platform-ui/components/Tooltip'
import { Button } from '@platform-ui/components/Button'
import { Slide, SlideDirection } from '@platform-ui/components/effects/Slide'
import { TextAreaField } from '@platform-ui/components/TextAreaField'
import { IconButton } from '@platform-ui/components/IconButton'
import { DeveloperStoreModel } from '../../developerStore/DeveloperStoreModel'
import _isNaN from 'lodash/isNaN'
import { useDeveloperStore } from '../../developerStore'
import { GroupTitle } from '../../components/GroupTitle'
export type TFeaturesProps = {
  className?: string
  children?: React.ReactNode
}

const FormContainer = styled.div(() => ({}))

const Root = styled.div(({ theme }) => {
  return {
    maxHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  }
})

const Content = styled.div(({ theme }) => {
  return {
    padding: '8px 24px 50px',
    maxHeight: '100%',
    width: 'calc(100vw - 48px)',

    [`${theme.media.md}`]: {
      width: '480px',
    },
  }
})

const CheckboxWrapper = styled.label(({ theme }) => {
  return {
    fontFamily: theme.fontFamily.base,
    padding: '16px 0',
    display: 'inline-flex',
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'pointer',
    '& > span': {
      marginLeft: '8px',
    },
  }
})

const TitleContainer = styled.div(({ theme }) => {
  return {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    justifyContent: 'space-between',
  }
})

const Title = styled.h4(({ theme }) => {
  return {
    textTransform: 'capitalize',
    flexGrow: 1,
    fontFamily: theme.fontFamily.base,
  }
})

const Group = styled.div(({ theme }) => {
  return {
    padding: '24px',
    backgroundColor: '#f5f5f5',
  }
})

const Footer = styled(Slide)(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'sticky',
    bottom: 0,
    padding: '8px 24px',
    ['&::before']: {
      display: 'block',
      position: 'absolute',
      content: "''",
      right: '24px',
      left: '24px',
      bottom: '0px',
      top: '0px',
      backgroundColor: '#fff',
      borderTop: '1px solid #ddd',
      zIndex: -1,
    },
  }
})

const FieldBoolean: React.FC<{
  name: string
  state: boolean
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isDirty: boolean
  handleReset: () => void
}> = ({ name, state, handleCheckboxChange, isDirty, handleReset }) => {
  return (
    <TitleContainer>
      <CheckboxWrapper>
        <input type="checkbox" checked={state} onChange={handleCheckboxChange} />
        <span>{name}</span>
      </CheckboxWrapper>
      <Fade isOpen={isDirty} timeout={200}>
        <Tooltip title="Сбросить изменения" enterDelay={500} enterNextDelay={500}>
          <IconButton onClick={handleReset} type="button" view="primary">
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Fade>
    </TitleContainer>
  )
}

const FieldString: React.FC<{
  name: string
  state: string
  handleTextChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  isDirty: boolean
  handleReset: () => void
}> = ({ name, state, handleTextChange, isDirty, handleReset }) => {
  return (
    <>
      <TitleContainer>
        <Title>{name}</Title>
        <Fade isOpen={isDirty} timeout={200}>
          <Tooltip title="Сбросить изменения" enterDelay={500} enterNextDelay={500}>
            <IconButton onClick={handleReset} type="button" view="primary">
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Fade>
      </TitleContainer>
      <TextAreaField
        isFullWidth
        value={state}
        status={isDirty ? 'success' : 'default'}
        onChange={handleTextChange}
      />
    </>
  )
}

const Item = observer<{
  id: DeveloperStoreModel.FeatureEntityId
  handleCheckboxChange: (
    id: DeveloperStoreModel.FeatureEntityId,
    state: boolean
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void
  handleReset: (id: DeveloperStoreModel.FeatureEntityId) => () => void
  handleTextChange: (
    id: DeveloperStoreModel.FeatureEntityId
  ) => (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}>(function Item({ id, handleCheckboxChange, handleReset, handleTextChange }) {
  const developerStore = useDeveloperStore()
  const entity = developerStore.features.entities[id]

  const isDirty = entity.state !== entity.originState

  return (
    <div>
      <FormContainer>
        {entity.type === DeveloperStoreModel.FeatureType.Boolean ? (
          <FieldBoolean
            handleCheckboxChange={handleCheckboxChange(id, entity.state)}
            handleReset={handleReset(id)}
            isDirty={isDirty}
            state={entity.state}
            name={entity.name}
          />
        ) : entity.type === DeveloperStoreModel.FeatureType.String ? (
          <FieldString
            handleReset={handleReset(id)}
            isDirty={isDirty}
            state={entity.state}
            handleTextChange={handleTextChange(id)}
            name={entity.name}
          />
        ) : entity.type === DeveloperStoreModel.FeatureType.Number ? (
          <FieldString
            handleReset={handleReset(id)}
            isDirty={isDirty}
            state={`${entity.state}`}
            handleTextChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              const number = +event.currentTarget.value
              developerStore.changeFeatureState({
                state: _isNaN(number) ? 0 : number,
                id,
              })
            }}
            name={entity.name}
          />
        ) : (
          ''
        )}
      </FormContainer>
    </div>
  )
})

const GroupItems: React.FC<{
  level: number
  groupedIds: DeveloperStoreModel.FeatureGroupedIds
  handleCheckboxChange: (
    id: DeveloperStoreModel.FeatureEntityId,
    state: boolean
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void
  handleReset: (id: DeveloperStoreModel.FeatureEntityId) => () => void
  handleTextChange: (
    id: DeveloperStoreModel.FeatureEntityId
  ) => (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  name: DeveloperStoreModel.FeatureEntityName
}> = ({ groupedIds, handleCheckboxChange, handleReset, handleTextChange, name }) => {
  return (
    <>
      <TitleContainer>
        <Title>{name}</Title>
      </TitleContainer>
      <Group>{repeat(groupedIds, handleCheckboxChange, handleReset, handleTextChange)}</Group>
    </>
  )
}

function repeat(
  groupedIds: DeveloperStoreModel.FeatureGroupedIds,
  handleCheckboxChange: (
    id: DeveloperStoreModel.FeatureEntityId,
    state: boolean
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleReset: (id: DeveloperStoreModel.FeatureEntityId) => () => void,
  handleTextChange: (
    id: DeveloperStoreModel.FeatureEntityId
  ) => (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
  level = 0
) {
  return groupedIds.map((id, index) => {
    if (typeof id === 'object') {
      return (
        <GroupItems
          key={index}
          groupedIds={id.list}
          handleCheckboxChange={handleCheckboxChange}
          handleReset={handleReset}
          handleTextChange={handleTextChange}
          name={id.name}
          level={level + 1}
        />
      )
    }
    return (
      <Item
        key={id}
        id={id}
        handleCheckboxChange={handleCheckboxChange}
        handleReset={handleReset}
        handleTextChange={handleTextChange}
      />
    )
  })
}

export const Features = observer(
  forwardRef<HTMLDivElement, TFeaturesProps>(({ children, ...otherProps }, ref) => {
    const developerStore = useDeveloperStore()

    const handleResetAll = () => {
      developerStore.resetAllFeaturesState()
    }

    const handleCheckboxChange = useCallback(
      (id: DeveloperStoreModel.FeatureEntityId, state: boolean) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          developerStore.changeFeatureState({
            state: !state,
            id,
          })
        },
      []
    )

    const handleReset = useCallback(
      (id: DeveloperStoreModel.FeatureEntityId) => () => {
        developerStore.resetFeatureState({
          id,
        })
      },
      []
    )

    const handleTextChange = useCallback(
      (id: DeveloperStoreModel.FeatureEntityId) =>
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
          developerStore.changeFeatureState({
            state: event.currentTarget.value || '',
            id,
          })
        },
      []
    )

    return (
      <Root ref={ref} {...otherProps}>
        <Content>
          <GroupTitle>Features</GroupTitle>
          {repeat(
            developerStore.features.groupedIds,
            handleCheckboxChange,
            handleReset,
            handleTextChange
          )}
        </Content>
        <Footer
          isOpen={developerStore.isDirtyAllFeatures}
          timeout={200}
          direction={SlideDirection.Down}
        >
          <Tooltip title="Сбросить изменения" enterDelay={500} enterNextDelay={500}>
            <Button onClick={handleResetAll} type="button" view="primary">
              Сбросить все
            </Button>
          </Tooltip>
        </Footer>
      </Root>
    )
  })
)
