{
  "orderId": "203998515",
  "maskCardTo": "445433******0644",
  "maskCardFrom": "553691******5369",
  "expMonth": "12",
  "expYear": "29",
  "totalPaymentSum": 10,
  "paymentSum": 10,
  "commissionSum": 0,
  "paymentSystem": "MC",
  "tranDateTime": "2023-05-11T10:26:32",
  "merchantTranID": "MTY4Mzc4OTk3ODgwODAwMDAwMDA=",
  "status": "DECLINED",
  "statusDescription": "На счёте не хватает средств",
  "contractNumber": "7007915264",
  "changeType": false
}
