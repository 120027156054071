{
  "$id": "1",
  "Result": [
    {
      "$id": "2",
      "MaskCardNumber": "553691******5369",
      "BankShortName": "Тинькофф Банк",
      "CardColor": "222222",
      "ColorScheme": "ffffff",
      "BankLogo": "Images/Resources/Image/banklogo_Tinkoff.png"
    }
  ],
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
