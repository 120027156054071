import React, { forwardRef } from 'react'
import { observer } from 'mobx-react-lite'
import { Content, Root } from './styled'
import { LottiePlayer } from './LottiePlayer'
import { Toasts } from './Toasts'
import { GroupTitle } from '../../components/GroupTitle'
import { PaymentSuccessModal } from './PaymentSuccessModal'
import { VerifyModal } from './VarifyModal'
import { Authorization } from './Authorization'
import { PayListModal } from './PayListModal'
import { PopupModal } from './PopupModal'
export type TCapabilitiesProps = {
  className?: string
  children?: React.ReactNode
}

export const Capabilities = observer(
  forwardRef<HTMLDivElement, TCapabilitiesProps>(({ children, ...otherProps }, ref) => {
    return (
      <Root ref={ref} {...otherProps}>
        <Content>
          <GroupTitle>Capabilities</GroupTitle>
          <Toasts />
          <PopupModal />
          <PaymentSuccessModal />
          <PayListModal />
          <VerifyModal />
          <Authorization />
          <LottiePlayer />
        </Content>
      </Root>
    )
  })
)
