{
  "products": [
    {
      "maskCardNumber": "446915XXXXXX6491",
      "accountNumber": "40817810890011944828",
      "type": "debit",
      "availableBalance": 938.16,
      "currency": "RUR",
      "isDefault": false
    },
    {
      "maskCardNumber": "446915XXXXXX0321",
      "accountNumber": "40817810390100081752",
      "type": "debit",
      "availableBalance": 4464.15,
      "currency": "RUR",
      "isDefault": false
    },
    {
      "maskCardNumber": "522199XXXXXX7656",
      "accountNumber": "40817810190010108048",
      "type": "debit",
      "availableBalance": 290.42,
      "currency": "RUR",
      "isDefault": true
    },
    {
      "maskCardNumber": "545762XXXXXX0944",
      "accountNumber": "40817810150010109207",
      "type": "credit",
      "availableBalance": 106.0,
      "currency": "RUR",
      "isDefault": false
    },
    {
      "maskCardNumber": "446098XXXXXX4595",
      "accountNumber": "40817810150010109207",
      "type": "credit",
      "availableBalance": 106.0,
      "currency": "RUR",
      "isDefault": false
    }
  ]
}
