{
  "$id": "1",
  "Result": {
    "$id": "2",
    "ClientCards": [
      {
        "$id": "3",
        "ProductName": "Польза",
        "ProductType": "Кредитная",
        "ProductSubType": "RD",
        "AvailableBalance": 61500.0,
        "ProductStyle": 4,
        "MaskedCardNumber": "446098XXXXXX1903",
        "PaymentSystem": "VISA",
        "AccountNumber": "40817810350140291555",
        "Currency": "RUR"
      },
      {
        "$id": "4",
        "ProductName": "Карта рассрочки",
        "ProductType": "Кредитная",
        "ProductSubType": "RDTW",
        "AvailableBalance": 210000.0,
        "ProductStyle": 6,
        "MaskedCardNumber": "450726XXXXXX8487",
        "PaymentSystem": "VISA",
        "AccountNumber": "40817810193140001371",
        "Currency": "RUR"
      },
      {
        "$id": "5",
        "ProductName": "Карта рассрочки (неименная)",
        "ProductType": "Кредитная",
        "ProductSubType": "RDTW",
        "AvailableBalance": 210000.0,
        "ProductStyle": 6,
        "MaskedCardNumber": "450726XXXXXX0807",
        "PaymentSystem": "VISA",
        "AccountNumber": "40817810193140001371",
        "Currency": "RUR"
      },
      {
        "$id": "6",
        "ProductName": "Visa Classic",
        "ProductType": "Дебетовая",
        "ProductSubType": "DC",
        "AvailableBalance": 29500.0,
        "ProductStyle": 0,
        "MaskedCardNumber": "445433XXXXXX3618",
        "PaymentSystem": "VISA",
        "AccountNumber": "40817810990010575253",
        "Currency": "RUR"
      },
      {
        "$id": "7",
        "ProductName": "Польза",
        "ProductType": "Дебетовая",
        "ProductSubType": "DC",
        "AvailableBalance": 2794873.13,
        "ProductStyle": 10,
        "MaskedCardNumber": "446915XXXXXX6195",
        "PaymentSystem": "VISA",
        "AccountNumber": "40817810790011981619",
        "Currency": "RUR"
      },
      {
        "$id": "8",
        "ProductName": "Зелёная Польза",
        "ProductType": "Дебетовая",
        "ProductSubType": "DC",
        "AvailableBalance": 0.0,
        "ProductStyle": 9,
        "MaskedCardNumber": "446915XXXXXX0646",
        "PaymentSystem": "VISA",
        "AccountNumber": "40817810190011369763",
        "Currency": "RUR"
      },
      {
        "$id": "9",
        "ProductName": "Метро",
        "ProductType": "Дебетовая",
        "ProductSubType": "DC",
        "AvailableBalance": 14378.85,
        "ProductStyle": 0,
        "MaskedCardNumber": "446915XXXXXX6207",
        "PaymentSystem": "VISA",
        "AccountNumber": "40817810690011887282",
        "Currency": "RUR"
      },
      {
        "$id": "10",
        "ProductName": "Польза Travel",
        "ProductType": "Дебетовая",
        "ProductSubType": "DC",
        "AvailableBalance": 3004.2,
        "ProductStyle": 13,
        "MaskedCardNumber": "446915XXXXXX6879",
        "PaymentSystem": "VISA",
        "AccountNumber": "40817810590012536315",
        "Currency": "RUR"
      }
    ]
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
