import React, { forwardRef, useState } from 'react'
import { AppContext } from '@/models/AppContext'
import { useAppContext } from '@/core'
import { Tab } from '@platform-ui/components/Tab'
import { TextAreaField } from '@platform-ui/components/TextAreaField'
import { IconButton } from '@platform-ui/components/IconButton'
import { Fade } from '@platform-ui/components/effects/Fade'
import { ClearIcon } from '@platform-ui/icons/ClearIcon'
import { Tooltip } from '@platform-ui/components/Tooltip'
import { Button } from '@platform-ui/components/Button'
import { Checkbox } from '@platform-ui/components/Checkbox'
import { Accordion } from '@platform-ui/components/Accordion'
import { CheckboxButton } from '@platform-ui/components/CheckboxButton'
import { observer } from 'mobx-react-lite'
import { useDeveloperStore } from '../../developerStore'
import { DeveloperStoreModel } from '../../developerStore/DeveloperStoreModel'
import _startCase from 'lodash/startCase'
import _uniq from 'lodash/uniq'
import { ChevronDownIcon } from '@platform-ui/icons/ChevronDownIcon'
import {
  Content,
  EmptyMockMessage,
  Footer,
  FormContainer,
  GroupTitle,
  GroupTitleLeftIcon,
  MockTitle,
  Root,
  SwitchRoot,
  TabsCustom,
  Title,
  TitleContainer,
  TitleText,
  TitleWrapper,
} from './styled'

export interface ApiWorkspaceProps {
  className?: string
  children?: React.ReactNode
}

const Item = observer(
  forwardRef<
    HTMLDivElement,
    {
      name: DeveloperStoreModel.ApiOptionName
      isDirty: boolean
      apiOption: DeveloperStoreModel.ApiOption
      groupName: keyof DeveloperStoreModel.ApiOptions
    }
  >((props, ref) => {
    const { groupName, name, isDirty, apiOption } = props
    const developerStore = useDeveloperStore()

    const mockList: DeveloperStoreModel.MockItem[] = developerStore.mocks[groupName][name]

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      developerStore.setApiOption({
        data: {
          ...apiOption,
          path: event.currentTarget.value || '',
        },
        groupName,
        name,
      })
    }

    const handleReset = () => {
      developerStore.setApiOption({
        data: {
          ...apiOption,
          path: apiOption.originPath,
        },
        groupName,
        name,
      })
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const firstMockElement = mockList.length ? mockList[0] : null
      developerStore.setApiOption({
        data: event.currentTarget.checked
          ? {
              ...apiOption,
              isMockActive: true,
              control: {
                delay: 0,
                mock: firstMockElement
                  ? {
                      data: firstMockElement.data,
                      status: firstMockElement.status,
                    }
                  : null,
              },
              selectedMockId: firstMockElement ? firstMockElement.id : null,
            }
          : {
              ...apiOption,
              isMockActive: false,
              control: null,
              selectedMockId: null,
            },
        groupName,
        name,
      })
    }
    const handleSelectMockChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedMockId = event.currentTarget.value
      const mock = mockList.find((elem) => elem.id === selectedMockId)

      developerStore.setApiOption({
        data: {
          ...apiOption,
          selectedMockId: selectedMockId,
          control: apiOption.control
            ? {
                ...apiOption.control,
                mock: {
                  data: mock.data,
                  status: mock.status,
                },
              }
            : {
                delay: 0,
                mock: {
                  data: mock.data,
                  status: mock.status,
                },
              },
        },
        groupName,
        name,
      })
    }

    const handleSelectMockAwaitMs = (event: React.ChangeEvent<HTMLSelectElement>) => {
      developerStore.setApiOption({
        data: {
          ...apiOption,
          control: apiOption.control
            ? {
                ...apiOption.control,
                delay: +event.currentTarget.value,
              }
            : {
                delay: +event.currentTarget.value,
              },
        },
        groupName,
        name,
      })
    }

    return (
      <FormContainer>
        <TitleContainer>
          <TitleWrapper>
            <Title>
              <Checkbox isChecked={apiOption.isMockActive} onChange={handleCheckboxChange} />
              <TitleText>{name}</TitleText>
            </Title>
          </TitleWrapper>
          <Fade isOpen={isDirty} timeout={200}>
            <Tooltip title="Сбросить изменения" enterDelay={500} enterNextDelay={500}>
              <IconButton onClick={handleReset} type="button" view="primary">
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Fade>
        </TitleContainer>
        <TextAreaField
          isFullWidth
          value={apiOption.path}
          status={isDirty ? 'success' : 'default'}
          onChange={handleTextChange}
        />

        <MockTitle>
          <Fade isOpen={apiOption.isMockActive && mockList.length > 0} css={{ minWidth: 0 }}>
            <select
              value={apiOption.isMockActive && apiOption.selectedMockId}
              onChange={handleSelectMockChange}
            >
              {mockList.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.title}
                    {item.description ? ` - ${item.description}` : ''}
                  </option>
                )
              })}
            </select>
          </Fade>
          <Fade isOpen={apiOption.isMockActive}>
            <select value={apiOption.control?.delay} onChange={handleSelectMockAwaitMs}>
              {[0, 400, 1000, 5000, 20000].map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}ms
                  </option>
                )
              })}
            </select>
          </Fade>
        </MockTitle>
        <Fade isOpen={apiOption.isMockActive && mockList.length === 0}>
          <EmptyMockMessage>
            Моки для данного метода еще не были подключены! см. настройки сервера BFF, по
            возможности добавьте свои по пути <strong>{`${groupName}.${name}`}</strong>
          </EmptyMockMessage>
        </Fade>
      </FormContainer>
    )
  })
)

const groupOpenState = new Set<string>()

const Group = (props: {
  groupName: keyof DeveloperStoreModel.ApiOptions
  list: {
    name: DeveloperStoreModel.ApiOptionName
    isDirty: boolean
    apiOption: DeveloperStoreModel.ApiOption
  }[]
}) => {
  const { groupName, list } = props
  const [isOpen, setIsOpen] = useState(() => groupOpenState.has(groupName))
  const handleClick = () => {
    if (isOpen) {
      setIsOpen(false)
      groupOpenState.delete(groupName)
    } else {
      setIsOpen(true)
      groupOpenState.add(groupName)
    }
  }

  return (
    <div key={groupName}>
      <GroupTitle
        onClick={handleClick}
        view="default"
        iconOffsetSm="start"
        leftIcon={
          <GroupTitleLeftIcon isOpen={isOpen}>
            <ChevronDownIcon />
          </GroupTitleLeftIcon>
        }
      >
        {_startCase(groupName)}
      </GroupTitle>
      <Accordion isOpen={isOpen}>
        {list.map(({ name, isDirty, apiOption }) => (
          <Item
            key={name}
            apiOption={apiOption}
            groupName={groupName}
            isDirty={isDirty}
            name={name}
          />
        ))}
      </Accordion>
    </div>
  )
}
export const SwitchStand = observer(function SwitchDevice({ children, ...otherProps }) {
  const developerStore = useDeveloperStore()

  return (
    <SwitchRoot>
      <TabsCustom
        value={developerStore.switchStand.activeStandId}
        onChange={(_, value) => {
          developerStore.switchStand.setActiveStandId(`${value}`)
        }}
      >
        {developerStore.switchStand.standList.map((item) => (
          <Tab key={item.id} value={item.id}>
            {item.name.toUpperCase()}
          </Tab>
        ))}
      </TabsCustom>
    </SwitchRoot>
  )
})

export const ApiWorkspace = observer<ApiWorkspaceProps, HTMLDivElement>(
  forwardRef(({ children, ...otherProps }, ref) => {
    const developerStore = useDeveloperStore()
    const context = useAppContext<AppContext>()

    const handleAllReset = () => {
      developerStore.resetAllApiOptionsOriginPath()
      developerStore.switchStand.reset()
    }

    const handleSetMocksStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
      developerStore.setMockApiOptionsStatus(event.currentTarget.checked)
    }

    const handleDevModeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
      developerStore.setDevModeValue(event.currentTarget.checked)
    }

    const handleSetDevModeApiUrls = (event: React.ChangeEvent<HTMLInputElement>) => {
      developerStore.setDevModeApiUrls(event.currentTarget.checked)
    }

    return (
      <Root ref={ref} {...otherProps}>
        <Content>
          {developerStore.getApiOptions.map(({ groupName, list }, index) => (
            <Group list={list} groupName={groupName} key={groupName} />
          ))}
          <CheckboxButton
            isChecked={context.config.devMode.isActive && context.config.devMode.isActiveApiUrl}
            onChange={handleSetDevModeApiUrls}
          >
            Включить изменение api и mocks при старте веб приложения
          </CheckboxButton>
        </Content>
        <Footer>
          <SwitchStand />
          <div>
            <CheckboxButton
              isChecked={developerStore.isEveryMocksActive}
              isIndeterminate={developerStore.isSomeMocksActive}
              onChange={handleSetMocksStatus}
            >
              Включить моки для всех запросов
            </CheckboxButton>

            <Button
              isDisabled={!developerStore.isSomeDirtyApiOptions}
              onClick={handleAllReset}
              type="button"
              view="primary"
              size="m"
            >
              Сбросить
            </Button>
          </div>
          <div>
            <CheckboxButton
              isChecked={context.config.devMode.isOpenedDevTools}
              onChange={handleDevModeValue}
            >
              Включить react-query dev tools
            </CheckboxButton>
          </div>
        </Footer>
      </Root>
    )
  })
)
