import success from './success.json'
import successEmpty from './success-empty.json'
import { createMocks } from '@/features/developer/utils/createMocks'

export const mockItems = createMocks([
  {
    title: 'Альфа, Открытие, Дальневосточный',
    status: 200,
    data: success,
    description: '',
  },
  {
    title: 'Нет карт',
    status: 200,
    data: successEmpty,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
