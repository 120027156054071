import { createMocks } from '@/features/developer/utils/createMocks'
import fileId1 from './success.json'
import fileId2 from './success-empty.json'

export const mockItems = createMocks([
  {
    title: 'Успешно',
    status: 200,
    data: fileId1,
    description: '',
  },
  {
    title: '200, но без result',
    status: 200,
    data: fileId2,
    description: '',
  },
  {
    title: 'Код 401',
    status: 401,
    data: null,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
