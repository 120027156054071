{
  "contractNumber": "7008000019",
  "minPaymentSum": 10,
  "maxPaymentSum": 150000,
  "nextPaymentInfo": [],
  "_links": {
    "bin-check": {
      "href": "https://balancer-gateway-rt.omni.homecredit.ru:8112/commissar/v1/bin-check"
    },
    "execution": {
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/cascader/v1/payments/dd6646c8-881e-4017-a5f8-4d6e2f45873b/execution"
    },
    "commission": {
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/cascader/v1/payments/dd6646c8-881e-4017-a5f8-4d6e2f45873b/commission?bin={bin}&paymentSum={paymentSum}",
      "templated": true
    },
    "commission-tariff": {
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/cascader/v1/payments/dd6646c8-881e-4017-a5f8-4d6e2f45873b/commission-tariff?bin={bin}",
      "templated": true
    }
  }
}
