import { createMocks } from '@/features/developer/utils/createMocks'
import debitCardTransactionsSuccess from './debitCardTransactionsSuccess.json'
import accountTransactionsSuccess from './accountTransactionsSuccess.json'

export const mockItems = createMocks([
  {
    title: 'Success debitCard',
    status: 200,
    data: debitCardTransactionsSuccess,
    description: '',
  },
  {
    title: 'Success account',
    status: 200,
    data: accountTransactionsSuccess,
    description: '',
  },
  {
    title: 'Нет транзакций',
    status: 200,
    data: [],
    description: '',
  },
  {
    title: 'Код 400',
    status: 400,
    data: null,
    description: '',
  },
])
