{
  "productFrom": [
    {
      "maskCardNumber": "446915XXXXXX6491",
      "accountNumber": "40817810890011944828",
      "type": "debit",
      "availableBalance": 938.16,
      "currency": "RUR"
    }
  ],
  "productTo": [
    {
      "maskCardNumber": "2200 50** **** 1559",
      "accountNumber": "40817810290013007651",
      "type": "debit",
      "availableBalance": 23107.89,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "2200 50** **** 1542",
      "accountNumber": "40817810590013007649",
      "type": "debit",
      "availableBalance": 98140199.14,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "4469 15** **** 9598",
      "accountNumber": "40817810290012082855",
      "type": "debit",
      "availableBalance": 334099.38,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "2200 50** **** 4369",
      "accountNumber": "40817810790013034001",
      "type": "debit",
      "availableBalance": 395352.25,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "2200 50** **** 9907",
      "accountNumber": "40817810090190119316",
      "type": "debit",
      "availableBalance": 55413.56,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "2200 50** **** 3615",
      "accountNumber": "40817810090190119316",
      "type": "debit",
      "availableBalance": 55413.56,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "4454 34** **** 1088",
      "accountNumber": "40817810090010320337",
      "type": "debit",
      "availableBalance": 1196920.43,
      "currency": "RUR"
    },
    {
      "accountNumber": "42303810750010077827",
      "type": "deposit",
      "availableBalance": 4300,
      "currency": "RUR"
    },
    {
      "accountNumber": "42301810040640519548",
      "type": "loan",
      "availableBalance": 25000,
      "currency": "RUR"
    },
    {
      "accountNumber": "42301810640640542148",
      "type": "loan",
      "availableBalance": 10000,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "2200 50** **** 0339",
      "accountNumber": "40817810693640032230",
      "type": "credit",
      "availableBalance": 0.14,
      "currency": "RUR"
    }
  ],
  "links": [
    {
      "rel": "commission",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/commission"
    },
    {
      "rel": "type",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/type"
    },
    {
      "rel": "confirm",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/confirm"
    },
    {
      "rel": "product-list",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/product-list"
    },
    {
      "rel": "product-list-v2",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v2/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/product-list"
    },
    {
      "rel": "exchange-rates",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/exchange-rates"
    }
  ]
}
