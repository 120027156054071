{
  "contractNumber": "2393398867",
  "covenantId": "OptimalRate",
  "title": "Процентная ставка 6,9%",
  "description": "Для сохранения указанной пониженной ставки совершите покупки ещё на 40\u00A0000,00 ₽",
  "periodDescription": "Период трат заканчивается 26.05.2023",
  "conditionsTitle": "Процентная ставка",
  "status": 0,
  "progress": 0,
  "conditions": [
    {
      "conditionId": "ExpenseAmount",
      "title": "6,9% годовых",
      "description": "Совершите ещё покупок на 40\u00A0000,00 ₽ до конца периода трат, чтобы сохранить указанную пониженную процентную ставку",
      "remainValue": "40\u00A0000,00 ₽",
      "remainTitle": "Осталось",
      "currentValue": "0,00 ₽",
      "currentTitle": "Сумма покупок",
      "status": 0,
      "progress": 0
    }
  ]
}
