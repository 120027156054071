import { createMocks } from '@/features/developer/utils/createMocks'
import errorFile from './error.json'
import resendNum1 from './resendNum1.json'
import resendNum0 from './resendNum0.json'

export const mockItems = createMocks([
  {
    title: 'Остались попытки',
    status: 200,
    data: resendNum1,
    description: '',
  },
  {
    title: 'Попыток не осталось',
    status: 200,
    data: resendNum0,
    description: '',
  },
  {
    title: 'Ошибка при превышении лимита отправки otp',
    status: 500,
    data: errorFile,
    description: '',
  },
  {
    title: 'Код 401',
    status: 401,
    data: null,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
