{
  "$id": "1",
  "Result": {
    "CheckType": 1,
    "ResultText": "Блокировка, по причине изменения мобильного телефона менее, чем phoneChangePeriod часов назад"
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
