import { createMocks } from '@/features/developer/utils/createMocks'
import success from './success.json'
import successHomeCard from './success-homeCard.json'
import successOneProduct from './success-one-product.json'
import emptyData from './empty.json'

export const mockItems = createMocks([
  {
    title: 'Успешно',
    status: 200,
    data: success,
    description: '',
  },
  {
    title: 'Хоум карта',
    status: 200,
    data: successHomeCard,
    description: '',
  },
  {
    title: 'Одна дебетовая карта',
    status: 200,
    data: successOneProduct,
    description: '',
  },
  {
    title: 'Пустой ответ',
    status: 200,
    data: emptyData,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
