{
  "$id": "1",
  "StatusCode": 500,
  "Errors": [],
  "ErrorDetails": [
    {
      "Title": "Ошибка",
      "Error": "Не удалось загрузить ссылку на ЛК польза"
    }
  ],
  "ContainsAnyError": true,
  "ContainsAnyWarning": false,
  "ContainsAnyInfo": false
}
