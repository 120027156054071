import React from 'react'
import { DeveloperStoreProvider } from './developerStore'
import { Main } from './containers/Main'

export const Developer: React.FC = () => {
  return (
    <DeveloperStoreProvider>
      <Main />
    </DeveloperStoreProvider>
  )
}

export default Developer
