{
  "$id": "1",
  "Result": {
    "$id": "2",
    "ServerDate": "2022-05-30T00:00:00+03:00",
    "CreditLoan": [],
    "CreditCard": [],
    "CreditCardTW": [],
    "IsCreditLoanAndCardError": false,
    "IsCreditCardTWError": false,
    "IsAvailableEarlyRepayment": false
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
