{
  "StatusCode": 401,
  "Errors": [],
  "ErrorDetails": [
    {
      "Title": "Не могу войти",
      "Error": "Проверьте дату рождения и номер мобильного телефона. Введите номер телефона, который Вы указывали при заключении договора с Банком. Если Вы сменили номер телефона, обратитесь в офис Банка",
      "ErrorType": 1
    }
  ]
}
