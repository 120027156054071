{
  "$id": "1",
  "Result": [
    {
      "$id": "2",
      "ContractNumber": "7317000350",
      "CardNumber": "446915XXXXXX9742",
      "CardMBR": 1,
      "DisplayOrder": 1,
      "IsHidden": false,
      "ChangedProductName": "Детская карта"
    },
    {
      "$id": "3",
      "ContractNumber": "7317000341",
      "CardNumber": "446915XXXXXX9841",
      "CardMBR": 1,
      "DisplayOrder": 2,
      "IsHidden": false,
      "ChangedProductName": "Детская карта"
    },
    {
      "$id": "4",
      "ContractNumber": "7008000019",
      "CardNumber": "446915XXXXXX8920",
      "CardMBR": 0,
      "DisplayOrder": 3,
      "IsHidden": false,
      "ChangedProductName": "Польза ЗП"
    },
    {
      "$id": "5",
      "ContractNumber": "7005436812",
      "CardNumber": "445433XXXXXX1786",
      "CardMBR": 0,
      "DisplayOrder": 4,
      "IsHidden": false,
      "ChangedProductName": "Польза ЗП"
    },
    {
      "$id": "6",
      "ContractNumber": "offer1049",
      "DisplayOrder": 5,
      "IsHidden": false,
      "ChangedProductName": "Детская карта"
    },
    {
      "$id": "7",
      "ContractNumber": "7209248202",
      "CardNumber": "446915XXXXXX8562",
      "CardMBR": 0,
      "DisplayOrder": 1,
      "IsHidden": true,
      "ChangedProductName": "Польза"
    }
  ],
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
