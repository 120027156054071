{
  "$id": "1",
  "Result": [
    {
      "$id": "2",
      "MaskCardNumber": "553691******5369",
      "BankShortName": "Тинькофф Банк",
      "CardColor": "222222",
      "ColorScheme": "ffffff",
      "BankLogo": "Images/Resources/Image/banklogo_Tinkoff.png"
    },
    {
      "$id": "3",
      "MaskCardNumber": "427616******8468",
      "BankShortName": "Сбербанк",
      "CardColor": "1E8942",
      "ColorScheme": "ffffff",
      "BankLogo": "Images/Resources/Image/banklogo_Sberbank.png"
    },
    {
      "$id": "4",
      "MaskCardNumber": "510621******7752"
    },
    {
      "$id": "5",
      "MaskCardNumber": "427668******0909",
      "BankShortName": "Сбербанк",
      "CardColor": "1E8942",
      "ColorScheme": "ffffff",
      "BankLogo": "Images/Resources/Image/banklogo_Sberbank.png"
    }
  ],
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
