{
  "Result": [
    {
      "CardId": "dc9742446915",
      "MaskCardNumber": "446098XXXXXX9262",
      "Enrolment": "в течение 1 рабочего дня"
    },
    {
      "CardId": "cc9262446098",
      "MaskCardNumber": "446915XXXXXX9742",
      "Enrolment": "в течение 2 рабочих дней"
    }
  ]
}
