{
  "$id": "1",
  "result": {
    "productFrom": [
      {
        "maskCardNumber": "4469 15** **** 9498",
        "accountNumber": "40817810790012545944",
        "type": 1,
        "availableBalance": 1913.36,
        "currency": "RUR"
      },
      {
        "maskCardNumber": "2200 50** **** 8244",
        "accountNumber": "40817810690012553780",
        "type": 1,
        "availableBalance": 672.0,
        "currency": "RUR"
      },
      {
        "maskCardNumber": null,
        "accountNumber": "40817810790012553774",
        "type": 3,
        "availableBalance": 0.0,
        "currency": "RUR"
      }
    ],
    "productTo": null,
    "links": [
      {
        "rel": "commission",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/account/transfer/1af77cca-975e-4d2a-ad8f-c86e6838bbdd/commission"
      },
      {
        "rel": "type",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/account/transfer/1af77cca-975e-4d2a-ad8f-c86e6838bbdd/type"
      },
      {
        "rel": "confirm",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/account/transfer/1af77cca-975e-4d2a-ad8f-c86e6838bbdd/confirm"
      }
    ],
    "resultCode": 0,
    "resultText": null
  },
  "statusCode": 200,
  "errors": [],
  "errorDetails": [],
  "containsAnyError": false,
  "containsAnyWarning": false,
  "containsAnyInfo": false
}
