{
  "Result": {
    "Fee": 0,
    "SumCheckResult": "CardBalanceExceeded",
    "Description": "Введённая сумма превышает доступный остаток"
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
