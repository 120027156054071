{
  "StatusCode": 500,
  "Result": "string",
  "Errors": ["string"],
  "ErrorDetails": [
    {
      "Title": "string",
      "Error": "string",
      "ErrorType": 0
    }
  ]
}
