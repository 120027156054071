import React from 'react'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Button } from '@platform-ui/components/Button'
import { ButtonList, Title, TitleContainer } from './styled'

export const PopupModal = (): JSX.Element => {
  const context = useAppContext<AppContext>()

  return (
    <>
      <TitleContainer>
        <Title>Popup</Title>
      </TitleContainer>
      <ButtonList>
        <Button
          size="m"
          onClick={() =>
            context.eventBus.popupModal.open.emit({
              popup: {
                id: 41,
                templateId: 5020,
                url: 'https://sales-father-images-nginx-rt.omni.homecredit.ru/images/popup_5020_2fb1cf35-833d-40bc-87e1-4d158d4d1363.html',
                channelNumber: 9427,
                priority: null,
                isCloseButtonEnabled: true,
                actions: [
                  {
                    targetId: null,
                    target: 'deeplink',
                    location: '',
                    link: 'https://4191472.redirect.appmetrica.yandex.com/?ClientAction=ReviewOfInformation&docType=PDNExceeding&contractNumber=7321111111&applicationNumber=3333-3333333&pdn=55,07&appmetrica_tracking_id=749091024537652113',
                    nameInFile: 'popupAction-0',
                    productIdent: null,
                  },
                ],
                displayScreens: [
                  {
                    id: 31,
                  },
                  {
                    id: 34,
                  },
                ],
                offerManagerInfo: null,
              },
            })
          }
        >
          Открыть
        </Button>
        <Button
          size="m"
          onClick={() => context.eventBus.popupModal.close.emit({ action: 'close' })}
        >
          Закрыть
        </Button>
      </ButtonList>
    </>
  )
}
