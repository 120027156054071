import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import { AppContext } from '@/models/AppContext'
import { useAppContext } from '@/core'
import { Tabs } from '@platform-ui/components/Tabs'
import { Tab } from '@platform-ui/components/Tab'
import { DeviceType } from '@/models/device'
import { GroupTitle } from '../../components/GroupTitle'
export type DeviceProps = {
  className?: string
  children?: React.ReactNode
}

const Root = styled.div(() => {
  return {
    maxHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  }
})

const Content = styled.div(({ theme }) => {
  return {
    padding: '8px 24px 50px',
    maxHeight: '100%',
    width: 'calc(100vw - 48px)',

    [`${theme.media.md}`]: {
      width: '480px',
    },
  }
})

const TitleContainer = styled.div(() => {
  return {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    justifyContent: 'space-between',
  }
})

const Title = styled.h4(({ theme }) => {
  return {
    textTransform: 'capitalize',
    flexGrow: 1,
    fontFamily: theme.fontFamily.base,
  }
})

const DEVICES: Record<DeviceType, number> = {
  [DeviceType.Mobile]: 0,
  [DeviceType.Tablet]: 1,
  [DeviceType.Desktop]: 2,
}

export const SwitchDevice = observer(function SwitchDevice() {
  const context = useAppContext<AppContext>()

  const value = DEVICES[context.config.device.type]
  return (
    <Tabs
      value={value}
      onChange={(_, value) => {
        context.config.setState((state) => {
          state.device.type = (Object.keys(DEVICES) as DeviceType[]).find((key) => {
            return DEVICES[key] === value
          })

          switch (state.device.type) {
            case DeviceType.Mobile:
              state.device.isMobile = true
              state.device.isTablet = false
              state.device.isDesktop = false
              break
            case DeviceType.Tablet:
              state.device.isMobile = false
              state.device.isTablet = true
              state.device.isDesktop = false
              break
            case DeviceType.Desktop:
              state.device.isMobile = false
              state.device.isTablet = false
              state.device.isDesktop = true
              break
          }

          return state
        })
      }}
    >
      <Tab>Mobile</Tab>
      <Tab>Tablet</Tab>
      <Tab>Desktop</Tab>
    </Tabs>
  )
})

const RootNetwork = styled.div(() => {
  return {}
})

const Item = styled.div(() => {
  return {
    display: 'flex',
    marginBottom: '8px',
  }
})

const ItemTitle = styled.div(() => {
  return {
    width: '120px',
  }
})

const ItemDescr = styled.div(() => {
  return {
    fontWeight: 700,
  }
})
export const Network = observer(function SwitchDevice() {
  const context = useAppContext<AppContext>()

  return (
    <RootNetwork>
      <Item>
        <ItemTitle>online:</ItemTitle>
        <ItemDescr>{String(context.network.online)}</ItemDescr>
      </Item>
      <Item>
        <ItemTitle>effectiveType:</ItemTitle>
        <ItemDescr>{context.network.effectiveType}</ItemDescr>
      </Item>
      <Item>
        <ItemTitle>downlink:</ItemTitle>
        <ItemDescr>{context.network.downlink}</ItemDescr>
      </Item>
      <Item>
        <ItemTitle>rtt:</ItemTitle>
        <ItemDescr>{context.network.rtt}</ItemDescr>
      </Item>
      <Item>
        <ItemTitle>saveData:</ItemTitle>
        <ItemDescr>{String(context.network.saveData)}</ItemDescr>
      </Item>
    </RootNetwork>
  )
})

export const Device = observer(
  forwardRef<HTMLDivElement, DeviceProps>((props, ref) => {
    return (
      <Root ref={ref} {...props}>
        <Content>
          <GroupTitle>Device</GroupTitle>
          <TitleContainer>
            <Title>Переключить устройство</Title>
          </TitleContainer>
          <SwitchDevice />

          <TitleContainer>
            <Title>Сеть</Title>
          </TitleContainer>
          <Network />
        </Content>
      </Root>
    )
  })
)
