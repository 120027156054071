import { HttpClientModel } from '@/core'
import { Config } from '@/models/Config'
import { Rules } from '@/models/Rules'
type OptionState<T> = {
  origin: T
  value: T
  isDirty: boolean
}

export namespace DeveloperStoreModel {
  export type Stand = string

  export type AccessRules = Rules[]

  export type AccessRulesState = OptionState<Set<Rules>>

  export type ApiOptionName = {
    [T in keyof ApiOptions]: keyof ApiOptions[T]
  }[keyof ApiOptions]

  export type InitApiAction = {
    apiOptions: ApiOptions
  }

  export type ApiOptions = {
    [groupName in keyof Config['api']]?: {
      [name in keyof Config['api'][groupName]]?: ApiOption
    }
  }

  export type ApiOption = {
    originPath: string
    path: string
    isMockActive: boolean
    control?: HttpClientModel.Control
    selectedMockId?: string | null
  }

  export type Mocks = Record<string, Record<string, MockItem[]>>

  export type MockItem = {
    id: string
    title: string
    description?: string
    data: any
    status: number
  }

  export enum FeatureType {
    Boolean,
    Number,
    String,
  }

  export type FeatureEntityId = string
  export type FeatureEntityBoolean = {
    id: FeatureEntityId
    name: FeatureEntityName
    type: FeatureType.Boolean
    originState: boolean
    state: boolean
  }
  export type FeatureEntityNumber = {
    id: FeatureEntityId
    name: FeatureEntityName
    type: FeatureType.Number
    originState: number
    state: number
  }
  export type FeatureEntityString = {
    id: FeatureEntityId
    name: FeatureEntityName
    type: FeatureType.String
    originState: string
    state: string
  }
  export type FeatureEntity = FeatureEntityBoolean | FeatureEntityNumber | FeatureEntityString

  export type FeatureEntityName = string
  export type FeatureGroupItem = {
    name: FeatureEntityName
    list: FeatureGroupedIds
  }
  export type FeatureGroupedIds = (FeatureEntityId | FeatureGroupItem)[]
  export type FeatureEntities = Record<FeatureEntityId, FeatureEntity>
  export type Features = {
    groupedIds: FeatureGroupedIds
    entities: FeatureEntities
  }
}
