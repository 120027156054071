{
  "$id": "1",
  "Result": [
    {
      "$id": "2",
      "MaskCardNumber": "555949******1452",
      "BankShortName": "Альфабанк",
      "CardColor": "CC2D23",
      "ColorScheme": "ffffff",
      "BankLogo": "https://mob.homecredit.ru/mycredit/Images/Resources/Image/banklogo_Alfa.svg"
    },
    {
      "$id": "3",
      "MaskCardNumber": "484800******5578",
      "BankShortName": "Банк Открытие",
      "CardColor": "00B2D2",
      "ColorScheme": "ffffff",
      "BankLogo": "https://myc-test.homecredit.ru/rp/api/Images/Resources/Image/banklogo_Otkritie.svg"
    },
    {
      "$id": "4",
      "MaskCardNumber": "416084******6350",
      "BankShortName": "Дальневосточный банк",
      "CardColor": "005B4B",
      "ColorScheme": "ffffff",
      "BankLogo": "https://myc-test.homecredit.ru/rp/api/Images/Resources/Image/banklogo_DVB.svg"
    }
  ],
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
