{
  "$id": "1",
  "Result": {
    "$id": "2",
    "ServerDate": "2021-03-15T00:00:00+03:00",
    "CreditLoan": [],
    "CreditCard": [],
    "CreditCardTW": [],
    "IsCreditLoanAndCardError": true,
    "IsCreditCardTWError": false,
    "IsAvailableEarlyRepayment": false
  },
  "StatusCode": 200,
  "Errors": ["Возможно, отображены не все Ваши продукты"],
  "ErrorDetails": [
    {
      "$id": "3",
      "Title": "Ведутся сервисные работы",
      "Error": "Возможно, отображены не все Ваши продукты",
      "ErrorType": 2
    }
  ]
}
