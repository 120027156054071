{
  "Result": {
    "$id": "2",
    "ServerDate": "2024-04-18T00:00:00+03:00",
    "ClientReferenceInfo": [
      {
        "$id": "3",
        "ContractStatus": 1,
        "Colour": 1,
        "DisplayOrder": 1,
        "ReferenceParams": {
          "$id": "4",
          "RefCode": 1,
          "IsDebit": false,
          "RefName": "Справка о полном погашении задолженности",
          "RefProductName": "Кредит на товар 21 624.00 ",
          "ContractNumber": "2288331322",
          "CreateDateTime": "2024-04-25T08:54:36.098929",
          "RefNumber": "123"
        },
        "RefImageUrl": "https://myc-test-f5.homecredit.ru/rt/api/Images/Reference/Reference_1.png",
        "IsAutogenRef": false,
        "IsChatOnly": false,
        "ProductType": 1,
        "ProductTag": "Кредит 23 000",
        "RefHeadlineName": "Кредит 21 624.00 "
      },
      {
        "$id": "5",
        "ContractStatus": 1,
        "Colour": 2,
        "DisplayOrder": 2,
        "ReferenceParams": {
          "$id": "6",
          "RefCode": 1,
          "IsDebit": false,
          "RefName": "Справка о полном погашении задолженности",
          "RefProductName": "Кредит на товар 7 545.00 ",
          "ContractNumber": "2880785860",
          "CreateDateTime": "2024-02-25T08:54:36.098929",
          "RefNumber": "1234"
        },
        "RefImageUrl": "https://myc-test-f5.homecredit.ru/rt/api/Images/Reference/Reference_1.png",
        "IsAutogenRef": true,
        "IsChatOnly": false,
        "ProductType": 2,
        "ProductTag": "Кредит 23 000",
        "RefHeadlineName": "Кредит 7 545.00 "
      },
      {
        "$id": "7",
        "ContractStatus": 1,
        "Colour": 3,
        "DisplayOrder": 1,
        "ReferenceParams": {
          "$id": "8",
          "RefCode": 4,
          "IsDebit": false,
          "RefName": "Справка о своевременном погашении задолженности",
          "RefProductName": "Кредит на товар 21 624.00 ",
          "ContractNumber": "2288331322",
          "CreateDateTime": "2024-04-23T08:54:36.098929",
          "RefNumber": "1235"
        },
        "RefImageUrl": "https://myc-test-f5.homecredit.ru/rt/api/Images/Reference/Reference_2.png",
        "IsAutogenRef": true,
        "IsChatOnly": false,
        "ProductType": 3,
        "ProductTag": "Кредит 23 000",
        "RefHeadlineName": "Кредит 21 624.00 "
      },
      {
        "$id": "9",
        "ContractStatus": 1,
        "Colour": 4,
        "DisplayOrder": 2,
        "ReferenceParams": {
          "$id": "10",
          "RefCode": 4,
          "IsDebit": false,
          "RefName": "Справка о своевременном погашении задолженности",
          "RefProductName": "Кредит на товар 7 545.00 ",
          "ContractNumber": "2880785860",
          "CreateDateTime": "2024-03-25T08:54:36.098929",
          "RefNumber": "1236"
        },
        "RefImageUrl": "https://myc-test-f5.homecredit.ru/rt/api/Images/Reference/Reference_2.png",
        "IsAutogenRef": false,
        "IsChatOnly": false,
        "ProductType": 4,
        "ProductTag": "Кредит 23 000",
        "RefHeadlineName": "Кредит 7 545.00 "
      },
      {
        "$id": "11",
        "ContractStatus": 2,
        "Colour": 5,
        "DisplayOrder": 0,
        "ReferenceParams": {
          "$id": "12",
          "RefCode": 3,
          "IsDebit": false,
          "RefName": "Справка о параметрах кредитного договора",
          "RefProductName": "Польза 0.00 ",
          "ContractNumber": "2217832621",
          "CreateDateTime": "2024-04-21T08:54:36.098929",
          "RefNumber": "1237"
        },
        "RefImageUrl": "https://myc-test-f5.homecredit.ru/rt/api/Images/Reference/Reference_3.png",
        "IsAutogenRef": true,
        "IsChatOnly": false,
        "ProductType": 5,
        "ProductTag": "Кредит 23 000",
        "RefHeadlineName": "Польза"
      },
      {
        "$id": "13",
        "ContractStatus": 2,
        "Colour": 6,
        "DisplayOrder": 0,
        "ReferenceParams": {
          "$id": "14",
          "RefCode": 4,
          "IsDebit": false,
          "RefName": "Справка о своевременном погашении задолженности",
          "RefProductName": "Польза 0.00 ",
          "ContractNumber": "2217832621",
          "CreateDateTime": "2024-04-19T08:54:36.098929",
          "RefNumber": "1238"
        },
        "RefImageUrl": "https://myc-test-f5.homecredit.ru/rt/api/Images/Reference/Reference_2.png",
        "IsAutogenRef": true,
        "IsChatOnly": false,
        "ProductType": 6,
        "ProductTag": "Кредит 23 000",
        "RefHeadlineName": "Польза"
      }
    ]
  }
}
