{
  "$id": "1",
  "Result": {
    "$id": "2",
    "ServerDate": "2023-12-12T00:00:00+03:00",
    "CreditLoan": [],
    "CreditCard": [],
    "CreditCardTW": [
      {
        "$id": "3",
        "CreditCardTWGuiData": {
          "$id": "4",
          "CreditCardTWGuiStatus": 0,
          "CreditCardTWPolzaGuiStatus": 1,
          "DisplayOrder": 0,
          "Colour": 3,
          "DisplayedPayments": 0,
          "ShowGauge": false,
          "DaysLeftWordAgreement": 0,
          "DebtDaysWordAgreement": 3,
          "PaymentSystem": 4,
          "ShowTwoBalances": false,
          "DebtDays": 0,
          "DebtAmount": 0.0
        },
        "ContractNumber": "2393398867",
        "ProductName": "Хоум Карта - неименная",
        "ProductId": 4822,
        "ProductType": 7,
        "Contract": {
          "$id": "5",
          "Properties": {
            "$id": "6",
            "ContractBillingDate": "2023-06-09T00:00:00",
            "NextPaymentDate": "2023-06-05T00:00:00",
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          }
        },
        "ContractStatus": 1,
        "AccountNumber": "40817810393640055630",
        "CreditLimit": 50000.0,
        "AvailableBalance": 50000.0,
        "InstalmentDebtSum": 0.0,
        "MinMonthDebtAmount": 0.0,
        "RecommendedPaymentSum": 0.0,
        "GracePayment": 0.0,
        "IsPolzaLkAvailable": false,
        "MainCardStatus": 2,
        "MainCardNumber": "220050XXXXXX0378",
        "MainCardMBR": 90,
        "IsPinGenerated": false,
        "IsAdditional": false,
        "IsNoName": true,
        "IsPolza": false,
        "IsInstalmentProduct": true,
        "TotalIndebtedness": 0.0,
        "PrincipalDebtSum": 0.0,
        "InterestDebtSum": 0.0,
        "FeeDebtSum": 0.0,
        "PenaltySum": 0.0,
        "DateCreate": "2023-05-01T00:00:00",
        "DateSign": "2023-12-01T00:00:00",
        "StartPaymentPeriod": 1,
        "CardType": 8,
        "OuterLimitInfo": {
          "$id": "7",
          "IsOuterLimitOn": false,
          "OuterCreditLimit": 0.0,
          "OuterAvailableBalance": 0.0,
          "OuterCreditBalance": 0.0,
          "UsedOuterCreditLimit": 0.0,
          "UsedPartnersCreditLimit": 0.0
        },
        "IsActivationAvailable": false,
        "IsPlasticActivationAvailable": false,
        "PaymentDetails": {
          "$id": "8",
          "OverdueInterest": 0.0,
          "OverdueDebtBody": 0.0,
          "OverduePenalties": 0.0,
          "NonOverduePenalties": 0.0,
          "NonOverdueInterest": 0.0,
          "NonOverdueDebtBody": 0.0,
          "CurrentInstallmentsInMinPayment": 0.0,
          "OverdueFees": 0.0,
          "NonOverdueFees": 0.0,
          "CurrentInstallments": 0.0
        },
        "ExtraServicesInfo": {
          "$id": "9",
          "IsFinProtectionAvailable": true,
          "IsFinProtectionOn": false,
          "IsSubscriptionAvailable": false,
          "IsSubscriptionOn": false,
          "IsPolzaOn": false
        },
        "AclipInfo": {
          "$id": "10",
          "OfferedIncrease": 0.0,
          "IncreasedCreditLimit": 0.0,
          "IncreasedAvailableBalance": 50000.0
        },
        "IsDigital": false,
        "AvailableCardInfo": 1,
        "AccountBalance": 0.0,
        "PaymentPeriod": "2-4 месяца 36 дней",
        "IsPlasticInDelivery": false,
        "ReissueUponExpirationStatus": 0,
        "ReturnToGrace": {
          "$id": "11",
          "GracePaymentStatus": "действует",
          "ReturnToGracePayment": 0.0,
          "CalcReturnToGracePayment": 0.0,
          "ReturnToGracePaymentInfo": "Эту сумму нужно внести, чтобы возобновить беспроцентный период на покупки"
        },
        "MinMonthDebtAmountInfo": "Эту сумму нужно внести, чтобы не допустить просрочку.",
        "RecommendedPaymentSumInfo": "Сумма, включающая в себя минимальный платёж и обязательную часть задолженности по рассрочкам. Необходимо внести, чтобы не платить проценты по рассрочке.",
        "GracePaymentInfo": "Эту сумму нужно внести, чтобы не платить проценты за уже совершённые операции.",
        "IsPPKRequire": false,
        "SalesInformation": {
          "$id": "12",
          "SoldState": 5
        },
        "ConsumerCreditInformation": {
          "$id": "13",
          "Stage": 3,
          "ActualRate": 6.9,
          "CreditAmount": 0.0,
          "AnnuityPayment": 0.0
        },
        "MaxRemainAfterPrepClose": 999.99
      }
    ],
    "IsCreditLoanAndCardError": false,
    "IsCreditCardTWError": false,
    "IsAvailableEarlyRepayment": false
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
