import styled from '@emotion/styled'
import { Button } from '@platform-ui/components/Button'
export const Root = styled.div(({ theme }) => {
  return {
    maxHeight: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  }
})

export const Content = styled.div(({ theme }) => {
  return {
    padding: '8px 24px 50px',
    maxHeight: '100%',
    width: 'calc(100vw - 48px)',

    [`${theme.media.md}`]: {
      width: '480px',
    },
  }
})

export const TitleContainer = styled.div(({ theme }) => {
  return {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    justifyContent: 'space-between',
  }
})

export const Title = styled.h4(({ theme }) => {
  return {
    textTransform: 'capitalize',
    flexGrow: 1,
    fontFamily: theme.fontFamily.base,
  }
})

export const ButtonList = styled.div(({ theme }) => {
  return {
    margin: '-4px',
    '& > button, & > a': {
      margin: '4px',
    },
  }
})
