{
  "commissionSum": 250.0,
  "totalPaymentSum": 111.0,
  "changeType": false,
  "_links": {
    "bin-check": {
      "href": "https://balancer-gateway-ft.omni.homecredit.ru:8112/commissar/v1/bin-check"
    },
    "execution": {
      "href": "https://balancer-gateway-ft.omni.homecredit.ru:8112/cascader/v1/payments/5a589f7a-7708-4ce0-9f75-5eb411f55279/execution"
    },
    "commission": {
      "href": "https://balancer-gateway-ft.omni.homecredit.ru:8112/cascader/v1/payments/5a589f7a-7708-4ce0-9f75-5eb411f55279/commission?bin={bin}&paymentSum={paymentSum}",
      "templated": true
    }
  }
}
