{
  "Result": {
    "Fee": 0,
    "PaymentId": "b5ea0004-a4b3-4cfc-9812-8cf2cb6dc1b1",
    "SumCheckResult": "Ok"
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
