{
  "verifyNum": 3,
  "resendNum": 1,
  "_links": {
    "resend": {
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/digital-card/v1/cardinfo/765d8fd9-66aa-4932-abe8-cd83410ebde2/resend"
    },
    "check": {
      "href": "https://ocelot-api-gateway-rt.omni.homecredit.ru/digital-card/v1/cardinfo/765d8fd9-66aa-4932-abe8-cd83410ebde2/check"
    }
  }
}
