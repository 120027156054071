{
  "$id": "1",
  "Result": {
    "CheckType": 3,
    "ResultText": "Что-то пошло не так. Операция недоступна."
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
