{
  "$id": "1",
  "Result": [
    {
      "$id": "2",
      "CategoryID": 1,
      "CategoryName": "Мобильная связь",
      "ImageCategoryUrl": "category_1_x3_v3.png"
    },
    {
      "$id": "3",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ImageCategoryUrl": "category_2_x3_v3.png"
    },
    {
      "$id": "4",
      "CategoryID": 3,
      "CategoryName": "ЖКХ",
      "ImageCategoryUrl": "category_3_x3_v3.png"
    },
    {
      "$id": "5",
      "CategoryID": 4,
      "CategoryName": "Оплата кредитов",
      "ImageCategoryUrl": "category_4_x3_v3.png"
    },
    {
      "$id": "6",
      "CategoryID": 5,
      "CategoryName": "Госуслуги",
      "ImageCategoryUrl": "category_5_x3_v3.png"
    },
    {
      "$id": "7",
      "CategoryID": 6,
      "CategoryName": "Платежные системы",
      "ImageCategoryUrl": "category_6_x3_v3.png"
    },
    {
      "$id": "8",
      "CategoryID": 7,
      "CategoryName": "Игры",
      "ImageCategoryUrl": "category_7_x3_v3.png"
    },
    {
      "$id": "9",
      "CategoryID": 8,
      "CategoryName": "Прочее",
      "ImageCategoryUrl": "category_8_x3_v3.png"
    }
  ],
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
