{
  "phone": "+7 (***) ***-23-16",
  "_links": {
    "resend": {
      "href": "https://ocelot-api-gateway-ft.omni.homecredit.ru/digital-card/v1/cardinfo/1d69fa55-50e7-424a-a24b-b703fc4bddd0/resend"
    },
    "check": {
      "href": "https://ocelot-api-gateway-ft.omni.homecredit.ru/digital-card/v1/cardinfo/1d69fa55-50e7-424a-a24b-b703fc4bddd0/check"
    }
  }
}
