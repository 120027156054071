{
  "Result": {
    "CUID": 43471032,
    "ClientId": 10596,
    "PhoneNumber": "79670075022",
    "FirstName": "Юбуъл",
    "LastName": "Рыжждддде",
    "Patronymic": "Еюхвмнндэи",
    "Gender": 1,
    "BlockInformation": {
      "IsClientBlocked": false
    },
    "PassportNumber": "**** ****06",
    "RegAddress": "*** ул. Садовая, д. 55",
    "FactAddress": "*** ул. Садовая, д. 55",
    "EmployerPhoneNumber": "",
    "AvatarUrl": "/api/ClientAvatar/GetClientAvatar/90a8bdf822c47cc836470badfd1bd371",
    "IsChild": false
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
