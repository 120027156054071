{
  "productFrom": [
    {
      "maskCardNumber": "220050******8244",
      "accountNumber": "40817810690012553780",
      "type": "debit",
      "availableBalance": 0,
      "currency": "RUR"
    },
    {
      "accountNumber": "40817810790012553774",
      "type": "account",
      "availableBalance": 0,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "450726******4933",
      "accountNumber": "40817810993640037870",
      "type": "credit",
      "availableBalance": 111,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "446098XXXXXX5783",
      "accountNumber": "40817810450010594871",
      "type": "credit",
      "availableBalance": 10000,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "406296******5152",
      "accountNumber": "40817810393640037632",
      "type": "credit",
      "availableBalance": 11602.17,
      "currency": "RUR"
    },
    {
      "maskCardNumber": "406296******2519",
      "accountNumber": "40817810293640037910",
      "type": "credit",
      "availableBalance": 39989.67,
      "currency": "RUR"
    }
  ],
  "links": [
    {
      "rel": "type",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/type"
    },
    {
      "rel": "exchange-rates",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/exchange-rates"
    },
    {
      "rel": "transfer",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer"
    },
    {
      "rel": "product-list-v2",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v2/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/product-list"
    }
  ]
}
