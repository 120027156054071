{
  "isExpiredData": "True",
  "maskCardNumber": "446098XXXXXX1297",
  "availableBalance": 22222.22,
  "contractNumber": "2189903814",
  "productName": "Польза CB лайт",
  "contractStatus": "Действующий",
  "cardStatus": "Active",
  "cardRole": "PRIMARY",
  "isDigital": false,
  "currency": "RUR",
  "accountNumber": "40817810050660261860",
  "cardMbr": 0,
  "isSalaryCard": false,
  "isArrestedAccount": false,
  "isRestrictedBalance": false,
  "isResident": false,
  "isPinGenerated": false,
  "isSalaryProject": false,
  "isEnableApplePay": false,
  "isEnableGooglePay": false,
  "isEnableSamsungPay": false,
  "isAvailablePlasticCardIssuance": false,
  "isAvailableCardInfo": false,
  "isChildCard": false,
  "isPlasticInDelivery": false,
  "freeLimits": false,
  "isFamilyCard": false,
  "productType": "CreditCard",
  "currencyCode": 643,
  "balance": 22074.22,
  "isPolza": false,
  "cardType": "TW",
  "isSalaryCardApplicationAvailable": false
}
