{
  "contractArrestsList": [
    {
      "contractNumber": "string",
      "contractArrests": [
        {
          "accountNumber": "string",
          "organizationCode": "string",
          "organizationName": "string",
          "documentNumber": "string",
          "documentDate": "2023-11-15T06:44:00.747Z",
          "currentAmount": 0,
          "currencyCode": 0,
          "currency": "ALL"
        }
      ]
    }
  ],
  "information": {
    "additionalProp1": "string",
    "additionalProp2": "string",
    "additionalProp3": "string"
  },
  "isExpiredData": true
}
