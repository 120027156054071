{
  "$id": "1",
  "Result": {
    "$id": "2",
    "ServerDate": "2021-03-24T00:00:00+03:00",
    "CreditLoan": [
      {
        "$id": "3",
        "Order": 9,
        "ContractNumber": "2251175637",
        "ProductName": "Кредит на товар",
        "ProductType": 1,
        "Contract": {
          "$id": "4",
          "Debt": {
            "$id": "5",
            "DebtDays": 0,
            "DebtAmount": 0.0
          },
          "Properties": {
            "$id": "6",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 24,
            "FirstPaymentDate": "0001-01-01T00:00:00",
            "RecPaymentDate": "0001-01-01T00:00:00",
            "UnpaidPaymentNum": 0,
            "SumToPay": 0.0
          },
          "LastPayDate": "2019-07-22T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 24999.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810440200482725",
        "DateSign": "2017-07-08T00:00:00",
        "DateClosure": "2019-07-23T00:00:00",
        "SmsPackService": false,
        "ProductSet": {
          "$id": "7",
          "Code": "Стандартные",
          "Name": "Выгодный"
        },
        "AccountBalance": 0.0,
        "AccBalanceWithoutDebt": 0.0,
        "SigningChannel": 2,
        "IsEarlyRepayment": false,
        "Colour": 3,
        "CreditLoanGuiData": {
          "$id": "8",
          "CreditLoanGuiStatus": 8,
          "ShowDetails": false,
          "ShowGauge": false,
          "ShowSchedule": false,
          "ScheduleAvailable": false,
          "DebtDaysWordAgreement": 3,
          "DisplayOrder": 0,
          "ShowCompleted": false
        }
      },
      {
        "$id": "9",
        "Order": 9,
        "ContractNumber": "2162574280",
        "ProductName": "Кредит на товар",
        "ProductType": 1,
        "Contract": {
          "$id": "10",
          "Debt": {
            "$id": "11",
            "DebtDays": 0,
            "DebtAmount": 0.0
          },
          "Properties": {
            "$id": "12",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 24,
            "FirstPaymentDate": "0001-01-01T00:00:00",
            "RecPaymentDate": "0001-01-01T00:00:00",
            "UnpaidPaymentNum": 0,
            "SumToPay": 0.0
          },
          "LastPayDate": "2014-10-17T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 6290.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810440200482725",
        "DateSign": "2012-11-25T00:00:00",
        "DateClosure": "2014-10-19T00:00:00",
        "SmsPackService": false,
        "ProductSet": {
          "$id": "13",
          "Code": "Без переплаты",
          "Name": "0-0-24 Вариант_отраслевые"
        },
        "AccountBalance": 0.0,
        "AccBalanceWithoutDebt": 0.0,
        "SigningChannel": 2,
        "IsEarlyRepayment": false,
        "Colour": 3,
        "CreditLoanGuiData": {
          "$id": "14",
          "CreditLoanGuiStatus": 8,
          "ShowDetails": false,
          "ShowGauge": false,
          "ShowSchedule": false,
          "ScheduleAvailable": false,
          "DebtDaysWordAgreement": 3,
          "DisplayOrder": 2,
          "ShowCompleted": false
        }
      },
      {
        "$id": "15",
        "Order": 10,
        "ContractNumber": "2205412170",
        "ProductName": "Кредит наличными",
        "ProductType": 2,
        "Contract": {
          "$id": "16",
          "Debt": {
            "$id": "17",
            "DebtDays": 0,
            "DebtAmount": 0.0
          },
          "Properties": {
            "$id": "18",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 48,
            "FirstPaymentDate": "0001-01-01T00:00:00",
            "RecPaymentDate": "0001-01-01T00:00:00",
            "UnpaidPaymentNum": 0,
            "SumToPay": 0.0
          },
          "LastPayDate": "0001-01-01T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 172000.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810440200482725",
        "DateSign": "2014-08-25T00:00:00",
        "DateClosure": "2018-02-06T00:00:00",
        "SmsPackService": false,
        "ProductSet": {
          "$id": "19",
          "Code": "Наличные в кредит для сотрудников",
          "Name": "Наличные в кредит для сотрудников_14,9%"
        },
        "AccountBalance": 0.0,
        "AccBalanceWithoutDebt": 0.0,
        "SigningChannel": 2,
        "IsEarlyRepayment": false,
        "Colour": 3,
        "CreditLoanGuiData": {
          "$id": "20",
          "CreditLoanGuiStatus": 8,
          "ShowDetails": false,
          "ShowGauge": false,
          "ShowSchedule": false,
          "ScheduleAvailable": false,
          "DebtDaysWordAgreement": 3,
          "DisplayOrder": 1,
          "ShowCompleted": false
        }
      },
      {
        "$id": "3",
        "CreditLoanGuiData": {
          "$id": "4",
          "CreditLoanGuiStatus": 8,
          "Colour": 3,
          "DisplayOrder": 1,
          "ShowDetails": false,
          "ScheduleAvailable": false,
          "ShowGauge": false,
          "DebtDaysWordAgreement": 3,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "InterestRate": 28.5,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2675657583",
        "ProductName": "Кредит на товар",
        "ProductType": 1,
        "Contract": {
          "$id": "5",
          "Properties": {
            "$id": "6",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 6,
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          },
          "LastPayDate": "0001-01-01T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 11250.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810840160066085",
        "DateSign": "2006-10-17T00:00:00",
        "DateClosure": "2007-10-14T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 0.0,
        "EarlyPaymentSum": 0.0,
        "ProductSet": {
          "$id": "7",
          "Code": "Стандартные",
          "Name": "Сада 0% Мобильный телефон"
        }
      }
    ],
    "CreditCard": [
      {
        "$id": "8",
        "CreditCardGuiData": {
          "$id": "9",
          "CreditCardGuiStatus": 1,
          "DisplayOrder": 0,
          "Colour": 3,
          "ShowGauge": false,
          "DaysLeft": 0,
          "DaysLeftWordAgreement": 3,
          "DebtDaysWordAgreement": 3,
          "PaymentSystem": 2,
          "PercentAvailable": 100,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "ShowTopUpWarning": true
        },
        "ContractNumber": "2686777736",
        "ProductName": "Кредитная карта",
        "ProductType": 3,
        "Contract": {
          "$id": "10",
          "Properties": {
            "$id": "11",
            "NextPaymentDate": "2021-04-01T00:00:00",
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          },
          "LastPayDate": "0001-01-01T00:00:00"
        },
        "ContractStatus": 1,
        "AccountNumber": "40817810050160040374",
        "CreditLimit": 10000.0,
        "AvailableBalance": 10000.0,
        "TotalIndebtedness": 0.0,
        "PrincipalDebtSum": 0.0,
        "PenaltySum": 0.0,
        "InstalmentDebtSum": 0.0,
        "MinMonthDebtAmount": 0.0,
        "OverdueDaysNum": 0,
        "MainCardStatus": 1,
        "DateSign": "2006-12-08T00:00:00",
        "ProductSet": {
          "$id": "12",
          "Code": "Карта для сотрудников",
          "Name": "Карта для сотрудников"
        },
        "MainCardNumber": "557734XXXXXX0880",
        "IsPolza": false,
        "CardType": 1,
        "IsPolzaLkAvalible": true,
        "IsActivationAvailable": false,
        "AccountArrest": {
          "$id": "13",
          "ActualBalance": 0.0,
          "IsArrest": true,
          "ArrestSum": 78049.82,
          "ArrestLeft": 78049.82,
          "PercentPaidArrest": 0
        }
      }
    ],
    "CreditCardTW": [],
    "IsCreditLoanAndCardError": false,
    "IsCreditCardTWError": false,
    "IsAvailableEarlyRepayment": true
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
