{
  "debitCards": [
    {
      "maskCardNumber": "220050XXXXXX0640",
      "mbr": 90,
      "accountNumber": "40817810190013044031",
      "availableBalance": 0,
      "cardStatusDisplayed": "Active",
      "currency": "RUR",
      "cardRole": "PRIMARY",
      "expiration": "2029-02-28T00:00:00",
      "isSalaryCard": false,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": true,
      "isDigital": true,
      "isAvailableCardInfo": true,
      "isFamilyCard": false,
      "contractNumber": "7321011548",
      "contractStatus": "Active",
      "guiContractStatus": "NotSet",
      "cardType": "TW",
      "cardSubType": "NotSet",
      "isPPKRequire": false,
      "isPolza": false,
      "isSalaryCardApplicationAvailable": true,
      "hasDebt": false,
      "displayOrder": 1,
      "productName": "Польза",
      "productType": "DebitCard",
      "paymentSystem": "MIR",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "productIdent": "KARTAPOLZA_RUR",
      "isMfoContractNumber": false,
      "isHomerMigratedCard": false,
      "contractBillingDay": "2024-02-01T00:00:00",
      "isActivationAvailable": false
    },
    {
      "maskCardNumber": "220050XXXXXX0426",
      "mbr": 90,
      "accountNumber": "40817810290013044025",
      "availableBalance": 0,
      "cardStatusDisplayed": "Active",
      "currency": "RUR",
      "cardRole": "PRIMARY",
      "expiration": "2029-02-28T00:00:00",
      "isSalaryCard": false,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": true,
      "isDigital": true,
      "isAvailableCardInfo": true,
      "isFamilyCard": false,
      "contractNumber": "7321011542",
      "contractStatus": "Active",
      "guiContractStatus": "NotSet",
      "cardType": "TW",
      "cardSubType": "NotSet",
      "isPPKRequire": false,
      "isPolza": false,
      "isSalaryCardApplicationAvailable": true,
      "hasDebt": false,
      "displayOrder": 2,
      "productName": "Польза",
      "productType": "DebitCard",
      "paymentSystem": "MIR",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "productIdent": "KARTAPOLZA_RUR",
      "isMfoContractNumber": false,
      "isHomerMigratedCard": false,
      "contractBillingDay": "2024-02-01T00:00:00",
      "isActivationAvailable": false
    }
  ],
  "creditCards": [
    {
      "maskCardNumber": "220050XXXXXX0378",
      "mbr": 90,
      "accountNumber": "40817810393640055630",
      "availableBalance": 50000,
      "cardStatusDisplayed": "Active",
      "currency": "RUR",
      "cardRole": "PRIMARY",
      "expiration": "2033-10-31T00:00:00",
      "isSalaryCard": false,
      "isChildCard": false,
      "isArrestedAccount": false,
      "isRestrictedBalance": false,
      "isResident": false,
      "isDigital": false,
      "isAvailableCardInfo": true,
      "isFamilyCard": false,
      "isTransferOn": "4;5",
      "contractNumber": "2393398867",
      "contractStatus": "Active",
      "guiContractStatus": "Active",
      "cardType": "TW",
      "cardSubType": "TWHomeCard",
      "isPPKRequire": false,
      "isPolza": false,
      "isSalaryCardApplicationAvailable": false,
      "displayOrder": 1,
      "productName": "Хоум Карта - неименная",
      "productType": "CreditCard",
      "paymentSystem": "MIR",
      "isPlasticInDelivery": false,
      "isPlasticActivationAvailable": false,
      "productIdent": "RK_KN_2_RUR",
      "productCode": "4822",
      "versionCard": "HomeCard",
      "detailIsFilled": true,
      "creditLimit": 50000,
      "instalmentDebtSum": 0,
      "minMonthDebtAmount": 0,
      "isMfoContractNumber": false,
      "recommendedPaymentSum": 0,
      "gracePayment": 0,
      "accountBalance": 0,
      "paymentPeriod": "2-4 месяца 36 дней",
      "isHomerMigratedCard": false,
      "dateCreate": "2023-05-01T00:00:00",
      "dateSign": "2023-12-01T00:00:00",
      "totalIndebtedness": 0,
      "principalDebtSum": 0,
      "penaltySum": 0,
      "maxRemainAfterPrepClose": 999.99,
      "creditCardTWGuiData": {
        "creditCardTWGuiStatus": "NoDebt",
        "creditCardTWPolzaGuiStatus": "NoDebt",
        "displayedPayments": "None",
        "showGauge": false,
        "showTwoBalances": false,
        "debtDays": 0,
        "debtAmount": 0,
        "colour": "Green",
        "displayOrder": 0
      },
      "returnToGrace": {
        "gracePaymentStatus": "2",
        "returnToGracePayment": 0,
        "returnToGracePaymentInfo": "Эту сумму нужно внести, чтобы возобновить беспроцентный период на покупки"
      },
      "contract": {
        "properties": {
          "nextPaymentDate": "2023-06-05T00:00:00",
          "contractBillingDate": "2023-06-09T00:00:00",
          "sumToPay": 0
        }
      },
      "paymentDetails": {
        "overduePenalties": 0,
        "nonOverduePenalties": 0,
        "nonOverdueInterest": 0,
        "nonOverdueDebtBody": 0,
        "overdueFees": 0,
        "nonOverdueFees": 0,
        "currentInstallments": 0
      },
      "aclipInfo": {
        "offeredIncrease": 0,
        "increasedCreditLimit": 0,
        "increasedAvailableBalance": 50000
      },
      "salesInformation": {
        "soldState": "NotForSale",
        "soldDate": "0001-01-01T00:00:00",
        "postServiceDate": "0001-01-01T00:00:00"
      },
      "consumerCreditInformation": {
        "stage": "CreditRepaidStage",
        "actualRate": 6.9,
        "creditAmount": 0,
        "annuityPayment": 0
      },
      "outerLimitInfo": {
        "isOuterLimitOn": false,
        "outerCreditLimit": 0,
        "outerAvailableBalance": 0,
        "usedOuterCreditLimit": 0,
        "usedPartnersCreditLimit": 0
      },
      "extraServicesInfo": {
        "isFinProtectionAvailable": true,
        "isFinProtectionOn": false,
        "isSubscriptionAvailable": false,
        "isSubscriptionOn": false,
        "isPolzaOn": false
      },
      "minMonthDebtAmountInfo": "Эту сумму нужно внести, чтобы не допустить просрочку.",
      "recommendedPaymentSumInfo": "Сумма, включающая в себя минимальный платёж и обязательную часть задолженности по рассрочкам. Необходимо внести, чтобы не платить проценты по рассрочке.",
      "gracePaymentInfo": "Эту сумму нужно внести, чтобы не платить проценты за уже совершённые операции.",
      "isActivationAvailable": false
    }
  ],
  "accounts": [
    {
      "accountNumber": "40817810190013044031",
      "contractNumber": "7321011548",
      "accountName": "Текущий счёт",
      "runningBalance": 0,
      "currency": "RUR",
      "status": "Open",
      "accountType": "DebitCard",
      "currencyCode": 643,
      "accountPrcRate": 0,
      "isExpiredData": false,
      "displayOrder": 0
    },
    {
      "accountNumber": "40817810290013044025",
      "contractNumber": "7321011542",
      "accountName": "Текущий счёт",
      "runningBalance": 0,
      "currency": "RUR",
      "status": "Open",
      "accountType": "DebitCard",
      "currencyCode": 643,
      "accountPrcRate": 0,
      "isExpiredData": false,
      "displayOrder": 1
    },
    {
      "accountNumber": "40817810790013039857",
      "contractNumber": "7321007671",
      "accountName": "Текущий счёт",
      "runningBalance": 0,
      "currency": "RUR",
      "status": "Open",
      "accountType": "DebitCard",
      "currencyCode": 643,
      "accountPrcRate": 0,
      "isExpiredData": false,
      "displayOrder": 2
    }
  ],
  "deposits": [],
  "mkks": [],
  "serverDate": "2024-05-30T09:12:41.3349357+03:00"
}
