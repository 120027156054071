import { mockItems as personalAgreement } from './auth/personalAgreement/mocks'
import { mockItems as password } from './auth/password/mocks'
import { mockItems as data } from './auth/data/mocks'
import { mockItems as smsVerify } from './auth/smsVerify/mocks'
import { mockItems as smsResend } from './auth/smsResend/mocks'
import { mockItems as passwordSet } from './auth/passwordSet/mocks'
import { mockItems as backgroundRefresh } from './auth/backgroundRefresh/mocks'
import { mockItems as refresh } from './auth/refresh/mocks'
import { mockItems as endSession } from './auth/endSession/mocks'
import { mockItems as getClientProducts } from './products/clientProducts/mocks'
import { mockItems as accounts } from './products/accounts/mocks'
import { mockItems as accountsArrests } from './products/accountsArrests/mocks'
import { mockItems as products } from './products/products/mocks'
import { mockItems as alienCardsTemplates } from './products/alienCards/mocksAlienCardsTemplates'
import { mockItems as alienCardsTemplatesInfo } from './products/alienCards/mocksAlienCardsTemplatesInfo'
import { mockItems as widgetsSettings } from './products/widgetsSettings/mocks'
import { mockItems as alienCardsInfo } from './products/alienCardsInfo/mocks'
import { mockItems as transactions } from './products/transactions/mocks'
import { mockItems as getProductDetails } from './products/productDetails/mocks'
import { mockItems as getDepositDetails } from './products/getDepositDetails/mocks'
import { mockItems as paymentsDetalization } from './products/paymentsDetalization/mocks'
import { mockItems as covenantInfo } from './products/covenantInfo/mocks'
import { mockItems as paymentsSchedule } from './products/paymentsSchedule/mocks'
import { mockItems as availableBetweenSelf } from './transfers/availableBetweenSelf/mocks'
import { mockItems as availableByCardNumber } from './transfers/availableByCardNumber/mocks'
import { mockItems as availableByRequisites } from './transfers/availableByRequisites/mocks'
import { mockItems as betweenSelf } from './transfers/betweenSelf/mocks'
import { mockItems as betweenSelfCheck } from './transfers/betweenSelfCheck/mocks'
import { mockItems as betweenSelfResend } from './transfers/betweenSelfResend/mocks'
import { mockItems as betweenSelfCommission } from './transfers/betweenSelfCommission/mocks'
import { mockItems as betweenSelfConfirm } from './transfers/betweenSelfConfirm/mocks'
import { mockItems as betweenSelfProductFromList } from './transfers/betweenSelfProductFromList/mocks'
import { mockItems as betweenSelfProductToList } from './transfers/betweenSelfProductToList/mocks'
import { mockItems as betweenSelfType } from './transfers/betweenSelfType/mocks'
import { mockItems as phone } from './transfers/phone/mocksPhone'
import { mockItems as phoneBanksInfo } from './transfers/phone/mocksBanksInfo'
import { mockItems as phoneCheck } from './transfers/phoneCheck/mocks'
import { mockItems as phoneCommission } from './transfers/phoneCommission/mocks'
import { mockItems as phonePrepare } from './transfers/phonePrepare/mocks'
import { mockItems as phoneResend } from './transfers/phoneResend/mocks'
import { mockItems as phoneTransfer } from './transfers/phoneTransfer/mocks'
import { mockItems as phoneProductList } from './transfers/phoneProductList/mocks'
import { mockItems as phoneGetSbpLogo } from './transfers/phoneGetSbpLogo/mocks'
import { mockItems as banksInfo } from './transfers/banksInfo/mocks'
import { mockItems as binCheck } from './transfers/binCheck/mocks'
import { mockItems as addCardCommission } from './transfers/addCardCommission/mocks'
import { mockItems as getClientChecks } from './transfers/getClientChecks/mocks'
import { mockItems as payments } from './transfers/payments/mocks'
import { mockItems as execution } from './transfers/execution/mocks'
import { mockItems as check3DS } from './transfers/check3DS/mocks'
import { mockItems as getClientInfo } from './client/clientInfo/mocks'
import { mockItems as getClientAvatar } from './client/getClientAvatar/mocks'
import { mockItems as settings } from './settings/settings/mocks'
import { mockItems as getCardProperty } from './digital-card/getCardProperty/mocks'
import { mockItems as checkSms } from './digital-card/checkSms/mocks'
import { mockItems as digitalCardResendSms } from './digital-card/resendSms/mocks'
import { mockItems as getInfoInterbankTransfer } from './payment/getInfoInterbankTransfer/mocks'
import { mockItems as getInstalmentSchedule } from './payment/getInstalmentSchedule/mocks'
import { mockItems as getReceivedPayments } from './payment/getReceivedPayments/mocks'
import { mockItems as getCardStatus } from './cardActivation/getCardStatus/mocks'
import { mockItems as sendSms } from './cardActivation/sendSms/mocks'
import { mockItems as pinCheckSms } from './cardActivation/pinCheckSms/mocks'
import { mockItems as resendSms } from './cardActivation/resendSms/mocks'
import { mockItems as getCreditCard } from './products/creditCards/mocks'
import { mockItems as mainWidget } from './partners/mainWidget/mocks'
import { mockItems as points } from './partners/points/mocks'
import { mockItems as freeCategories } from './partners/freeCategories/mocks'
import { mockItems as setFreeCategories } from './partners/setFreeCategories/mocks'
import { mockItems as ccSign } from './approvalContracts/ccSign/mocks'
import { mockItems as debitCardRegistry } from './approvalContracts/debitCardRegistry/mocks'
import { mockItems as getApprovalContracts } from './approvalContracts/getApprovalContracts/mocks'
import { mockItems as saveResponse } from './approvalContracts/saveResponse/mocks'
import { mockItems as getContractInfoMFO } from './products/getContractInfoMFO/mocks'
import { mockItems as getClientProductsMFO } from './products/clientProductsMFO/mocks'
import { mockItems as cards } from './partners/cards/mocks'
import { mockItems as polzaLink } from './partners/polzaLink/mocks'
import { mockItems as pointsExchange } from './partners/pointsExchange/mocks'
import { mockItems as getAllReferences } from './references/getAllReferences/mocks'
import { mockItems as getReferenceUrl } from './references/getReferenceUrl/mocks'
import { mockItems as orderReferences } from './references/orderReferences/mocks'
import { mockItems as offers } from './products/offers/mocks'
import { mockItems as offerContent } from './products/offerContent/mocks'
import { mockItems as cardsMcp } from './products/cardsMcp/mocks'
import { mockItems as categories } from './pms/categories/mocks'
import { mockItems as beneficiaries } from './pms/beneficiaries/mocks'
import { mockItems as beneficiaryDetails } from './pms/beneficiaryDetails/mocks'
import { mockItems as clientCards } from './pms/clientCards/mocks'
import { mockItems as checkPayment } from './pms/checkPayment/mocks'
import { mockItems as createPayment } from './pms/createPayment/mocks'
import { mockItems as verification } from './pms/verification/mocks'
import { mockItems as resending } from './pms/resending/mocks'
import { mockItems as popup } from './clientProposals/popup/mocks'
import { mockItems as getCreditLinesInfoMFO } from './products/mfo/getCreditLinesMFO/mocks'

export class MocksImport {
  auth = {
    personalAgreement,
    password,
    data,
    smsVerify,
    smsResend,
    passwordSet,
    backgroundRefresh,
    refresh,
    endSession,
  }
  client = {
    getClientInfo,
    getClientAvatar,
  }
  clientProposals = {
    popup,
  }
  cardActivation = {
    getCardStatus,
    sendSms,
    pinCheckSms,
    resendSms,
  }
  settings = {
    settings,
  }
  products = {
    accounts,
    accountsArrests,
    getClientProducts,
    products,
    cardsMcp,
    alienCardsTemplates,
    alienCardsTemplatesInfo,
    widgetsSettings,
    alienCardsInfo,
    transactions,
    getProductDetails,
    getDepositDetails,
    getCreditCard,
    paymentsDetalization,
    covenantInfo,
    paymentsSchedule,
    getContractInfoMFO,
    getClientProductsMFO,
    getCreditLinesInfoMFO,
  }
  transfers = {
    addCardCommission,
    availableBetweenSelf,
    availableByCardNumber,
    availableByRequisites,
    binCheck,
    betweenSelf,
    betweenSelfCheck,
    betweenSelfResend,
    betweenSelfCommission,
    betweenSelfConfirm,
    betweenSelfProductFromList,
    betweenSelfProductToList,
    betweenSelfType,
    getClientChecks,
    execution,
    payments,
    phone,
    phoneBanksInfo,
    phoneCheck,
    phoneCommission,
    phonePrepare,
    phoneResend,
    phoneTransfer,
    phoneProductList,
    phoneGetSbpLogo,
    banksInfo,
    check3DS,
  }
  digitalCard = {
    getCardProperty,
    checkSms,
    resendSms: digitalCardResendSms,
  }
  payment = {
    getInfoInterbankTransfer,
    getInstalmentSchedule,
    getReceivedPayments,
    getEarlyRepaymentInstalmentSchedule: getInstalmentSchedule,
  }
  approvalContracts = {
    ccSign,
    debitCardRegistry,
    getApprovalContracts,
    saveResponse,
  }
  partners = {
    mainWidget,
    points,
    pointsExchange,
    freeCategories,
    setFreeCategories,
    cards,
    polzaLink,
  }
  references = {
    getAllReferences,
    getReferenceUrl,
    orderReferences,
  }
  offers = {
    getOffers: offers,
    getOfferContent: offerContent,
  }
  pms = {
    categories,
    beneficiaries,
    beneficiaryDetails,
    clientCards,
    checkPayment,
    createPayment,
    verification,
    resending,
  }
}
