import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Button } from '@platform-ui/components/Button'
import React from 'react'
import { ButtonList, Title, TitleContainer } from './styled'

export const VerifyModal = (): JSX.Element => {
  const context = useAppContext<AppContext>()

  return (
    <>
      <TitleContainer>
        <Title>Verify Modal</Title>
      </TitleContainer>
      <ButtonList>
        <Button
          size="m"
          onClick={() =>
            context.eventBus.verifyModal.open.emit({
              maskedPhoneNumber: '+7 (000) 000-00-00',
            })
          }
        >
          Открыть
        </Button>
        <Button
          size="m"
          onClick={() => context.eventBus.verifyModal.close.emit({ action: 'close' })}
        >
          Закрыть
        </Button>
      </ButtonList>
    </>
  )
}
