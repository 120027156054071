{
  "links": [
    {
      "href": "https://balancer-gateway-rt.omni.homecredit.ru:8112/pin/v3/mcpingenerations/874d1af1-ab9a-4b48-80c3-cf0aba862227/pin",
      "rel": "pin"
    },
    {
      "href": "https://balancer-gateway-rt.omni.homecredit.ru:8112/pin/v3/mcpingenerations/874d1af1-ab9a-4b48-80c3-cf0aba862227/resendsms",
      "rel": "resendsms"
    }
  ]
}
