import { MocksImport } from '@/api/MocksImport'
import { ApiManagerModel } from '@/core'
import { AdmincacheModel } from '@/storage/admincache/AdmincacheModel'
import { DeveloperStoreModel } from '../developerStore/DeveloperStoreModel'

export function mergeApiManagerWithStorageState(
  apiManagerState: ApiManagerModel.State,
  storageState: AdmincacheModel.State,
  mocksImport: MocksImport
): { apiOptions: DeveloperStoreModel.ApiOptions; mocks: DeveloperStoreModel.Mocks } {
  const storageApiOptions: AdmincacheModel.State['apiOptions'] =
    (storageState && storageState.apiOptions) || {}

  return Object.keys(apiManagerState).reduce<{
    apiOptions: DeveloperStoreModel.ApiOptions
    mocks: DeveloperStoreModel.Mocks
  }>(
    (acc, groupName: keyof DeveloperStoreModel.ApiOptions) => {
      const groupApi = apiManagerState[groupName]
      const storageGroup = storageApiOptions[groupName] || {}
      const mocksGroup = mocksImport[groupName] || {}

      acc.apiOptions[groupName] = {}
      acc.mocks[groupName] = {}

      Object.keys(groupApi).forEach((name: DeveloperStoreModel.ApiOptionName) => {
        const storageApiOption: DeveloperStoreModel.ApiOption = storageGroup[name]
        const apiOption = groupApi[name]
        const mocks: DeveloperStoreModel.MockItem[] = mocksGroup[name] || []
        /**
         * mock данные кешируются и при внесении изменений могут отобразиться устаревшие данные
         * по этому требуется полностью перезаписать новыми данными
         */
        const newApiOption: Omit<DeveloperStoreModel.ApiOption, 'originPath'> =
          (storageApiOption && {
            ...storageApiOption,
            control: storageApiOption.control
              ? {
                  ...storageApiOption.control,
                  mock: storageApiOption.selectedMockId
                    ? mocks.find((item) => item.id === storageApiOption.selectedMockId)
                    : null,
                }
              : null,
          }) || {
            path: apiOption.path,
            isMockActive: false,
          }
        /**
         * originPath - для восстановление к первоначальному состоянию path
         * Перезапись originPath на новый в случае если он был изменен в конфиге
         */
        acc.apiOptions[groupName][name] = {
          ...newApiOption,
          originPath: apiOption.path,
        }

        acc.mocks[groupName][name] = mocks
      })

      return acc
    },
    {
      apiOptions: {},
      mocks: {},
    }
  )
}
