import { MocksImport } from '@/api/MocksImport'
import { ApiManagerModel } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Rules } from '@/models/Rules'
import { ConfigModel } from '@/server/config'
import { admincache } from '@/storage/admincache'
import { action, observable, computed, makeObservable, autorun, reaction } from 'mobx'
import { mergeApiManagerWithStorageState } from '../utils'
import { DeveloperStoreModel } from './DeveloperStoreModel'
import { CookieName } from '@/models/CookieName'
import { SwitchStandStore } from './SwitchStandStore'

export class DeveloperStore {
  private apiManager: AppContext['apiManager']
  private eventBus: AppContext['eventBus']
  private httpClient: AppContext['httpClient']
  private toastify: AppContext['toastify']
  private config: AppContext['config']
  private auth: AppContext['auth']
  private cookie: AppContext['cookie']
  mocks: DeveloperStoreModel.Mocks = {}
  apiOptions: DeveloperStoreModel.ApiOptions = {}
  features: DeveloperStoreModel.Features = {
    entities: {},
    groupedIds: [],
  }

  switchStand: SwitchStandStore

  constructor(
    apiManager: AppContext['apiManager'],
    eventBus: AppContext['eventBus'],
    httpClient: AppContext['httpClient'],
    toastify: AppContext['toastify'],
    config: AppContext['config'],
    auth: AppContext['auth'],
    cookie: AppContext['cookie']
  ) {
    makeObservable(this, {
      apiOptions: observable,
      features: observable,
      mocks: observable,
      init: action.bound,
      setApiOption: action.bound,
      setApiOptions: action.bound,
      setFeatureItem: action.bound,
      setFeatures: action.bound,
      changeFeatureState: action.bound,
      resetAllApiOptionsOriginPath: action.bound,
      resetAllFeaturesState: action.bound,
      resetFeatureState: action.bound,
      setMockApiOptionsStatus: action.bound,
      addAccessRule: action.bound,
      deleteAccessRule: action.bound,
      setDevModeApiUrls: action.bound,
      isSomeMocksActive: computed,
      isEveryMocksActive: computed,
      getApiOptions: computed,
      isSomeDirtyApiOptions: computed,
      isDirtyAllFeatures: computed,
    })

    this.apiManager = apiManager
    this.eventBus = eventBus
    this.httpClient = httpClient
    this.toastify = toastify
    this.config = config
    this.auth = auth
    this.cookie = cookie

    this.switchStand = new SwitchStandStore(this)

    /**
     * Сохранение изменений apiOptions или mockOptions или features в локальном хранилище
     */
    autorun(
      () => {
        admincache.setState(() => ({
          apiOptions: this.apiOptions,
          features: this.features,
          accessRules: [...this.auth.rules],
          switchStand: {
            activeStandId: this.switchStand.activeStandId,
          },
        }))
      },
      {
        delay: 600,
      }
    )

    /**
     * Сохранение изменений apiOptions в apiManager
     */
    reaction(
      () => this.apiOptions,
      (apiOptions) => {
        if (this.config.devMode.isActive && this.config.devMode.isActiveApiUrl) {
          this.apiManager.setState(() => apiOptions)
        }
      }
    )

    /**
     * Сохранение изменений apiOptions в apiManager
     */
    reaction(
      () => this.switchStand.activeStandId,
      (activeStandId) => {
        this.setApiOptionsByStand()
      }
    )
  }

  init() {
    const adminStorageState = admincache.getState()
    const { api, devMode } = this.config

    /**
     * Преобразование Config['api'] в ApiManagerModel.State
     */
    const apiManagerState = Object.keys(api).reduce<ApiManagerModel.State>(
      (acc, groupName: keyof ApiManagerModel.State) => {
        acc[groupName] = {}

        for (const name in api[groupName]) {
          const apiOption: ApiManagerModel.ApiOption = {
            path: api[groupName][name],
          }
          acc[groupName][name] = apiOption
        }

        return acc
      },
      {}
    )

    const mockImport = new MocksImport()

    /**
     * Наложение данных из кеша на данные из api серверного конфига
     */
    const { apiOptions, mocks } = mergeApiManagerWithStorageState(
      apiManagerState,
      adminStorageState,
      mockImport
    )

    this.mocks = mocks
    /**
     * Обновление состояния apiManager
     */
    if (this.config.devMode.isActive && this.config.devMode.isActiveApiUrl) {
      this.apiManager.setState((state) => apiOptions)
    }

    this.apiOptions = apiOptions

    /**
     * Права доступа
     */

    const aceessRulesStorage = adminStorageState?.accessRules

    if (aceessRulesStorage) {
      this.auth.resetRules(...aceessRulesStorage)
    }

    /**
     * Switch Stand Init
     */
    if (adminStorageState?.switchStand?.activeStandId) {
      this.switchStand.init({
        activeStandId: adminStorageState.switchStand.activeStandId,
        originActiveStandId: this.config.stand,
      })
    } else {
      this.switchStand.init({
        activeStandId: this.config.stand,
        originActiveStandId: this.config.stand,
      })
    }

    /**
     * Успешная инициализация Developer панели
     */
    this.eventBus.developer.ready.emit()
  }

  resetAllApiOptionsOriginPath() {
    for (const groupName in this.apiOptions) {
      const groupApi: Record<string, DeveloperStoreModel.ApiOption> = this.apiOptions[groupName]
      for (const name in groupApi) {
        const apiOption: DeveloperStoreModel.ApiOption = groupApi[name]
        const newApiOption: DeveloperStoreModel.ApiOption = {
          ...apiOption,
          path: apiOption.originPath,
        }
        this.apiOptions[groupName][name] = newApiOption
      }
    }
  }

  setApiOption(data: {
    groupName: keyof DeveloperStoreModel.ApiOptions
    name: DeveloperStoreModel.ApiOptionName
    data: DeveloperStoreModel.ApiOption
  }) {
    this.apiOptions[data.groupName][data.name] = data.data
  }

  setApiOptions(data: { apiOptions: DeveloperStoreModel.ApiOptions }) {
    this.apiOptions = data.apiOptions
  }

  setFeatureItem(data: DeveloperStoreModel.FeatureEntity) {
    this.features.entities[data.id] = data
  }

  setFeatures(data: DeveloperStoreModel.Features) {
    this.features = data
  }

  setApiOptionsByStand() {
    for (const groupName in this.apiOptions) {
      const groupApi: Record<string, DeveloperStoreModel.ApiOption> = this.apiOptions[groupName]

      for (const name in groupApi) {
        const apiOption: DeveloperStoreModel.ApiOption = groupApi[name]

        const newApiOption: DeveloperStoreModel.ApiOption = {
          ...apiOption,
          path: this.switchStand.replaceValue(apiOption.path),
        }

        this.apiOptions[groupName][name] = newApiOption
      }
    }
  }

  setApiOptionsPath(api: ConfigModel.State['api']) {
    for (const groupName in this.apiOptions) {
      const groupApi: Record<string, DeveloperStoreModel.ApiOption> = this.apiOptions[groupName]

      for (const name in groupApi) {
        const apiOption: DeveloperStoreModel.ApiOption = groupApi[name]
        const path: DeveloperStoreModel.ApiOption['path'] = api[groupName][name]

        const newApiOption: DeveloperStoreModel.ApiOption = {
          ...apiOption,
          path,
        }

        this.apiOptions[groupName][name] = newApiOption
      }
    }
  }

  setDevModeValue(state: boolean) {
    this.config.setState((prevState) => ({
      devMode: {
        isActive: prevState.devMode.isActive,
        isOpenedDevTools: state,
      },
    }))
    if (state) {
      this.cookie.set(CookieName.IsOpenedDevTools, '1')
    } else {
      this.cookie.set(CookieName.IsOpenedDevTools, '0')
    }
  }

  setDevModeApiUrls(state: boolean) {
    this.config.setState({
      devMode: {
        isActive: true,
        isActiveApiUrl: state,
      },
    })
    if (state) {
      this.cookie.set(CookieName.IsActiveDevModeApiUrls, '1')
    } else {
      this.cookie.set(CookieName.IsActiveDevModeApiUrls, '0')
    }
  }
  setMockApiOptionsStatus(newMockStatus: boolean) {
    for (const groupName in this.apiOptions) {
      const groupApi: Record<string, DeveloperStoreModel.ApiOption> = this.apiOptions[groupName]
      const groupMocks: Record<string, DeveloperStoreModel.MockItem[]> = this.mocks[groupName]

      for (const name in groupApi) {
        const apiOption: DeveloperStoreModel.ApiOption = groupApi[name]
        const mockList: DeveloperStoreModel.MockItem[] = groupMocks[name]

        let newApiOption: DeveloperStoreModel.ApiOption
        if (newMockStatus) {
          const mockItem = mockList[0]
          const mockControl: DeveloperStoreModel.ApiOption['control']['mock'] = mockItem
            ? {
                data: mockItem.data,
                status: mockItem.status,
              }
            : null
          newApiOption = {
            ...apiOption,
            isMockActive: true,
            selectedMockId: mockItem ? mockItem.id : null,
            control: apiOption.control
              ? {
                  ...apiOption.control,
                  mock: mockControl,
                }
              : {
                  delay: 0,
                  mock: mockControl,
                },
          }
        } else {
          newApiOption = {
            ...apiOption,
            isMockActive: false,
            selectedMockId: null,
            control: null,
          }
        }

        this.apiOptions[groupName][name] = newApiOption
      }
    }
  }

  resetAllFeaturesState() {
    for (const key in this.features.entities) {
      this.features.entities[key].state = this.features.entities[key].originState
    }
  }

  resetFeatureState({ id }: { id: DeveloperStoreModel.FeatureEntityId }) {
    this.features.entities[id].state = this.features.entities[id].originState
  }

  changeFeatureState({
    id,
    state,
  }: {
    id: DeveloperStoreModel.FeatureEntityId
    state: DeveloperStoreModel.FeatureEntity['state']
  }) {
    this.features.entities[id].state = state
  }

  addAccessRule(rule: Rules) {
    this.auth.addRules(rule)
  }

  deleteAccessRule(rule: Rules) {
    this.auth.deleteRules(rule)
  }

  get isSomeMocksActive() {
    return Object.values(this.getApiOptions).some((group) =>
      group.list.some((apiOption) => !!apiOption.apiOption.isMockActive)
    )
  }

  get isEveryMocksActive() {
    return Object.values(this.getApiOptions).every((group) =>
      group.list.every((apiOption) => !!apiOption.apiOption.isMockActive)
    )
  }

  get getApiOptions(): {
    groupName: keyof DeveloperStoreModel.ApiOptions
    list: {
      name: DeveloperStoreModel.ApiOptionName
      isDirty: boolean
      apiOption: DeveloperStoreModel.ApiOption
    }[]
  }[] {
    return Object.keys(this.apiOptions).map((groupName: keyof DeveloperStoreModel.ApiOptions) => {
      return {
        groupName,
        list: Object.keys(this.apiOptions[groupName]).map(
          (
            name: DeveloperStoreModel.ApiOptionName
          ): {
            name: DeveloperStoreModel.ApiOptionName
            isDirty: boolean
            apiOption: DeveloperStoreModel.ApiOption
          } => {
            const apiOption: DeveloperStoreModel.ApiOption = this.apiOptions[groupName][name]
            return {
              name,
              isDirty: apiOption.path !== apiOption.originPath,
              apiOption,
            }
          }
        ),
      }
    })
  }

  get isSomeDirtyApiOptions() {
    return Object.values(this.getApiOptions).some((group) =>
      group.list.some((apiOption) => apiOption.isDirty)
    )
  }

  get isDirtyAllFeatures(): boolean {
    return Object.values(this.features.entities).some((item) => item.state !== item.originState)
  }
}
