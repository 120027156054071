{
  "$id": "1",
  "Result": {
    "$id": "2",
    "CountSuccessLogin": 5,
    "CountDaysPreviousFailurePush": 5,
    "CountOffersActivatePush": 2,
    "CountDevicePush": 5,
    "SessionActivityMinutes": 10,
    "DaysCountFromLastResponseDate": 30,
    "LoginCountFromLastOfferPopupShow": 5,
    "DaysCountFromLastOfferPopupShow": 30,
    "EnableCompleteTransactionSC": true,
    "EnableCompleteTransactionSS": true,
    "EnableCompleteTransactionRD": false,
    "RefillCardNum": 3,
    "EnablePopupOffersMicrocash": false,
    "PaymentProcessingMode": 3,
    "CascaderWebpageURL": "https://balancer-gateway-ft.omni.homecredit.ru:8112/cascader/v1/url",
    "EnableEarlyRepayment": true,
    "PrepaymentUrl": "",
    "LannisterUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/",
    "EnableCardTransactionsRD": true,
    "EnableCardTransactionsRDTW": true,
    "EnableCardTransactionsDC": true,
    "EnableDepositTransactions": false,
    "EnableAccountTransactions": true,
    "ShowAclipSurvey": true,
    "EnableChat": true,
    "ChatDomain": "test-homechat.homecredit.ru",
    "ChatAccount": "homecredit.ru",
    "IbsMethods": {
      "$id": "3",
      "CreditCardHomerTransactionsUrl": "http://os-2900.homecredit.ru:7010/rest/creditCards/transactions",
      "CreditCardTWTransactionsUrl": "http://os-2900.homecredit.ru:7010/rest/merchantCards/transactions",
      "CreditCardBlockURL": "https://balancer-gateway-ft.omni.homecredit.ru:8112/cards-status-manager/v1/homer/SetCardStatus",
      "ShoppingCardPaymentInfoURL": "http://os-2900.homecredit.ru:7010/rest/merchantCards/installments",
      "BscHost": "http://os-2062.homecredit.ru:7010/rest/",
      "TWCardBlockURL": "https://balancer-gateway-ft.omni.homecredit.ru:8112/cards-status-manager/v1/tw/SetCardStatus",
      "DebitCardTransactionsUrl": "http://os-2900.homecredit.ru:7010/rest/debitCards/transactions",
      "DepositTransactionsUrl": "http://os-2900.homecredit.ru:7010/rest/deposits/transactions",
      "CreditCardHomerTransactionsClioUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/clio/v1/Transactions/creditCard",
      "CreditCardTWTransactionsClioUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/clio/v1/Transactions/merchantCard",
      "DebitCardTransactionsClioUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/clio/v1/Transactions/debitCard",
      "DepositTransactionsClioUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/clio/v1/Transactions/deposit",
      "ShoppingCardPaymentInfoClioUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/clio/v1/installments/merchantCard"
    },
    "EnableDataCollect": true,
    "SmartDataURL": "http://google.com",
    "EnableCall": false,
    "EnableCityCall": true,
    "EnableCession": true,
    "SayNoCount": 3,
    "DataCompression": false,
    "EnableSigning": true,
    "SigningUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/cc-sign/v1/sessions/url",
    "OnlineRequestURL": "https://balancer-gateway-ft.omni.homecredit.ru:8112/q5f/v1/contracts",
    "PinGenerationUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/pin/v1/mcpingenerations",
    "PinGenerationUrlv2": "https://balancer-gateway-ft.omni.homecredit.ru:8112/pin/v2/mcpingenerations",
    "PinGenerationUrlv3": "https://balancer-gateway-ft.omni.homecredit.ru:8112/pin/v3/mcpingenerations",
    "EnableCreditCardActivation": true,
    "EnableTWCardActivation": true,
    "EnableCreditCardPinGeneration": true,
    "EnableInAppRating": true,
    "EnableTWCardPinGeneration": true,
    "ImagesUrl": "http://sales-father-images-nginx-ft.omni.homecredit.ru/images/",
    "EnablePayments": true,
    "EnableSecureBank": true,
    "EnableCloseReasons": true,
    "DaysBeforeCardContractClosed": 14,
    "EnableReferences": true,
    "ReferenceLifetime": 60,
    "DecardUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/decard/",
    "CrecardUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/crecard/v2",
    "EnableBoxInsurances": true,
    "PaymentsFlags": {
      "$id": "4",
      "EnablePaymentForServices": true,
      "EnableVisaPhoneTransfer": true,
      "EnableCardTransfer": false,
      "EnableSelfTransfer": true,
      "EnablePaymentTemplates": true,
      "EnableTransactionTemplates": true,
      "EnableAccountTransfer": true,
      "EnableAccountTransferBudget": false,
      "EnableBarcode": true,
      "EnableSbpTransfer": true,
      "BeneficiaryDetailsUrl": "v2/api/rapidapayment/beneficiaryDetails",
      "EnableMobileProviderAutoDiscovery": true,
      "EnableNewDesignPhoneTransfer": false,
      "ShowExchangeButton": true,
      "EnableExchangeTemplates": false,
      "EnableSbpButtonInTransfersByPhone": true,
      "EnableNewDesignPaymentScreen": false,
      "EnableNewDesignCardTransfer": false,
      "EnableNewSelfTransfer": true,
      "EnableTransactionRepeatFromHistory": true,
      "EnableTransferByPhoneNumberTemplates": false,
      "EnablePhoneNumberTransferReworked": true,
      "EnableRegularTransactions": false,
      "EnableRegularTransactionsCreation": false,
      "EnableSbpMe2meTransfer": true,
      "EnableSbpC2BTransfer": true,
      "EnableSbpayTransfer": false,
      "EnableCreditCardSbpC2CTransfer": false,
      "CreditCardTextForSbpTransfers": "В случае использования кредита для перевода взимается комиссия 5,9% от суммы использованного кредита плюс 590 ₽, включаемая в Минимальный платеж. Льготного периода нет, со дня подтверждения перевода  начисляются 59,9% годовых на сумму использованного кредита при переводе.",
      "EnableCreditCardSbpC2BTransfer": true,
      "EnableCreditCardSbpMe2MeTransfer": false,
      "EnableSortSbpMe2MeNative": false,
      "EnableSbpC2BSubscriptions": false,
      "EnableCreditCardSbpC2BSubscriptions": false
    },
    "VisaPhoneTransferUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/visa-phone-transfer/v1/resolve",
    "TransferUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/transfer/v1",
    "VisaCard2PhoneUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/card2phone/v1/bind",
    "EnableVisaCard2PhoneBind": true,
    "EnableVisaCard2PhoneUnBind": true,
    "EnableWidgetsSettings": true,
    "EnableDebitCardActivation": true,
    "EnableDebitCardPinGeneration": true,
    "EnableCreditCardPinRegeneration": true,
    "EnableTWCardPinRegeneration": true,
    "EnableDebitCardPinRegeneration": true,
    "EnablePlasticCardActivation": true,
    "ShoppingCardPartnersFromBanksiteUrl": "https://www.homecredit.ru/api/v1/installment_card/integration/recommended_partners/",
    "EnableMgm": true,
    "EnablePushSettings": true,
    "EnablePushNotificationReminder": false,
    "EnableOTPPushSettings": true,
    "OtpSetDeviceBlockTimeout": "23:59:59",
    "EnableGooglePay": false,
    "EnableApplePay": false,
    "EnableSamsungPay": true,
    "EnableMirPay": true,
    "PayPayUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/paypay/",
    "EnableDepositoDepositOpening": true,
    "ShowDeposits": true,
    "DepositoUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/deposito/v1",
    "DepositoV2Url": "https://balancer-gateway-ft.omni.homecredit.ru:8112/deposito/v2",
    "ShoppingCardFreedomTariffUrl": "https://www.homecredit.ru/download.php?id=26807",
    "MinSumReplenishment": 1000,
    "MinForeignSumReplenishment": 100,
    "EnableOfferManager": true,
    "ChangUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/chang/v1/sessions ",
    "EnableChang": true,
    "EnableChangCommissionDescription": false,
    "OSSFZCheckUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/oss/v1/fz-check",
    "OSSCreateSessionUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/oss/v1/sessions",
    "EnableOSS": true,
    "EnableManzaner": true,
    "SecureBankApiUrl": "https://old.homecredit.ru/sbbe/api/fl",
    "ManzanerUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/manzaner/v1/",
    "ManzanerHostUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/manzaner/",
    "EnableDocuments": false,
    "EnableSmsPackages": true,
    "EnableTransService": true,
    "TransServiceCheckDBO": "https://balancer-gateway-ft.omni.homecredit.ru:8112/trans/v1/client",
    "TransServiceChangeData": "https://balancer-gateway-ft.omni.homecredit.ru:8112/trans/v1/client/data",
    "TransServiceCheckData": "https://balancer-gateway-ft.omni.homecredit.ru:8112/trans/v1/client/data/check",
    "DadataAPIKey": "c24ddc0272c7519545c60bd717e626b98b7a58a3",
    "DadataUrl": "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
    "EnableChangeActAddress": true,
    "EnableChangeEmployer": true,
    "EnableChangeEmail": true,
    "EnableChangeRegAddress": true,
    "EnableChangePassportData": true,
    "EnableChangePhoneNumber": true,
    "PaymentPrintUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/clio/v1/print/pdf",
    "EmailPaymentUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/clio/v1/sendmail/pdf",
    "WithoutLoanBalance": true,
    "DigitalCardUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/digital-card/v1/",
    "DigitalCardUrlV2": "https://balancer-gateway-ft.omni.homecredit.ru:8112/digital-card/v2/",
    "EnableDigitalCard": true,
    "FinProtectManagerUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/fin-protect-manager/v1/",
    "FinProtectManagerV2Url": "https://balancer-gateway-ft.omni.homecredit.ru:8112/fin-protect-manager/v2/",
    "EnableFinProtectManager": true,
    "EnableFinProtectForShoppingCard1": true,
    "BikerUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/biker/v1/bik/?search",
    "CommissarUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/commissar/v1",
    "TokenLifetimeInMinutes": 30,
    "TransactionsCount": 25,
    "EnableCreditHintAndroid": true,
    "EnableCreditHintIos": true,
    "HintAndroidDelayedDisplay": 5,
    "HintIosDelayedDisplay": 5,
    "SalesFatherUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/sales-father",
    "EnableQuickNoApplication": true,
    "SalesFatherQuickNoPath": "/v1/quickNo",
    "SalesFatherInsurancePath": "/v1/boxInsurance",
    "SalesFatherOffersPath": "/v1/offer",
    "EnableDocumentsNew": true,
    "SafetyCardUrl": "https://www.homecredit.ru/download.php?fid=93452",
    "EnableReissueCard": true,
    "ReissueCardUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/emissio/v1",
    "CloseableProducts": ["DC", "RDTW"],
    "EnableThirdPartyBKS": true,
    "BKSFrontUrl": "https://investcab.com/api/v1/security/auth/hcb",
    "CascaderUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/cascader/v1/",
    "TemplatorUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/templator/v1/",
    "ConsentsUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/consents/v1/consents",
    "CreditCardPolzaEnabled": true,
    "EnableCreditPolzaCashbackLightPlus": true,
    "EnablePersonalCardQR": false,
    "EnablePolzaMGMWidget": true,
    "EnablePolzaMainWidget": true,
    "EnablePolzaCardWidget": true,
    "DebitCardRegistryGetUrlPath": "/debit-card-registry/v3/url",
    "Card2PhoneUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/card2phone/v1",
    "EnableCardTemplates": true,
    "CreditBureauSettings": {
      "$id": "5",
      "IsEnabled": true,
      "Url": "https://ucbreport.ru/?utm_source=homecredit&utm_medium=referral&utm_campaign=promo&utm_content=mycredit&utm_term=to_main",
      "Title": "Кредитный отчет онлайн"
    },
    "PaymentHistoryMaxDifference": 7,
    "IsVkPayEnabled": true,
    "IsLevelUpWidgetEnabled": false,
    "EnableRepaymentMoratory": true,
    "RepaymentMoratoryUrl": "https://www.homecredit.ru/kanikuly/?id=48175416",
    "RepaymentMoratoryButtonDate": "07.10.2022",
    "EnableDebitCardCommission": false,
    "CommissionInfo": {
      "$id": "6",
      "DebitCardCommissionVisa": "50",
      "DebitCardCommissionMir": "50",
      "DebitCardCommissionMC": "50",
      "DebitCardCommissionThreshold": "50 000",
      "DebitCardCommissionRate": "0,5"
    },
    "EnableNewRedesignCredit": false,
    "EnableProjectedPayments": true,
    "PaymentsServiceUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/paymentmanagerservice/v2",
    "PaymentManagerServiceApiUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/paymentmanagerservice/v3",
    "ArrestUrl": "https://www.homecredit.ru/help/arrest",
    "BankFreePhoneNumber": "88007008766",
    "TwServicesManagerUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/tw-services-manager/v1",
    "EnableDivideBy3": true,
    "EnableShowcase": true,
    "EnableShoppingCardPartnersNativeCatalog": true,
    "EnablePolzaCardPartnersNativeCatalog": true,
    "EnableGenesysChatAndroid": true,
    "EnableCardRedesignAndroid": true,
    "EnableCascaderV2": true,
    "SecureBankCustomerIdForAndroid": "gib-a-homecredit",
    "SecureBankCustomerIdForIOs": "gib-i-homecredit",
    "SecureBankCustomerIdForWeb": "gib-w-homecredit",
    "EnableLimitManage": true,
    "LimitatorUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/limitator/v1",
    "LimitatorUrlV2": "https://balancer-gateway-ft.omni.homecredit.ru:8112/limitator/v2",
    "EnableLimitInfoOnCardWidget": true,
    "TransServiceFrontUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/trans/v1/sessions/url",
    "EnableOperationHistoryReport": true,
    "ClioUrl": "https://balancer-gateway-rp.omni.homecredit.ru:8112/clio/v1/",
    "ClioBaseUrl": "https://balancer-gateway-rp.omni.homecredit.ru:8112/clio",
    "EnableBalanceHiding": true,
    "ShowPdfInAppAndroid": true,
    "PersonalFinanceManagementSettings": {
      "$id": "7",
      "EnablePfm": true,
      "EnablePfmV2": true,
      "EnablePaymentHistoryPaging": true,
      "EnablePfmCategoryHistory": true,
      "OperationPagingCount": 15
    },
    "EnableGetSavedStories": true,
    "EnableTariffNative": true,
    "CommissionInfoUrl": "https://www.homecredit.ru/pages/commission_polza/",
    "EnableChangeUserPin": true,
    "EnableThirdPartyBKSPortfolio": true,
    "EnableCancelSubscription": true,
    "EnableChildNameOnCard": true,
    "EnableRequisitesDialog": true,
    "EnableComplaints": true,
    "EnableComplaintsAttachments": true,
    "ThirdPartyDeeplinkHosts": ["market.homecredit.ru", "hc-mrkt.app.link"],
    "ComplaintLifetimeInDays": 60,
    "EnableDigitalCardOnboarding": false,
    "ShowPaymentsButtonOnCardPage": true,
    "EnableOpenTracingMobile": true,
    "EnablePlasticIssue": true,
    "UnreadCountUpdateDelay": 3000,
    "EnableMarketplaceModule": false,
    "EnablePolzaCardTextForTransfers": true,
    "PolzaCardTextForTransfers": "В случае использования кредита для перевода взимается комиссия 5,9% от суммы использованного кредита плюс 590 ₽, включаемая в Минимальный платеж. Льготного периода нет, со дня подтверждения перевода начисляются 59,9% годовых на сумму использованного кредита при переводе.",
    "PaymentHistoryMaxPagingDepth": 12,
    "EnableEmailForPayments": true,
    "CloseDepositMinLevel": 3,
    "UnableCloseDepositStartTime": "23:30:00",
    "UnableCloseDepositEndTime": "03:30:00",
    "CloseDepositNeedLevelUp": true,
    "EnableCloseDeposit": true,
    "EnableBreakForFamilyAcc": false,
    "EnablePolzaFreeCategories": true,
    "EnableOldPolzaWidget": false,
    "EnableNewAccForDemanded": true,
    "EnableRdAccountOpening": true,
    "DisableCvvForPayments": true,
    "ReferenceTypes": "AccountStatement",
    "EnablePolzaPointsExchange": true,
    "EnableInfoModeWidget": true,
    "EnableNewHttpClientInTransferByRequisitesService": true,
    "EnableFamilyAccBaseStreetWebView": false,
    "BalanceTransferConditionsUrl": "https://homecredit.ru",
    "EnableBalanceTransfer": true,
    "SbpApiUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/visa-phone-transfer/v1/sbp",
    "SbpTransferUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/sbp-transfer/v1/",
    "EnableSavingAccountDetailedTooltip": true,
    "PolzaCategoriesDCIdents": [
      "KARTAPOLZA_RUR",
      "SPACE_RUR",
      "KARTACOINKEEPER_01_RUR",
      "POLZAZARPLATNIY_RUR",
      "KARTAKOSMOSZARPLATNYY_01_RUR",
      "Polza_Travel_01",
      "KARTAMIR_01_RUR",
      "Pensionnaya_RUR",
      "CARD_INGAME",
      "CARD_GREENPOLZA",
      "KARTATELE2BLACK_01_RUR",
      "KARTATELE2MINT_01_RUR",
      "SPACE_RUR",
      "KARTAM5MOLL_01_RUR",
      "KARTAFAIR_01_RUR",
      "KARTACOINKEEPER_01_RUR"
    ],
    "FamilyAcc": {
      "$id": "8",
      "EnableQ5FRegistry": false,
      "EnableBreak": false,
      "EnableBaseStreetWebView": false
    },
    "ShowTWCreditCardPaymentsDetalization": false,
    "EnableVisaMasterDateValidation": false,
    "EnablePaymentManagerServiceApiV3": true,
    "EmissioUrlV2": "https://balancer-gateway-ft.omni.homecredit.ru:8112/emissio/v2",
    "EnableReissueByExpirationDate": true,
    "EnableReissueCardV2": true,
    "EnablePlasticIssueV2": true,
    "EnableMarketplaceModuleAndroid": false,
    "EnableSkipPaymentCreditCard": true,
    "EnableCreditCardFinProtection": true,
    "EnableScreenRecordingSecurity": true,
    "EnableInstallmentService": true,
    "InstallmentPartnersCardTypes": [2, 3, 4, 6],
    "EnableCardDeliveryStatus": false,
    "EnableAdditionalCardIssue": false,
    "EnableHomePay": false,
    "EnableFinPolzaHelpWidget": false,
    "SbpayRulesUrl": "",
    "SbpRulesUrl": "",
    "SbpAgreeUrl": "",
    "TransactMakeuperUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/transact-makeuper/v1",
    "EnableSalaryCardApplication": true,
    "CreditCardCommissionSettings": {
      "$id": "9",
      "CreditCardCommissionRate": "1",
      "CreditCardCommissionMin": "100",
      "EnableCreditCardCommissionHint": false
    },
    "EnableProductsMfo": true,
    "EnableCreditLinesMfo": false,
    "CardsStatusManagerUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/cards-status-manager/v1",
    "TimeSecondsResendOtpCode": 40,
    "EnableServicesMfo": true,
    "EnableV3Offers": true,
    "PushPopupNotificationRepeatMinutes": 129600,
    "NetflixSettings": {
      "$id": "10",
      "WorkflowManagerUrl": "https://balancer-gateway-loanf.omni.homecredit.ru:8112/workflow-manager/v1/api/workflow/",
      "WebSocketUrl": "wss://balancer-gateway-loanf.omni.homecredit.ru:8112/conductor-websocket/v1/socket",
      "WorkflowNameCreateSubscriptionSbp": "ft.MYC_SBP_SUB_create_subscription",
      "WorkflowNameUpdateSubscriptionSbp": "ft.MYC_SBP_SUB_update_subscription",
      "RouteNameSbpSubscription": "netflix-request-sbpSubpscriptionsOtp-ft"
    },
    "DigitalProfileSettings": {
      "$id": "11",
      "EnableDigitalProfile": true,
      "DigitalProfileRedirectUrl": "https://rt.homecredit.ru/online/credit/?returnFromDP=1",
      "DigitalProfileMethodName": "ft.MYC_digital_profile"
    },
    "EnableHidingSmsPackageButtonForCredit": true,
    "CodewordCreationSettings": {
      "$id": "12",
      "IsEnabled": true,
      "ServiceUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/cool-code/v2/codeword",
      "Timeout": 30
    },
    "EnableMfoProductSchedule": true,
    "EnableSelfBalanceTransfer": true,
    "MycClientProductsUrl": "https://balancer-gateway-ft.omni.homecredit.ru:8112/myc-client-products/v1.0/api/",
    "EnableSubscriptionServiceV1": false,
    "EnableRegisterAgainWidget": true,
    "SKBCessionParams": {
      "$id": "13",
      "SKBmobileAppUrl": "https://scb.im/hva",
      "SKBpaymentsChecklistUrl": "https://sovcombank.ru/payments/pamjatka-platejey",
      "SKBDetailsButtonUrl": "https://halvacard.ru/dencredit-hcb/"
    },
    "SoldOutCreditDescription": "на основании перевода прав и обязанностей ваш кредитный договор перешел на обслуживание в банк-партнер БЕЗ ИЗМЕНЕНИЯ УСЛОВИЙ, размера и даты платежа.<br>Чтобы оперативно получать информацию по вашему кредиту и быстро вносить платежи, используйте <a href=https://pwa.sovcombank.ru/ > мобильное приложение </a> банка-партнера (Войти в мобильный банк – Регистрация для клиентов Хоум Банка). Все способы внесения платежей <a href=https://sovcombank.ru/payments/pamjatka-platejey > здесь</a>.",
    "SoldOutCreditPaymentDescription": "<b>Внимание</b><br>Текущий платеж можно внести здесь. Будущие платежи для зачисления без задержек рекомендуем делать в <a href=https://scb.im/hva > мобильном приложении </a> «Халва – Совкомбанк» (Войти в мобильное приложение. Для клиентов Хоум Банка) или в <a href=https://halvacard.ru/lk > личном кабинете </a>. Все способы внесения платежей <a href=https://sovcombank.ru/payments/pamjatka-platejey > здесь </a>.",
    "IsFeedBackStatusEnabled": false,
    "MicroCashOffersLink": "https://balancer-gateway-ft.omni.homecredit.ru:8112/application/v1/offers/popup",
    "MicroCashRequestURL": "https://balancer-gateway-ft.omni.homecredit.ru:8112/application/v1/applications/url"
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
