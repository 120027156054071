[
  {
    "amount": 35.0,
    "hexColor": "9E9E9E",
    "creditDebitIndicator": true,
    "fee": 0.0,
    "merchant": "Банк Хоум Кредит",
    "merchantName": "Банк Хоум Кредит",
    "movementNumber": "+2838091733",
    "picUrl": "https://ib.homecredit.ru/hcfbib.server.portal.app-theme-main/pictures/plus_40.png",
    "primaryDescription": "Банк Хоум Кредит",
    "secondaryDescription": "Поступление денежных средств на счет ",
    "shortDescription": "Поступление денежных средств на счет ",
    "valueDate": "2023-10-04T10:38:02+00:00",
    "category": "OTHER",
    "nfc": false
  },
  {
    "amount": 100.0,
    "hexColor": "9E9E9E",
    "creditDebitIndicator": true,
    "fee": 0.0,
    "merchant": "Банк Хоум Кредит",
    "merchantName": "Банк Хоум Кредит",
    "movementNumber": "+2838091732",
    "picUrl": "https://ib.homecredit.ru/hcfbib.server.portal.app-theme-main/pictures/plus_40.png",
    "postingDate": "2023-10-03T21:00:00+00:00",
    "primaryDescription": "Банк Хоум Кредит",
    "secondaryDescription": "Пополнение счета  ",
    "shortDescription": "Пополнение счета  ",
    "valueDate": "2023-10-04T10:36:49+00:00",
    "category": "OTHER",
    "nfc": false
  },
  {
    "amount": 50.0,
    "hexColor": "9E9E9E",
    "creditDebitIndicator": true,
    "fee": 0.0,
    "merchant": "Банк Хоум Кредит",
    "merchantName": "Банк Хоум Кредит",
    "movementNumber": "+2838091731",
    "picUrl": "https://ib.homecredit.ru/hcfbib.server.portal.app-theme-main/pictures/plus_40.png",
    "postingDate": "2023-10-03T21:00:00+00:00",
    "primaryDescription": "Банк Хоум Кредит",
    "secondaryDescription": "Пополнение счета  ",
    "shortDescription": "Пополнение счета  ",
    "valueDate": "2023-10-04T10:36:08+00:00",
    "category": "OTHER",
    "nfc": false
  },
  {
    "amount": 124.0,
    "hexColor": "D17272",
    "creditDebitIndicator": false,
    "fee": 0.0,
    "merchant": "ХКФБ",
    "merchantName": "МТС",
    "movementNumber": "2577481231",
    "paymentDetail": {
      "status": "InProgress",
      "operationType": "Payment",
      "inn": "7740000076",
      "referenceId": "4.33.02.01_329073303",
      "transactionId": "329073303",
      "paymentOrder": false,
      "paymentFieldValue": {
        "Номер телефона": "9191796268"
      }
    },
    "picUrl": "https://sales-father-image.dev.myc.homecredit.ru/images/category_1_x3.png",
    "primaryDescription": "МТС",
    "secondaryDescription": "Мобильная связь",
    "valueDate": "2022-12-22T00:00:00+00:00",
    "mccCode": "4814",
    "category": "TELECOMMUNICATION",
    "nfc": false,
    "clientRole": 1
  }
]
