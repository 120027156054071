import styled from '@emotion/styled'
import { Tabs } from '@platform-ui/components/Tabs'
import { Button } from '@platform-ui/components/Button'

export const FormContainer = styled.div(() => ({}))

export const Root = styled.div(({ theme }) => {
  return {
    /* mobile viewport bug fix */
    minHeight: theme.fillContent,
    maxHeight: theme.fillContent,
    overflowX: 'hidden',
    overflowY: 'auto',
    flexDirection: 'column',
    display: 'flex',
  }
})

export const GroupTitle = styled(Button)(({ theme }) => {
  return {
    fontFamily: theme.fontFamily.base,
    marginTop: '8px',
    padding: '16px 0 8px 0',
    borderBottom: '1px solid #ddd',
    textTransform: 'uppercase',
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    marginBottom: 0,
    zIndex: 2,
    display: 'flex',
    fontSize: '1.5em',
    width: '100%',
    fontWeight: '800',
    textAlign: 'left',
    justifyContent: 'flex-start',
    borderRadius: 0,
  }
})

export const GroupTitleLeftIcon = styled.div<{ isOpen: boolean }>(({ isOpen }) => {
  return {
    transition: 'transform 200ms linear',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }
})

export const Content = styled.div(({ theme }) => {
  return {
    padding: '8px 24px 50px',
    maxHeight: '100%',
    width: 'calc(100vw - 48px)',

    [`${theme.media.md}`]: {
      width: '480px',
    },
  }
})

export const TitleContainer = styled.div(() => {
  return {
    display: 'flex',
    alignItems: 'flex-end',
  }
})

export const TitleWrapper = styled.label(() => {
  return {
    flexGrow: 1,
  }
})

export const Title = styled.h4(({ theme }) => {
  return {
    textTransform: 'capitalize',
    flexGrow: 1,
    fontFamily: theme.fontFamily.base,
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
  }
})

export const TitleText = styled.span(() => {
  return {
    marginLeft: '8px',
  }
})

export const SwitchRoot = styled.div(() => {
  return {
    paddingLeft: '16px',
    width: '100%',
  }
})

export const TabsCustom = styled(Tabs)(() => {
  return {
    width: '100%',
  }
})

export const Footer = styled.div(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    bottom: 0,
    padding: '8px 24px 8px 8px',
    zIndex: 3,
    marginTop: 'auto',
    alignItems: 'center',
    flexDirection: 'column',

    ['&::before']: {
      display: 'block',
      position: 'absolute',
      content: "''",
      right: '24px',
      left: '24px',
      bottom: '0px',
      top: '0px',
      backgroundColor: '#fff',
      borderTop: '1px solid #ddd',
      zIndex: -1,
    },
  }
})

export const MockTitle = styled.div(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '8px',

    '> :nth-of-type(2)': {
      marginLeft: 'auto',
      paddingLeft: '8px',
    },
  }
})

export const EmptyMockMessage = styled.div(() => {
  return {
    marginTop: '8px',
  }
})
