import { createMocks } from '@/features/developer/utils/createMocks'
import fileId1 from './success.json'
import fileId2 from './empty.json'
import fileId3 from './only-autogen.json'
import reject401 from './reject-401.json'
import reject403 from './reject-403.json'
import reject500 from './reject-500.json'

export const mockItems = createMocks([
  {
    title: 'Успешный ответ',
    status: 200,
    data: fileId1,
    description: '',
  },
  {
    title: 'Пустой ответ',
    status: 200,
    data: fileId2,
    description: '',
  },
  {
    title: 'Только автогенерируемые справки',
    status: 200,
    data: fileId3,
    description: '',
  },
  {
    title: 'Код 401',
    status: 401,
    data: reject401,
    description: '',
  },
  {
    title: 'Код 403',
    status: 403,
    data: reject403,
    description: 'Доступ запрещен, нужно поднять уровень доступа',
  },
  {
    title: 'Код 500',
    status: 500,
    data: reject500,
    description: '',
  },
])
