import { createMocks } from '@/features/developer/utils/createMocks'
import error from './error.json'

export const mockItems = createMocks([
  {
    title: 'Успешно',
    status: 200,
    data: null,
    description: '',
  },
  {
    title: 'Ошибка 401',
    status: 401,
    data: error,
    description: '',
  },
])
