import React, { forwardRef } from 'react'
import { TIconProps } from '@platform-ui/icons/core/Icon.types'
import { Button, IconWrapper, Label } from './styled'
export interface MenuItemProps {
  icon: React.FC<TIconProps & React.RefAttributes<HTMLDivElement>>
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  isActive: boolean
  children: React.ReactNode
}

export const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(function MenuItem(props, ref) {
  const { icon: Icon, children, onClick, isActive } = props
  return (
    <Button onClick={onClick} ref={ref} isActive={isActive}>
      <IconWrapper>
        <Icon size="s12" />
      </IconWrapper>
      <Label>{children}</Label>
    </Button>
  )
})
