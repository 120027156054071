import styled from '@emotion/styled'

export const GroupTitle = styled.h2(({ theme }) => {
  return {
    fontFamily: theme.fontFamily.base,
    marginTop: '8px',
    paddingTop: '16px',
    borderBottom: '1px solid #ddd',
    paddingBottom: '8px',
    textTransform: 'uppercase',
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    marginBottom: 0,
    zIndex: 2,
  }
})
