import styled from '@emotion/styled'
import { ButtonBase } from '@platform-ui/components/ButtonBase'

export const Button = styled(ButtonBase)<{ isActive: boolean }>(({ theme, isActive }) => ({
  backgroundColor: '#fff',
  width: '36px',
  height: '36px',
  cursor: 'pointer',
  transition: 'color 240ms, transform 240ms',
  border: '0',
  borderRadius: 16,
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  color: isActive ? theme.color['text/primary'] : theme.color['text/main'],

  '&:hover': {
    color: theme.color['text/primary'],
  },

  '&:active': {
    transform: 'scale(0.9, 0.9)',
  },

  [`${theme.media.md}`]: {
    width: '60px',
    height: '60px',
  },
}))

export const IconWrapper = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '4px',
}))

export const Label = styled.div(({ theme }) => ({
  marginTop: '4px',
  fontSize: '8px',
  textAlign: 'center',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: 'none',

  [`${theme.media.md}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))
