import { createMocks } from '@/features/developer/utils/createMocks'
import fileId1 from './success.json'

export const mockItems = createMocks([
  {
    title: 'Василий Пупкин',
    status: 200,
    data: fileId1,
    description: '',
  },
])
