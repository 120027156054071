{
  "accounts": [
    {
      "accountNumber": "40817810090011226948",
      "contractNumber": "7209248202",
      "accountName": "Текущий счёт",
      "runningBalance": 2461999.5,
      "status": "Open",
      "accountType": "DebitCard",
      "currency": "RUR",
      "currencyCode": 643,
      "accountPrcRate": 0,
      "recomendationText": "",
      "displayOrder": 0
    },
    {
      "accountNumber": "40817810150010109207",
      "contractNumber": "207213218",
      "accountName": "Текущий счёт",
      "runningBalance": 106,
      "status": "Open",
      "accountType": "CreditCard",
      "currency": "RUR",
      "currencyCode": 643,
      "displayOrder": 1
    },
    {
      "accountNumber": "40817810190013213723",
      "contractNumber": "7319321837",
      "accountName": "Накопительный счёт",
      "runningBalance": 7061.16,
      "status": "Open",
      "accountType": "SavingAccount",
      "currency": "RUR",
      "currencyCode": 643,
      "accountPrcRate": 6,
      "recomendationText": "Для ставки 9% годовых:\nСовершите покупки по дебетовым и кредитным картам на сумму от 20000 руб., осталось 9923,04 руб.",
      "recomendationTextJson": "{\"recomendationTextJson\":{\"maxRate\":15,\"tipsLevelInfo\":[{\"textId\":101,\"amountTarget\":20000,\"amountLeft\":9923.04,\"targetRate\":9,\"currency\":\"RUR\"},{\"textId\":101,\"amountTarget\":60000,\"amountLeft\":49923.04,\"targetRate\":12,\"currency\":\"RUR\"},{\"textId\":101,\"amountTarget\":100000,\"amountLeft\":89923.04,\"targetRate\":15,\"currency\":\"RUR\"}],\"additionalInfo\":[{\"textId\":103},{\"textId\":104},{\"textId\":105},{\"textId\":106},{\"textId\":107},{\"textId\":108},{\"textId\":102,\"reduceInterestRateAmount\":1500000,\"currency\":\"RUR\",\"reduceInterestRate\":6}]}}",
      "displayOrder": 2
    },
    {
      "accountNumber": "40817810590011812700",
      "contractNumber": "7008000019",
      "accountName": "Текущий счёт",
      "runningBalance": 327652.56,
      "status": "Open",
      "accountType": "DebitCard",
      "currency": "RUR",
      "currencyCode": 643,
      "accountPrcRate": 0,
      "recomendationText": "",
      "displayOrder": 3
    },
    {
      "accountNumber": "40817810790010355796",
      "contractNumber": "7005436812",
      "accountName": "Текущий счёт",
      "runningBalance": 26950.04,
      "status": "Open",
      "accountType": "DebitCard",
      "currency": "RUR",
      "currencyCode": 643,
      "accountPrcRate": 0,
      "recomendationText": "",
      "displayOrder": 4
    },
    {
      "accountNumber": "40817810790012167908",
      "contractNumber": "7317000350",
      "accountName": "Текущий счёт",
      "runningBalance": 14664,
      "status": "Open",
      "accountType": "DebitCard",
      "currency": "RUR",
      "currencyCode": 643,
      "accountPrcRate": 0,
      "recomendationText": "",
      "displayOrder": 5
    },
    {
      "accountNumber": "42301810340640272857",
      "contractNumber": "419118399",
      "accountName": "Счёт",
      "runningBalance": 60000,
      "status": "Open",
      "accountType": "Credit",
      "currency": "RUR",
      "currencyCode": 643,
      "displayOrder": 6
    },
    {
      "accountNumber": "42301810540640299656",
      "contractNumber": "419147026",
      "accountName": "Счёт",
      "runningBalance": 60033,
      "status": "Open",
      "accountType": "Credit",
      "currency": "RUR",
      "currencyCode": 643,
      "displayOrder": 7
    },
    {
      "accountNumber": "42301810840640320458",
      "contractNumber": "419169462",
      "accountName": "Счёт",
      "runningBalance": 60000,
      "status": "Open",
      "accountType": "Credit",
      "currency": "RUR",
      "currencyCode": 643,
      "displayOrder": 8
    },
    {
      "accountNumber": "42301810940640290857",
      "contractNumber": "419139365",
      "accountName": "Счёт",
      "runningBalance": 60000,
      "status": "Open",
      "accountType": "Credit",
      "currency": "RUR",
      "currencyCode": 643,
      "displayOrder": 9
    }
  ],
  "isExpired": false
}
