[
  {
    "amount": 3000.0,
    "hexColor": "9E9E9E",
    "creditDebitIndicator": true,
    "fee": 0.0,
    "movementNumber": "+3228693127",
    "picUrl": "https://sales-father-images-nginx-rt.omni.homecredit.ru/images/accounts.png",
    "postingDate": "2023-11-15T05:38:07+00:00",
    "primaryDescription": "Поступление от Демид С.",
    "secondaryDescription": "Поступивший перевод",
    "senderAccount": "40817810090011226948",
    "senderName": "Демид С.",
    "valueDate": "2023-11-15T05:38:07+00:00",
    "senderBank": "ООО \"ХКФ БАНК\"",
    "category": "PHONETRANSFER",
    "nfc": false,
    "categoryExtended": {
      "categoryNameEn": "PHONETRANSFER",
      "categoryNameRu": "По номеру телефона",
      "colorCategory": "8376DA",
      "imgCategory": "https://sales-father-images-nginx-rt.omni.homecredit.ru/images/phone.png"
    }
  },
  {
    "amount": 222.0,
    "hexColor": "9E9E9E",
    "creditDebitIndicator": true,
    "fee": 0.0,
    "movementNumber": "+3228677590",
    "picUrl": "https://sales-father-images-nginx-rt.omni.homecredit.ru/images/accounts.png",
    "postingDate": "2023-11-13T13:08:07+00:00",
    "primaryDescription": "Поступление от Демид С.",
    "secondaryDescription": "Поступивший перевод",
    "senderAccount": "40817810090011226948",
    "senderName": "Демид С.",
    "valueDate": "2023-11-13T13:08:07+00:00",
    "senderBank": "ООО \"ХКФ БАНК\"",
    "category": "PHONETRANSFER",
    "nfc": false,
    "categoryExtended": {
      "categoryNameEn": "PHONETRANSFER",
      "categoryNameRu": "По номеру телефона",
      "colorCategory": "8376DA",
      "imgCategory": "https://sales-father-images-nginx-rt.omni.homecredit.ru/images/phone.png"
    }
  }
]
