{
  "$id": "1",
  "Result": {
    "$id": "2",
    "ServerDate": "2021-06-08T00:00:00+03:00",
    "CreditLoan": [],
    "CreditCard": [],
    "CreditCardTW": [
      {
        "$id": "3",
        "CreditCardTWGuiData": {
          "$id": "4",
          "CreditCardTWGuiStatus": 0,
          "CreditCardTWPolzaGuiStatus": 3,
          "DisplayOrder": 0,
          "Colour": 2,
          "DisplayedPayments": 4,
          "ShowGauge": true,
          "GaugeMaxValue": 30,
          "GaugeCurrentValue": 30,
          "DaysLeft": -277,
          "DaysLeftWordAgreement": 3,
          "DebtDaysWordAgreement": 3,
          "PaymentSystem": 1,
          "ShowTwoBalances": false,
          "DebtDays": 0,
          "DebtAmount": 0.0
        },
        "ContractNumber": "2296579371",
        "ProductName": "Кредитная Польза",
        "ProductId": 1633,
        "ProductType": 7,
        "Contract": {
          "$id": "5",
          "Properties": {
            "$id": "6",
            "ContractBillingDate": "2020-09-04T00:00:00",
            "NextPaymentDate": "2020-09-04T00:00:00",
            "SumToPay": 1280.12,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          }
        },
        "ContractStatus": 1,
        "AccountNumber": "40817810893040020590",
        "CreditLimit": 80000.0,
        "AvailableBalance": 68678.21,
        "InstalmentDebtSum": 1280.12,
        "MinMonthDebtAmount": 1280.12,
        "RecommendedPaymentSum": 0.0,
        "GracePayment": 11809.38,
        "IsPolzaLkAvailable": true,
        "MainCardStatus": 3,
        "MainCardNumber": "406296XXXXXX1291",
        "MainCardMBR": 1,
        "IsPinGenerated": true,
        "IsAdditional": false,
        "IsNoName": false,
        "IsPolza": true,
        "IsInstalmentProduct": false,
        "TotalIndebtedness": 11321.79,
        "PrincipalDebtSum": 11321.79,
        "InterestDebtSum": 338.59,
        "FeeDebtSum": 149.0,
        "PenaltySum": 0.0,
        "DateCreate": "2020-05-06T00:00:00",
        "DateSign": "2020-02-04T00:00:00",
        "StartPaymentPeriod": 5,
        "CardType": 4,
        "OuterLimitInfo": {
          "$id": "7",
          "IsOuterLimitOn": false,
          "OuterCreditLimit": 0.0,
          "OuterAvailableBalance": 0.0,
          "OuterCreditBalance": 0.0,
          "UsedOuterCreditLimit": 0.0,
          "UsedPartnersCreditLimit": 11321.79
        },
        "IsActivationAvailable": false,
        "IsPlasticActivationAvailable": false,
        "PaymentDetails": {
          "$id": "8",
          "OverdueInterest": 0.0,
          "OverdueDebtBody": 0.0,
          "OverduePenalties": 0.0,
          "NonOverduePenalties": 0.0,
          "NonOverdueInterest": 338.59,
          "NonOverdueDebtBody": 792.53,
          "CurrentInstallmentsInMinPayment": 0.0,
          "OverdueFees": 0.0,
          "NonOverdueFees": 149.0,
          "CurrentInstallments": 0.0,
          "Commissions": [
            {
              "$id": "9",
              "Name": "Финансовая Польза",
              "Amount": 149.0,
              "Remain": 149.0,
              "DateLastActive": "2020-05-06T00:00:00+03:00"
            }
          ]
        },
        "ExtraServicesInfo": {
          "$id": "10",
          "IsFinProtectionAvailable": true,
          "IsFinProtectionOn": true,
          "IsSubscriptionAvailable": false,
          "IsSubscriptionOn": false,
          "IsPolzaOn": false
        },
        "AclipInfo": {
          "$id": "11",
          "OfferedIncrease": 0.0,
          "IncreasedCreditLimit": 80000.0,
          "IncreasedAvailableBalance": 68678.21
        },
        "IsDigital": true,
        "AvailableCardInfo": 0,
        "AccountBalance": 0.0,
        "PaymentPeriod": "1 месяц"
      }
    ],
    "IsCreditLoanAndCardError": false,
    "IsCreditCardTWError": false,
    "IsAvailableEarlyRepayment": false
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
