{
  "$id": "1",
  "Result": {
    "CheckType": 2,
    "ResultText": "Превышено количество попыток ввода СМС-кода. В целях вашей безопасности совершение платежей и переводов будет доступно через num минут."
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
