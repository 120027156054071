{
  "$id": "1",
  "Result": {
    "$id": "2",
    "ServerDate": "2021-04-13T00:00:00+03:00",
    "CreditLoan": [
      {
        "$id": "3",
        "CreditLoanGuiData": {
          "$id": "4",
          "CreditLoanGuiStatus": 5,
          "Colour": 1,
          "DisplayOrder": 2,
          "ShowDetails": true,
          "ScheduleAvailable": true,
          "ShowGauge": true,
          "GaugeMaxValue": 30,
          "GaugeCurrentValue": 30,
          "DebtDaysWordAgreement": 3,
          "PercentPaid": 0,
          "RemainingDebt": 100629.04,
          "DebtDays": 400,
          "DebtAmount": 100765.14,
          "InterestRate": 16.9,
          "LoanBalance": 250000.0,
          "PercentPaidBodyCredit": 100,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2330789738",
        "ProductName": "Кредит наличными",
        "ProductType": 2,
        "Contract": {
          "$id": "5",
          "Properties": {
            "$id": "6",
            "NextPaymentDate": "2021-05-09T00:00:00",
            "NextPaymentDateDisplayed": "2021-05-09T00:00:00",
            "PaymentNum": 48,
            "LastPaymentNum": 14,
            "SumToPay": 107962.65,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "EarlyPaymentCalculationDate": "2021-05-09T00:00:00"
          },
          "LastPayDate": "2024-02-09T00:00:00"
        },
        "ContractStatus": 1,
        "CreditAmount": 250000.0,
        "Payment": 7197.51,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810640300725904",
        "DateSign": "2020-02-09T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 136.1,
        "EarlyPaymentSum": 0.0,
        "ProductSet": {
          "$id": "7",
          "Code": "Наличные в кредит для сотрудников",
          "Name": "Наличные сотрудникам_16,9%"
        },
        "AccountArrest": {
          "$id": "8",
          "ActualBalance": 136.1,
          "IsArrest": false,
          "ArrestSum": 0.0,
          "ArrestLeft": 0.0,
          "PercentPaidArrest": 0
        }
      },
      {
        "$id": "9",
        "CreditLoanGuiData": {
          "$id": "10",
          "CreditLoanGuiStatus": 5,
          "Colour": 1,
          "DisplayOrder": 1,
          "ShowDetails": true,
          "ScheduleAvailable": true,
          "ShowGauge": true,
          "GaugeMaxValue": 30,
          "GaugeCurrentValue": 30,
          "DebtDaysWordAgreement": 3,
          "PercentPaid": 16,
          "RemainingDebt": 23312.87,
          "DebtDays": 400,
          "DebtAmount": 23312.87,
          "InterestRate": 19.53,
          "LoanBalance": 22029.95,
          "PercentPaidBodyCredit": 87,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2325605303",
        "ProductName": "Кредит на товар",
        "ProductType": 1,
        "Contract": {
          "$id": "11",
          "Properties": {
            "$id": "12",
            "NextPaymentDate": "2021-04-13T00:00:00+03:00",
            "NextPaymentDateDisplayed": "2021-04-13T00:00:00+03:00",
            "PaymentNum": 12,
            "LastPaymentNum": 12,
            "SumToPay": 23312.87,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "EarlyPaymentCalculationDate": "2021-04-13T00:00:00+03:00"
          },
          "LastPayDate": "2020-12-06T00:00:00"
        },
        "ContractStatus": 1,
        "CreditAmount": 25191.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810640300725904",
        "DateSign": "2019-12-07T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 136.1,
        "EarlyPaymentSum": 0.0,
        "ProductSet": { "$id": "13", "Code": "Без переплаты", "Name": "0-0-12_СК10_Онлайн" },
        "ProductAnnuityType": "g",
        "PrivilegedInterestRate": 36.3,
        "PrimaryInterestRate": 36.3,
        "ChangedInstalment": 3,
        "AccountArrest": {
          "$id": "14",
          "ActualBalance": 136.1,
          "IsArrest": false,
          "ArrestSum": 0.0,
          "ArrestLeft": 0.0,
          "PercentPaidArrest": 0
        }
      },
      {
        "$id": "15",
        "CreditLoanGuiData": {
          "$id": "16",
          "CreditLoanGuiStatus": 8,
          "Colour": 3,
          "DisplayOrder": 3,
          "ShowDetails": false,
          "ScheduleAvailable": false,
          "ShowGauge": false,
          "DebtDaysWordAgreement": 3,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "InterestRate": 12.5,
          "LoanBalance": 250000.0,
          "PercentPaidBodyCredit": 0,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2275280960",
        "ProductName": "Кредит наличными",
        "ProductType": 2,
        "Contract": {
          "$id": "17",
          "Properties": {
            "$id": "18",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 24,
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          },
          "LastPayDate": "2019-08-09T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 155000.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810640300725904",
        "DateSign": "2018-07-09T00:00:00",
        "DateClosure": "2019-08-12T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 136.1,
        "EarlyPaymentSum": 0.0,
        "ProductSet": { "$id": "19", "Code": "Улица Экспресс", "Name": "Кредит легко_12,5%" }
      },
      {
        "$id": "20",
        "CreditLoanGuiData": {
          "$id": "21",
          "CreditLoanGuiStatus": 8,
          "Colour": 3,
          "DisplayOrder": 4,
          "ShowDetails": false,
          "ScheduleAvailable": false,
          "ShowGauge": false,
          "DebtDaysWordAgreement": 3,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "InterestRate": 14.9,
          "LoanBalance": 250000.0,
          "PercentPaidBodyCredit": 0,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2185818161",
        "ProductName": "Кредит наличными",
        "ProductType": 2,
        "Contract": {
          "$id": "22",
          "Properties": {
            "$id": "23",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 36,
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          },
          "LastPayDate": "2016-11-03T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 63000.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810640300725904",
        "DateSign": "2013-11-19T00:00:00",
        "DateClosure": "2016-11-08T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 136.1,
        "EarlyPaymentSum": 0.0,
        "ProductSet": {
          "$id": "24",
          "Code": "Наличные в кредит для сотрудников",
          "Name": "Наличные в кредит для сотрудников_14,9%"
        }
      },
      {
        "$id": "25",
        "CreditLoanGuiData": {
          "$id": "26",
          "CreditLoanGuiStatus": 8,
          "Colour": 3,
          "DisplayOrder": 5,
          "ShowDetails": false,
          "ScheduleAvailable": false,
          "ShowGauge": false,
          "DebtDaysWordAgreement": 3,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "InterestRate": 14.9,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2195260642",
        "ProductName": "Кредит наличными",
        "ProductType": 2,
        "Contract": {
          "$id": "27",
          "Properties": {
            "$id": "28",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 30,
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          },
          "LastPayDate": "0001-01-01T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 35000.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810640300725904",
        "DateSign": "2014-03-21T00:00:00",
        "DateClosure": "2016-09-07T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 136.1,
        "EarlyPaymentSum": 0.0,
        "ProductSet": {
          "$id": "29",
          "Code": "Наличные в кредит для сотрудников",
          "Name": "Наличные в кредит для сотрудников_14,9%"
        }
      },
      {
        "$id": "30",
        "CreditLoanGuiData": {
          "$id": "31",
          "CreditLoanGuiStatus": 8,
          "Colour": 3,
          "DisplayOrder": 6,
          "ShowDetails": false,
          "ScheduleAvailable": false,
          "ShowGauge": false,
          "DebtDaysWordAgreement": 3,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "InterestRate": 29.9,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2199963522",
        "ProductName": "Кредит на товар",
        "ProductType": 1,
        "Contract": {
          "$id": "32",
          "Properties": {
            "$id": "33",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 24,
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          },
          "LastPayDate": "0001-01-01T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 15660.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810640300725904",
        "DateSign": "2014-05-27T00:00:00",
        "DateClosure": "2016-05-17T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 136.1,
        "EarlyPaymentSum": 0.0,
        "ProductSet": { "$id": "34", "Code": "Стандартные", "Name": "Кредит_29,9%" }
      },
      {
        "$id": "35",
        "CreditLoanGuiData": {
          "$id": "36",
          "CreditLoanGuiStatus": 8,
          "Colour": 3,
          "DisplayOrder": 7,
          "ShowDetails": false,
          "ScheduleAvailable": false,
          "ShowGauge": false,
          "DebtDaysWordAgreement": 3,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "InterestRate": 21.68,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2181815811",
        "ProductName": "Кредит на товар",
        "ProductType": 1,
        "Contract": {
          "$id": "37",
          "Properties": {
            "$id": "38",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 24,
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          },
          "LastPayDate": "2013-12-22T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 20990.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810640300725904",
        "DateSign": "2013-09-22T00:00:00",
        "DateClosure": "2013-12-29T00:00:00",
        "SmsPackService": false,
        "AccountBalance": 136.1,
        "EarlyPaymentSum": 0.0,
        "ProductSet": { "$id": "39", "Code": "X% в месяц переплаты", "Name": "1% в месяц_бытовая" }
      },
      {
        "$id": "40",
        "CreditLoanGuiData": {
          "$id": "41",
          "CreditLoanGuiStatus": 8,
          "Colour": 3,
          "DisplayOrder": 8,
          "ShowDetails": false,
          "ScheduleAvailable": false,
          "ShowGauge": false,
          "DebtDaysWordAgreement": 3,
          "DebtDays": 0,
          "DebtAmount": 0.0,
          "InterestRate": 28.86,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "DebtInterestAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtOtherIncomeAct": 0.0
        },
        "ContractNumber": "2177070274",
        "ProductName": "Кредит на товар",
        "ProductType": 1,
        "Contract": {
          "$id": "42",
          "Properties": {
            "$id": "43",
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 12,
            "SumToPay": 0.0,
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false
          },
          "LastPayDate": "2013-12-12T00:00:00"
        },
        "ContractStatus": 4,
        "CreditAmount": 11990.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "AccountNumber": "42301810640300725904",
        "DateSign": "2013-07-14T00:00:00",
        "DateClosure": "2013-12-15T00:00:00",
        "SmsPackService": true,
        "AccountBalance": 136.1,
        "EarlyPaymentSum": 0.0,
        "ProductSet": { "$id": "44", "Code": "Без переплаты", "Name": "0-0-12_СК14_связь" }
      }
    ],
    "CreditCard": [],
    "CreditCardTW": [],
    "IsCreditLoanAndCardError": false,
    "IsCreditCardTWError": false,
    "IsAvailableEarlyRepayment": false
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
