import { MobileIcon } from '@platform-ui/icons/MobileIcon'
import { LockIcon } from '@platform-ui/icons/LockIcon'
import { MenuOffersCatalogIcon } from '@platform-ui/icons/MenuOffersCatalogIcon'
import { Popover, TAnchorEl } from '@platform-ui/components/Popover'
import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Badge, Panel, Root } from './styled'
import { MenuItem } from '../../components/MenuItem'
import { Auth } from '../Auth'
import { ApiWorkspace } from '../ApiWorkspace'
import { Device } from '../Device'
import { Features } from '../Features'
import { Capabilities } from '../Capabilities'
import { useDeveloperStore } from '../../developerStore'

export interface MainProps {
  className?: string
  children?: React.ReactNode
}

enum PopoverId {
  Auth,
  ApiUrls,
  Device,
  Features,
  Capabilities,
}

const ApiUrlsIcon = observer(() => {
  const developerStore = useDeveloperStore()
  return <Badge>{developerStore.switchStand.activeStandId || ' '}</Badge>
})

export const Main = observer<MainProps>((props) => {
  const [popoverId, setPopoverId] = useState<PopoverId | null>(null)
  const [popoverAnchor, setPopoverAnchor] = useState<TAnchorEl | null>(null)

  return (
    <Root {...props}>
      <Panel>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            setPopoverId((state) => (state !== PopoverId.Auth ? PopoverId.Auth : null))
            setPopoverAnchor(event.currentTarget)
          }}
          isActive={popoverId === PopoverId.Auth}
          icon={LockIcon}
        >
          Auth
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            setPopoverId((state) => (state !== PopoverId.ApiUrls ? PopoverId.ApiUrls : null))
            setPopoverAnchor(event.currentTarget)
          }}
          isActive={popoverId === PopoverId.ApiUrls}
          icon={ApiUrlsIcon}
        >
          API Urls
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            setPopoverId((state) => (state !== PopoverId.Device ? PopoverId.Device : null))
            setPopoverAnchor(event.currentTarget)
          }}
          isActive={popoverId === PopoverId.Device}
          icon={MobileIcon}
        >
          Device
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            setPopoverId((state) =>
              state !== PopoverId.Capabilities ? PopoverId.Capabilities : null
            )
            setPopoverAnchor(event.currentTarget)
          }}
          isActive={popoverId === PopoverId.Capabilities}
          icon={MenuOffersCatalogIcon}
        >
          Capabilities
        </MenuItem>
      </Panel>
      <Popover
        isOpen={popoverId !== null}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverId(null)}
        placement={'right'}
        paperProps={{ elevation: 2 }}
        isFocusTrapped={false}
      >
        {popoverId === PopoverId.Auth ? (
          <Auth />
        ) : popoverId === PopoverId.ApiUrls ? (
          <ApiWorkspace />
        ) : popoverId === PopoverId.Capabilities ? (
          <Capabilities />
        ) : popoverId === PopoverId.Device ? (
          <Device />
        ) : popoverId === PopoverId.Features ? (
          <Features />
        ) : (
          <div></div>
        )}
      </Popover>
    </Root>
  )
})
