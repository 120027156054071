{
  "$id": "1",
  "Result": {
    "$id": "2",
    "PermissionItem": [],
    "SolutionItem": [
      {
        "$id": "3",
        "HeadSolution": " приветствуем",
        "Description": "Выберите, какой информацией вы готовы с нами поделиться, чтобы помочь нам стать лучше",
        "Solution": "Чтобы создавать продукты, которые максимально подходят именно вам, нам нужно узнать о вас немного больше.\n\nДля этого мы хотим собирать статистические данные о вашем устройстве, которые будут надежно храниться в банке.",
        "SolutionURL": "https://www.homecredit.ru/docs/terms_data_processing_mobile.pdf",
        "UrlWord": "Узнать подробнее"
      }
    ],
    "DefaultTransmissionFrequency": "24*60"
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
