{
  "fee": 0.0,
  "feeSum": 0,
  "sum": 1000.0,
  "fullSum": 1000.0,
  "currency": "RUR",
  "links": [
    {
      "rel": "type",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/type"
    },
    {
      "rel": "commission",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/commission"
    },
    {
      "rel": "confirm",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/confirm"
    },
    {
      "rel": "product-list",
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/self/transfer/0a787836-1e51-4670-833b-3152df7f0081/product-list"
    }
  ]
}
