{
  "Result": [
    {
      "MonthNumber": 2,
      "CashbackPercentText": "6%",
      "TermsUrl": "https://polza.home.bank/LegalInformation",
      "CategoriesDescriptionUrl": "https://polza.home.bank/Content/docs/Tetms_Dop_categories_20201201.pdf",
      "MaxSelectedPositions": 3,
      "SelectedPositions": 0,
      "Categories": [
        {
          "Id": "HAK7824JFK-1111-1089-DK1E3-HF638HFN",
          "Name": "1.5% Кэшбэк за всё",
          "Description": "За все покупки",
          "MCC": "5411",
          "CashbackPercent": 1.5,
          "IconUrl": "https://sales-father-images-nginx.homecredit.ru/images/polza_category_ZKH.png",
          "IsSelected": false,
          "IsSpecial": true
        },
        {
          "Id": "B104877D-B619-41C2-A793-405C267DF6E5",
          "Name": "4.5% Кафе и рестораны",
          "Description": "Кафе и рестораны",
          "MCC": "7395, 7399, 7511, 7512, 7513, 7519",
          "CashbackPercent": 4.5,
          "IconUrl": "https://sales-father-images-nginx.homecredit.ru/images/polza_category_Kafe_i_restorani.png",
          "IsSelected": false,
          "IsSpecial": false
        },
        {
          "Id": "8D604C0D-7252-4741-8052-49E52D02648B",
          "Name": "Здоровье",
          "Description": "Ортопедические  салоны, оптика, стоматология, больницы",
          "MCC": "3007, 3065, 3280",
          "CashbackPercent": 1.5,
          "IconUrl": "https://sales-father-images-nginx.homecredit.ru/images/polza_category_Zdorovie.png",
          "IsSelected": false,
          "IsSpecial": false
        },
        {
          "Id": "CCDDC439-9040-4258-9AE9-95352AD85E03",
          "Name": "ЖКХ",
          "Description": "Для получения повышенного кэшбэка рекомендуем оплачивать в мобильном приложении «Homecredit»",
          "MCC": "5411",
          "CashbackPercent": 5.0,
          "IconUrl": "https://sales-father-images-nginx.homecredit.ru/images/polza_category_ZKH.png",
          "IsSelected": false,
          "IsSpecial": false
        },
        {
          "Id": "GHD6172N-1028-1123-8DH4-65352AR85G01",
          "Name": "Развлечения",
          "Description": "Для получения повышенного кэшбэка рекомендуем оплачивать в мобильном приложении «Homecredit»",
          "MCC": "6412",
          "CashbackPercent": 7.0,
          "IconUrl": "https://sales-father-images-nginx.homecredit.ru/images/polza_category_ZKH.png",
          "IsSelected": false,
          "IsSpecial": false
        }
      ]
    },
    {
      "MonthNumber": 1,
      "CashbackPercentText": "5%",
      "TermsUrl": "https://polza.home.bank/LegalInformation",
      "CategoriesDescriptionUrl": "https://polza.home.bank/Content/docs/Tetms_Dop_categories_20201201.pdf",
      "MaxSelectedPositions": 3,
      "SelectedPositions": 3,
      "Categories": [
        {
          "Id": "B104877D-B619-41C2-A793-405C267DF6E5",
          "Name": "Кафе и рестораны",
          "Description": "Кафе и рестораны",
          "MCC": "7395, 7399, 7511, 7512, 7513, 7519",
          "CashbackPercent": 5.0,
          "IconUrl": "https://sales-father-images-nginx.homecredit.ru/images/polza_category_Kafe_i_restorani.png",
          "IsSelected": true,
          "IsSpecial": false
        },
        {
          "Id": "8D604C0D-7252-4741-8052-49E52D02648B",
          "Name": "Здоровье",
          "Description": "Ортопедические  салоны, оптика, стоматология, больницы",
          "MCC": "3007, 3065, 3280",
          "CashbackPercent": 5.7,
          "IconUrl": "https://sales-father-images-nginx.homecredit.ru/images/polza_category_Zdorovie.png",
          "IsSelected": true,
          "IsSpecial": false
        },
        {
          "Id": "CCDDC439-9040-4258-9AE9-95352AD85E03",
          "Name": "ЖКХ",
          "Description": "Для получения повышенного кэшбэка рекомендуем оплачивать в мобильном приложении «Homecredit»",
          "MCC": "5411",
          "CashbackPercent": 5.0,
          "IconUrl": "https://sales-father-images-nginx.homecredit.ru/images/polza_category_ZKH.png",
          "IsSelected": true,
          "IsSpecial": false
        }
      ]
    }
  ]
}
