{
  "paymentInfo": {
    "orderId": "17791",
    "maskCardTo": "446915******5999",
    "maskCardFrom": "427668******0909",
    "expMonth": "07",
    "expYear": "23",
    "totalPaymentSum": 1000,
    "paymentSum": 1000,
    "commissionSum": 0,
    "paymentSystem": "VISA",
    "tranDateTime": "2023-01-23T04:22:36",
    "approvalCode": "524347",
    "status": "APPROVED",
    "contractNumber": "7205069076",
    "changeType": false
  },
  "_links": {
    "self": {
      "href": "https://balancer-gateway-ft.omni.homecredit.ru:8112/cascader/v1/payments/d7e535d0-3935-4d2d-b5ad-2b829753b1cc/execution"
    }
  }
}
