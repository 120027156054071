{
  "StatusCode": 403,
  "Errors": ["Произошла ошибка или ведутся работы по улучшению сервиса"],
  "ErrorDetails": [
    {
      "Title": "Функционал оплаты заблокирован",
      "Error": "Произошла ошибка или ведутся работы по улучшению сервиса",
      "ErrorType": 1
    }
  ]
}
