{
  "accountNumber": "42305810450010181969",
  "contractNumber": "318/013980",
  "systemCode": "Tw",
  "depositName": "6 месяцев New",
  "depositType": "Срочный",
  "contractStatus": "Active",
  "startDate": "2022-03-22T00:00:00+03:00",
  "maturityDate": "2023-03-22T00:00:00+03:00",
  "runningBalance": 50620,
  "currency": "RUR",
  "currencyCode": 643,
  "isPossibilityOfReplenishment": false,
  "isPossibilityOfPartialWithdrawal": false,
  "contractUniqueId": "MzE4LzAxMzk4MDtUVzsyMTsyNjUyOTkzMg==",
  "isExpiredData": false,
  "lastRolloverDate": "2022-09-22T00:00:00+03:00",
  "originalAmount": 1000,
  "accruedInterest": 1163.98,
  "daysTerm": 184,
  "monthsTerm": 6,
  "daysBeforeMaturity": -434,
  "interestRate": 7.7,
  "minimumBalance": 0,
  "isPossibilityOfWithdrawal": false,
  "isPossibilityOfRollover": true,
  "relatedAccounts": [
    {
      "accountNumber": "40817810390100081752",
      "accountName": "Карточный счет",
      "runningBalance": 4464.15,
      "currency": "RUR"
    }
  ],
  "productVersion": ""
}
