import { createMocks } from '@/features/developer/utils/createMocks'
import success from './success.json'
import reject441 from './reject-441.json'
import reject500 from './reject-500.json'

export const mockItems = createMocks([
  {
    title: 'Success',
    status: 200,
    data: success,
    description: '',
  },
  {
    title: 'Код 400',
    status: 400,
    data: null,
    description: '',
  },
  {
    title: 'Код 441',
    status: 441,
    data: reject441,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: reject500,
    description: '',
  },
])
