import { createMocks } from '@/features/developer/utils/createMocks'
import successFile from './success.json'
import errorFile from './error.json'

export const mockItems = createMocks([
  {
    title: 'Успешный ответ',
    status: 200,
    data: successFile,
    description: '',
  },
  {
    title: 'Ошибка NotFound',
    status: 200,
    data: errorFile,
    description: '',
  },
  {
    title: 'Код 401',
    status: 401,
    data: null,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
