{
  "fee": 30.0,
  "sum": 12.0,
  "fullSum": 42.0,
  "currency": "RUR",
  "links": [
    {
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/visa-phone-transfer/v1/20d1b6b5-1fd6-475a-9fec-34fadb025ae8/transfer"
    },
    {
      "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/visa-phone-transfer/v1/20d1b6b5-1fd6-475a-9fec-34fadb025ae8/commission"
    }
  ]
}
