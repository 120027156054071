{
  "$id": "1",
  "ErrorDetails": [],
  "Errors": [],
  "Result": {
    "$id": "2",
    "CreditCard": [],
    "CreditCardTW": [
      {
        "$id": "54",
        "AccountBalance": 0.0,
        "AccountNumber": "40817810093160043529",
        "AclipInfo": {
          "$id": "61",
          "IncreasedAvailableBalance": 26761.35,
          "IncreasedCreditLimit": 35000.0,
          "OfferedIncrease": 0.0
        },
        "AvailableBalance": 26761.35,
        "AvailableCardInfo": 0,
        "CardType": 5,
        "Contract": {
          "$id": "56",
          "Properties": {
            "$id": "57",
            "ContractBillingDate": "2022-02-14T00:00:00",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "NextPaymentDate": "2022-02-14T00:00:00",
            "SumToPay": 2620.86
          }
        },
        "ContractNumber": "2370882220",
        "ContractStatus": 1,
        "CreditCardTWGuiData": {
          "$id": "55",
          "Colour": 2,
          "CreditCardTWGuiStatus": 3,
          "CreditCardTWPolzaGuiStatus": 0,
          "DaysLeft": -186,
          "DaysLeftWordAgreement": 3,
          "DebtAmount": 0.0,
          "DebtDays": 0,
          "DebtDaysWordAgreement": 3,
          "DisplayOrder": 1,
          "DisplayedPayments": 4,
          "GaugeCurrentValue": 20,
          "GaugeMaxValue": 20,
          "PaymentSystem": 1,
          "ShowGauge": true,
          "ShowTwoBalances": false
        },
        "CreditLimit": 35000.0,
        "DateCreate": "2021-06-27T00:00:00",
        "DateSign": "2021-06-27T00:00:00",
        "ExtraServicesInfo": {
          "$id": "60",
          "IsFinProtectionAvailable": true,
          "IsFinProtectionOn": false,
          "IsPolzaOn": false,
          "IsSubscriptionAvailable": false,
          "IsSubscriptionOn": false
        },
        "FeeDebtSum": 0.0,
        "GracePayment": 0.0,
        "InstalmentDebtSum": 2620.86,
        "InterestDebtSum": 0.0,
        "IsActivationAvailable": false,
        "IsAdditional": false,
        "IsDigital": true,
        "IsInstalmentProduct": true,
        "IsNoName": false,
        "IsPinGenerated": true,
        "IsPlasticActivationAvailable": false,
        "IsPlasticInDelivery": false,
        "IsPolza": false,
        "IsPolzaLkAvailable": false,
        "MainCardMBR": 0,
        "MainCardNumber": "450726XXXXXX8188",
        "MainCardStatus": 2,
        "MinMonthDebtAmount": 2620.86,
        "OuterLimitInfo": {
          "$id": "58",
          "IsOuterLimitOn": false,
          "OuterAvailableBalance": 0.0,
          "OuterCreditBalance": 0.0,
          "OuterCreditLimit": 0.0,
          "UsedOuterCreditLimit": 0.0,
          "UsedPartnersCreditLimit": 8238.65
        },
        "PaymentDetails": {
          "$id": "59",
          "CurrentInstallments": 2620.86,
          "CurrentInstallmentsInMinPayment": 2620.86,
          "NonOverdueDebtBody": 0.0,
          "NonOverdueFees": 0.0,
          "NonOverdueInterest": 0.0,
          "NonOverduePenalties": 0.0,
          "OverdueDebtBody": 0.0,
          "OverdueFees": 0.0,
          "OverdueInterest": 0.0,
          "OverduePenalties": 0.0
        },
        "PaymentPeriod": "51 день",
        "PenaltySum": 0.0,
        "PrincipalDebtSum": 8238.65,
        "ProductId": 1713,
        "ProductName": "VK Pay",
        "ProductType": 7,
        "RecommendedPaymentSum": 2620.86,
        "ReissueUponExpirationStatus": 0,
        "StartPaymentPeriod": 25,
        "TotalIndebtedness": 8238.65
      },
      {
        "$id": "62",
        "AccountBalance": 0.0,
        "AccountNumber": "40817810993160047735",
        "AclipInfo": {
          "$id": "70",
          "IncreasedAvailableBalance": 14.01,
          "IncreasedCreditLimit": 40000.0,
          "OfferedIncrease": 0.0
        },
        "AvailableBalance": 14.01,
        "AvailableCardInfo": 0,
        "CardType": 6,
        "Contract": {
          "$id": "64",
          "Properties": {
            "$id": "65",
            "ContractBillingDate": "2022-02-24T00:00:00",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "NextPaymentDate": "2022-02-24T00:00:00",
            "SumToPay": 4472.05
          }
        },
        "ContractNumber": "2380209990",
        "ContractStatus": 1,
        "CreditCardTWGuiData": {
          "$id": "63",
          "Colour": 2,
          "CreditCardTWGuiStatus": 0,
          "CreditCardTWPolzaGuiStatus": 3,
          "DaysLeft": -176,
          "DaysLeftWordAgreement": 3,
          "DebtAmount": 0.0,
          "DebtDays": 0,
          "DebtDaysWordAgreement": 3,
          "DisplayOrder": 0,
          "DisplayedPayments": 4,
          "GaugeCurrentValue": 30,
          "GaugeMaxValue": 30,
          "PaymentSystem": 1,
          "ShowGauge": true,
          "ShowTwoBalances": false
        },
        "CreditLimit": 40000.0,
        "DateCreate": "2021-09-25T00:00:00",
        "DateSign": "2021-09-25T00:00:00",
        "ExtraServicesInfo": {
          "$id": "69",
          "IsFinProtectionAvailable": false,
          "IsFinProtectionOn": true,
          "IsPolzaOn": true,
          "IsSubscriptionAvailable": true,
          "IsSubscriptionOn": true
        },
        "FeeDebtSum": 348.0,
        "GracePayment": 4472.05,
        "InstalmentDebtSum": 4472.05,
        "InterestDebtSum": 2959.48,
        "IsActivationAvailable": false,
        "IsAdditional": false,
        "IsDigital": true,
        "IsInstalmentProduct": false,
        "IsNoName": false,
        "IsPinGenerated": true,
        "IsPlasticActivationAvailable": false,
        "IsPlasticInDelivery": false,
        "IsPolza": true,
        "IsPolzaLkAvailable": true,
        "MainCardMBR": 1,
        "MainCardNumber": "406296XXXXXX2312",
        "MainCardStatus": 2,
        "MinMonthDebtAmount": 4472.05,
        "OuterLimitInfo": {
          "$id": "66",
          "IsOuterLimitOn": false,
          "OuterAvailableBalance": 0.0,
          "OuterCreditBalance": 0.0,
          "OuterCreditLimit": 0.0,
          "UsedOuterCreditLimit": 0.0,
          "UsedPartnersCreditLimit": 39985.99
        },
        "PaymentDetails": {
          "$id": "67",
          "Commissions": [
            {
              "$id": "68",
              "Amount": 199.0,
              "DateLastActive": "2021-09-25T00:00:00+03:00",
              "Name": "Финансовая Польза",
              "Remain": 199.0
            }
          ],
          "CurrentInstallments": 0.0,
          "CurrentInstallmentsInMinPayment": 0.0,
          "NonOverdueDebtBody": 1164.57,
          "NonOverdueFees": 348.0,
          "NonOverdueInterest": 2959.48,
          "NonOverduePenalties": 0.0,
          "OverdueDebtBody": 0.0,
          "OverdueFees": 0.0,
          "OverdueInterest": 0.0,
          "OverduePenalties": 0.0
        },
        "PaymentPeriod": "4 месяца",
        "PenaltySum": 0.0,
        "PrincipalDebtSum": 39985.99,
        "ProductId": 2054,
        "ProductName": "120 дней без %",
        "ProductType": 7,
        "RecommendedPaymentSum": 0.0,
        "ReissueUponExpirationStatus": 0,
        "StartPaymentPeriod": 25,
        "TotalIndebtedness": 38819.7
      }
    ],
    "CreditLoan": [
      {
        "$id": "3",
        "AccountArrest": {
          "$id": "8",
          "ActualBalance": 0.0,
          "ArrestLeft": 0.0,
          "ArrestSum": 0.0,
          "IsArrest": false,
          "PercentPaidArrest": 0
        },
        "AccountBalance": 0.0,
        "AccountNumber": "42301810640160599338",
        "ChangedInstalment": 3,
        "Contract": {
          "$id": "5",
          "LastPayDate": "2023-12-02T00:00:00",
          "Properties": {
            "$id": "6",
            "EarlyPaymentCalculationDate": "2022-08-24T00:00:00",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "LastPaymentNum": 8,
            "NextPaymentDate": "2022-08-24T00:00:00",
            "NextPaymentDateDisplayed": "2022-08-24T00:00:00",
            "PaymentNum": 24,
            "SumToPay": 4171.72
          }
        },
        "ContractNumber": "2385266161",
        "ContractStatus": 1,
        "CreditAmount": 16246.0,
        "CreditLoanGuiData": {
          "$id": "4",
          "Colour": 1,
          "CreditLoanGuiStatus": 5,
          "DebtAmount": 3575.76,
          "DebtBankLostAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtDays": 181,
          "DebtDaysWordAgreement": 1,
          "DebtInterestAct": 0.0,
          "DebtOtherIncomeAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DisplayOrder": 2,
          "GaugeCurrentValue": 30,
          "GaugeMaxValue": 30,
          "InterestRate": 40.52,
          "LoanBalance": 11911.24,
          "PercentPaid": 28,
          "PercentPaidBodyCredit": 73,
          "RemainingDebt": 3575.76,
          "ScheduleAvailable": true,
          "ShowDetails": true,
          "ShowGauge": true
        },
        "DateSign": "2021-11-17T00:00:00",
        "EarlyPaymentSum": 0.0,
        "Payment": 595.96,
        "PenaltySum": 0.0,
        "PrimaryInterestRate": 40.52,
        "PrivilegedInterestRate": 40.52,
        "ProductAnnuityType": "g",
        "ProductName": "Кредит на товар",
        "ProductSet": {
          "$id": "7",
          "Code": "Стандартные",
          "Name": "Выгодный 1_Онлайн"
        },
        "ProductType": 1,
        "SmsPackService": false
      },
      {
        "$id": "9",
        "AccountBalance": 0.0,
        "AccountNumber": "42301810640160599338",
        "ChangedInstalment": 3,
        "Contract": {
          "$id": "11",
          "LastPayDate": "2021-12-13T00:00:00",
          "Properties": {
            "$id": "12",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 18,
            "SumToPay": 0.0
          }
        },
        "ContractNumber": "2353664853",
        "ContractStatus": 4,
        "CreditAmount": 9423.0,
        "CreditLoanGuiData": {
          "$id": "10",
          "Colour": 3,
          "CreditLoanGuiStatus": 8,
          "DebtAmount": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtDays": 0,
          "DebtDaysWordAgreement": 3,
          "DebtInterestAct": 0.0,
          "DebtOtherIncomeAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DisplayOrder": 3,
          "InterestRate": 7.0,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "ScheduleAvailable": false,
          "ShowDetails": false,
          "ShowGauge": false
        },
        "DateClosure": "2021-12-14T00:00:00",
        "DateSign": "2021-01-03T00:00:00",
        "EarlyPaymentSum": 0.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "PrimaryInterestRate": 27.9,
        "PrivilegedInterestRate": 27.9,
        "ProductAnnuityType": "g",
        "ProductName": "Кредит на товар",
        "ProductSet": {
          "$id": "13",
          "Code": "Без переплаты",
          "Name": "0-0-18_СК10%_Онлайн"
        },
        "ProductType": 1,
        "SmsPackService": false
      },
      {
        "$id": "14",
        "AccountBalance": 0.0,
        "AccountNumber": "42301810640160599338",
        "ChangedInstalment": 3,
        "Contract": {
          "$id": "16",
          "LastPayDate": "2021-10-18T00:00:00",
          "Properties": {
            "$id": "17",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 12,
            "SumToPay": 0.0
          }
        },
        "ContractNumber": "2348564430",
        "ContractStatus": 4,
        "CreditAmount": 8180.0,
        "CreditLoanGuiData": {
          "$id": "15",
          "Colour": 3,
          "CreditLoanGuiStatus": 8,
          "DebtAmount": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtDays": 0,
          "DebtDaysWordAgreement": 3,
          "DebtInterestAct": 0.0,
          "DebtOtherIncomeAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DisplayOrder": 4,
          "InterestRate": 7.0,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "ScheduleAvailable": false,
          "ShowDetails": false,
          "ShowGauge": false
        },
        "DateClosure": "2021-10-19T00:00:00",
        "DateSign": "2020-11-09T00:00:00",
        "EarlyPaymentSum": 0.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "PrimaryInterestRate": 36.34,
        "PrivilegedInterestRate": 36.34,
        "ProductAnnuityType": "g",
        "ProductName": "Кредит на товар",
        "ProductSet": {
          "$id": "18",
          "Code": "Без переплаты",
          "Name": "0-0-12_СК10_УР"
        },
        "ProductType": 1,
        "SmsPackService": false
      },
      {
        "$id": "19",
        "AccountBalance": 0.0,
        "AccountNumber": "42301810640160599338",
        "ChangedInstalment": 3,
        "Contract": {
          "$id": "21",
          "LastPayDate": "2021-10-13T00:00:00",
          "Properties": {
            "$id": "22",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 18,
            "SumToPay": 0.0
          }
        },
        "ContractNumber": "2339417141",
        "ContractStatus": 4,
        "CreditAmount": 6012.0,
        "CreditLoanGuiData": {
          "$id": "20",
          "Colour": 3,
          "CreditLoanGuiStatus": 8,
          "DebtAmount": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtDays": 0,
          "DebtDaysWordAgreement": 3,
          "DebtInterestAct": 0.0,
          "DebtOtherIncomeAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DisplayOrder": 5,
          "InterestRate": 7.0,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "ScheduleAvailable": false,
          "ShowDetails": false,
          "ShowGauge": false
        },
        "DateClosure": "2021-10-14T00:00:00",
        "DateSign": "2020-06-22T00:00:00",
        "EarlyPaymentSum": 0.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "PrimaryInterestRate": 27.9,
        "PrivilegedInterestRate": 27.9,
        "ProductAnnuityType": "g",
        "ProductName": "Кредит на товар",
        "ProductSet": {
          "$id": "23",
          "Code": "Без переплаты",
          "Name": "0-0-18_СК10%_Онлайн"
        },
        "ProductType": 1,
        "SmsPackService": false
      },
      {
        "$id": "24",
        "AccountBalance": 0.0,
        "AccountNumber": "42301810640160599338",
        "ChangedInstalment": 3,
        "Contract": {
          "$id": "26",
          "LastPayDate": "2021-09-21T00:00:00",
          "Properties": {
            "$id": "27",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 18,
            "SumToPay": 0.0
          }
        },
        "ContractNumber": "2333261253",
        "ContractStatus": 4,
        "CreditAmount": 11691.0,
        "CreditLoanGuiData": {
          "$id": "25",
          "Colour": 3,
          "CreditLoanGuiStatus": 8,
          "DebtAmount": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtDays": 0,
          "DebtDaysWordAgreement": 3,
          "DebtInterestAct": 0.0,
          "DebtOtherIncomeAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DisplayOrder": 6,
          "InterestRate": 7.0,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "ScheduleAvailable": false,
          "ShowDetails": false,
          "ShowGauge": false
        },
        "DateClosure": "2021-09-22T00:00:00",
        "DateSign": "2020-03-09T00:00:00",
        "EarlyPaymentSum": 0.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "PrimaryInterestRate": 27.9,
        "PrivilegedInterestRate": 27.9,
        "ProductAnnuityType": "g",
        "ProductName": "Кредит на товар",
        "ProductSet": {
          "$id": "28",
          "Code": "Без переплаты",
          "Name": "0-0-18_СК10%_Онлайн"
        },
        "ProductType": 1,
        "SmsPackService": false
      },
      {
        "$id": "29",
        "AccountBalance": 0.0,
        "AccountNumber": "42301810640160599338",
        "ChangedInstalment": 3,
        "Contract": {
          "$id": "31",
          "LastPayDate": "2021-06-30T00:00:00",
          "Properties": {
            "$id": "32",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 18,
            "SumToPay": 0.0
          }
        },
        "ContractNumber": "2339418081",
        "ContractStatus": 4,
        "CreditAmount": 3753.0,
        "CreditLoanGuiData": {
          "$id": "30",
          "Colour": 3,
          "CreditLoanGuiStatus": 8,
          "DebtAmount": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtDays": 0,
          "DebtDaysWordAgreement": 3,
          "DebtInterestAct": 0.0,
          "DebtOtherIncomeAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DisplayOrder": 7,
          "InterestRate": 7.0,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "ScheduleAvailable": false,
          "ShowDetails": false,
          "ShowGauge": false
        },
        "DateClosure": "2021-07-01T00:00:00",
        "DateSign": "2020-06-22T00:00:00",
        "EarlyPaymentSum": 0.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "PrimaryInterestRate": 27.9,
        "PrivilegedInterestRate": 27.9,
        "ProductAnnuityType": "g",
        "ProductName": "Кредит на товар",
        "ProductSet": {
          "$id": "33",
          "Code": "Без переплаты",
          "Name": "0-0-18_СК10%_Онлайн"
        },
        "ProductType": 1,
        "SmsPackService": false
      },
      {
        "$id": "34",
        "AccountBalance": 0.0,
        "AccountNumber": "42301810640160599338",
        "Contract": {
          "$id": "36",
          "LastPayDate": "2020-11-14T00:00:00",
          "Properties": {
            "$id": "37",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 24,
            "SumToPay": 0.0
          }
        },
        "ContractNumber": "2283112238",
        "ContractStatus": 4,
        "CreditAmount": 82132.0,
        "CreditLoanGuiData": {
          "$id": "35",
          "Colour": 3,
          "CreditLoanGuiStatus": 8,
          "DebtAmount": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtDays": 0,
          "DebtDaysWordAgreement": 3,
          "DebtInterestAct": 0.0,
          "DebtOtherIncomeAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DisplayOrder": 8,
          "InterestRate": 10.13,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "ScheduleAvailable": false,
          "ShowDetails": false,
          "ShowGauge": false
        },
        "DateClosure": "2020-11-16T00:00:00",
        "DateSign": "2018-10-30T00:00:00",
        "EarlyPaymentSum": 0.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "ProductName": "Кредит на товар",
        "ProductSet": {
          "$id": "38",
          "Code": "Без переплаты",
          "Name": "0-0-24_СК10_NEW"
        },
        "ProductType": 1,
        "SmsPackService": false
      },
      {
        "$id": "39",
        "AccountBalance": 0.0,
        "AccountNumber": "42301810640160599338",
        "Contract": {
          "$id": "41",
          "LastPayDate": "2020-11-05T00:00:00",
          "Properties": {
            "$id": "42",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 24,
            "SumToPay": 0.0
          }
        },
        "ContractNumber": "2282454683",
        "ContractStatus": 4,
        "CreditAmount": 24974.0,
        "CreditLoanGuiData": {
          "$id": "40",
          "Colour": 3,
          "CreditLoanGuiStatus": 8,
          "DebtAmount": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtDays": 0,
          "DebtDaysWordAgreement": 3,
          "DebtInterestAct": 0.0,
          "DebtOtherIncomeAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DisplayOrder": 9,
          "InterestRate": 7.45,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "ScheduleAvailable": false,
          "ShowDetails": false,
          "ShowGauge": false
        },
        "DateClosure": "2020-11-06T00:00:00",
        "DateSign": "2018-10-21T00:00:00",
        "EarlyPaymentSum": 0.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "ProductName": "Кредит на товар",
        "ProductSet": {
          "$id": "43",
          "Code": "Без переплаты",
          "Name": "0-0-24_новый"
        },
        "ProductType": 1,
        "SmsPackService": false
      },
      {
        "$id": "44",
        "AccountBalance": 0.0,
        "AccountNumber": "42301810640160599338",
        "Contract": {
          "$id": "46",
          "LastPayDate": "2020-06-29T00:00:00",
          "Properties": {
            "$id": "47",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 24,
            "SumToPay": 0.0
          }
        },
        "ContractNumber": "2275878816",
        "ContractStatus": 4,
        "CreditAmount": 34864.0,
        "CreditLoanGuiData": {
          "$id": "45",
          "Colour": 3,
          "CreditLoanGuiStatus": 8,
          "DebtAmount": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtDays": 0,
          "DebtDaysWordAgreement": 3,
          "DebtInterestAct": 0.0,
          "DebtOtherIncomeAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DisplayOrder": 10,
          "InterestRate": 7.45,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "ScheduleAvailable": false,
          "ShowDetails": false,
          "ShowGauge": false
        },
        "DateClosure": "2020-06-30T00:00:00",
        "DateSign": "2018-07-17T00:00:00",
        "EarlyPaymentSum": 0.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "ProductName": "Кредит на товар",
        "ProductSet": {
          "$id": "48",
          "Code": "Без переплаты",
          "Name": "0-0-24_новый"
        },
        "ProductType": 1,
        "SmsPackService": false
      },
      {
        "$id": "49",
        "AccountBalance": 0.0,
        "AccountNumber": "42301810640160599338",
        "Contract": {
          "$id": "51",
          "LastPayDate": "2019-10-07T00:00:00",
          "Properties": {
            "$id": "52",
            "IsDayAfterPayment": false,
            "IsUncertainPaymentSum": false,
            "NextPaymentDate": "0001-01-01T00:00:00",
            "PaymentNum": 12,
            "SumToPay": 0.0
          }
        },
        "ContractNumber": "2292817723",
        "ContractStatus": 4,
        "CreditAmount": 4999.0,
        "CreditLoanGuiData": {
          "$id": "50",
          "Colour": 3,
          "CreditLoanGuiStatus": 8,
          "DebtAmount": 0.0,
          "DebtBankLostAct": 0.0,
          "DebtCommissionAct": 0.0,
          "DebtDays": 0,
          "DebtDaysWordAgreement": 3,
          "DebtInterestAct": 0.0,
          "DebtOtherIncomeAct": 0.0,
          "DebtStateDutyAct": 0.0,
          "DisplayOrder": 11,
          "InterestRate": 17.0,
          "LoanBalance": 0.0,
          "PercentPaidBodyCredit": 0,
          "ScheduleAvailable": false,
          "ShowDetails": false,
          "ShowGauge": false
        },
        "DateClosure": "2019-10-08T00:00:00",
        "DateSign": "2019-03-03T00:00:00",
        "EarlyPaymentSum": 0.0,
        "Payment": 0.0,
        "PenaltySum": 0.0,
        "ProductName": "Кредит на товар",
        "ProductSet": {
          "$id": "53",
          "Code": "Стандартные",
          "Name": "Выгодный 1"
        },
        "ProductType": 1,
        "SmsPackService": false
      }
    ],
    "IsAvailableEarlyRepayment": true,
    "IsCreditCardTWError": false,
    "IsCreditLoanAndCardError": false,
    "ServerDate": "2022-08-19T00:00:00+03:00"
  },
  "StatusCode": 200
}
