{
  "$id": "1",
  "Result": [
    {
      "$id": "1",
      "ContractNumber": "2419275695",
      "ContractDate": "0001-01-01T00:00:00",
      "RequestExpiry": "2024-01-05T00:00:00",
      "ContractDateTicks": 0,
      "RequestExpiryTicks": 638400096000000000,
      "ProductName": "Кредит наличными",
      "WidgetHeader": "Кредит одобрен",
      "Status": 1,
      "CashDetail": {
        "$id": "3",
        "ContractSum": 50000,
        "DateExpiry": "2024-01-05T00:00:00",
        "DateExpiryTicks": 0,
        "MonthPayment": 3406.51,
        "PaymentNum": 18,
        "Rate": 26.9,
        "ApprovalServices": [],
        "ContractSumWithoutServices": 50000
      },
      "ProductType": "SC",
      "DebitStatus": 0,
      "IsPlatinum": false,
      "ImageURL": "https://sales-father-images-nginx-rt.omni.homecredit.ru/images/sc.png"
    },
    {
      "$id": "2",
      "ContractNumber": "2419265384",
      "ContractDate": "0001-01-01T00:00:00",
      "RequestExpiry": "2023-12-28T00:00:00",
      "ContractDateTicks": 0,
      "RequestExpiryTicks": 638393184000000000,
      "ProductName": "Хоум Карта",
      "WidgetHeader": "Хоум Карта",
      "Status": 1,
      "CardDetail": {
        "$id": "3",
        "CardLimit": 70000,
        "Rate": 6.9,
        "MonthPayment": 4782.78,
        "PaymentNum": 18,
        "IsPolza": false,
        "IsCreditCardTW": false,
        "PaymentSystem": "",
        "ApprovalServices": [],
        "GracePeriod": {
          "Prefix": "до",
          "Value": 62,
          "Postfix": "дней"
        },
        "PartnersCashback": {
          "Prefix": "до",
          "Value": 30,
          "Postfix": "%"
        },
        "SelectedCashback": {
          "Prefix": "до",
          "Value": 15,
          "Postfix": "%"
        }
      },
      "ProductType": "RD",
      "ProductSubtype": "HomeCard",
      "DebitStatus": 0,
      "IsPlatinum": false,
      "NameRDTW": "HomeCard",
      "ImageURL": "https://sales-father-images-nginx-rt.omni.homecredit.ru/images/homecard.png"
    },
    {
      "$id": "3",
      "ContractNumber": "7321024866",
      "ContractDate": "0001-01-01T00:00:00",
      "RequestExpiry": "2023-12-27T00:00:00",
      "ContractDateTicks": 0,
      "RequestExpiryTicks": 638392320000000000,
      "ProductName": "Польза",
      "WidgetHeader": "Неподписанная заявка",
      "Status": 1,
      "DebitCardDetail": {
        "$id": "3",
        "DebitCardCode": 1124,
        "DebitCardName": "Польза",
        "DebitCardType": "",
        "DebitCardCurrency": "RUR",
        "CreateSystemType": "3",
        "ProductIdent": "KARTAPOLZA_RUR",
        "ParticipantRole": 2
      },
      "ProductType": "DC",
      "DebitStatus": 0,
      "IsPlatinum": false
    },
    {
      "$id": "4",
      "ContractNumber": "2419275696",
      "ContractDate": "0001-01-01T00:00:00",
      "RequestExpiry": "2024-01-05T00:00:00",
      "ContractDateTicks": 0,
      "RequestExpiryTicks": 638400096000000000,
      "ProductName": "120 дней без %",
      "WidgetHeader": "Одобрена",
      "Status": 1,
      "CardDetail": {
        "$id": "3",
        "CardLimit": 50000,
        "Rate": 34.9,
        "MonthPayment": 0,
        "PaymentNum": 0,
        "IsPolza": false,
        "IsCreditCardTW": false,
        "PaymentSystem": "",
        "ApprovalServices": [],
        "GracePeriod": {
          "Prefix": "до",
          "Value": 120,
          "Postfix": "дней"
        }
      },
      "ProductType": "GR",
      "DebitStatus": 0,
      "IsPlatinum": false,
      "NameRDTW": "LongGrace",
      "ImageURL": "https://sales-father-images-nginx-rt.omni.homecredit.ru/images/HC_LongGrace_CG8.png"
    }
  ],
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
