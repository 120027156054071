import { createMocks } from '@/features/developer/utils/createMocks'
import success from './success.json'
import mismatch from './mismatch.json'
import blocked from './blocked.json'

export const mockItems = createMocks([
  {
    title: 'Успешный ответ Match + Success',
    status: 200,
    data: success,
    description: '',
  },
  {
    title: 'СМС введен не верно, код: mismatch',
    status: 200,
    data: mismatch,
    description: '',
  },
  {
    title: 'Код: blocked',
    status: 403,
    data: blocked,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
