{
  "Result": {
    "CurrentBalance": 9600,
    "PointsToBe": 2960,
    "PointsToBurn": 230,
    "IsExchangePossible": true,
    "PolzaInfo": {
      "MinSumExchange": 100,
      "Expiration": 12
    },
    "MonthBalance": [
      {
        "MaskCardNumber": "123456******5678",
        "Balance": 400
      },
      {
        "MaskCardNumber": "654321******9876",
        "Balance": 250
      }
    ],
    "LevelInfo": {
      "CurrentLevel": 4
    },
    "PolzaPartnersCatalogImageUrl": "https://local.myc-test.homebank.ru"
  }
}
