import { createMocks } from '@/features/developer/utils/createMocks'
import successCredit from './success-credit.json'
import successDebit from './success-debit.json'
import successForeign from './success-foreign.json'

export const mockItems = createMocks([
  {
    title: 'Успешный ответ, не валидна кредитная карта',
    status: 200,
    data: successCredit,
    description: '',
  },
  {
    title: 'Успешный ответ, не валидна дебетовая карта',
    status: 200,
    data: successDebit,
    description: '',
  },
  {
    title: 'Успешный ответ, оплата доступна',
    status: 200,
    data: successForeign,
    description: '',
  },
  {
    title: 'Код 401',
    status: 401,
    data: null,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
