{
  "offersContents": [
    {
      "id": 41,
      "templateId": 9010,
      "image": "https://sales-father-images-nginx.homebank.ru/images/nakop04.09.23.1.png",
      "firstBackgroundColor": "#FF6945FA",
      "secondBackgroundColor": "#FFA06CF3",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Получайте доход",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_deposit_icon.png",
              "priority": 1,
              "text": "До 15% на остаток"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Refinance_icon_v1.png",
              "priority": 2,
              "text": "Пополнение и снятие без ограничений"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_insurance_icon.png",
              "priority": 3,
              "text": "Средства застрахованы Государством"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Стандартные условия по счёту",
          "text": "Процент начисляется на ежедневный остаток средств и выплачивается в последний день каждого месяца",
          "content": [
            {
              "text": "15% годовых при совершении покупок по дебетовым и кредитным картам на сумму не менее 60 000 ₽",
              "priority": 2
            },
            {
              "text": "10% годовых при совершении покупок по дебетовым и кредитным картам на сумму не менее 20 000 ₽",
              "priority": 3
            },
            {
              "text": "5% годовых – при отсутствии покупок или если их сумма менее 20 000 ₽",
              "priority": 4
            },
            {
              "text": "5% годовых на остаток свыше 1 500 000 ₽",
              "priority": 5
            }
          ],
          "priority": 6,
          "type": "unnumbered_list"
        },
        {
          "title": "Накопительный счет",
          "subtitle": "Пополнение и снятие без ограничений",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Открыть",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 42,
      "templateId": 4025,
      "image": "https://sales-father-images-nginx.homebank.ru/images/topup04.09.23.1.png",
      "firstBackgroundColor": "#FF9E36C2",
      "secondBackgroundColor": "#FFEC80FE",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "До {limit} ₽ "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17,9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Объедините все кредиты в разных банках в один для удобной оплаты"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 5,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Кредитов в других банках",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 43,
      "templateId": 1008,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17.9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит под 17.9%",
          "subtitle": "Это полная и окончательная ставка",
          "textColor": "#FFFFFF",
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 61,
      "templateId": 1000,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 3,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 62,
      "templateId": 1006,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFBC140A",
      "secondBackgroundColor": "#FFFD665C",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 4,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 81,
      "templateId": 1061,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 295 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 36 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 101,
      "templateId": 9020,
      "image": "https://sales-father-images-nginx.homebank.ru/images/nakop04.09.23.1.png",
      "firstBackgroundColor": "#FF6945FA",
      "secondBackgroundColor": "#FFA06CF3",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Получайте доход",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_deposit_icon.png",
              "priority": 1,
              "text": "До 15% на остаток"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Refinance_icon_v1.png",
              "priority": 2,
              "text": "Пополнение и снятие без ограничений"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_insurance_icon.png",
              "priority": 3,
              "text": "Средства застрахованы Государством"
            }
          ],
          "priority": 4,
          "type": "icon_list"
        },
        {
          "title": "Стандартные условия по счёту",
          "text": "Процент начисляется на ежедневный остаток средств и выплачивается в последний день каждого месяца",
          "content": [
            {
              "text": "15% годовых при совершении покупок по дебетовым и кредитным картам на сумму не менее 60 000 ₽",
              "priority": 2
            },
            {
              "text": "10% годовых при совершении покупок по дебетовым и кредитным картам на сумму не менее 20 000 ₽",
              "priority": 3
            },
            {
              "text": "5% годовых – при отсутствии покупок или если их сумма менее 20 000 ₽",
              "priority": 4
            },
            {
              "text": "5% годовых на остаток свыше 1 500 000 ₽",
              "priority": 5
            }
          ],
          "priority": 12,
          "type": "unnumbered_list"
        },
        {
          "title": "Накопительный счет",
          "subtitle": "Пополнение и снятие без ограничений",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Открыть",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 102,
      "templateId": 1007,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 3,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 103,
      "templateId": 4022,
      "image": "https://sales-father-images-nginx.homebank.ru/images/topup04.09.23.1.png",
      "firstBackgroundColor": "#FF9E36C2",
      "secondBackgroundColor": "#FFEC80FE",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "До {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Объедините все кредиты в разных банках в один для удобной оплаты"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 5,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 6,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 7,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Платите легче",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 104,
      "templateId": 1011,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 420 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 121,
      "templateId": 1096,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 3,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 122,
      "templateId": 1097,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 3,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 123,
      "templateId": 1197,
      "image": "https://sales-father-images-nginx.homebank.ru/images/clcarbackspring2.png",
      "firstBackgroundColor": "#FF244db7",
      "secondBackgroundColor": "#FFbc7aff",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Увеличенная сумма до 5 000 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении Программы «Гарантия низкой ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "15 минут для оформления кредита"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Получите деньги прямо сейчас",
          "text": "Заполните онлайн-заявку и узнайте ваши персональные условия — это займёт пару минут.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит под залог автомобиля",
          "subtitle": null,
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 124,
      "templateId": 1059,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 420 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 125,
      "templateId": 1072,
      "image": "https://sales-father-images-nginx.homebank.ru/images/tpcl22.png",
      "firstBackgroundColor": "#FFBC140A",
      "secondBackgroundColor": "#FFFD665C",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 420 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 126,
      "templateId": 1073,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 295 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 36 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 127,
      "templateId": 1063,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 430 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17,9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит под 17,9%",
          "subtitle": "Это полная и окончательная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 128,
      "templateId": 1064,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 430 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17,9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит под 17,9%",
          "subtitle": "Это полная и окончательная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 143,
      "templateId": 1110,
      "image": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf2.png",
      "firstBackgroundColor": "#FF8441ff",
      "secondBackgroundColor": "#FF0bdd78",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Оформите дебетовую карту Польза и получите:",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 2,
              "text": "До 15% кэшбэка за покупки в выбранных категориях"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "До 30% кэшбэка за покупки у партнёров"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/DigitalCard.png",
              "priority": 4,
              "text": "Бесплатные переводы в рамках лимитов"
            }
          ],
          "priority": 7,
          "type": "icon_list"
        },
        {
          "title": "Оформить карту за пару кликов",
          "text": "Виртуальная карта доступна сразу",
          "priority": 10,
          "type": "html_text"
        },
        {
          "title": "Дебетовая Польза",
          "subtitle": "Получите бесплатную карту ",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 144,
      "templateId": 1046,
      "image": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf2.png",
      "firstBackgroundColor": "#FF024360",
      "secondBackgroundColor": "#FF71D4FF",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "«Карта выпускается на платежной системе МИР»",
          "text": null,
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кэшбэк и бонусы",
          "text": "Кэшбэк возвращается баллами, 1 балл = 1 ₽.",
          "content": [
            {
              "text": "До 15% в выбранных категориях",
              "priority": 1
            },
            {
              "text": "До 30% кэшбэк у партнёров",
              "priority": 2
            },
            {
              "text": "+ кэшбэк по программе лояльности МИР",
              "priority": 3
            }
          ],
          "priority": 3,
          "type": "unnumbered_list"
        },
        {
          "title": "Предложения партнёров",
          "content": [
            {
              "title": "VPROK",
              "subtitle": "Кэшбэк 15%",
              "link": "https://www.vprok.ru/",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/vproklogo.png",
              "priority": 1
            },
            {
              "title": "SOKOLOV",
              "subtitle": "Кэшбэк 15%",
              "link": "https://sokolov.ru",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Sokolov.png",
              "priority": 2
            },
            {
              "title": "ТЕХНОПАРК",
              "subtitle": "Кэшбэк 5,3%",
              "link": "https://www.technopark.ru",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/technopark.png",
              "priority": 3
            },
            {
              "title": "HUAWEI",
              "subtitle": "Кэшбэк 5%",
              "link": "https://consumer.huawei.com/ru/",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/huawei.png",
              "priority": 4
            }
          ],
          "priority": 4,
          "type": "partners"
        },
        {
          "title": "Оформите новую зарплатную карту и получайте больше выгоды с первого дня!",
          "text": "Сотрудники Москвы могут получить карту в центральном офисе или по почте, сотрудники в других городах могут получить карту в Банковском офисе, по почте или курьером.",
          "content": [
            {
              "text": "Ознакомьтесь с условиями",
              "priority": 1
            },
            {
              "text": "Выберите способ доставки пластиковой карты",
              "priority": 2
            },
            {
              "text": "Подпишите договор с помощью SMS-кода",
              "priority": 3
            },
            {
              "text": "Получите пластиковую карту",
              "priority": 4
            },
            {
              "text": "Следующая выплата зарплаты поступит на данную карту",
              "priority": 5
            }
          ],
          "priority": 5,
          "type": "unnumbered_list"
        },
        {
          "title": "Бесплатные переводы, снятие наличных и пополнение",
          "text": null,
          "content": [
            {
              "text": "Бесплатные переводы по номеру карты на карты других банков, по номеру телефона и снятия наличных в сторонних банкоматах в рамках лимита в соответствии с тарифами банка",
              "priority": 1
            },
            {
              "text": "Комиссия за снятие наличных в банкоматах Банка Хоум Кредит не взимается",
              "priority": 2
            },
            {
              "text": "Бесплатное пополнение в мобильном приложении, интернет-банке, банкоматах и отделениях банка, в салонах связи и терминалах МТС, а также в терминалах ВТБ",
              "priority": 3
            }
          ],
          "priority": 6,
          "type": "unnumbered_list"
        },
        {
          "title": "Зарплатная карта",
          "subtitle": "Мгновенный выпуск цифровой карты",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Кэшбэк до 15% в 4 бесплатных категориях на выбор",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-CashMax.png",
              "text": "Кэшбэк до 30% у партнёров",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Feature.png",
              "text": "Бесплатный выпуск и обслуживание ",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Arrows.png",
              "text": "Бесплатные переводы через СБП",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Заказать карту",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 145,
      "templateId": 1023,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccspv22.png",
      "firstBackgroundColor": "#FF5A007A",
      "secondBackgroundColor": "#FFF26CFE",
      "displayScreens": [
        {
          "id": 11
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное снятие наличных ",
          "text": "<p>Снимайте наличные без комиссии в течение месяца с даты оформления карты.</p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "<p>Ваша карта будет с вами без каких-либо комиссий и плат за открытие и обслуживание.</p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 4 месяцев",
          "text": "<p>После первой покупки начнётся беспроцентный период до 4 месяцев. В это время можно продолжить покупки, параллельно погашая долг минимальными и другими платежами. Беспроцентный период возобновляется после полного погашения долга и новой покупки. Если задолженность не будет погашена в течение 4 месяцев, на неё будут начислены проценты согласно договору. </p>",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Подробнее о карте",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://www.homecredit.ru/download.php?id=37906",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://www.homecredit.ru/download.php?id=34747",
              "linkType": "pdf"
            }
          ],
          "priority": 6,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} ₽",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Money.png",
              "text": "Акция: бесплатное снятие наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Bag.png",
              "text": "Рассрочка до 24 месяцев на покупки ",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Заказать карту",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 161,
      "templateId": 1055,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 15,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 16,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 17,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 19,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 2,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 3,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 20,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до 100 000 ₽",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Present.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 4
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 5
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 162,
      "templateId": 1166,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccspv22.png",
      "firstBackgroundColor": "#FF5A007A",
      "secondBackgroundColor": "#FFF26CFE",
      "displayScreens": [
        {
          "id": 11
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное снятие наличных ",
          "text": "<p>Снимайте наличные без комиссии в течение месяца с даты оформления карты.</p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "<p>Ваша карта будет с вами без каких-либо комиссий и плат за открытие и обслуживание.</p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 4 месяцев",
          "text": "<p>После первой покупки начнётся беспроцентный период до 4 месяцев. В это время можно продолжить покупки, параллельно погашая долг минимальными и другими платежами. Беспроцентный период возобновляется после полного погашения долга и новой покупки. Если задолженность не будет погашена в течение 4 месяцев, на неё будут начислены проценты согласно договору. </p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Подробнее о карте",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://www.homecredit.ru/download.php?id=37906",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://www.homecredit.ru/download.php?id=34747",
              "linkType": "pdf"
            }
          ],
          "priority": 5,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Кредитная карта одобрена",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит {limit} ₽",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Money.png",
              "text": "Акция: бесплатное снятие наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Bag.png",
              "text": "Рассрочка до 24 месяцев на покупки ",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Заказать карту",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 163,
      "templateId": 1178,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 14,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 15,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 16,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 17,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 18,
          "type": "link_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 164,
      "templateId": 1179,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 14,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 15,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 16,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 17,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 18,
          "type": "link_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 181,
      "templateId": 1100,
      "image": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf2.png",
      "firstBackgroundColor": "#FF8441ff",
      "secondBackgroundColor": "#FF0bdd78",
      "displayScreens": [
        {
          "id": 17
        }
      ],
      "blocks": [
        {
          "title": "Преимущества карты",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Кэшбэк до 15% в 4 категориях на выбор, включая 1% за все покупки"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Bag.png",
              "priority": 3,
              "text": "Кэшбэк до 30% за покупки у партнёров"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/ByCash.png",
              "priority": 4,
              "text": "Дополнительный кэшбэк и скидки по программе лояльности платёжной системы «Мир»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/DigitalCard.png",
              "priority": 5,
              "text": "Бесплатные переводы в другие банки по номеру телефона через СБП в рамках лимитов согласно тарифам банка"
            }
          ],
          "priority": 6,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 7,
          "type": "html_text"
        },
        {
          "title": "Дебетовая карта Польза",
          "subtitle": "Получите бесплатную карту ",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 201,
      "templateId": 1198,
      "image": "https://sales-father-images-nginx.homebank.ru/images/clcarbackspring2.png",
      "firstBackgroundColor": "#FF244db7",
      "secondBackgroundColor": "#FFbc7aff",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Увеличенная сумма до 5 000 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении Программы «Гарантия низкой ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "15 минут для оформления кредита"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Получите деньги прямо сейчас",
          "text": "Заполните онлайн-заявку и узнайте ваши персональные условия — это займёт пару минут.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит под залог автомобиля",
          "subtitle": null,
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 202,
      "templateId": 1095,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 3,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 221,
      "templateId": 1062,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 0
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17.9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит под 17.9%",
          "subtitle": "Это полная и окончательная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 222,
      "templateId": 1101,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 420 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 223,
      "templateId": 1199,
      "image": "https://sales-father-images-nginx.homebank.ru/images/GettyImages-1458847517_05 1.png",
      "firstBackgroundColor": "#FF4730D4",
      "secondBackgroundColor": "#FF735CFD",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "До {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Объедините все кредиты в разных банках в один для удобной оплаты"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 5,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Платите легче",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 246,
      "templateId": 1212,
      "image": "https://sales-father-images-nginx.homebank.ru/images/GettyImages-1458847517_05 1.png",
      "firstBackgroundColor": "#FF4730D4",
      "secondBackgroundColor": "#FF735CFD",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Объедините все кредиты в разных банках в один для удобной оплаты"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 5,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Платите легче",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 247,
      "templateId": 1052,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF4641EC",
      "secondBackgroundColor": "#FF40CDF5",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 14,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 15,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 16,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 17,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 18,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 248,
      "templateId": 1167,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 14,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 15,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 16,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 17,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 18,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 251,
      "templateId": 1112,
      "image": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf2.png",
      "firstBackgroundColor": "#FF8441ff",
      "secondBackgroundColor": "#FF0bdd78",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Оформите дебетовую карту Польза и получите:",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 2,
              "text": "До 15% кэшбэка за покупки в выбранных категориях"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "До 30% кэшбэка за покупки у партнёров"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/DigitalCard.png",
              "priority": 4,
              "text": "Бесплатные переводы в рамках лимитов"
            }
          ],
          "priority": 7,
          "type": "icon_list"
        },
        {
          "title": "Оформить карту за пару кликов",
          "text": "Виртуальная карта доступна сразу\r\n<p></p>",
          "priority": 8,
          "type": "html_text"
        },
        {
          "title": "Дебетовая карта Польза",
          "subtitle": "Получите бесплатную карту ",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 252,
      "templateId": 1113,
      "image": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf2.png",
      "firstBackgroundColor": "#FF8441ff",
      "secondBackgroundColor": "#FF0bdd78",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Бесплатные переводы, снятие наличных и пополнение",
          "text": null,
          "content": [
            {
              "text": "Бесплатные переводы по номеру карты на карты других банков, по номеру телефона и снятия наличных в сторонних банкоматах в рамках лимита в соответствии с тарифами банка",
              "priority": 1
            },
            {
              "text": "Комиссия за снятие наличных в банкоматах Банка Хоум Кредит не взимается",
              "priority": 2
            },
            {
              "text": "Бесплатное пополнение в мобильном приложении, интернет-банке, банкоматах и отделениях банка",
              "priority": 3
            }
          ],
          "priority": 1,
          "type": "unnumbered_list"
        },
        {
          "title": "Оформите новую зарплатную карту и получайте больше выгоды с первого дня!",
          "text": "Сотрудники Москвы могут получить карту в центральном офисе или по почте, сотрудники в других городах могут получить карту в Банковском офисе, по почте или курьером.",
          "content": [
            {
              "text": "Ознакомьтесь с условиями",
              "priority": 1
            },
            {
              "text": "Выберите способ доставки пластиковой карты",
              "priority": 2
            },
            {
              "text": "Подпишите договор с помощью SMS-кода",
              "priority": 3
            },
            {
              "text": "Получите пластиковую карту",
              "priority": 4
            },
            {
              "text": "Следующая выплата зарплаты поступит на данную карту",
              "priority": 5
            }
          ],
          "priority": 2,
          "type": "unnumbered_list"
        },
        {
          "title": "Зарплатная карта",
          "subtitle": "Получите бесплатную карту",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 253,
      "templateId": 1102,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 295 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 36 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 3,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 254,
      "templateId": 1065,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 430 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17,9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит под 17,9%",
          "subtitle": "Это полная и окончательная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 255,
      "templateId": 1066,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 430 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17,9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит под 17,9%",
          "subtitle": "Это полная и окончательная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 256,
      "templateId": 1042,
      "image": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf2.png",
      "firstBackgroundColor": "#FF8441ff",
      "secondBackgroundColor": "#FF0bdd78",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Оформите дебетовую карту Польза и получите:",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 2,
              "text": "До 15% кэшбэка за покупки в выбранных категориях"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "До 30% кэшбэка за покупки у партнёров"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/DigitalCard.png",
              "priority": 4,
              "text": "Бесплатные переводы в рамках лимитов"
            }
          ],
          "priority": 7,
          "type": "icon_list"
        },
        {
          "title": "Оформить карту за пару кликов",
          "text": "Виртуальная карта доступна сразу\r\n<p></p>",
          "priority": 8,
          "type": "html_text"
        },
        {
          "title": "Дебетовая карта Польза",
          "subtitle": "Получите бесплатную карту ",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 257,
      "templateId": 1190,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 19
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 258,
      "templateId": 1191,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 19
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 3,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 259,
      "templateId": 1195,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccspv22.png",
      "firstBackgroundColor": "#FF5A007A",
      "secondBackgroundColor": "#FFF26CFE",
      "displayScreens": [
        {
          "id": 19
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное снятие наличных ",
          "text": "Снимайте наличные без комиссии в течение месяца с даты оформления карты.",
          "priority": 1,
          "type": "html_text"
        },
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и плат за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 4 месяцев",
          "text": "После первой покупки начнётся беспроцентный период до 4 месяцев. В это время можно продолжить покупки, параллельно погашая долг минимальными и другими платежами. Беспроцентный период возобновляется после полного погашения долга и новой покупки. Если задолженность не будет погашена в течение 4 месяцев, на неё будут начислены проценты согласно договору.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Подробнее о карте",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://www.homecredit.ru/download.php?id=37906",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://www.homecredit.ru/download.php?id=34747",
              "linkType": "pdf"
            }
          ],
          "priority": 4,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Кредитная карта одобрена",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит {limit} ₽",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Money.png",
              "text": "Акция: бесплатное снятие наличных",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Bag.png",
              "text": "Рассрочка до 24 месяцев на покупки ",
              "priority": 3
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Заказать карту",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 261,
      "templateId": 1153,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 1
        },
        {
          "id": 2
        },
        {
          "id": 3
        },
        {
          "id": 4
        },
        {
          "id": 5
        },
        {
          "id": 6
        },
        {
          "id": 7
        },
        {
          "id": 8
        },
        {
          "id": 9
        },
        {
          "id": 10
        },
        {
          "id": 23
        },
        {
          "id": 24
        },
        {
          "id": 25
        },
        {
          "id": 26
        },
        {
          "id": 27
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17,9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит под 17,9%",
          "subtitle": "Это полная и окончательная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 281,
      "templateId": 1176,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 12
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 15,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 16,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 17,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 18,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 19,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 301,
      "templateId": 1150,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 1
        },
        {
          "id": 2
        },
        {
          "id": 3
        },
        {
          "id": 4
        },
        {
          "id": 5
        },
        {
          "id": 6
        },
        {
          "id": 7
        },
        {
          "id": 8
        },
        {
          "id": 9
        },
        {
          "id": 10
        },
        {
          "id": 23
        },
        {
          "id": 24
        },
        {
          "id": 25
        },
        {
          "id": 26
        },
        {
          "id": 27
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 302,
      "templateId": 1151,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 1
        },
        {
          "id": 2
        },
        {
          "id": 3
        },
        {
          "id": 4
        },
        {
          "id": 5
        },
        {
          "id": 6
        },
        {
          "id": 7
        },
        {
          "id": 8
        },
        {
          "id": 9
        },
        {
          "id": 10
        },
        {
          "id": 23
        },
        {
          "id": 24
        },
        {
          "id": 25
        },
        {
          "id": 26
        },
        {
          "id": 27
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 303,
      "templateId": 1026,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF6411b5",
      "secondBackgroundColor": "#FFE07F5E",
      "displayScreens": [
        {
          "id": 1
        },
        {
          "id": 2
        },
        {
          "id": 3
        },
        {
          "id": 4
        },
        {
          "id": 5
        },
        {
          "id": 6
        },
        {
          "id": 7
        },
        {
          "id": 8
        },
        {
          "id": 9
        },
        {
          "id": 10
        },
        {
          "id": 23
        },
        {
          "id": 24
        },
        {
          "id": 25
        },
        {
          "id": 26
        },
        {
          "id": 27
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и плат за открытие и обслуживание.",
          "priority": 15,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 16,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 17,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 18,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 19,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 304,
      "templateId": 1106,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 1
        },
        {
          "id": 2
        },
        {
          "id": 3
        },
        {
          "id": 4
        },
        {
          "id": 5
        },
        {
          "id": 6
        },
        {
          "id": 7
        },
        {
          "id": 8
        },
        {
          "id": 9
        },
        {
          "id": 10
        },
        {
          "id": 23
        },
        {
          "id": 24
        },
        {
          "id": 25
        },
        {
          "id": 26
        },
        {
          "id": 27
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 16,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 17,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 18,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 19,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 20,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 305,
      "templateId": 1213,
      "image": "https://sales-father-images-nginx.homebank.ru/images/clgh1.png",
      "firstBackgroundColor": "#FF7955DF",
      "secondBackgroundColor": "#FF4449D7",
      "displayScreens": [
        {
          "id": 22
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 306,
      "templateId": 1214,
      "image": "https://sales-father-images-nginx.homebank.ru/images/clgh1.png",
      "firstBackgroundColor": "#FF7955DF",
      "secondBackgroundColor": "#FF4449D7",
      "displayScreens": [
        {
          "id": 22
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка от 2,9% при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Решение за 2 минуты, сразу перечислим деньги на карту"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 5,
              "text": "Участвуйте в акции «Снижаем до 0», и мы вернём все % по кредиту"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит от 2,9%",
          "subtitle": "Вернём все % с акцией «Снижаем до 0»",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 321,
      "templateId": 4026,
      "image": "https://sales-father-images-nginx.homebank.ru/images/topup04.09.23.1.png",
      "firstBackgroundColor": "#FF9E36C2",
      "secondBackgroundColor": "#FFEC80FE",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "{limit} ₽ уже одобрено"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Объедините все кредиты в разных банках в один для удобной оплаты"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 5,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 4,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Платите легче",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 341,
      "templateId": 4028,
      "image": "https://sales-father-images-nginx.homebank.ru/images/topup04.09.23.1.png",
      "firstBackgroundColor": "#FF9E36C2",
      "secondBackgroundColor": "#FFEC80FE",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "{limit} ₽ уже одобрено "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Объедините все кредиты в разных банках в один для удобной оплаты"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 5,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": " платите легче",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 342,
      "templateId": 4029,
      "image": "https://sales-father-images-nginx.homebank.ru/images/topup04.09.23.1.png",
      "firstBackgroundColor": "#FF9E36C2",
      "secondBackgroundColor": "#FFEC80FE",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "До {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Объедините все кредиты в разных банках в один для удобной оплаты"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 5,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Платите легче",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 343,
      "templateId": 4030,
      "image": "https://sales-father-images-nginx.homebank.ru/images/topup04.09.23.1.png",
      "firstBackgroundColor": "#FF9E36C2",
      "secondBackgroundColor": "#FFEC80FE",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 1,
              "text": "Получите деньги по ставке 17,9% годовых"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "До {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "Объедините все кредиты в разных банках в один для удобной оплаты"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 4,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>\r\n<p></p>",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Кредитов в других банках",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 361,
      "templateId": 1016,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 17
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 3 000 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 3,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 381,
      "templateId": 1900,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 17
        }
      ],
      "blocks": [
        {
          "title": "Преимущества карты",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Лимит до 700 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 2,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок онлайн или через Mir Pay в течение 30 дней на общую сумму 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 3,
              "text": "Бесплатное снятие наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/CalendarPercent.png",
              "priority": 4,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/ByCash.png",
              "priority": 5,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 403,
      "templateId": 1115,
      "image": "https://sales-father-images-nginx.homebank.ru/images/debitcrebbonusf4.png",
      "firstBackgroundColor": "#FF024360",
      "secondBackgroundColor": "#FF71D4FF",
      "displayScreens": [
        {
          "id": 12
        }
      ],
      "blocks": [
        {
          "title": "Бонус 500 ₽",
          "text": "Оформите карту сейчас, сделайте покупки на сумму от 10 000 рублей в течение 30 дней и получите 500 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 1,
          "type": "html_text"
        },
        {
          "title": "Кэшбэк и бонусы",
          "text": "Кэшбэк возвращается баллами, 1 балл = 1 ₽.",
          "content": [
            {
              "text": "До 15% в выбранных категориях",
              "priority": 1
            },
            {
              "text": "До 30% кэшбэк у партнёров",
              "priority": 2
            },
            {
              "text": "+ кэшбэк по программе лояльности МИР",
              "priority": 3
            }
          ],
          "priority": 2,
          "type": "unnumbered_list"
        },
        {
          "title": "Предложения партнёров",
          "content": [
            {
              "title": "VPROK",
              "subtitle": "Кэшбэк 15%",
              "link": "https://www.vprok.ru/",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/vproklogo.png",
              "priority": 1
            },
            {
              "title": "SOKOLOV",
              "subtitle": "Кэшбэк 15%",
              "link": "https://sokolov.ru",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Sokolov.png",
              "priority": 2
            },
            {
              "title": "ТЕХНОПАРК",
              "subtitle": "Кэшбэк 5,3%",
              "link": "https://www.technopark.ru",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/technopark.png",
              "priority": 3
            },
            {
              "title": "HUAWEI",
              "subtitle": "Кэшбэк 5%",
              "link": "https://consumer.huawei.com/ru/",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/huawei.png",
              "priority": 4
            }
          ],
          "priority": 3,
          "type": "partners"
        },
        {
          "title": "Как получить карту",
          "text": null,
          "content": [
            {
              "text": "Подпишите договор с помощью SMS-кода",
              "priority": 1
            },
            {
              "text": "Получите Digital (цифровую) карту моментально",
              "priority": 2
            },
            {
              "text": "При необходимости выпуска пластиковой карты, закажите её в приложении",
              "priority": 3
            },
            {
              "text": "Получите пластиковую карту в течение 3 дней",
              "priority": 4
            }
          ],
          "priority": 4,
          "type": "unnumbered_list"
        },
        {
          "title": "Бесплатные переводы, снятие наличных и пополнение",
          "text": null,
          "content": [
            {
              "text": "Бесплатные переводы по номеру карты на карты других банков, по номеру телефона и снятия наличных в сторонних банкоматах в рамках лимита в соответствии с тарифами банка",
              "priority": 1
            },
            {
              "text": "Комиссия за снятие наличных в банкоматах Банка Хоум Кредит не взимается",
              "priority": 2
            },
            {
              "text": "Бесплатное пополнение в мобильном приложении, интернет-банке, банкоматах и отделениях банка, в салонах связи и терминалах МТС, а также в терминалах ВТБ",
              "priority": 3
            }
          ],
          "priority": 5,
          "type": "unnumbered_list"
        },
        {
          "title": "Подробнее о карте",
          "content": [
            {
              "priority": 1,
              "text": "Тарифы и условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://www.homecredit.ru/download.php?id=26803",
              "linkType": "pdf"
            }
          ],
          "priority": 6,
          "type": "link_text"
        },
        {
          "title": "Дебетовая карта",
          "subtitle": "Мгновенный выпуск цифровой карты",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-CashMax.png",
              "text": "До 15% кэшбэк в выбранных категориях",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Снятия и переводы",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатный выпуск и обслуживание ",
              "priority": 3
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Заказать карту",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 421,
      "templateId": 1074,
      "image": "https://sales-father-images-nginx.homebank.ru/images/refintopspv22.png",
      "firstBackgroundColor": "#FF1B41AE",
      "secondBackgroundColor": "#FF6BB0FE",
      "displayScreens": [
        {
          "id": 11
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 2,9% при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Объедините кредиты в один, чтобы не запоминать суммы и даты платежей в разных банках"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 6,
              "text": "Получите больше денег на любые цели"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 7,
              "text": "Участвуйте в акции «Снижаем до 0», и мы вернём все % по кредиту"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Решение за 2 минуты, сразу перечислим деньги онлайн. Нужен только паспорт.\r\n<p></p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Кредитов других банков",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 422,
      "templateId": 1075,
      "image": "https://sales-father-images-nginx.homebank.ru/images/rfinjune4.png",
      "firstBackgroundColor": "#FF8737e5",
      "secondBackgroundColor": "#FFe58484",
      "displayScreens": [
        {
          "id": 11
        }
      ],
      "blocks": [
        {
          "title": "Обновите кредит в нашем банке",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 2,9% при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Решение за 2 минуты, сразу перечислим деньги онлайн"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 5,
              "text": "Получите больше денег на любые цели"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 6,
              "text": "Участвуйте в акции «Снижаем до 0», и мы вернём все % по кредиту"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Кредитов нашего банка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 423,
      "templateId": 1076,
      "image": "https://sales-father-images-nginx.homebank.ru/images/clcartopsp2.png",
      "firstBackgroundColor": "#FF6B40BD",
      "secondBackgroundColor": "#FF73D277",
      "displayScreens": [
        {
          "id": 11
        }
      ],
      "blocks": [
        {
          "title": "Получите деньги прямо сейчас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Увеличенная сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 4,9% при подключении «Гарантии низкой ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Решение за 2 минуты, сразу перечислим деньги на карту"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 5,
              "text": "Участвуйте в акции «Снижаем до 0», и мы вернём все % по кредиту"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Кредит от 4,9%",
          "subtitle": "Под залог автомобиля",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 427,
      "templateId": 1221,
      "image": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf2.png",
      "firstBackgroundColor": "#FF024360",
      "secondBackgroundColor": "#FF71D4FF",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "«Карта выпускается на платежной системе МИР»",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Кэшбэк и бонусы",
          "text": "Кэшбэк возвращается баллами, 1 балл = 1 ₽.",
          "content": [
            {
              "text": "До 15% в выбранных категориях",
              "priority": 1
            },
            {
              "text": "До 30% кэшбэк у партнёров",
              "priority": 2
            },
            {
              "text": "+ кэшбэк по программе лояльности МИР",
              "priority": 3
            }
          ],
          "priority": 5,
          "type": "unnumbered_list"
        },
        {
          "title": "Предложения партнёров",
          "content": [
            {
              "title": "VPROK",
              "subtitle": "Кэшбэк 15%",
              "link": "https://www.vprok.ru/",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/vproklogo.png",
              "priority": 1
            },
            {
              "title": "SOKOLOV",
              "subtitle": "Кэшбэк 15%",
              "link": "https://sokolov.ru",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Sokolov.png",
              "priority": 2
            },
            {
              "title": "ТЕХНОПАРК",
              "subtitle": "Кэшбэк 5,3%",
              "link": "https://www.technopark.ru",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/technopark.png",
              "priority": 3
            },
            {
              "title": "HUAWEI",
              "subtitle": "Кэшбэк 5%",
              "link": "https://consumer.huawei.com/ru/",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/huawei.png",
              "priority": 4
            }
          ],
          "priority": 6,
          "type": "partners"
        },
        {
          "title": "Оформите новую зарплатную карту и получайте больше выгоды с первого дня!",
          "text": "Сотрудники Москвы могут получить карту в центральном офисе или по почте, сотрудники в других городах могут получить карту в Банковском офисе, по почте или курьером.",
          "content": [
            {
              "text": "Ознакомьтесь с условиями",
              "priority": 1
            },
            {
              "text": "Выберите способ доставки пластиковой карты",
              "priority": 2
            },
            {
              "text": "Подпишите договор с помощью SMS-кода",
              "priority": 3
            },
            {
              "text": "Получите пластиковую карту",
              "priority": 4
            },
            {
              "text": "Следующая выплата зарплаты поступит на данную карту",
              "priority": 5
            }
          ],
          "priority": 7,
          "type": "unnumbered_list"
        },
        {
          "title": "Бесплатные переводы, снятие наличных и пополнение",
          "text": null,
          "content": [
            {
              "text": "Бесплатные переводы по номеру карты на карты других банков, по номеру телефона и снятия наличных в сторонних банкоматах в рамках лимита в соответствии с тарифами банка",
              "priority": 1
            },
            {
              "text": "Комиссия за снятие наличных в банкоматах Банка Хоум Кредит не взимается",
              "priority": 2
            },
            {
              "text": "Бесплатное пополнение в мобильном приложении, интернет-банке, банкоматах и отделениях банка, в салонах связи и терминалах МТС, а также в терминалах ВТБ",
              "priority": 3
            }
          ],
          "priority": 8,
          "type": "unnumbered_list"
        },
        {
          "title": "Зарплатная карта",
          "subtitle": "Мгновенный выпуск цифровой карты",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Кэшбэк до 15% в 4 бесплатных категориях на выбор",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-CashMax.png",
              "text": "Кэшбэк до 30% у партнёров",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Feature.png",
              "text": "Бесплатный выпуск и обслуживание ",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Arrows.png",
              "text": "Бесплатные переводы через СБП",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Заказать карту",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 428,
      "templateId": 1222,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF6411b5",
      "secondBackgroundColor": "#FFE07F5E",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 11,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 12,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 13,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 15,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 16,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 441,
      "templateId": 1223,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ref04.09.23.1.png",
      "firstBackgroundColor": "#FF0155B9",
      "secondBackgroundColor": "#FF89D9FE",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "До {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Обновите кредит в нашем банке",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 442,
      "templateId": 1224,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 12
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 10,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 11,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 12,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 13,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 14,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до 100 000 рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 461,
      "templateId": 4102,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ref04.09.23.1.png",
      "firstBackgroundColor": "#FF0155B9",
      "secondBackgroundColor": "#FF89D9FE",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "До {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Обновите кредит в нашем банке",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 462,
      "templateId": 1225,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebbonusf2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Преимущества карты",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Лимит до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 2,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок на общую сумму от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "Бесплатные переводы и снятия наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 5,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 6,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 7,
          "type": "html_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 481,
      "templateId": 1226,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ref04.09.23.1.png",
      "firstBackgroundColor": "#FF0155B9",
      "secondBackgroundColor": "#FF89D9FE",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "До {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17,9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Обновите кредит в нашем банке",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 482,
      "templateId": 4103,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ref04.09.23.1.png",
      "firstBackgroundColor": "#FF0155B9",
      "secondBackgroundColor": "#FF89D9FE",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "До {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17,9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Обновите кредит в нашем банке",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 501,
      "templateId": 1227,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 29
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 502,
      "templateId": 1228,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 29
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 521,
      "templateId": 1921,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF6411b5",
      "secondBackgroundColor": "#FFE07F5E",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Ваш лимит до {limit} ₽",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание навсегда"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 3,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок на общую сумму от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Бесплатные переводы и снятия наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 6,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 522,
      "templateId": 1922,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Ваш лимит до {limit} ₽",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание навсегда"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 3,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок на общую сумму от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Бесплатные переводы и снятия наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 6,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 523,
      "templateId": 1923,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Ваш лимит {limit} ₽",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание навсегда"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 3,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок на общую сумму от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Бесплатные переводы и снятия наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 6,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 524,
      "templateId": 1924,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Ваш лимит до {limit} ₽",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание навсегда"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 3,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок на общую сумму от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Бесплатные переводы и снятия наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 6,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 525,
      "templateId": 1925,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Ваш лимит {limit} ₽",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание навсегда"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 3,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок на общую сумму от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Бесплатные переводы и снятия наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 6,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 526,
      "templateId": 1926,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 12
        }
      ],
      "blocks": [
        {
          "title": "Ваш лимит до {limit} ₽",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание навсегда"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 3,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок на общую сумму от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Бесплатные переводы и снятия наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 6,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 527,
      "templateId": 1927,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 12
        }
      ],
      "blocks": [
        {
          "title": "Ваш лимит до 100 000 ₽",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание навсегда"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 3,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок на общую сумму от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Бесплатные переводы и снятия наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 6,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 528,
      "templateId": 1928,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 1
        },
        {
          "id": 2
        },
        {
          "id": 3
        },
        {
          "id": 4
        },
        {
          "id": 5
        },
        {
          "id": 6
        },
        {
          "id": 7
        },
        {
          "id": 8
        },
        {
          "id": 9
        },
        {
          "id": 10
        },
        {
          "id": 23
        },
        {
          "id": 24
        },
        {
          "id": 25
        },
        {
          "id": 26
        },
        {
          "id": 27
        }
      ],
      "blocks": [
        {
          "title": "Ваш лимит до {limit} ₽",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание навсегда"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 2,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок на общую сумму от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 3,
              "text": "5% кэшбэка за покупки к новому учебному году"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Бесплатное снятие наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 6,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 529,
      "templateId": 1929,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 1
        },
        {
          "id": 2
        },
        {
          "id": 3
        },
        {
          "id": 4
        },
        {
          "id": 5
        },
        {
          "id": 6
        },
        {
          "id": 7
        },
        {
          "id": 8
        },
        {
          "id": 9
        },
        {
          "id": 10
        },
        {
          "id": 23
        },
        {
          "id": 24
        },
        {
          "id": 25
        },
        {
          "id": 26
        },
        {
          "id": 27
        }
      ],
      "blocks": [
        {
          "title": "Ваш лимит {limit} ₽",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание навсегда"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 2,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок на общую сумму от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 3,
              "text": "5% кэшбэка за покупки к новому учебному году"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Бесплатное снятие наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 6,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 530,
      "templateId": 1930,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Ваш лимит до 100 000 ₽",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Бесплатное обслуживание навсегда"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 3,
              "text": "Бонус 1000 ₽ баллами Пользы — при оформлении карты онлайн и совершении покупок на общую сумму от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Бесплатные переводы и снятия наличных в течение 30 дней с даты подписания договора"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Рассрочка на покупки от 1000 до 150 000 ₽ до 24 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 6,
              "text": "Рефинансирование кредиток других банков  "
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p><p/>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 541,
      "templateId": 1229,
      "image": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf2.png",
      "firstBackgroundColor": "#FF8441ff",
      "secondBackgroundColor": "#FF0bdd78",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Преимущества карты",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Кэшбэк до 15% в 4 категориях на выбор, включая 1% за все покупки"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 2,
              "text": "Кэшбэк до 30% за покупки у партнёров"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "Дополнительный кэшбэк и скидки по программе лояльности платёжной системы «Мир»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/DigitalCard.png",
              "priority": 4,
              "text": "Бесплатные переводы в другие банки по номеру телефона через СБП в рамках лимитов согласно тарифам банка"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Дебетовая карта Польза",
          "subtitle": "Получите бесплатную карту",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 542,
      "templateId": 1230,
      "image": "https://sales-father-images-nginx.homebank.ru/images/debitcrebbonusf4.png",
      "firstBackgroundColor": "#FF024360",
      "secondBackgroundColor": "#FF71D4FF",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бонус 500 ₽",
          "text": "Оформите карту сейчас, сделайте покупки на сумму от 10 000 рублей в течение 30 дней и получите 500 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кэшбэк и бонусы",
          "text": "Кэшбэк возвращается баллами, 1 балл = 1 ₽.",
          "content": [
            {
              "text": "До 15% в выбранных категориях",
              "priority": 1
            },
            {
              "text": "До 30% кэшбэк у партнёров",
              "priority": 2
            },
            {
              "text": "+ кэшбэк по программе лояльности МИР",
              "priority": 3
            }
          ],
          "priority": 3,
          "type": "unnumbered_list"
        },
        {
          "title": "Предложения партнёров",
          "content": [
            {
              "title": "VPROK",
              "subtitle": "Кэшбэк 15%",
              "link": "https://www.vprok.ru/",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/vproklogo.png",
              "priority": 1
            },
            {
              "title": "SOKOLOV",
              "subtitle": "Кэшбэк 15%",
              "link": "https://sokolov.ru",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Sokolov.png",
              "priority": 2
            },
            {
              "title": "ТЕХНОПАРК",
              "subtitle": "Кэшбэк 5,3%",
              "link": "https://www.technopark.ru",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/technopark.png",
              "priority": 3
            },
            {
              "title": "HUAWEI",
              "subtitle": "Кэшбэк 5%",
              "link": "https://consumer.huawei.com/ru/",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/huawei.png",
              "priority": 4
            }
          ],
          "priority": 4,
          "type": "partners"
        },
        {
          "title": "Как получить карту",
          "text": null,
          "content": [
            {
              "text": "Подпишите договор с помощью SMS-кода",
              "priority": 1
            },
            {
              "text": "Получите Digital (цифровую) карту моментально",
              "priority": 2
            },
            {
              "text": "При необходимости выпуска пластиковой карты, закажите её в приложении",
              "priority": 3
            },
            {
              "text": "Получите пластиковую карту в течение 3 дней",
              "priority": 4
            }
          ],
          "priority": 5,
          "type": "unnumbered_list"
        },
        {
          "title": "Бесплатные переводы, снятие наличных и пополнение",
          "text": null,
          "content": [
            {
              "text": "Бесплатные переводы по номеру карты на карты других банков, по номеру телефона и снятия наличных в сторонних банкоматах в рамках лимита в соответствии с тарифами банка",
              "priority": 1
            },
            {
              "text": "Комиссия за снятие наличных в банкоматах Банка Хоум Кредит не взимается",
              "priority": 2
            },
            {
              "text": "Бесплатное пополнение в мобильном приложении, интернет-банке, банкоматах и отделениях банка, в салонах связи и терминалах МТС, а также в терминалах ВТБ",
              "priority": 3
            }
          ],
          "priority": 6,
          "type": "unnumbered_list"
        },
        {
          "title": "Подробнее о карте",
          "content": [
            {
              "priority": 1,
              "text": "Тарифы и условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://www.homecredit.ru/download.php?id=26803",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "Дебетовая карта",
          "subtitle": "Мгновенный выпуск цифровой карты",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-CashMax.png",
              "text": "До 15% кэшбэк в выбранных категориях",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Снятие и переводы",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатный выпуск и обслуживание ",
              "priority": 3
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Заказать карту",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 543,
      "templateId": 1231,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ref04.09.23.1.png",
      "firstBackgroundColor": "#FF0155B9",
      "secondBackgroundColor": "#FF89D9FE",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Обновите кредит в нашем банке",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 544,
      "templateId": 1232,
      "image": "https://sales-father-images-nginx.homebank.ru/images/GettyImages-1458847517_05 1.png",
      "firstBackgroundColor": "#FF1B41AE",
      "secondBackgroundColor": "#FF6BB0FE",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Объедините все кредиты в разных банках в один для удобной оплаты"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 5,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Платите легче",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 545,
      "templateId": 1233,
      "image": "https://sales-father-images-nginx.homebank.ru/images/topup04.09.23.1.png",
      "firstBackgroundColor": "#FF9E36C2",
      "secondBackgroundColor": "#FFEC80FE",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Объедините все кредиты в разных банках в один для удобной оплаты"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 5,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Платите легче",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 546,
      "templateId": 1234,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF6411b5",
      "secondBackgroundColor": "#FFE07F5E",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Акция: кэшбэк 15% за покупки с 10 по 12 ноября на сумму от 15 000 рублей",
          "text": "Оформите карту до 9 ноября, сделайте ей покупки на общую сумму от 15 000 рублей с 10 по 12 ноября, а на все следующие покупки онлайн в эти дни мы начислим кэшбэк 15%.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Акция: бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 7,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 8,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Bag.png",
              "text": "Акция: кэшбэк 15% за покупки с 10 по 12 ноября на сумму от 15 000 рублей",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Акция: бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 547,
      "templateId": 1235,
      "image": "https://sales-father-images-nginx.homebank.ru/images/oneclick1.png",
      "firstBackgroundColor": "#FF6A79FD",
      "secondBackgroundColor": "#FF6877FB",
      "displayScreens": [
        {
          "id": 30
        }
      ],
      "blocks": [
        {
          "title": "Услуга «Гарантия оптимальной ставки» подключена",
          "text": "Ставка снижена до {rate}% за счет подключения услуги «‎Гарантия оптимальной ставки», стоимость услуги {optimalRateCost} ₽.",
          "content": [],
          "priority": 3,
          "type": "unnumbered_list"
        },
        {
          "title": "Вы участвуете в акции «Верните Больше» и экономите на расходах",
          "text": "При выполнении условий акции «Верните Больше» часть выплат вернется вам бонусом. Бонус выплачивается рублями в течение 30 дней с момента получения от вас заявления о выплате бонуса.",
          "priority": 7,
          "type": "html_text"
        },
        {
          "title": "Условия участия в акции:",
          "text": null,
          "content": [
            {
              "text": "Оформите кредит с присоединением к Страховой Программе (стоимость услуги {fzFeeCost} ₽ / день) и подключением СМС-информирования (стоимость услуги 199 ₽ / месяц) и получите его на дебетовую карту банка",
              "priority": 1
            },
            {
              "text": "Совершайте ежемесячно не менее 5 покупок на общую сумму от 10 000 ₽ по дебетовой карте банка",
              "priority": 2
            },
            {
              "text": "Весь срок действия кредита пользуйтесь подключенными дополнительными услугами",
              "priority": 3
            }
          ],
          "priority": 9,
          "type": "unnumbered_list"
        },
        {
          "title": "Дополнение",
          "text": "Участие в акции не является обязательным и не влияет на выдачу кредита. Вы можете выбрать иные варианты кредитования для формирования кредитного предложения.",
          "priority": 10,
          "type": "html_text"
        },
        {
          "title": "Прочие условия",
          "text": "Ставка {rate}% действует при выполнении следующих условий (иначе — ставка {penaltyRate}%):",
          "content": [
            {
              "text": "Не менее 80% от суммы кредита потрачены безналично",
              "priority": 1
            },
            {
              "text": "Безналичные покупки совершены в течение 20 дней с даты выдачи кредита",
              "priority": 2
            }
          ],
          "priority": 11,
          "type": "unnumbered_list"
        },
        {
          "title": "Кредит {rate}%",
          "subtitle": "На любые цели",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Сумма на руки {netAmount} ₽",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-CashMax.png",
              "text": "Общая сумма кредита {limit} ₽",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Calendar.png",
              "text": "Срок кредита {term} мес.",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Ежемесячный платёж {monthPayment} ₽ / месяц",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Хочу оформить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 561,
      "templateId": 1236,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 562,
      "templateId": 1237,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF6411b5",
      "secondBackgroundColor": "#FFE07F5E",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay и Samsung Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Акция: бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Акция: бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 563,
      "templateId": 1238,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и плат за открытие и обслуживание.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Акция: бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 7,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 8,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Акция: бесплатные переводы и снятия наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Рассрочка до 24 месяцев на покупки",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 564,
      "templateId": 1239,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay и Samsung Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Акция: бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Акция: бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 581,
      "templateId": 4104,
      "image": "https://sales-father-images-nginx.homebank.ru/images/topup04.09.23.1.png",
      "firstBackgroundColor": "#FF9E36C2",
      "secondBackgroundColor": "#FFEC80FE",
      "displayScreens": [
        {
          "id": 17
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "До 3 000 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Объедините все кредиты в разных банках в один для удобной оплаты"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 5,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 2,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Платите легче",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 584,
      "templateId": 1243,
      "image": "https://sales-father-images-nginx.homebank.ru/images/call04.09.23.3.png",
      "firstBackgroundColor": "#FF399D49",
      "secondBackgroundColor": "#FF8AF881",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Сколько одобрит банк",
          "text": "Вы можете бесплатно узнать свой кредитный лимит за несколько кликов",
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_cash_loan_icon.png",
              "priority": 1,
              "text": "Просто заполните заявку — после вы увидите персональную сумму кредита, ставку и другие условия."
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_insurance_icon.png",
              "priority": 2,
              "text": "При подаче заявки могут потребоваться дополнительные данные — мы гарантируем их защиту."
            }
          ],
          "priority": 3,
          "type": "icon_list"
        },
        {
          "title": "Дополнительная возможность",
          "text": "Если вам понравится одобренная сумма, вы сможете продолжить заявку и подписать договор онлайн — мы перечислим деньги в этот же день",
          "content": [],
          "priority": 6,
          "type": "unnumbered_list"
        },
        {
          "title": "Узнайте сумму кредита",
          "subtitle": null,
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 585,
      "templateId": 1244,
      "image": "https://sales-father-images-nginx.homebank.ru/images/call04.09.23.3.png",
      "firstBackgroundColor": "#FF399D49",
      "secondBackgroundColor": "#FF8AF881",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Сколько одобрит банк",
          "text": "Вы можете бесплатно узнать свой кредитный лимит за несколько кликов",
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_cash_loan_icon.png",
              "priority": 1,
              "text": "Просто заполните заявку — после вы увидите персональную сумму кредита, ставку и другие условия."
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_insurance_icon.png",
              "priority": 2,
              "text": "При подаче заявки могут потребоваться дополнительные данные — мы гарантируем их защиту."
            }
          ],
          "priority": 3,
          "type": "icon_list"
        },
        {
          "title": "Дополнительная возможность",
          "text": "Если вам понравится одобренная сумма, вы сможете продолжить заявку и подписать договор онлайн — мы перечислим деньги в этот же день",
          "content": [],
          "priority": 4,
          "type": "unnumbered_list"
        },
        {
          "title": "Узнайте сумму кредита",
          "subtitle": null,
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 601,
      "templateId": 1245,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFBC140A",
      "secondBackgroundColor": "#FFFD665C",
      "displayScreens": [
        {
          "id": 0
        },
        {
          "id": 14
        },
        {
          "id": 18
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 6,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 4,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 602,
      "templateId": 1246,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashapr26.06.23.21.png",
      "firstBackgroundColor": "#FFD20C2D",
      "secondBackgroundColor": "#FFF83A5C",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма: {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 2,
              "text": "Ежемесячный платеж: {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок кредита: {term} мес."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Получите кредит",
          "text": "Осталось только подписать договор.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Заявка одобрена",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 603,
      "templateId": 1247,
      "image": "https://sales-father-images-nginx.homebank.ru/images/clspvvfirst2.png",
      "firstBackgroundColor": "#FFC8102E",
      "secondBackgroundColor": "#FFC8102E",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма: {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 2,
              "text": "Ежемесячный платеж: {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок кредита: {term} мес."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Получите кредит",
          "text": "Осталось только подписать договор.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Заявка одобрена",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 604,
      "templateId": 1248,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashapr26.06.23.21.png",
      "firstBackgroundColor": "#FFD20C2D",
      "secondBackgroundColor": "#FFF83A5C",
      "displayScreens": [
        {
          "id": 29
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма: {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 2,
              "text": "Ежемесячный платеж: {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок кредита: {term} мес."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Получите кредит",
          "text": "Осталось только подписать договор.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Заявка одобрена",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 605,
      "templateId": 1249,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 31
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас ",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными ",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 606,
      "templateId": 1250,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 31
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 607,
      "templateId": 1251,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 31
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17,9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит под 17,9%",
          "subtitle": "Это полная и окончательная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 608,
      "templateId": 1252,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ref04.09.23.1.png",
      "firstBackgroundColor": "#FF0155B9",
      "secondBackgroundColor": "#FF89D9FE",
      "displayScreens": [
        {
          "id": 31
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "До {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении \"Гарантии оптимальной ставки\" "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Обновите кредит в нашем банке",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 609,
      "templateId": 1253,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ref04.09.23.1.png",
      "firstBackgroundColor": "#FF0155B9",
      "secondBackgroundColor": "#FF89D9FE",
      "displayScreens": [
        {
          "id": 31
        }
      ],
      "blocks": [
        {
          "title": "Обновите условия и платите легче",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "До {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Ставка 17,9%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Получите больше денег на любые цели"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование",
          "subtitle": "Обновите кредит в нашем банке",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 682,
      "templateId": 1256,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF6411b5",
      "secondBackgroundColor": "#FFE07F5E",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Акция: кэшбэк 15% за покупки с 10 по 12 ноября на сумму от 15 000 рублей",
          "text": "Оформите карту до 9 ноября, сделайте ей покупки на общую сумму от 15 000 рублей с 10 по 12 ноября, а на все следующие покупки онлайн в эти дни мы начислим кэшбэк 15%.",
          "priority": 1,
          "type": "html_text"
        },
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Акция: бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Bag.png",
              "text": "Акция: кэшбэк 15% за покупки с 10 по 12 ноября на сумму от 15 000 рублей",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Акция: бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 683,
      "templateId": 1257,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и плат за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Рассрочка до 24 месяцев на покупки",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 684,
      "templateId": 1258,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Рассрочка до 24 месяцев на покупки",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 685,
      "templateId": 1259,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до 100 000 ₽",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Рассрочка до 24 месяцев на покупки",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 686,
      "templateId": 1260,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 12
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Рассрочка до 24 месяцев на покупки",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 687,
      "templateId": 1261,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 12
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до 100 000 рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Рассрочка до 24 месяцев на покупки",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 688,
      "templateId": 1262,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Рассрочка до 24 месяцев на покупки",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 689,
      "templateId": 1263,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Рассрочка до 24 месяцев на покупки",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 690,
      "templateId": 1264,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF6411b5",
      "secondBackgroundColor": "#FFE07F5E",
      "displayScreens": [
        {
          "id": 1
        },
        {
          "id": 2
        },
        {
          "id": 3
        },
        {
          "id": 4
        },
        {
          "id": 5
        },
        {
          "id": 6
        },
        {
          "id": 7
        },
        {
          "id": 8
        },
        {
          "id": 9
        },
        {
          "id": 10
        },
        {
          "id": 23
        },
        {
          "id": 24
        },
        {
          "id": 25
        },
        {
          "id": 26
        },
        {
          "id": 27
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Рассрочка до 24 месяцев на покупки",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 691,
      "templateId": 1265,
      "image": "https://sales-father-images-nginx.homebank.ru/images/creditcrebf2.png",
      "firstBackgroundColor": "#FF6411b5",
      "secondBackgroundColor": "#FFE07F5E",
      "displayScreens": [
        {
          "id": 1
        },
        {
          "id": 2
        },
        {
          "id": 3
        },
        {
          "id": 4
        },
        {
          "id": 5
        },
        {
          "id": 6
        },
        {
          "id": 7
        },
        {
          "id": 8
        },
        {
          "id": 9
        },
        {
          "id": 10
        },
        {
          "id": 23
        },
        {
          "id": 24
        },
        {
          "id": 25
        },
        {
          "id": 26
        },
        {
          "id": 27
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Рассрочка до 24 месяцев на покупки",
          "text": "Вы можете оформлять рассрочку на совершённые покупки от 1000 до 150 000 ₽ на срок до 24 месяцев.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Рефинансирование кредиток других банков",
          "text": "У вас есть возможность рефинансировать кредитные карты других банков без % до 18 месяцев.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Рассрочка до 24 месяцев на покупки",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 702,
      "templateId": 1177,
      "image": "https://sales-father-images-nginx.homebank.ru/images/debitcrebf4.png",
      "firstBackgroundColor": "#FF024360",
      "secondBackgroundColor": "#FF71D4FF",
      "displayScreens": [
        {
          "id": 12
        }
      ],
      "blocks": [
        {
          "title": "Кэшбэк и бонусы",
          "text": "Кэшбэк возвращается баллами, 1 балл = 1 ₽.",
          "content": [
            {
              "text": "До 15% в выбранных категориях",
              "priority": 1
            },
            {
              "text": "До 30% кэшбэк у партнёров",
              "priority": 2
            },
            {
              "text": "+ кэшбэк по программе лояльности МИР",
              "priority": 3
            }
          ],
          "priority": 3,
          "type": "unnumbered_list"
        },
        {
          "title": "Предложения партнёров",
          "content": [
            {
              "title": "VPROK",
              "subtitle": "Кэшбэк 15%",
              "link": "https://www.vprok.ru/",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/vproklogo.png",
              "priority": 1
            },
            {
              "title": "SOKOLOV",
              "subtitle": "Кэшбэк 15%",
              "link": "https://sokolov.ru",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Sokolov.png",
              "priority": 2
            },
            {
              "title": "ТЕХНОПАРК",
              "subtitle": "Кэшбэк 5,3%",
              "link": "https://www.technopark.ru",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/technopark.png",
              "priority": 3
            },
            {
              "title": "HUAWEI",
              "subtitle": "Кэшбэк 5%",
              "link": "https://consumer.huawei.com/ru/",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/huawei.png",
              "priority": 4
            }
          ],
          "priority": 4,
          "type": "partners"
        },
        {
          "title": "Как получить карту",
          "text": null,
          "content": [
            {
              "text": "Подпишите договор с помощью SMS-кода",
              "priority": 1
            },
            {
              "text": "Получите Digital (цифровую) карту моментально",
              "priority": 2
            },
            {
              "text": "При необходимости выпуска пластиковой карты, закажите её в приложении",
              "priority": 3
            },
            {
              "text": "Получите пластиковую карту в течение 3 дней",
              "priority": 4
            }
          ],
          "priority": 5,
          "type": "unnumbered_list"
        },
        {
          "title": "Бесплатные переводы, снятия наличных и пополнение",
          "text": null,
          "content": [
            {
              "text": "Бесплатные переводы по номеру карты на карты других банков, по номеру телефона и снятия наличных в сторонних банкоматах в рамках лимита в соответствии с тарифами банка",
              "priority": 1
            },
            {
              "text": "Комиссия за снятие наличных в банкоматах Банка Хоум Кредит не взимается",
              "priority": 2
            },
            {
              "text": "Бесплатное пополнение в мобильном приложении, интернет-банке, банкоматах и отделениях банка, в салонах связи и терминалах МТС, а также в терминалах ВТБ",
              "priority": 3
            }
          ],
          "priority": 6,
          "type": "unnumbered_list"
        },
        {
          "title": "Подробнее о карте",
          "content": [
            {
              "priority": 1,
              "text": "Тарифы и условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://www.homecredit.ru/download.php?id=26803",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "Дебетовая карта",
          "subtitle": "Мгновенный выпуск цифровой карты",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-CashMax.png",
              "text": "До 15% кэшбэк в выбранных категориях",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Снятия и переводы",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатный выпуск и обслуживание ",
              "priority": 3
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Заказать карту",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 722,
      "templateId": 9050,
      "image": "https://sales-father-images-nginx.homebank.ru/images/nakop04.09.23.1.png",
      "firstBackgroundColor": "#FF6945FA",
      "secondBackgroundColor": "#FFA06CF3",
      "displayScreens": [
        {
          "id": 11
        }
      ],
      "blocks": [
        {
          "title": "Получайте доход",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_deposit_icon.png",
              "priority": 1,
              "text": "До 15% на остаток"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Refinance_icon_v1.png",
              "priority": 2,
              "text": "Пополнение и снятие без ограничений"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/catalog_insurance_icon.png",
              "priority": 3,
              "text": "Средства застрахованы Государством"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Стандартные условия по счёту",
          "text": "Процент начисляется на ежедневный остаток средств и выплачивается в последний день каждого месяца",
          "content": [
            {
              "text": "15% годовых при совершении покупок по дебетовым и кредитным картам на сумму не менее 60 000 ₽",
              "priority": 2
            },
            {
              "text": "10% годовых при совершении покупок по дебетовым и кредитным картам на сумму не менее 20 000 ₽",
              "priority": 3
            },
            {
              "text": "5% годовых – при отсутствии покупок или если их сумма менее 20 000 ₽",
              "priority": 4
            },
            {
              "text": "5% годовых на остаток свыше 1 500 000 ₽",
              "priority": 5
            }
          ],
          "priority": 5,
          "type": "unnumbered_list"
        },
        {
          "title": "Накопительный счет",
          "subtitle": "Пополнение и снятие без ограничений",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Открыть",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 742,
      "templateId": 1266,
      "image": "https://sales-father-images-nginx.homebank.ru/images/cashlrebwel3.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до 3 000 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Быстрое решение, перечислим деньги на карту за 10 минут."
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": null,
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 762,
      "templateId": 1267,
      "image": "https://sales-father-images-nginx.homebank.ru/images/mncl32.png",
      "firstBackgroundColor": "#FFBC140A",
      "secondBackgroundColor": "#FFFD665C",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/gift_icon.png",
              "priority": 5,
              "text": "Возможость оплатить на один платеж меньше  с акцией \"Минус 1 платеж\""
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Нужен только паспорт.\r\n<p></p>",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "На один платеж меньше",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 782,
      "templateId": 1269,
      "image": "https://sales-father-images-nginx.homebank.ru/images/oneclick1.png",
      "firstBackgroundColor": "#FF6A79FD",
      "secondBackgroundColor": "#FF6877FB",
      "displayScreens": [
        {
          "id": 30
        }
      ],
      "blocks": [
        {
          "title": "Услуга «Гарантия оптимальной ставки» подключена",
          "text": "Ставка снижена до {rate}% за счет подключения услуги «‎Гарантия оптимальной ставки», стоимость услуги {optimalRateCost} ₽.",
          "content": [],
          "priority": 1,
          "type": "unnumbered_list"
        },
        {
          "title": "Вы участвуете в акции «Верните Больше» и экономите на расходах",
          "text": "При выполнении условий акции «Верните Больше» часть выплат вернется вам бонусом. Бонус выплачивается рублями в течение 30 дней с момента получения от вас заявления о выплате бонуса.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Условия участия в акции:",
          "text": null,
          "content": [
            {
              "text": "Оформите кредит с присоединением к Страховой Программе (стоимость услуги {fzFeeCost} ₽ / день) и подключением СМС-информирования (стоимость услуги 199 ₽ / месяц) и получите его на дебетовую карту банка",
              "priority": 1
            },
            {
              "text": "Совершайте ежемесячно не менее 5 покупок на общую сумму от 10 000 ₽ по дебетовой карте банка",
              "priority": 4
            },
            {
              "text": "Весь срок действия кредита пользуйтесь подключенными дополнительными услугами",
              "priority": 5
            }
          ],
          "priority": 5,
          "type": "unnumbered_list"
        },
        {
          "title": "Дополнение",
          "text": "Участие в акции не является обязательным и не влияет на выдачу кредита. Вы можете выбрать иные варианты кредитования для формирования кредитного предложения.",
          "priority": 8,
          "type": "html_text"
        },
        {
          "title": "Прочие условия",
          "text": "Ставка {rate}% действует при выполнении следующих условий (иначе — ставка {penaltyRate}%):",
          "content": [
            {
              "text": "Не менее 80% от суммы кредита потрачены безналично",
              "priority": 1
            },
            {
              "text": "Безналичные покупки совершены в течение 20 дней с даты выдачи кредита",
              "priority": 2
            }
          ],
          "priority": 9,
          "type": "unnumbered_list"
        },
        {
          "title": "Кредит {rate}%",
          "subtitle": "На любые цели",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Сумма на руки {netAmount} ₽",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-CashMax.png",
              "text": "Общая сумма кредита {limit} ₽",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Calendar.png",
              "text": "Срок кредита {term} мес.",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-TimeInstallment.png",
              "text": "Ежемесячный платёж {monthPayment} ₽ / месяц",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Хочу оформить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 802,
      "templateId": 1270,
      "image": "https://sales-father-images-nginx.homebank.ru/images/RD120_2PAGE_8mounths.png",
      "firstBackgroundColor": "#FF4641EC",
      "secondBackgroundColor": "#FF40CDF5",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатная рассрочка на 8 месяцев",
          "text": "Оформите кредитку, оплатите ей покупки от 1000 до 150\u00A0000 рублей и разложите любую из них в бесплатную рассрочку на 8 месяцев.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней после первого оформления договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 7,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download/efe54d28-11ff-41da-a55a-a4dc056b07a8/link",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 8,
          "type": "link_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Present.png",
              "text": "Акция: бесплатная рассрочка на покупку на 8 месяцев",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Present.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 822,
      "templateId": 1271,
      "image": "https://sales-father-images-nginx.homebank.ru/images/clcarbackspring2.png",
      "firstBackgroundColor": "#FF244db7",
      "secondBackgroundColor": "#FFbc7aff",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Увеличенная сумма до 5 000 000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 2,
              "text": "Сниженная ставка при подключении Программы «Гарантия низкой ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 3,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 4,
              "text": "15 минут для оформления кредита"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Получите деньги прямо сейчас",
          "text": "Заполните онлайн-заявку и узнайте ваши персональные условия — это займёт пару минут.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит под залог автомобиля",
          "subtitle": null,
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 842,
      "templateId": 1272,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 843,
      "templateId": 1277,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 844,
      "templateId": 1282,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 845,
      "templateId": 1287,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 846,
      "templateId": 1273,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2-2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 847,
      "templateId": 1274,
      "image": "https://sales-father-images-nginx.homebank.ru/images/clgh1.png",
      "firstBackgroundColor": "#FF7955DF",
      "secondBackgroundColor": "#FF4449D7",
      "displayScreens": [
        {
          "id": 22
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 848,
      "templateId": 1275,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2-2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 29
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 849,
      "templateId": 1276,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 850,
      "templateId": 1278,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2-2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит одобрен",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 851,
      "templateId": 1280,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 29
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 852,
      "templateId": 1281,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 853,
      "templateId": 1283,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2-2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 854,
      "templateId": 1284,
      "image": "https://sales-father-images-nginx.homebank.ru/images/clgh1.png",
      "firstBackgroundColor": "#FF7955DF",
      "secondBackgroundColor": "#FF4449D7",
      "displayScreens": [
        {
          "id": 22
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 855,
      "templateId": 1285,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 29
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 856,
      "templateId": 1286,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 857,
      "templateId": 1288,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 858,
      "templateId": 1290,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 29
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 859,
      "templateId": 1291,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж {monthPayment} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма {limit} ₽ - уже одобрили"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 862,
      "templateId": 1292,
      "image": "https://sales-father-images-nginx.homebank.ru/images/RD120_2PAGE_8mounths.png",
      "firstBackgroundColor": "#FF4641EC",
      "secondBackgroundColor": "#FF40CDF5",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатная рассрочка на 8 месяцев",
          "text": "Оформите кредитку, оплатите ей покупки от 1000 до 150\u00A0000 рублей и разложите любую из них в бесплатную рассрочку на 8 месяцев.",
          "priority": 1,
          "type": "html_text"
        },
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней после первого оформления договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 5,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download/efe54d28-11ff-41da-a55a-a4dc056b07a8/link",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без % ",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит {limit} рублей",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Present.png",
              "text": "Акция: бесплатная рассрочка на покупку на 8 месяцев",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Present.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 863,
      "templateId": 1293,
      "image": "https://sales-father-images-nginx.homebank.ru/images/RD120_2PAGE_8mounths.png",
      "firstBackgroundColor": "#FF4641EC",
      "secondBackgroundColor": "#FF40CDF5",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Бесплатная рассрочка на 8 месяцев",
          "text": "Оформите кредитку, оплатите ей покупки от 1000 до 150 000 рублей и разложите любую из них в бесплатную рассрочку на 8 месяцев.",
          "priority": 1,
          "type": "html_text"
        },
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней после первого оформления договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 6,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download/efe54d28-11ff-41da-a55a-a4dc056b07a8/link",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 7,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Present.png",
              "text": "Акция: бесплатная рассрочка на покупку на 8 месяцев",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Present.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 882,
      "templateId": 1294,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 883,
      "templateId": 1295,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 884,
      "templateId": 1296,
      "image": "https://sales-father-images-nginx.homebank.ru/images/clgh1.png",
      "firstBackgroundColor": "#FF7955DF",
      "secondBackgroundColor": "#FF4449D7",
      "displayScreens": [
        {
          "id": 22
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 885,
      "templateId": 1297,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 29
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 886,
      "templateId": 1298,
      "image": "https://sales-father-images-nginx.homebank.ru/images/2ndcash12.09.23.2.png",
      "firstBackgroundColor": "#FFCF0929",
      "secondBackgroundColor": "#FFFC3B5D",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Наши лучшие условия для вас",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 1,
              "text": "Ежемесячный платёж от 1000 ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 2,
              "text": "Сумма до {limit} ₽"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/percent_icon.png",
              "priority": 3,
              "text": "Сниженная ставка при подключении «Гарантии оптимальной ставки»"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 4,
              "text": "Срок до 84 месяцев"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 5,
              "text": "Подтвердите доход онлайн за пару кликов, и мы снизим ставку по кредиту на 3%"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Заполните заявку онлайн",
          "text": "Быстрое решение, перечислим деньги на карту за 10 минут.\r\n<p></p>",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Сниженная ставка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 902,
      "templateId": 1940,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 1,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 5,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 903,
      "templateId": 1941,
      "image": "https://sales-father-images-nginx.homebank.ru/images/ccbacks2.png",
      "firstBackgroundColor": "#FF9F43FB",
      "secondBackgroundColor": "#FF4CBCD9",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Бесплатное обслуживание навсегда",
          "text": "Ваша карта будет с вами без каких-либо комиссий и платежей за открытие и обслуживание.",
          "priority": 1,
          "type": "html_text"
        },
        {
          "title": "Акция: 1000 рублей баллами Пользы",
          "text": "Оформите карту сейчас, сделайте покупки с помощью реквизитов карты онлайн или через Mir Pay в течение 30 дней и получите 1000 баллов Пользы, 1 балл = 1 рубль. Баллы в рамках этой акции могут быть начислены только один раз.",
          "priority": 2,
          "type": "html_text"
        },
        {
          "title": "Бесплатные переводы и снятия наличных",
          "text": "В течение 30 дней с даты подписания договора вы сможете бесплатно переводить и снимать деньги с карты.",
          "priority": 3,
          "type": "html_text"
        },
        {
          "title": "Беспроцентный период до 123 дней",
          "text": "После первой покупки начнётся беспроцентный период до 123 дней. В это время можно продолжить покупки, параллельно погашая текущую задолженность минимальными и другими платежами. Беспроцентный период возобновляется после того, как вы полностью погасили текущую задолженность и сделаете новую покупку. Если задолженность не будет погашена в течение беспроцентного периода, на неё будут начислены проценты согласно договору.",
          "priority": 4,
          "type": "html_text"
        },
        {
          "title": "Подробные условия",
          "content": [
            {
              "priority": 1,
              "text": "Подробные условия",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=37779",
              "linkType": "pdf"
            },
            {
              "priority": 2,
              "text": "Выписка из тарифов по карте",
              "icon": "https://sales-father-images-nginx.homebank.ru/images/commission_receipt.png",
              "link": "https://home.bank/download.php?id=34747?utm_source=120days",
              "linkType": "pdf"
            }
          ],
          "priority": 5,
          "type": "link_text"
        },
        {
          "title": "120 дней без %",
          "subtitle": "Бесплатная кредитная карта",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатное обслуживание навсегда",
              "priority": 1
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-ByCash.png",
              "text": "Лимит до {limit} рублей",
              "priority": 2
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-Cashback.png",
              "text": "Акция: 1000 рублей баллами Пользы",
              "priority": 3
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/bnft-0RUB.png",
              "text": "Бесплатные переводы и снятия наличных",
              "priority": 4
            }
          ],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 922,
      "templateId": 1299,
      "image": "https://sales-father-images-nginx.homebank.ru/images/SKBwidget2.png",
      "firstBackgroundColor": "#FF3357B6",
      "secondBackgroundColor": "#FF368FE2",
      "displayScreens": [
        {
          "id": 0
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Условия кредита",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма — {limit} ₽ "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 2,
              "text": "Срок — до 5 лет"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "Решение по кредиту — от 5 минут"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Удобное погашение онлайн"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Выгодные ставки от Совкомбанка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": "#FFFFFF",
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 931,
      "templateId": 1300,
      "image": "https://sales-father-images-nginx.homebank.ru/images/SKBwidget2.png",
      "firstBackgroundColor": "#FF3357B6",
      "secondBackgroundColor": "#FF368FE2",
      "displayScreens": [
        {
          "id": 11
        },
        {
          "id": 21
        }
      ],
      "blocks": [
        {
          "title": "Условия кредита",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма — {limit} ₽ "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 2,
              "text": "Срок — до 5 лет"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "Решение по кредиту — от 5 минут"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Удобное погашение онлайн"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Выгодные ставки от Совкомбанка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 932,
      "templateId": 1301,
      "image": "https://sales-father-images-nginx.homebank.ru/images/SKBwidget2.png",
      "firstBackgroundColor": "#FF3357B6",
      "secondBackgroundColor": "#FF368FE2",
      "displayScreens": [
        {
          "id": 18
        }
      ],
      "blocks": [
        {
          "title": "Условия кредита",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма — {limit} ₽ "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 2,
              "text": "Срок — до 5 лет"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "Решение по кредиту — от 5 минут"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Удобное погашение онлайн"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Выгодные ставки от Совкомбанка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 933,
      "templateId": 1303,
      "image": "https://sales-father-images-nginx.homebank.ru/images/SKBwidget2.png",
      "firstBackgroundColor": "#FF3357B6",
      "secondBackgroundColor": "#FF368FE2",
      "displayScreens": [
        {
          "id": 19
        }
      ],
      "blocks": [
        {
          "title": "Условия кредита",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма — {limit} ₽ "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 2,
              "text": "Срок — до 5 лет"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "Решение по кредиту — от 5 минут"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Удобное погашение онлайн"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Выгодные ставки от Совкомбанка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 934,
      "templateId": 1304,
      "image": "https://sales-father-images-nginx.homebank.ru/images/SKBwidget2.png",
      "firstBackgroundColor": "#FF3357B6",
      "secondBackgroundColor": "#FF368FE2",
      "displayScreens": [
        {
          "id": 31
        }
      ],
      "blocks": [
        {
          "title": "Условия кредита",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма — {limit} ₽ "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 2,
              "text": "Срок — до 5 лет"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "Решение по кредиту — от 5 минут"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Удобное погашение онлайн"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Выгодные ставки от Совкомбанка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 935,
      "templateId": 1305,
      "image": "https://sales-father-images-nginx.homebank.ru/images/SKBwidget2.png",
      "firstBackgroundColor": "#FF3357B6",
      "secondBackgroundColor": "#FF368FE2",
      "displayScreens": [
        {
          "id": 29
        }
      ],
      "blocks": [
        {
          "title": "Условия кредита",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма — {limit} ₽ "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 2,
              "text": "Срок — до 5 лет"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "Решение по кредиту — от 5 минут"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Удобное погашение онлайн"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Выгодные ставки от Совкомбанка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 936,
      "templateId": 1306,
      "image": "https://sales-father-images-nginx.homebank.ru/images/SKBwidget2.png",
      "firstBackgroundColor": "#FF3357B6",
      "secondBackgroundColor": "#FF368FE2",
      "displayScreens": [
        {
          "id": 13
        }
      ],
      "blocks": [
        {
          "title": "Условия кредита",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма — {limit} ₽ "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 2,
              "text": "Срок — до 5 лет"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "Решение по кредиту — от 5 минут"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Удобное погашение онлайн"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Выгодные ставки от Совкомбанка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    },
    {
      "id": 937,
      "templateId": 1307,
      "image": "https://sales-father-images-nginx.homebank.ru/images/SKBwidget2.png",
      "firstBackgroundColor": "#FF3357B6",
      "secondBackgroundColor": "#FF368FE2",
      "displayScreens": [
        {
          "id": 1
        },
        {
          "id": 2
        },
        {
          "id": 3
        },
        {
          "id": 4
        },
        {
          "id": 5
        },
        {
          "id": 6
        },
        {
          "id": 7
        },
        {
          "id": 8
        },
        {
          "id": 9
        },
        {
          "id": 10
        },
        {
          "id": 23
        },
        {
          "id": 24
        },
        {
          "id": 25
        },
        {
          "id": 26
        },
        {
          "id": 27
        }
      ],
      "blocks": [
        {
          "title": "Условия кредита",
          "text": null,
          "content": [
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/coin_icon.png",
              "priority": 1,
              "text": "Сумма — {limit} ₽ "
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/calendare_icon.png",
              "priority": 2,
              "text": "Срок — до 5 лет"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/time_icon.png",
              "priority": 3,
              "text": "Решение по кредиту — от 5 минут"
            },
            {
              "icon": "https://sales-father-images-nginx.homebank.ru/images/Money.png",
              "priority": 4,
              "text": "Удобное погашение онлайн"
            }
          ],
          "priority": 1,
          "type": "icon_list"
        },
        {
          "title": "Кредит наличными",
          "subtitle": "Выгодные ставки от Совкомбанка",
          "textColor": null,
          "type": "header_h1"
        },
        {
          "textColor": null,
          "content": [],
          "type": "main_advantages"
        },
        {
          "text": "Продолжить",
          "type": "button"
        }
      ],
      "contactInfo": null
    }
  ]
}
