{
  "$id": "1",
  "Result": {
    "Contracts": [
      {
        "$id": "3",
        "ContractId": "77715054959",
        "Status": 4,
        "PartnerInfo": {
          "$id": "4",
          "PartnerName": "Wildberries",
          "LogoUrl": "https://223104.selcdn.ru/mk_prod/partners/wildberries.png"
        },
        "InterestRate": 45.0,
        "Amount": 8000.0,
        "ProductName": "Весенний_WB",
        "ContractType": "brainySoftContracts",
        "ProductType": 15
      }
    ],
    "CreditLines": [
      {
        "$id": "4",
        "ContractId": "77715054950",
        "Status": "4",
        "PartnerInfo": {
          "$id": "5",
          "PartnerName": "Wildberries",
          "LogoUrl": "https://223104.selcdn.ru/mk_prod/partners/wildberries.png"
        },
        "Limit": 100000,
        "productName": "Кредитная линия",
        "contractType": "brainySoftContracts",
        "productType": 15
      }
    ],
    "ServerDate": "2021-03-15T00:00:00+03:00"
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
