import { useAppContext } from '@/core'
import { EventPaymentSuccessModalModel } from '@/event-bus/events/PaymentSuccessModal'
import { AppContext } from '@/models/AppContext'
import { Button } from '@platform-ui/components/Button'
import React from 'react'
import { ButtonList, Title, TitleContainer } from './styled'

export const PaymentSuccessModal = (): JSX.Element => {
  const context = useAppContext<AppContext>()

  return (
    <>
      <TitleContainer>
        <Title>Payment Success Modal</Title>
      </TitleContainer>
      <ButtonList>
        <Button
          size="m"
          onClick={() =>
            context.eventBus.paymentSuccessModal.open.emit({
              amount: 500,
              currency: 'RUB',
              from: {
                transferType: EventPaymentSuccessModalModel.FromTransferType.Card,
                cardNumber: '123123123123213',
                accountNumber: '123',
                id: '123',
                name: 'Название',
              },
              id: '123',
              to: {
                transferType: EventPaymentSuccessModalModel.ToTransferType.Card,
                accountNumber: '123',
                cardNumber: '1231231231232112',
                icon: '',
                name: 'Название',
              },
            })
          }
        >
          С карты на карту
        </Button>
        <Button
          size="m"
          onClick={() =>
            context.eventBus.paymentSuccessModal.open.emit({
              amount: 500,
              currency: 'RUB',
              from: {
                transferType: EventPaymentSuccessModalModel.FromTransferType.Account,
                accountNumber: '123',
                id: '123',
                name: 'Название',
              },
              id: '123',
              to: {
                transferType: EventPaymentSuccessModalModel.ToTransferType.Requisites,
                accountNumber: '123',
                bankIcon: '',
                bankName: 'Сбербанк',
                bik: '12412234',
                userName: 'Валера',
              },
            })
          }
        >
          Оплата ЖКХ
        </Button>
      </ButtonList>
    </>
  )
}
