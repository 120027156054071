{
  "resultCode": "Mismatch",
  "resultText": "СМС-код введен неверно. Попробуйте еще раз",
  "verifyNum": 3,
  "resendNum": 0,
  "links": [
    {
      "rel": "check",
      "href": "{apiUrl}/visa-phone-transfer/v1/{uid}/check"
    }
  ]
}
