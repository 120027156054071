import { Player } from '@lottiefiles/react-lottie-player'
import React from 'react'
import { Title, TitleContainer } from './styled'

export const LottiePlayer = (): JSX.Element => {
  return (
    <>
      <TitleContainer>
        <Title>Lottie player</Title>
      </TitleContainer>
      <Player
        autoplay={true}
        loop={true}
        controls={false}
        src="https://assets1.lottiefiles.com/packages/lf20_uzamaojr.json"
        style={{ height: '300px', width: '300px' }}
      ></Player>
    </>
  )
}
