import { createMocks } from '@/features/developer/utils/createMocks'
import fileId1 from './success.json'
import successCreditLoanCreditCardArrested from './success-creditLoan-creditCardArrested.json'
import successCreditLoanCreditCardTW from './success-creditLoan-creditCardTW.json'
import successCreditCardTW from './success-creditCardTW.json'
import errorCreditLoanOtherEmpty from './error-creditLoan-empty.json'
import resolveEmpty from './resolve-empty.json'
import successCreditLoan from './success-creditLoan.json'
import successVKPay from './success-VKPay.json'
import successHomeCard from './success-homeCard.json'

export const mockItems = createMocks([
  {
    title: 'Кредитная карта, Кредит наличными, Карта рассрочки',
    status: 200,
    data: fileId1,
    description: '',
  },
  {
    title: 'Кредитная карта (Арестована), Кредит наличными',
    status: 200,
    data: successCreditLoanCreditCardArrested,
    description: '',
  },
  {
    title: 'Хоум карта',
    status: 200,
    data: successHomeCard,
    description: '',
  },
  {
    title: 'VkPay',
    status: 200,
    data: successVKPay,
    description: '',
  },
  {
    title: 'Ошибка в кредитах наличными, остальных нет',
    status: 200,
    data: errorCreditLoanOtherEmpty,
    description: '',
  },
  {
    title: 'Карта рассрочки, Кредит наличными',
    status: 200,
    data: successCreditLoanCreditCardTW,
    description: '',
  },
  {
    title: 'Кредит наличными',
    status: 200,
    data: successCreditLoan,
    description: '',
  },
  {
    title: 'Пустой ответ без продуктов и ошибок',
    status: 200,
    data: resolveEmpty,
    description: '',
  },
  {
    title: 'Карта рассрочки',
    status: 200,
    data: successCreditCardTW,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
