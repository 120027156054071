import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Button } from '@platform-ui/components/Button'
import React from 'react'
import { ButtonList, Title, TitleContainer } from './styled'

export const Toasts = (): JSX.Element => {
  const context = useAppContext<AppContext>()

  return (
    <>
      <TitleContainer>
        <Title>Toasts</Title>
      </TitleContainer>
      <ButtonList>
        <Button
          size="m"
          onClick={() =>
            context.toastify.default({
              title: 'Заголовок уведомления',
              description: 'Подробное описание уведомления с типом default',
            })
          }
        >
          Default
        </Button>
        <Button
          size="m"
          onClick={() =>
            context.toastify.info({
              title: 'Заголовок уведомления',
              description: 'Подробное описание уведомления с типом info',
            })
          }
        >
          Info
        </Button>
        <Button
          size="m"
          onClick={() =>
            context.toastify.error({
              title: 'Заголовок уведомления',
              description: 'Подробное описание уведомления с типом error',
            })
          }
        >
          Error
        </Button>
        <Button
          size="m"
          onClick={() =>
            context.toastify.success({
              title: 'Заголовок уведомления',
              description: 'Подробное описание уведомления с типом success',
            })
          }
        >
          Success
        </Button>
        <Button
          size="m"
          onClick={() =>
            context.toastify.warning({
              title: 'Заголовок уведомления',
              description: 'Подробное описание уведомления с типом warning',
            })
          }
        >
          Warning
        </Button>
      </ButtonList>
    </>
  )
}
