import { createMocks } from '@/features/developer/utils/createMocks'
import successMatchSuccess from './matchSuccess.json'
import successMatchInProgress from './matchInProgress.json'
import mismatch from './mismatch.json'
import blocked from './blocked.json'
import limit from './limit.json'
import limitCanceled from './limitCanceled.json'
import mismatchVerifyNum0 from './mismatchVerifyNum0.json'
import resultError from './resultError.json'
import codeNotFound from './codeNotFound.json'
import rejectedError from './rejectedError.json'

export const mockItems = createMocks([
  {
    title: 'Успешный ответ Match + Success',
    status: 200,
    data: successMatchSuccess,
    description: '',
  },
  {
    title: 'Успешный ответ Match + InProgress',
    status: 200,
    data: successMatchInProgress,
    description: '',
  },
  {
    title: 'СМС введен не верно, код: mismatch',
    status: 200,
    data: mismatch,
    description: '',
  },
  {
    title: 'СМС введен не верно, попытки закончились, код: mismatch',
    status: 200,
    data: mismatchVerifyNum0,
    description: '',
  },
  {
    title: 'Код: blocked',
    status: 403,
    data: blocked,
    description: '',
  },
  {
    title: 'Код: limit',
    status: 200,
    data: limit,
    description: '',
  },
  {
    title: 'Код: limit, Статус: Canceled',
    status: 200,
    data: limitCanceled,
    description: '',
  },
  {
    title: 'Код: error',
    status: 200,
    data: resultError,
    description: '',
  },
  {
    title: 'Неизвестный код',
    status: 200,
    data: codeNotFound,
    description: '',
  },
  {
    title: 'Неизвестная ошибка взаимодействия',
    status: 500,
    data: rejectedError,
    description: '',
  },
  {
    title: 'Код 401',
    status: 401,
    data: null,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
