{
  "Result": {
    "IsOtpValid": true,
    "PaymentResult": {
      "Amount": 10,
      "PaymentDate": "2024-05-13T13:07:44.9121087",
      "CurrencyCode": "RUR",
      "Status": 41,
      "BeneficiaryCode": "7602",
      "TransactionId": "4308",
      "DescriptionStatus": "Платёж принят",
      "FromAccount": "40817810590011812700",
      "CategoryId": 3,
      "FieldValues": {
        "amount": "10",
        "cheque_param_0": "111111",
        "cheque_param_4": "10",
        "cheque_param_2": "0.00",
        "cheque_param_3": "0.00",
        "cheque_param_1": "Кошачья ул. д.13 кв.2"
      }
    }
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
