{
  "$id": "1",
  "result": {
    "productFrom": [
      {
        "maskCardNumber": "4469 15** **** 9498",
        "accountNumber": "40817810790012545944",
        "type": 1,
        "availableBalance": 1913.36,
        "currency": "RUR"
      },
      {
        "maskCardNumber": "2200 50** **** 8244",
        "accountNumber": "40817810690012553780",
        "type": 1,
        "availableBalance": 672.0,
        "currency": "RUR"
      },
      {
        "maskCardNumber": "4062 96** **** 5152",
        "accountNumber": "40817810393640037632",
        "type": 2,
        "availableBalance": 11602.17,
        "currency": "RUR"
      },
      {
        "maskCardNumber": "4062 96** **** 2519",
        "accountNumber": "40817810293640037910",
        "type": 2,
        "availableBalance": 39989.67,
        "currency": "RUR"
      }
    ],
    "productTo": null,
    "links": [
      {
        "rel": "commission",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/card/transfer/fa77eb6b-dd40-4bac-9096-7210127977d4/commission"
      },
      {
        "rel": "type",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/card/transfer/fa77eb6b-dd40-4bac-9096-7210127977d4/type"
      },
      {
        "rel": "confirm",
        "href": "https://balancer-gateway-rp.omni.homecredit.ru:8112/transfer/v1/card/transfer/fa77eb6b-dd40-4bac-9096-7210127977d4/confirm"
      }
    ],
    "resultCode": 0,
    "resultText": null
  },
  "statusCode": 200,
  "errors": [],
  "errorDetails": [],
  "containsAnyError": false,
  "containsAnyWarning": false,
  "containsAnyInfo": false
}
