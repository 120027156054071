{
  "$id": "1",
  "Result": {
    "$id": "2",
    "IsFinalStep": true,
    "ProcessPaymentType": 0,
    "Name": "БашИнформсвязь",
    "Icon": "bashinform",
    "FeeInfo": "",
    "Fields": [
      {
        "$id": "3",
        "Name": "Способ оплаты",
        "FieldKey": "account",
        "Type": "SupportValues",
        "InputRules": {
          "$id": "4",
          "AllowedValue": [
            {
              "$id": "5",
              "Value": "1",
              "DisplayName": "По номеру телефона"
            },
            {
              "$id": "6",
              "Value": "2",
              "DisplayName": "По номеру счета"
            }
          ]
        },
        "InputMethods": "ListBox",
        "IsRequired": true,
        "IsHidden": false,
        "IsReadOnly": false,
        "Hint": "",
        "IsRefreshRequired": false
      },
      {
        "$id": "7",
        "Name": "Номер телефона",
        "FieldKey": "a1",
        "Type": "String",
        "InputRules": {
          "$id": "8",
          "Mask": "8([000])[000]-[00]-[00]",
          "Regexp": ""
        },
        "InputMethods": "Keypad",
        "IsRequired": false,
        "IsHidden": false,
        "IsReadOnly": false,
        "Hint": "",
        "IsRefreshRequired": false
      },
      {
        "$id": "9",
        "Name": "Номер счета",
        "FieldKey": "a2",
        "Type": "String",
        "InputRules": {
          "$id": "10",
          "Mask": "",
          "Regexp": "^\\d{3,15}$"
        },
        "InputMethods": "Keypad",
        "IsRequired": false,
        "IsHidden": true,
        "IsReadOnly": true,
        "Hint": "От 3 до 15 цифр",
        "IsRefreshRequired": false
      },
      {
        "$id": "11",
        "Name": "Сумма к оплате, руб.",
        "FieldKey": "amount",
        "Type": "amount",
        "InputRules": {
          "$id": "12",
          "MinSumValue": 1.0,
          "MaxSumValue": 15000.0,
          "Currency": "RUR"
        },
        "InputMethods": "Keypad",
        "IsRequired": true,
        "IsHidden": false,
        "IsReadOnly": false,
        "Hint": "От 1.00 ₽ до 15000.00 ₽",
        "IsRefreshRequired": false
      }
    ]
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
