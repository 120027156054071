{
  "$id": "1",
  "Result": [
    {
      "Amount": 6653.66,
      "Date": "2021-12-17T00:00:00+03:00"
    },
    {
      "Amount": 6701.84,
      "Date": "2022-10-19T00:00:00+03:00"
    },
    {
      "Amount": 6677.75,
      "Date": "2022-10-14T00:00:00+03:00"
    },
    {
      "Amount": 6677.75,
      "Date": "2021-10-08T00:00:00+03:00"
    },
    {
      "Amount": 6701.84,
      "Date": "2021-09-09T00:00:00+03:00"
    },
    {
      "Amount": 6653.66,
      "Date": "2021-12-17T00:00:00+03:00"
    },
    {
      "Amount": 6701.0,
      "Date": "2022-10-19T00:00:00+03:00"
    },
    {
      "Amount": 6677.75,
      "Date": "2022-10-14T00:00:00+03:00"
    },
    {
      "Amount": 6677.75,
      "Date": "2021-10-08T00:00:00+03:00"
    },
    {
      "Amount": 6701.84,
      "Date": "2021-09-09T00:00:00+03:00"
    }
  ],
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
