{
  "$id": "1",
  "StatusCode": 500,
  "Errors": ["Request failed with status code 500"],
  "ErrorDetails": [
    {
      "Title": "Ошибка сервера",
      "Error": "Не удаётся получить данные",
      "ErrorType": 1
    }
  ],
  "ContainsAnyError": false,
  "ContainsAnyWarning": false,
  "ContainsAnyInfo": false
}
