{
  "Result": {
    "MaskCardNumber": "446915XXXXXX9742",
    "IconUrls": [
      "https://polza.homecredit.ru/content/img/edit/actions/CPA_logo/Skillbox_logo.png",
      "https://polza.homecredit.ru/content/img/edit/actions/CPA_logo/smotri.png",
      "https://polza.homecredit.ru/Content/img/Edit/actions/CPA_logo/productstar.jpeg"
    ],
    "Title": "Кэшбэк и бонусы",
    "Subtitle": "Выберите категории кэшбэка на январь",
    "IsNotificationLabelShown": true
  }
}
