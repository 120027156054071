{
  "contractNumber": "2393398867",
  "nextPaymentDate": "2023-06-05T00:00:00",
  "contractBillingDate": "2023-06-09T00:00:00",
  "debtAmount": 0,
  "debtDays": 0,
  "visualStyle": "NoDebt",
  "daysForPaymentLeft": -188,
  "debtDaysWordAgreement": "Like5",
  "daysLeftWordAgreement": "Like5",
  "paymentsDetails": [],
  "isExpiredData": false
}
