{
  "$id": "1",
  "Result": [
    {
      "$id": "2",
      "BeneficiaryID": 7550,
      "BeneficiaryName": "АВК ВЕЛЛКОМ",
      "BeneficiaryIcon": "avkwellcome_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 1
    },
    {
      "$id": "3",
      "BeneficiaryID": 125,
      "BeneficiaryName": "Аист",
      "BeneficiaryIcon": "aist_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "4",
      "BeneficiaryID": 6580,
      "BeneficiaryName": "Айконет - оплата Интернета",
      "BeneficiaryIcon": "5046_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "5",
      "BeneficiaryID": 127,
      "BeneficiaryName": "АйТи Сервис",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "6",
      "BeneficiaryID": 6243,
      "BeneficiaryName": "АКАДО - Москва - оплата Интернета",
      "BeneficiaryIcon": "410_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "7",
      "BeneficiaryID": 6123,
      "BeneficiaryName": "АКАДО - Москва - оплата телефонной связи",
      "BeneficiaryIcon": "410_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "8",
      "BeneficiaryID": 6244,
      "BeneficiaryName": "АКАДО - Москва – оплата ТВ",
      "BeneficiaryIcon": "410_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "9",
      "BeneficiaryID": 6617,
      "BeneficiaryName": "АКАДО-Екатеринбург - оплата Интернета, ТВ и телефонной связи",
      "BeneficiaryIcon": "5099_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "10",
      "BeneficiaryID": 6079,
      "BeneficiaryName": "Алло Инкогнито - пополнение счета",
      "BeneficiaryIcon": "168_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "11",
      "BeneficiaryID": 843,
      "BeneficiaryName": "Альбеон",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "12",
      "BeneficiaryID": 132,
      "BeneficiaryName": "Альтегро",
      "BeneficiaryIcon": "altegro_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "13",
      "BeneficiaryID": 190,
      "BeneficiaryName": "Альянстелеком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "14",
      "BeneficiaryID": 6196,
      "BeneficiaryName": "Анет",
      "BeneficiaryIcon": "595_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "15",
      "BeneficiaryID": 134,
      "BeneficiaryName": "АрменТел(фиксированная связь)",
      "BeneficiaryIcon": "armentel_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "16",
      "BeneficiaryID": 6621,
      "BeneficiaryName": "АСТРА-ОРЕОЛ – оплата доступа в интернет",
      "BeneficiaryIcon": "5116_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "17",
      "BeneficiaryID": 189,
      "BeneficiaryName": "АТК - Архангельская телевизионная компания",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "18",
      "BeneficiaryID": 6145,
      "BeneficiaryName": "баZа Офис – оплата услуг",
      "BeneficiaryIcon": "baza_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "19",
      "BeneficiaryID": 313,
      "BeneficiaryName": "Балнет",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "20",
      "BeneficiaryID": 7571,
      "BeneficiaryName": "БашИнформсвязь",
      "BeneficiaryIcon": "bashinform_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "21",
      "BeneficiaryID": 6152,
      "BeneficiaryName": "Билайн WiFi",
      "BeneficiaryIcon": "502_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "22",
      "BeneficiaryID": 6119,
      "BeneficiaryName": "Билайн Домашний телефон – пополнение л/счета",
      "BeneficiaryIcon": "101_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "23",
      "BeneficiaryID": 6157,
      "BeneficiaryName": "Билайн Интернет и ТВ",
      "BeneficiaryIcon": "101_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "24",
      "BeneficiaryID": 6245,
      "BeneficiaryName": "Билайн ТВ - пополнение лицевого счета",
      "BeneficiaryIcon": "101_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "25",
      "BeneficiaryID": 7563,
      "BeneficiaryName": "Брянские кабельные сети",
      "BeneficiaryIcon": "bks_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "26",
      "BeneficiaryID": 143,
      "BeneficiaryName": "Вайфайн",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "27",
      "BeneficiaryID": 144,
      "BeneficiaryName": "Вест Колл (СПб)",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "28",
      "BeneficiaryID": 146,
      "BeneficiaryName": "Владивосток Телеком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "29",
      "BeneficiaryID": 147,
      "BeneficiaryName": "Владинфо",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "30",
      "BeneficiaryID": 368,
      "BeneficiaryName": "Востоктелеком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "31",
      "BeneficiaryID": 6788,
      "BeneficiaryName": "Восточный экспресс – оплата услуг",
      "BeneficiaryIcon": "5602_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "32",
      "BeneficiaryID": 187,
      "BeneficiaryName": "Вэб Плас",
      "BeneficiaryIcon": "webplast_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "33",
      "BeneficiaryID": 737,
      "BeneficiaryName": "ВЭЛЛКОМ",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "34",
      "BeneficiaryID": 7528,
      "BeneficiaryName": "Гала-ТВ",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "35",
      "BeneficiaryID": 479,
      "BeneficiaryName": "Гарс Телеком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "36",
      "BeneficiaryID": 6130,
      "BeneficiaryName": "Глотес",
      "BeneficiaryIcon": "428_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "37",
      "BeneficiaryID": 186,
      "BeneficiaryName": "Голден Телеком Екатеринбург",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "38",
      "BeneficiaryID": 6670,
      "BeneficiaryName": "Горком",
      "BeneficiaryIcon": "5177_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "39",
      "BeneficiaryID": 6671,
      "BeneficiaryName": "Горком регион",
      "BeneficiaryIcon": "5177_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "40",
      "BeneficiaryID": 6785,
      "BeneficiaryName": "Город-ТВ",
      "BeneficiaryIcon": "5598_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "41",
      "BeneficiaryID": 766,
      "BeneficiaryName": "ГТК",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "42",
      "BeneficiaryID": 6184,
      "BeneficiaryName": "ГТС",
      "BeneficiaryIcon": "573_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "43",
      "BeneficiaryID": 406,
      "BeneficiaryName": "Данцер",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "44",
      "BeneficiaryID": 6549,
      "BeneficiaryName": "ДОМ.RU Домашний телефон",
      "BeneficiaryIcon": "840_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "45",
      "BeneficiaryID": 6782,
      "BeneficiaryName": "ДОМ.RU Интернет",
      "BeneficiaryIcon": "domru_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "46",
      "BeneficiaryID": 6260,
      "BeneficiaryName": "ДОМ.RU Кабельное ТВ",
      "BeneficiaryIcon": "840_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "47",
      "BeneficiaryID": 6783,
      "BeneficiaryName": "ДОМ.RU Комплексное предложение - оплата Интернета, ТВ и телефонной связи",
      "BeneficiaryIcon": "78034_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "48",
      "BeneficiaryID": 6262,
      "BeneficiaryName": "ДОМ.RU Цифровое кабельное ТВ",
      "BeneficiaryIcon": "840_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "49",
      "BeneficiaryID": 6637,
      "BeneficiaryName": "Домолан – оплата доступа в интернет",
      "BeneficiaryIcon": "5136_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "50",
      "BeneficiaryID": 762,
      "BeneficiaryName": "ЗАО \"АМТ\"",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "51",
      "BeneficiaryID": 156,
      "BeneficiaryName": "Зебра Телеком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "52",
      "BeneficiaryID": 6780,
      "BeneficiaryName": "Зеленая точка г. Элиста",
      "BeneficiaryIcon": "5324_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "53",
      "BeneficiaryID": 6194,
      "BeneficiaryName": "Зеленая точка г.Ставрополь",
      "BeneficiaryIcon": "591_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "54",
      "BeneficiaryID": 6565,
      "BeneficiaryName": "Зеленая точка г.Тамбов",
      "BeneficiaryIcon": "5024_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "55",
      "BeneficiaryID": 7595,
      "BeneficiaryName": "Зеленая точка г.Томск",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "56",
      "BeneficiaryID": 233,
      "BeneficiaryName": "Золотая линия",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "57",
      "BeneficiaryID": 730,
      "BeneficiaryName": "Игра-Сервис",
      "BeneficiaryIcon": "igraservice_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "58",
      "BeneficiaryID": 1558,
      "BeneficiaryName": "Инет Ком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "59",
      "BeneficiaryID": 6646,
      "BeneficiaryName": "Инкомсвязь – оплата Интернет и ТВ",
      "BeneficiaryIcon": "5147_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "60",
      "BeneficiaryID": 6713,
      "BeneficiaryName": "Инсис-Екатеринбург",
      "BeneficiaryIcon": "insis_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "61",
      "BeneficiaryID": 824,
      "BeneficiaryName": "Интерсвязь",
      "BeneficiaryIcon": "inter_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "62",
      "BeneficiaryID": 6791,
      "BeneficiaryName": "Инфоком – оплата услуг ТВ и Интернет",
      "BeneficiaryIcon": "infocom_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "63",
      "BeneficiaryID": 215,
      "BeneficiaryName": "Инфолинк",
      "BeneficiaryIcon": "infolink_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "64",
      "BeneficiaryID": 371,
      "BeneficiaryName": "Информсвязь-Черноземье",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "65",
      "BeneficiaryID": 380,
      "BeneficiaryName": "Искрателеком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "66",
      "BeneficiaryID": 7593,
      "BeneficiaryName": "Исток",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "67",
      "BeneficiaryID": 7592,
      "BeneficiaryName": "ИТКМ",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "68",
      "BeneficiaryID": 838,
      "BeneficiaryName": "К-Телеком",
      "BeneficiaryIcon": "k-telecom_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "69",
      "BeneficiaryID": 6198,
      "BeneficiaryName": "Карта 8-800",
      "BeneficiaryIcon": "597_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "70",
      "BeneficiaryID": 6623,
      "BeneficiaryName": "Катрина – оплата ТВ",
      "BeneficiaryIcon": "5118_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "71",
      "BeneficiaryID": 533,
      "BeneficiaryName": "Кварц",
      "BeneficiaryIcon": "kvartz_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "72",
      "BeneficiaryID": 6171,
      "BeneficiaryName": "Коммуникационная компания Марк",
      "BeneficiaryIcon": "547_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "73",
      "BeneficiaryID": 367,
      "BeneficiaryName": "Компания ТТК (Транстелеком)",
      "BeneficiaryIcon": "ttk_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "74",
      "BeneficiaryID": 6792,
      "BeneficiaryName": "Компьютерные коммуникационные системы – оплата доступа в интернет",
      "BeneficiaryIcon": "5611_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "75",
      "BeneficiaryID": 6552,
      "BeneficiaryName": "Компьютерные коммуникационные системы – оплата услуг связи",
      "BeneficiaryIcon": "4611_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "76",
      "BeneficiaryID": 6251,
      "BeneficiaryName": "Континент ТВ – оплата услуг",
      "BeneficiaryIcon": "828_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "77",
      "BeneficiaryID": 6700,
      "BeneficiaryName": "Корвет Телеком",
      "BeneficiaryIcon": "5215_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "78",
      "BeneficiaryID": 6664,
      "BeneficiaryName": "Кузбассвязьуголь",
      "BeneficiaryIcon": "5170_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "79",
      "BeneficiaryID": 6709,
      "BeneficiaryName": "Курорт-Телеком",
      "BeneficiaryIcon": "5227_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "80",
      "BeneficiaryID": 6165,
      "BeneficiaryName": "Курск Online",
      "BeneficiaryIcon": "535_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "81",
      "BeneficiaryID": 484,
      "BeneficiaryName": "Курская телефонная компания",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "82",
      "BeneficiaryID": 6131,
      "BeneficiaryName": "КурскТелеком - оплата IP-телефонии",
      "BeneficiaryIcon": "429_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "83",
      "BeneficiaryID": 6132,
      "BeneficiaryName": "КурскТелеком - оплата городской связи",
      "BeneficiaryIcon": "430_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "84",
      "BeneficiaryID": 6164,
      "BeneficiaryName": "КурскТелеком - оплата Интернета",
      "BeneficiaryIcon": "533_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "85",
      "BeneficiaryID": 6250,
      "BeneficiaryName": "КурскТелеком - оплата ТВ",
      "BeneficiaryIcon": "819_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "86",
      "BeneficiaryID": 619,
      "BeneficiaryName": "Курьер плюс",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "87",
      "BeneficiaryID": 266,
      "BeneficiaryName": "Кэтис",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "88",
      "BeneficiaryID": 290,
      "BeneficiaryName": "Лаборатория информационных технологий",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "89",
      "BeneficiaryID": 6658,
      "BeneficiaryName": "Лайнер – оплата доступа в интернет",
      "BeneficiaryIcon": "5164_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "90",
      "BeneficiaryID": 721,
      "BeneficiaryName": "Лан-Оптик",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "91",
      "BeneficiaryID": 6193,
      "BeneficiaryName": "ЛинкИнтел",
      "BeneficiaryIcon": "589_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "92",
      "BeneficiaryID": 6776,
      "BeneficiaryName": "М9 КОМ",
      "BeneficiaryIcon": "5320_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "93",
      "BeneficiaryID": 297,
      "BeneficiaryName": "Магинфо ТВ",
      "BeneficiaryIcon": "maginfo_tv_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "94",
      "BeneficiaryID": 298,
      "BeneficiaryName": "Магинфоцентр",
      "BeneficiaryIcon": "maginfocenter_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "95",
      "BeneficiaryID": 7589,
      "BeneficiaryName": "Магнитка.ru",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "96",
      "BeneficiaryID": 6648,
      "BeneficiaryName": "Макскомтел – оплата доступа в интернет",
      "BeneficiaryIcon": "5150_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "97",
      "BeneficiaryID": 657,
      "BeneficiaryName": "МАКХОСТ",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "98",
      "BeneficiaryID": 6147,
      "BeneficiaryName": "Манго Телеком",
      "BeneficiaryIcon": "475_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "99",
      "BeneficiaryID": 168,
      "BeneficiaryName": "Марьино NET",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "100",
      "BeneficiaryID": 6116,
      "BeneficiaryName": "МГТС - оплата по квитанции",
      "BeneficiaryIcon": "400_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "101",
      "BeneficiaryID": 7543,
      "BeneficiaryName": "МГТС оплата с автоматическим определением суммы задолженности",
      "BeneficiaryIcon": "400_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "102",
      "BeneficiaryID": 435,
      "BeneficiaryName": "Межгалактическая Компания Связи",
      "BeneficiaryIcon": "mks_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "103",
      "BeneficiaryID": 558,
      "BeneficiaryName": "Моснет (MOSNET)",
      "BeneficiaryIcon": "mosnet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "104",
      "BeneficiaryID": 774,
      "BeneficiaryName": "МСН Телеком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "105",
      "BeneficiaryID": 6181,
      "BeneficiaryName": "МТренд",
      "BeneficiaryIcon": "562_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "106",
      "BeneficiaryID": 6774,
      "BeneficiaryName": "МТС Домашний Интернет, ТВ и Телефония",
      "BeneficiaryIcon": "mts_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "107",
      "BeneficiaryID": 471,
      "BeneficiaryName": "МТС. Домашний интернет и ТВ Воронеж (ООО Телесервис)",
      "BeneficiaryIcon": "mts_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "108",
      "BeneficiaryID": 263,
      "BeneficiaryName": "МТС. Домашний интернет и ТВ. Московская область (МКС Балашихи)",
      "BeneficiaryIcon": "mts_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "109",
      "BeneficiaryID": 6162,
      "BeneficiaryName": "МТТ - оплата доступа в Интернет",
      "BeneficiaryIcon": "300_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "110",
      "BeneficiaryID": 6102,
      "BeneficiaryName": "МТТ - пополнение баланса Абонентского номера",
      "BeneficiaryIcon": "301_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "111",
      "BeneficiaryID": 6101,
      "BeneficiaryName": "МТТ Дальняя связь",
      "BeneficiaryIcon": "300_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "112",
      "BeneficiaryID": 258,
      "BeneficiaryName": "МЭЛТ-ИНТЕРНЕТ",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "113",
      "BeneficiaryID": 81,
      "BeneficiaryName": "Навигатор",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "114",
      "BeneficiaryID": 6667,
      "BeneficiaryName": "Наука Связь – оплата доступа в интернет",
      "BeneficiaryIcon": "5173_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "115",
      "BeneficiaryID": 6597,
      "BeneficiaryName": "Невалинк – оплата доступа в интернет",
      "BeneficiaryIcon": "5066_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "116",
      "BeneficiaryID": 6599,
      "BeneficiaryName": "Невод – оплата доступа в интернет",
      "BeneficiaryIcon": "5068_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "117",
      "BeneficiaryID": 828,
      "BeneficiaryName": "Неторн",
      "BeneficiaryIcon": "netorn_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "118",
      "BeneficiaryID": 240,
      "BeneficiaryName": "НИР-Телеком",
      "BeneficiaryIcon": "nirtelecom_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "119",
      "BeneficiaryID": 462,
      "BeneficiaryName": "НКТВ",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "120",
      "BeneficiaryID": 159,
      "BeneficiaryName": "Новгород Дейтаком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "121",
      "BeneficiaryID": 304,
      "BeneficiaryName": "Новлайн",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "122",
      "BeneficiaryID": 6177,
      "BeneficiaryName": "Новотелеком",
      "BeneficiaryIcon": "556_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "123",
      "BeneficiaryID": 421,
      "BeneficiaryName": "Новые Информационные Технологии",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "124",
      "BeneficiaryID": 210,
      "BeneficiaryName": "Новые Телесистемы",
      "BeneficiaryIcon": "newtelesystems_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "125",
      "BeneficiaryID": 7552,
      "BeneficiaryName": "Новые технологии. Клинцы",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 1
    },
    {
      "$id": "126",
      "BeneficiaryID": 840,
      "BeneficiaryName": "НОРБИ г. Солнечногорск",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "127",
      "BeneficiaryID": 6241,
      "BeneficiaryName": "НТВ Плюс",
      "BeneficiaryIcon": "802_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "128",
      "BeneficiaryID": 6790,
      "BeneficiaryName": "НТС РЕАЛ",
      "BeneficiaryIcon": "5605_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "129",
      "BeneficiaryID": 6669,
      "BeneficiaryName": "О-Связь – оплата услуг",
      "BeneficiaryIcon": "5175_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "130",
      "BeneficiaryID": 6656,
      "BeneficiaryName": "Обит – оплата услуг",
      "BeneficiaryIcon": "5162_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "131",
      "BeneficiaryID": 352,
      "BeneficiaryName": "Облтелеком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "132",
      "BeneficiaryID": 407,
      "BeneficiaryName": "Омские кабельные сети",
      "BeneficiaryIcon": "omskkabseti_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "133",
      "BeneficiaryID": 247,
      "BeneficiaryName": "Орбител",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "134",
      "BeneficiaryID": 724,
      "BeneficiaryName": "Орион телеком",
      "BeneficiaryIcon": "oriontelecom_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "135",
      "BeneficiaryID": 6242,
      "BeneficiaryName": "Орион Экспресс",
      "BeneficiaryIcon": "806_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "136",
      "BeneficiaryID": 7555,
      "BeneficiaryName": "Пермская телефонная компания (Пермь)",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 1
    },
    {
      "$id": "137",
      "BeneficiaryID": 6172,
      "BeneficiaryName": "Петерлинк",
      "BeneficiaryIcon": "550_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "138",
      "BeneficiaryID": 176,
      "BeneficiaryName": "Планета",
      "BeneficiaryIcon": "planetamelkiy_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "139",
      "BeneficiaryID": 594,
      "BeneficiaryName": "Подряд Интернет",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "140",
      "BeneficiaryID": 612,
      "BeneficiaryName": "Подряд ТВ",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "141",
      "BeneficiaryID": 7581,
      "BeneficiaryName": "Прокма-Телеком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "142",
      "BeneficiaryID": 6659,
      "BeneficiaryName": "Просервис – оплата доступа в интернет",
      "BeneficiaryIcon": "5165_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "143",
      "BeneficiaryID": 183,
      "BeneficiaryName": "Простор Телеком",
      "BeneficiaryIcon": "prostortelecom_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "144",
      "BeneficiaryID": 6160,
      "BeneficiaryName": "Псковлайн - доступ в Интернет",
      "BeneficiaryIcon": "519_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "145",
      "BeneficiaryID": 465,
      "BeneficiaryName": "Радиотрансляционная сеть Санкт-Петербурга",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "146",
      "BeneficiaryID": 6568,
      "BeneficiaryName": "Радуга NET (Ставропольские коммуникации) – оплата услуг",
      "BeneficiaryIcon": "5030_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "147",
      "BeneficiaryID": 6567,
      "BeneficiaryName": "Радуга NET (СтавСвязьИнформ)  – оплата услуг",
      "BeneficiaryIcon": "5029_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "148",
      "BeneficiaryID": 647,
      "BeneficiaryName": "РайON (Владимирские локальные сети)",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "149",
      "BeneficiaryID": 218,
      "BeneficiaryName": "РБК хостинг",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "150",
      "BeneficiaryID": 720,
      "BeneficiaryName": "Регистратор Р01",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "151",
      "BeneficiaryID": 7546,
      "BeneficiaryName": "Ростелеком - плати просто - оплата по номеру лицевого счета",
      "BeneficiaryIcon": "308_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "152",
      "BeneficiaryID": 7545,
      "BeneficiaryName": "Ростелеком - плати просто - оплата по номеру телефона",
      "BeneficiaryIcon": "308_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "153",
      "BeneficiaryID": 728,
      "BeneficiaryName": "Ростелеком МРФ Урал",
      "BeneficiaryIcon": "308_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "154",
      "BeneficiaryID": 6253,
      "BeneficiaryName": "Ростелеком/Твое TV",
      "BeneficiaryIcon": "308_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "155",
      "BeneficiaryID": 6653,
      "BeneficiaryName": "Ростелеком/Твой Интернет",
      "BeneficiaryIcon": "308_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "156",
      "BeneficiaryID": 6532,
      "BeneficiaryName": "Ростелеком/Твой телефон Санкт-Петербург (межгород) – оплата услуг",
      "BeneficiaryIcon": "308_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "157",
      "BeneficiaryID": 6533,
      "BeneficiaryName": "Ростелеком/Твой телефон Санкт-Петербург (местный) – оплата услуг",
      "BeneficiaryIcon": "308_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "158",
      "BeneficiaryID": 6668,
      "BeneficiaryName": "РТС Телеком – оплата доступа в интернет",
      "BeneficiaryIcon": "5174_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "159",
      "BeneficiaryID": 459,
      "BeneficiaryName": "РЦТК",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "160",
      "BeneficiaryID": 414,
      "BeneficiaryName": "Рэдком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "161",
      "BeneficiaryID": 646,
      "BeneficiaryName": "Сампо.ру",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "162",
      "BeneficiaryID": 878,
      "BeneficiaryName": "Саха Спринт Сеть",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "163",
      "BeneficiaryID": 412,
      "BeneficiaryName": "Связьинформ Орел",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "164",
      "BeneficiaryID": 593,
      "BeneficiaryName": "Северсвязь",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "165",
      "BeneficiaryID": 211,
      "BeneficiaryName": "Сибирские Сети",
      "BeneficiaryIcon": "sibseti_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "166",
      "BeneficiaryID": 7553,
      "BeneficiaryName": "Сигнал",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 1
    },
    {
      "$id": "167",
      "BeneficiaryID": 500,
      "BeneficiaryName": "СИПСТРИМ",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "168",
      "BeneficiaryID": 306,
      "BeneficiaryName": "Ситилинк (Карелия)",
      "BeneficiaryIcon": "citilink_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "169",
      "BeneficiaryID": 283,
      "BeneficiaryName": "Скайнет (Санкт Петербург)",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "170",
      "BeneficiaryID": 223,
      "BeneficiaryName": "Смайл",
      "BeneficiaryIcon": "smile_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "171",
      "BeneficiaryID": 6180,
      "BeneficiaryName": "Старт Телеком - Интернет",
      "BeneficiaryIcon": "561_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "172",
      "BeneficiaryID": 6121,
      "BeneficiaryName": "Стартелеком Северо-Запад - городская связь",
      "BeneficiaryIcon": "405_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "173",
      "BeneficiaryID": 6175,
      "BeneficiaryName": "Стартелеком Северо-Запад - Интернет",
      "BeneficiaryIcon": "553_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "174",
      "BeneficiaryID": 6105,
      "BeneficiaryName": "Стартелеком Северо-Запад - междугородная/международная связь",
      "BeneficiaryIcon": "304_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "175",
      "BeneficiaryID": 374,
      "BeneficiaryName": "Стелстелеком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "176",
      "BeneficiaryID": 592,
      "BeneficiaryName": "Стрела Телеком (Вариант-TV)",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "177",
      "BeneficiaryID": 375,
      "BeneficiaryName": "Сумма Телеком (SUMTEL)",
      "BeneficiaryIcon": "summatel_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "178",
      "BeneficiaryID": 604,
      "BeneficiaryName": "Таттелеком (Wi-Fi)",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "179",
      "BeneficiaryID": 6248,
      "BeneficiaryName": "ТВ-Медиа",
      "BeneficiaryIcon": "816_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "180",
      "BeneficiaryID": 275,
      "BeneficiaryName": "ТВОЙнэт",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "181",
      "BeneficiaryID": 505,
      "BeneficiaryName": "ТДС+ (Технологии Домовых Сетей)",
      "BeneficiaryIcon": "tdsplus_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "182",
      "BeneficiaryID": 810,
      "BeneficiaryName": "Теле.ру",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "183",
      "BeneficiaryID": 6679,
      "BeneficiaryName": "Телекарта",
      "BeneficiaryIcon": "5187_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "184",
      "BeneficiaryID": 7572,
      "BeneficiaryName": "Телеконика",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "185",
      "BeneficiaryID": 812,
      "BeneficiaryName": "Телесервис-плюс",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "186",
      "BeneficiaryID": 6197,
      "BeneficiaryName": "Телесети",
      "BeneficiaryIcon": "596_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "187",
      "BeneficiaryID": 6199,
      "BeneficiaryName": "Телесети плюс",
      "BeneficiaryIcon": "598_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "188",
      "BeneficiaryID": 6515,
      "BeneficiaryName": "ТЕЛКО – оплата IP-телефонии",
      "BeneficiaryIcon": "4015_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "189",
      "BeneficiaryID": 6186,
      "BeneficiaryName": "ТЕЛКО – оплата услуг",
      "BeneficiaryIcon": "576_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "190",
      "BeneficiaryID": 6516,
      "BeneficiaryName": "ТЕЛКО – оплата услуг городской связи",
      "BeneficiaryIcon": "4016_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "191",
      "BeneficiaryID": 6638,
      "BeneficiaryName": "Тема Телеком",
      "BeneficiaryIcon": "5137_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "192",
      "BeneficiaryID": 6192,
      "BeneficiaryName": "Терабита",
      "BeneficiaryIcon": "588_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "193",
      "BeneficiaryID": 7591,
      "BeneficiaryName": "ТИС-Диалог. Калининград. Интернет",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "194",
      "BeneficiaryID": 7590,
      "BeneficiaryName": "ТИС-Диалог. Калининград. Телефония",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "195",
      "BeneficiaryID": 6602,
      "BeneficiaryName": "ТКС НЕВА – оплата доступа в интернет",
      "BeneficiaryIcon": "5073_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "196",
      "BeneficiaryID": 116,
      "BeneficiaryName": "Томтел",
      "BeneficiaryIcon": "tomtel_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "197",
      "BeneficiaryID": 239,
      "BeneficiaryName": "Транком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "198",
      "BeneficiaryID": 6240,
      "BeneficiaryName": "Триколор ТВ",
      "BeneficiaryIcon": "801_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "199",
      "BeneficiaryID": 6624,
      "BeneficiaryName": "УГМК-Телеком",
      "BeneficiaryIcon": "5119_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "200",
      "BeneficiaryID": 733,
      "BeneficiaryName": "Умные сети",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "201",
      "BeneficiaryID": 188,
      "BeneficiaryName": "ФОНЕКОМ",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "202",
      "BeneficiaryID": 6572,
      "BeneficiaryName": "Формула Связи – оплата услуг",
      "BeneficiaryIcon": "5036_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "203",
      "BeneficiaryID": 6166,
      "BeneficiaryName": "Хостинг-Центр",
      "BeneficiaryIcon": "536_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "204",
      "BeneficiaryID": 6551,
      "BeneficiaryName": "Центральный телеграф",
      "BeneficiaryIcon": "581_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "205",
      "BeneficiaryID": 6163,
      "BeneficiaryName": "Цифра Один",
      "BeneficiaryIcon": "531_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "206",
      "BeneficiaryID": 770,
      "BeneficiaryName": "Эконотел",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "207",
      "BeneficiaryID": 809,
      "BeneficiaryName": "Экотелеком",
      "BeneficiaryIcon": "ecotelecom_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "208",
      "BeneficiaryID": 765,
      "BeneficiaryName": "Экспресс сеть",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "209",
      "BeneficiaryID": 7585,
      "BeneficiaryName": "ЭСОТЕЛ-Рустелком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "210",
      "BeneficiaryID": 6189,
      "BeneficiaryName": "ЭТ ХОУМ",
      "BeneficiaryIcon": "582_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "211",
      "BeneficiaryID": 204,
      "BeneficiaryName": "Южный Телеком",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "212",
      "BeneficiaryID": 560,
      "BeneficiaryName": "-Кузбасские телефонные сети ",
      "BeneficiaryIcon": "kts_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "213",
      "BeneficiaryID": 82,
      "BeneficiaryName": "2 Ком",
      "BeneficiaryIcon": "2kom_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "214",
      "BeneficiaryID": 84,
      "BeneficiaryName": "airNet",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "215",
      "BeneficiaryID": 6786,
      "BeneficiaryName": "Albeon – оплата интернет услуг",
      "BeneficiaryIcon": "5600_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "216",
      "BeneficiaryID": 6133,
      "BeneficiaryName": "Apollophone",
      "BeneficiaryIcon": "431_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "217",
      "BeneficiaryID": 6581,
      "BeneficiaryName": "Arbital – оплата доступа в интернет",
      "BeneficiaryIcon": "5047_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "218",
      "BeneficiaryID": 6148,
      "BeneficiaryName": "ART-TEL",
      "BeneficiaryIcon": "476_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "219",
      "BeneficiaryID": 6600,
      "BeneficiaryName": "Astra telecom – оплата доступа в интернет",
      "BeneficiaryIcon": "5069_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "220",
      "BeneficiaryID": 6578,
      "BeneficiaryName": "Avanti Web Services – оплата доступа в интернет",
      "BeneficiaryIcon": "5044_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "221",
      "BeneficiaryID": 6620,
      "BeneficiaryName": "Avtovo.net – оплата доступа в интернет",
      "BeneficiaryIcon": "5111_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "222",
      "BeneficiaryID": 6725,
      "BeneficiaryName": "Butovo.com",
      "BeneficiaryIcon": "5246_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "223",
      "BeneficiaryID": 138,
      "BeneficiaryName": "Byte City (ООО \"Байт Сити\") ",
      "BeneficiaryIcon": "baytsity_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "224",
      "BeneficiaryID": 6108,
      "BeneficiaryName": "Cardtel",
      "BeneficiaryIcon": "325_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "225",
      "BeneficiaryID": 6662,
      "BeneficiaryName": "CityTelecom.ru – оплата доступа в интернет",
      "BeneficiaryIcon": "5168_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "226",
      "BeneficiaryID": 6639,
      "BeneficiaryName": "Comlink Telecom – оплата доступа в интернет",
      "BeneficiaryIcon": "5140_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "227",
      "BeneficiaryID": 6673,
      "BeneficiaryName": "Convex – оплата услуг",
      "BeneficiaryIcon": "5181_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "228",
      "BeneficiaryID": 6640,
      "BeneficiaryName": "Country Online – оплата доступа в интернет",
      "BeneficiaryIcon": "5141_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "229",
      "BeneficiaryID": 6591,
      "BeneficiaryName": "Cyber.net – оплата доступа в интернет",
      "BeneficiaryIcon": "5059_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "230",
      "BeneficiaryID": 6649,
      "BeneficiaryName": "Diamond Network (Сланцы) – оплата доступа в интернет",
      "BeneficiaryIcon": "5151_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "231",
      "BeneficiaryID": 6674,
      "BeneficiaryName": "Elsite – оплата услуг",
      "BeneficiaryIcon": "5182_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "232",
      "BeneficiaryID": 6585,
      "BeneficiaryName": "ETelecom – оплата доступа в интернет",
      "BeneficiaryIcon": "5052_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "233",
      "BeneficiaryID": 6676,
      "BeneficiaryName": "Etherway – оплата услуг",
      "BeneficiaryIcon": "5184_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "234",
      "BeneficiaryID": 6641,
      "BeneficiaryName": "evrotelecom (Санкт-Петербург) – оплата доступа в интернет",
      "BeneficiaryIcon": "5142_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "235",
      "BeneficiaryID": 7551,
      "BeneficiaryName": "Flex",
      "BeneficiaryIcon": "flex_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "236",
      "BeneficiaryID": 6760,
      "BeneficiaryName": "Freedom - оплата Интернета и ТВ",
      "BeneficiaryIcon": "freedom_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "237",
      "BeneficiaryID": 602,
      "BeneficiaryName": "FREELINE",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "238",
      "BeneficiaryID": 618,
      "BeneficiaryName": "Freshtel (Столица)",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "239",
      "BeneficiaryID": 7565,
      "BeneficiaryName": "Fryazino.Net (Интернет)",
      "BeneficiaryIcon": "fryazinonet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "240",
      "BeneficiaryID": 6642,
      "BeneficiaryName": "GATCHINA ONLINE – оплата доступа в интернет",
      "BeneficiaryIcon": "5143_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "241",
      "BeneficiaryID": 94,
      "BeneficiaryName": "Goodline",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "242",
      "BeneficiaryID": 6627,
      "BeneficiaryName": "HeliosNet – оплата доступа в интернет",
      "BeneficiaryIcon": "5122_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "243",
      "BeneficiaryID": 96,
      "BeneficiaryName": "HOME NET TELECOM",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "244",
      "BeneficiaryID": 6628,
      "BeneficiaryName": "Homenet – оплата доступа в интернет",
      "BeneficiaryIcon": "5124_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "245",
      "BeneficiaryID": 6209,
      "BeneficiaryName": "Hoster.ru – оплата хостинга",
      "BeneficiaryIcon": "722_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "246",
      "BeneficiaryID": 6586,
      "BeneficiaryName": "Ilan – оплата доступа в интернет",
      "BeneficiaryIcon": "5054_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "247",
      "BeneficiaryID": 6589,
      "BeneficiaryName": "Info-Lan – оплата доступа в интернет",
      "BeneficiaryIcon": "5057_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "248",
      "BeneficiaryID": 7503,
      "BeneficiaryName": "InterZet (ДОМ.RU) Домашний телефон (Санкт Петербург)",
      "BeneficiaryIcon": "840_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "249",
      "BeneficiaryID": 7473,
      "BeneficiaryName": "InterZet (ДОМ.RU) Интернет (Санкт Петербург)",
      "BeneficiaryIcon": "840_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "250",
      "BeneficiaryID": 7492,
      "BeneficiaryName": "InterZet (ДОМ.RU) Кабельное ТВ (Санкт Петербург)",
      "BeneficiaryIcon": "840_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "251",
      "BeneficiaryID": 7475,
      "BeneficiaryName": "InterZet (ДОМ.RU) Комплексное предложение (Санкт Петербург) - оплата Интернета, ТВ и телефонной связи",
      "BeneficiaryIcon": "840_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "252",
      "BeneficiaryID": 7493,
      "BeneficiaryName": "InterZet (ДОМ.RU) Цифровое кабельное ТВ (Санкт Петербург)",
      "BeneficiaryIcon": "840_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "253",
      "BeneficiaryID": 6793,
      "BeneficiaryName": "InTv",
      "BeneficiaryIcon": "5612_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "254",
      "BeneficiaryID": 101,
      "BeneficiaryName": "IP CITY",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "255",
      "BeneficiaryID": 7564,
      "BeneficiaryName": "IP HOME.NET",
      "BeneficiaryIcon": "iphomenet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "256",
      "BeneficiaryID": 6609,
      "BeneficiaryName": "IP Teleinterphone – оплата доступа в интернет",
      "BeneficiaryIcon": "5082_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "257",
      "BeneficiaryID": 6596,
      "BeneficiaryName": "ISP MEDIA GROUP – оплата доступа в интернет",
      "BeneficiaryIcon": "5065_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "258",
      "BeneficiaryID": 99,
      "BeneficiaryName": "IZET (Великий Новгород)",
      "BeneficiaryIcon": "5095_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "259",
      "BeneficiaryID": 100,
      "BeneficiaryName": "IZET (Челябинск)",
      "BeneficiaryIcon": "5095_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "260",
      "BeneficiaryID": 6614,
      "BeneficiaryName": "IZET (Череповец)",
      "BeneficiaryIcon": "5095_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "261",
      "BeneficiaryID": 97,
      "BeneficiaryName": "IZET (Ярославль)",
      "BeneficiaryIcon": "5095_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "262",
      "BeneficiaryID": 6618,
      "BeneficiaryName": "KolpinoNet – оплата доступа в интернет",
      "BeneficiaryIcon": "5103_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "263",
      "BeneficiaryID": 312,
      "BeneficiaryName": "Koptevo.Net",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "264",
      "BeneficiaryID": 296,
      "BeneficiaryName": "LanTa",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "265",
      "BeneficiaryID": 6594,
      "BeneficiaryName": "Maxhost – оплата доступа в интернет",
      "BeneficiaryIcon": "5063_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "266",
      "BeneficiaryID": 6631,
      "BeneficiaryName": "MK-NET – оплата услуг",
      "BeneficiaryIcon": "5128_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "267",
      "BeneficiaryID": 6655,
      "BeneficiaryName": "MNS.ru – оплата услуг",
      "BeneficiaryIcon": "5160_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "268",
      "BeneficiaryID": 6643,
      "BeneficiaryName": "MOSNET – оплата доступа в интернет",
      "BeneficiaryIcon": "5144_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "269",
      "BeneficiaryID": 6644,
      "BeneficiaryName": "MSNet – оплата доступа в интернет",
      "BeneficiaryIcon": "5145_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "270",
      "BeneficiaryID": 6151,
      "BeneficiaryName": "MultiHOST",
      "BeneficiaryIcon": "501_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "271",
      "BeneficiaryID": 6195,
      "BeneficiaryName": "MYBOX",
      "BeneficiaryIcon": "592_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "272",
      "BeneficiaryID": 6629,
      "BeneficiaryName": "myLAN – оплата доступа в интернет",
      "BeneficiaryIcon": "5126_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "273",
      "BeneficiaryID": 104,
      "BeneficiaryName": "NetByNet (Москва)",
      "BeneficiaryIcon": "507_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "274",
      "BeneficiaryID": 106,
      "BeneficiaryName": "NetByNet (Регионы)",
      "BeneficiaryIcon": "507_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "275",
      "BeneficiaryID": 6598,
      "BeneficiaryName": "Nevacom – оплата доступа в интернет",
      "BeneficiaryIcon": "5067_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "276",
      "BeneficiaryID": 389,
      "BeneficiaryName": "NewLine",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "277",
      "BeneficiaryID": 6630,
      "BeneficiaryName": "NEWLink – оплата доступа в интернет",
      "BeneficiaryIcon": "5127_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "278",
      "BeneficiaryID": 718,
      "BeneficiaryName": "OngNET",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "279",
      "BeneficiaryID": 607,
      "BeneficiaryName": "OnLime(ОнЛайм)",
      "BeneficiaryIcon": "3487_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "280",
      "BeneficiaryID": 803,
      "BeneficiaryName": "PETERHOST",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "281",
      "BeneficiaryID": 6657,
      "BeneficiaryName": "PIN (Петербургская Интернет Сеть) – оплата доступа в интернет",
      "BeneficiaryIcon": "5163_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "282",
      "BeneficiaryID": 6652,
      "BeneficiaryName": "Prudok – оплата услуг",
      "BeneficiaryIcon": "5154_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "283",
      "BeneficiaryID": 6182,
      "BeneficiaryName": "QWERTY",
      "BeneficiaryIcon": "568_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "284",
      "BeneficiaryID": 6632,
      "BeneficiaryName": "REDSTAR – оплата доступа в интернет",
      "BeneficiaryIcon": "5131_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "285",
      "BeneficiaryID": 6203,
      "BeneficiaryName": "REG.RU",
      "BeneficiaryIcon": "705_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "286",
      "BeneficiaryID": 7588,
      "BeneficiaryName": "RialCom (РиалКом)",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "287",
      "BeneficiaryID": 6773,
      "BeneficiaryName": "RiNet",
      "BeneficiaryIcon": "rinetmelkiy_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "288",
      "BeneficiaryID": 6576,
      "BeneficiaryName": "RNet – оплата доступа в интернет",
      "BeneficiaryIcon": "5042_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "289",
      "BeneficiaryID": 6570,
      "BeneficiaryName": "ROADNET – оплата услуг",
      "BeneficiaryIcon": "5034_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "290",
      "BeneficiaryID": 6158,
      "BeneficiaryName": "RU-CENTER",
      "BeneficiaryIcon": "512_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "291",
      "BeneficiaryID": 7573,
      "BeneficiaryName": "SamaraLan",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "292",
      "BeneficiaryID": 6753,
      "BeneficiaryName": "SatNet",
      "BeneficiaryIcon": "satnet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "293",
      "BeneficiaryID": 6605,
      "BeneficiaryName": "Selectel – оплата телематических услуг связи",
      "BeneficiaryIcon": "5078_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "294",
      "BeneficiaryID": 3759,
      "BeneficiaryName": "SevenSky",
      "BeneficiaryIcon": "sevensky_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "295",
      "BeneficiaryID": 112,
      "BeneficiaryName": "Shelkovo-Net",
      "BeneficiaryIcon": "shelkovonet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "296",
      "BeneficiaryID": 6104,
      "BeneficiaryName": "SIPNET",
      "BeneficiaryIcon": "303_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "297",
      "BeneficiaryID": 6612,
      "BeneficiaryName": "SkyLine-WiMAX",
      "BeneficiaryIcon": "5092_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "298",
      "BeneficiaryID": 7455,
      "BeneficiaryName": "SkyNet (Санкт-Петербург)",
      "BeneficiaryIcon": "skynet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "299",
      "BeneficiaryID": 6607,
      "BeneficiaryName": "Smart telecom – оплата доступа в интернет",
      "BeneficiaryIcon": "5080_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "300",
      "BeneficiaryID": 6645,
      "BeneficiaryName": "SpaceWeb - оплата хостинга",
      "BeneficiaryIcon": "5146_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "301",
      "BeneficiaryID": 6588,
      "BeneficiaryName": "SPBINFINITY – оплата доступа в интернет",
      "BeneficiaryIcon": "5056_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "302",
      "BeneficiaryID": 6635,
      "BeneficiaryName": "SPLine – оплата доступа в интернет",
      "BeneficiaryIcon": "5134_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "303",
      "BeneficiaryID": 6634,
      "BeneficiaryName": "STARNET – оплата доступа в интернет",
      "BeneficiaryIcon": "5133_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "304",
      "BeneficiaryID": 6608,
      "BeneficiaryName": "TeleGlobal – оплата доступа в интернет",
      "BeneficiaryIcon": "5081_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "305",
      "BeneficiaryID": 6661,
      "BeneficiaryName": "TEYLA – оплата доступа в интернет",
      "BeneficiaryIcon": "5167_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "306",
      "BeneficiaryID": 6575,
      "BeneficiaryName": "Tiera – оплата услуг",
      "BeneficiaryIcon": "5041_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "307",
      "BeneficiaryID": 457,
      "BeneficiaryName": "TomICA",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "308",
      "BeneficiaryID": 7562,
      "BeneficiaryName": "tv.fryazino.net",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "309",
      "BeneficiaryID": 6574,
      "BeneficiaryName": "U-LAN  – оплата услуг",
      "BeneficiaryIcon": "5040_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "310",
      "BeneficiaryID": 6561,
      "BeneficiaryName": "UfaNet Ишимбай",
      "BeneficiaryIcon": "581_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "311",
      "BeneficiaryID": 6559,
      "BeneficiaryName": "UfaNet Нефтекамск ",
      "BeneficiaryIcon": "581_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "312",
      "BeneficiaryID": 6558,
      "BeneficiaryName": "UfaNet Октябрьский",
      "BeneficiaryIcon": "581_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "313",
      "BeneficiaryID": 6564,
      "BeneficiaryName": "UfaNet Оренбург",
      "BeneficiaryIcon": "581_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "314",
      "BeneficiaryID": 6563,
      "BeneficiaryName": "UfaNet Орск - оплата Интернета и ТВ",
      "BeneficiaryIcon": "581_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "315",
      "BeneficiaryID": 6562,
      "BeneficiaryName": "UfaNet Салават",
      "BeneficiaryIcon": "581_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "316",
      "BeneficiaryID": 6560,
      "BeneficiaryName": "UfaNet Стерлитамак",
      "BeneficiaryIcon": "581_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "317",
      "BeneficiaryID": 6188,
      "BeneficiaryName": "UfaNet Уфа",
      "BeneficiaryIcon": "581_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "318",
      "BeneficiaryID": 117,
      "BeneficiaryName": "UNET",
      "BeneficiaryIcon": "unet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "319",
      "BeneficiaryID": 6611,
      "BeneficiaryName": "Unis – оплата доступа в интернет",
      "BeneficiaryIcon": "5087_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "320",
      "BeneficiaryID": 6636,
      "BeneficiaryName": "ValueHost – оплата хостинга",
      "BeneficiaryIcon": "5135_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "321",
      "BeneficiaryID": 6619,
      "BeneficiaryName": "VASH.NET – оплата доступа в интернет",
      "BeneficiaryIcon": "5106_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "322",
      "BeneficiaryID": 6584,
      "BeneficiaryName": "Viartcom – оплата доступа в интернет",
      "BeneficiaryIcon": "5050_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "323",
      "BeneficiaryID": 205,
      "BeneficiaryName": "VladLink",
      "BeneficiaryIcon": "vladlink_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "324",
      "BeneficiaryID": 6606,
      "BeneficiaryName": "Weba – оплата доступа в интернет",
      "BeneficiaryIcon": "5079_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "325",
      "BeneficiaryID": 6784,
      "BeneficiaryName": "WestCall",
      "BeneficiaryIcon": "5597_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "326",
      "BeneficiaryID": 1561,
      "BeneficiaryName": "WestCall Рязань",
      "BeneficiaryIcon": "inet_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "327",
      "BeneficiaryID": 434,
      "BeneficiaryName": "WinNER",
      "BeneficiaryIcon": "winner_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "328",
      "BeneficiaryID": 6787,
      "BeneficiaryName": "WiTe – оплата интернет услуг",
      "BeneficiaryIcon": "5601_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "329",
      "BeneficiaryID": 6168,
      "BeneficiaryName": "Yota - пополнение лицевого счета",
      "BeneficiaryIcon": "541_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "330",
      "BeneficiaryID": 6503,
      "BeneficiaryName": "YOUMAGIC",
      "BeneficiaryIcon": "3000_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    },
    {
      "$id": "331",
      "BeneficiaryID": 6582,
      "BeneficiaryName": "YourNet – оплата доступа в интернет",
      "BeneficiaryIcon": "5048_ibs_800x600.png",
      "CategoryID": 2,
      "CategoryName": "Интернет, ТВ, телефон",
      "ProcessPaymentType": 0
    }
  ],
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
