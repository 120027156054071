{
  "Result": {
    "$id": "2",
    "ServerDate": "2024-04-18T00:00:00+03:00",
    "ClientReferenceInfo": [
      {
        "$id": "3",
        "ContractStatus": 1,
        "Colour": 1,
        "DisplayOrder": 1,
        "ReferenceParams": {
          "$id": "4",
          "RefCode": 1,
          "IsDebit": false,
          "RefName": "Справка о полном погашении задолженности",
          "RefProductName": "Кредит на товар 21 624.00 ",
          "ContractNumber": "2288331322",
          "CreateDateTime": "27.03.2024",
          "RefNumber": "123"
        },
        "RefImageUrl": "https://myc-test-f5.homecredit.ru/rt/api/Images/Reference/Reference_1.png",
        "IsAutogenRef": true,
        "IsChatOnly": false,
        "ProductType": 1,
        "ProductTag": "Кредит 23 000",
        "RefHeadlineName": "Кредит 21 624.00 "
      },
      {
        "$id": "5",
        "ContractStatus": 1,
        "Colour": 2,
        "DisplayOrder": 2,
        "ReferenceParams": {
          "$id": "6",
          "RefCode": 1,
          "IsDebit": false,
          "RefName": "Справка о полном погашении задолженности",
          "RefProductName": "Кредит на товар 7 545.00 ",
          "ContractNumber": "2880785860",
          "CreateDateTime": "27.03.2024",
          "RefNumber": "124"
        },
        "RefImageUrl": "https://myc-test-f5.homecredit.ru/rt/api/Images/Reference/Reference_1.png",
        "IsAutogenRef": true,
        "IsChatOnly": false,
        "ProductType": 2,
        "ProductTag": "Кредит 23 000",
        "RefHeadlineName": "Кредит 7 545.00 "
      },
      {
        "$id": "7",
        "ContractStatus": 1,
        "Colour": 3,
        "DisplayOrder": 1,
        "ReferenceParams": {
          "$id": "8",
          "RefCode": 4,
          "IsDebit": false,
          "RefName": "Справка о своевременном погашении задолженности",
          "RefProductName": "Кредит на товар 21 624.00 ",
          "ContractNumber": "2288331322",
          "CreateDateTime": "27.03.2024",
          "RefNumber": "125"
        },
        "RefImageUrl": "https://myc-test-f5.homecredit.ru/rt/api/Images/Reference/Reference_2.png",
        "IsAutogenRef": true,
        "IsChatOnly": false,
        "ProductType": 3,
        "ProductTag": "Кредит 23 000",
        "RefHeadlineName": "Кредит 21 624.00 "
      }
    ]
  }
}
