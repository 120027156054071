import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Button } from '@platform-ui/components/Button'
import React from 'react'
import { Title, TitleContainer } from './styled'

export const PayListModal = (): JSX.Element => {
  const context = useAppContext<AppContext>()

  return (
    <>
      <TitleContainer>
        <Title>Pay List Modal</Title>
      </TitleContainer>
      <Button
        size="m"
        onClick={() =>
          context.eventBus.payListModal.open.emit({
            id: 'CARD ID 1234125123123',
          })
        }
      >
        Modal Pay List
      </Button>
    </>
  )
}
