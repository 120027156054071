import { Storage } from '@/core'
import { localStorage } from '../localStorage'
import { AdmincacheModel } from './AdmincacheModel'

export const admincache = new Storage<AdmincacheModel.State>({
  name: 'admincache',
  storage: localStorage,
  version: 5,
  defaultValue: {},
})
