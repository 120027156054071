import { createMocks } from '@/features/developer/utils/createMocks'
import success from './success.json'
import error from './error.json'
import errorNoValid from './error-no-valid.json'

export const mockItems = createMocks([
  {
    title: 'Успешно',
    status: 200,
    data: success,
    description: '',
  },
  {
    title: 'Не валидный код',
    status: 200,
    data: errorNoValid,
    description: '',
  },
  {
    title: 'Ошибка 404',
    status: 404,
    data: error,
    description: '',
  },
  {
    title: 'Код 500',
    status: 500,
    data: null,
    description: '',
  },
])
