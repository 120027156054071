{
  "$id": "1",
  "Result": {
    "$id": "2",
    "PaymentNum": 15,
    "Detail": [
      {
        "$id": "3",
        "InstalmentNumber": 1,
        "DueDateTicks": 638345664000000000,
        "DueDate": "2023-11-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 4,
        "DebtMain": 4451.88,
        "InterestSum": 925.91,
        "ServiceAmount": 0.0
      },
      {
        "$id": "4",
        "InstalmentNumber": 2,
        "DueDateTicks": 638371584000000000,
        "DueDate": "2023-12-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 4536.27,
        "InterestSum": 841.52,
        "ServiceAmount": 0.0
      },
      {
        "$id": "5",
        "InstalmentNumber": 3,
        "DueDateTicks": 638398368000000000,
        "DueDate": "2024-01-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 4565.83,
        "InterestSum": 811.96,
        "ServiceAmount": 0.0
      },
      {
        "$id": "6",
        "InstalmentNumber": 4,
        "DueDateTicks": 638425152000000000,
        "DueDate": "2024-02-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 4625.46,
        "InterestSum": 752.33,
        "ServiceAmount": 0.0
      },
      {
        "$id": "7",
        "InstalmentNumber": 5,
        "DueDateTicks": 638450208000000000,
        "DueDate": "2024-03-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 4728.61,
        "InterestSum": 649.18,
        "ServiceAmount": 0.0
      },
      {
        "$id": "8",
        "InstalmentNumber": 6,
        "DueDateTicks": 638476992000000000,
        "DueDate": "2024-04-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 4743.51,
        "InterestSum": 634.28,
        "ServiceAmount": 0.0
      },
      {
        "$id": "9",
        "InstalmentNumber": 7,
        "DueDateTicks": 638502912000000000,
        "DueDate": "2024-05-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 4821.9,
        "InterestSum": 555.89,
        "ServiceAmount": 0.0
      },
      {
        "$id": "10",
        "InstalmentNumber": 8,
        "DueDateTicks": 638529696000000000,
        "DueDate": "2024-06-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 4864.23,
        "InterestSum": 513.56,
        "ServiceAmount": 0.0
      },
      {
        "$id": "11",
        "InstalmentNumber": 9,
        "DueDateTicks": 638555616000000000,
        "DueDate": "2024-07-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 4940.2,
        "InterestSum": 437.59,
        "ServiceAmount": 0.0
      },
      {
        "$id": "12",
        "InstalmentNumber": 10,
        "DueDateTicks": 638582400000000000,
        "DueDate": "2024-08-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 4987.96,
        "InterestSum": 389.83,
        "ServiceAmount": 0.0
      },
      {
        "$id": "13",
        "InstalmentNumber": 11,
        "DueDateTicks": 638609184000000000,
        "DueDate": "2024-09-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 5050.91,
        "InterestSum": 326.88,
        "ServiceAmount": 0.0
      },
      {
        "$id": "14",
        "InstalmentNumber": 12,
        "DueDateTicks": 638635104000000000,
        "DueDate": "2024-10-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 5123.14,
        "InterestSum": 254.65,
        "ServiceAmount": 0.0
      },
      {
        "$id": "15",
        "InstalmentNumber": 13,
        "DueDateTicks": 638661888000000000,
        "DueDate": "2024-11-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 5179.31,
        "InterestSum": 198.48,
        "ServiceAmount": 0.0
      },
      {
        "$id": "16",
        "InstalmentNumber": 14,
        "DueDateTicks": 638687808000000000,
        "DueDate": "2024-12-03T00:00:00+03:00",
        "InstalmentAmount": 5377.79,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 5248.97,
        "InterestSum": 128.82,
        "ServiceAmount": 0.0
      },
      {
        "$id": "17",
        "InstalmentNumber": 15,
        "DueDateTicks": 638714592000000000,
        "DueDate": "2025-01-03T00:00:00+03:00",
        "InstalmentAmount": 5365.71,
        "DatePaidTicks": 0,
        "DatePaid": "0001-01-01T00:00:00",
        "AmountPaid": 0.0,
        "InstalmentType": 1,
        "InstalmentStatus": 3,
        "DebtMain": 5298.82,
        "InterestSum": 66.89,
        "ServiceAmount": 0.0
      }
    ],
    "Debt": {
      "$id": "18",
      "OutstandingDebtAmount": 0.0,
      "PenaltyAmount": 0.0,
      "TotalDebtAmount": 5377.79
    }
  },
  "StatusCode": 200,
  "Errors": [],
  "ErrorDetails": []
}
