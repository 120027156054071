{
  "resultCode": "MISMATCH",
  "resultText": "СМС-код введен неверно. Попробуйте еще раз",
  "resendNum": 2,
  "verifyNum": 2,
  "_links": {
    "check": {
      "href": "https://ocelot-api-gateway-ft.omni.homecredit.ru/digital-card/v1/cardinfo/2d2d8b46-b26a-4df5-beda-e9cecb182997/check"
    }
  }
}
