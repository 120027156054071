{
  "cvv": null,
  "resultCode": "BLOCKED",
  "resultText": "В целях вашей безопасности просмотр данных карты ограничен на 59 минут",
  "pan": null,
  "cardHolder": null,
  "expireDate": null,
  "resendNum": 0,
  "verifyNum": 0,
  "links": null
}
