{
  "phone": "+7 (***) ***-23-16",
  "smsCheck": true,
  "links": [
    {
      "rel": "check",
      "href": "https://ocelot-api-gateway-ft.omni.homecredit.ru/visa-phone-transfer/v1/58edecd6-7acf-4c14-98e0-59d599183015/check"
    },
    {
      "rel": "resend",
      "href": "https://ocelot-api-gateway-ft.omni.homecredit.ru/visa-phone-transfer/v1/58edecd6-7acf-4c14-98e0-59d599183015/resend"
    }
  ]
}
