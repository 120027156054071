import { makeAutoObservable } from 'mobx'
import { DeveloperStore } from './DeveloperStore'

export interface SwitchStandState {
  activeStandId: string
}

export class SwitchStandStore {
  rules: {
    replace: RegExp
    value: (newValue: string) => (substring: string, ...args: any[]) => string
  }[] = [
    {
      replace: /(gateway\/)(.{0,4})(\/api)/i,
      value: (newValue: string) => (_, p1, p2, p3) => {
        return [p1, newValue, p3].join('')
      },
    },
    {
      replace: /(gateway-)(.{0,4})(\.omni)/i,
      value: (newValue: string) => (_, p1, p2, p3) => {
        return [p1, newValue, p3].join('')
      },
    },
  ]

  originActiveStandId: string | null = null
  activeStandId: string | null = null
  prevStandId: string | null = null

  private stands: {
    id: string
    name: string
    value: string
    rules?: {
      replace: RegExp
      value: (newValue: string) => (substring: string, ...args: any[]) => string
    }[]
  }[] = [
    {
      id: 'ft',
      name: 'ft',
      value: 'ft',
    },
    {
      id: 'rp',
      name: 'rp',
      value: 'rp',
    },
    {
      id: 'rt',
      name: 'rt',
      value: 'rt',
    },
    {
      id: 'fp',
      name: 'fp',
      value: 'fp',
    },
    {
      id: 'fsc',
      name: 'fsc',
      value: 'fsc',
    },
  ]

  constructor(private parent: DeveloperStore) {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  init(data: { activeStandId: string; originActiveStandId: string }) {
    this.originActiveStandId = data.originActiveStandId
    this.activeStandId = data.activeStandId
    this.prevStandId = data.originActiveStandId
  }

  reset() {
    this.activeStandId = this.originActiveStandId
  }

  setActiveStandId(id: string) {
    this.prevStandId = this.activeStandId
    this.activeStandId = id
  }

  replaceValue(value: string) {
    if (!value) {
      return value
    }
    const currentStand = this.stands.find((item) => item.id === this.activeStandId)
    if (!currentStand) {
      return
    }

    let newValue = value

    let newRules = [...this.rules]
    if (currentStand.rules) {
      newRules = newRules.concat(currentStand.rules)
    }

    newRules.forEach((item) => {
      newValue = newValue.replace(item.replace, item.value(currentStand.value))

      /**
       * В api новой авторизации стенд дублируется. Если его находим, то меняем на текущий
       */
      if (newValue.indexOf(`gateway/${this.prevStandId}`) >= 0) {
        newValue = newValue.replace(
          new RegExp(`gateway/${this.prevStandId}`, 'ig'),
          `gateway/${currentStand.value}`
        )
      }
    })

    return newValue
  }

  get standList(): { id: string; name: string; isActive: boolean }[] {
    return this.stands.map((item) => {
      return {
        id: item.id,
        isActive: item.id === this.activeStandId,
        name: item.name,
      }
    })
  }
}
